@let menuItems = $menuItems();
<ul class="layout-menu">
    <ng-container *ngFor="let menuItem of menuItems; let i = index;">
        <li app-menuitem *ngIf="!menuItem.separator" [item]="menuItem" [index]="i" [root]="true"></li>
        <li *ngIf="menuItem.separator" class="menu-separator"></li>
    </ng-container>
    <li>
        <a href="https://www.primefaces.org/primeblocks-ng/#/"></a>
    </li>
</ul>
