import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Warehouse} from '@model/warehouse';
import {WarehouseService} from '@services/warehouse.service';
import {WarehouseSearch} from "@model/search/warehouse-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {WarehouseEditComponent} from "@components/warehouse/warehouse-edit/warehouse-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";
import {UserSignalService} from "@services/user-signal.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DonationService} from "@services/donation.service";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {Organization} from "@model/organization";
import {ExcelService} from "@services/excel.service";
import {formatDate} from "@angular/common";
import {CheckboxModule} from "primeng/checkbox";

enum WarehouseColumn {
  id = 'id',
  name = 'name',
  address = 'address',
  zip = 'zip',
  city = 'city',
  email = 'email',
  active = 'active',
}

const COLUMN_LABELS: ColumnLabels<WarehouseColumn> = {
  id: 'Id',
  name: 'Name',
  address: 'Address',
  zip: 'Zip',
  city: 'City',
  email: 'Email',
  active: 'Active',
}

const FOODBANK_PREFERENCE_WAREHOUSES_LIST_COLUMNS = 'preference_warehouses_list_columns';

const ALL_COLUMNS: WarehouseColumn[] = Object.values(WarehouseColumn) as WarehouseColumn[];
const DEFAULT_COLUMNS: WarehouseColumn[] = [WarehouseColumn.id, WarehouseColumn.name, WarehouseColumn.address];

@Component({
  selector: 'foodbank-warehouses',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.scss'],
  providers: [DialogService, ExcelService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, ConfirmDialogModule, CheckboxModule,]
})
export class WarehouseListComponent {


  // this view search
  warehouseSearch: Signal<WarehouseSearch>;
  pagination: WritableSignal<Pagination>;
  filterActive = model(true);

  // results
  warehousePage: Resource<Page<Warehouse> | undefined>;


  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #warehouseService = inject(WarehouseService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #confirmationService = inject(ConfirmationService);
  #messageService = inject(MessageService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  #injector = inject(Injector);
// columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<WarehouseColumn[]>(FOODBANK_PREFERENCE_WAREHOUSES_LIST_COLUMNS, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;

    this.warehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: this.filterActive(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.warehousePage = rxResource({
      request: () => ({
        warehouseSearch: this.warehouseSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#warehouseService.findWarehouses$(this.warehouseSearch(), this.pagination())
    });

  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(warehouse: Warehouse): Warehouse {
    return warehouse;
  }

  openEditDialog(warehouse: Warehouse) {
    this.#dialogRef = this.#dialogService.open(WarehouseEditComponent, {
      header: 'Edit Warehouse',
      width: '40%',
      data: {...warehouse}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.warehousePage.reload()
    })
  }


  getAddress(warehouse: Warehouse) {
    const location = joinWhenPresent(" ", [warehouse.zip.toString(), warehouse.city]);
    return joinWhenPresent(" ,", [warehouse.address, location]);
  }

  getColumnLabel(column: WarehouseColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly WarehouseColumn = WarehouseColumn;

}



