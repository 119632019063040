import {Component, input, model} from '@angular/core';
import {SupplyMonth} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationDistribMonthComponent} from "@components/organization-distrib-month/organization-distrib-month.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-distrib-month-selection',
  imports: [
    DropdownModule,
    OrganizationDistribMonthComponent,
    FormsModule
  ],
  templateUrl: './organization-distrib-month-selection.component.html',
  styleUrl: './organization-distrib-month-selection.component.scss'
})
export class OrganizationDistribMonthSelectionComponent {
  ngModel = model<SupplyMonth>();
  required = input(false);

  organizationDistribMonths: SupplyMonth[];

  constructor() {

    this.organizationDistribMonths = [  "NONE" , "UNEVEN" , "EVEN" , "EVERY"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}

