import {Component, computed, inject, Injector, model, Signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Donation} from '@model/donation';
import {DonationService} from '@services/donation.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {DonorSelectionComponent} from "@components/donor/selection/donor-selection.component";
import {DonorSearch} from "@model/search/donor-search";
import {UserService} from "@services/user.service";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {UserSignalService} from "@services/user-signal.service";


@Component({
  selector: 'foodbank-donation-edit',
  templateUrl: './donation-edit.component.html',
  styleUrls: ['./donation-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, InputSwitchModule, Button, MessageModule, DropdownModule, DonorSelectionComponent]
})
export class DonationEditComponent {

  donation = model<Donation>();
  booIsCreate = false;
  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Donation>);
  #donationService = inject(DonationService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #auditChangeService = inject(AuditChangeService);
  #injector = inject(Injector);

  donorSearch: Signal<DonorSearch>;

  constructor() {
    this.donation.set(this.config.data);
    this.booIsCreate = this.donation()?.dateDonation === undefined;
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
    }));
  }

  save(donation: Donation) {

  const action = this.booIsCreate ? 'CREATE' : 'UPDATE';
    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
    const donorName = `${donation.donor.value()?.familyName} ${donation.donor.value()?.firstName}`;
    const auditChange: AuditChangeCreationBase = {
      company: donation.donor.value()?.company!,
      organization: currentUserOrg,
      user: currentUser,
      entity: "GIFT",
      entityKey: donorName,
      action: action,
    }

    this.#donationService.updateDonation(donation)
      .subscribe(donation => {
        this.donation.set(donation);
        this.#messageService.add({severity: 'success', summary: 'Success', detail:  `Donation for ${donorName}  ${action} Successful`});
        this.ref.close(donation);
        this.#auditChangeService.createAuditChange(auditChange).subscribe();
      });
  }

  close() {
    this.ref.close({});
  }


}

