<p-dropdown [(ngModel)]="ngModel"
            [options]="organizationStatuses"
            optionLabel="status"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-organization-status ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-organizationStatus pTemplate="item">
    <foodbank-organization-status ngDefaultControl [ngModel]="organizationStatus" />
  </ng-template>
</p-dropdown>
