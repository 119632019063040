@let articleCategories = articleCategoryPage.value()?.content ?? [];
<p-multiSelect [(ngModel)]="selectedArticleCategories"
               [options]="articleCategories"
               placeholder="Any"
               filterMatchMode="contains"
               [showClear]="true"
               filterBy="id,nameFr,nameNl"
               appendTo="body"
               dataKey="id">
  <ng-template let-selectedItems pTemplate="selectedItems">
    <ng-container *ngIf="selectedItems?.length > 0">
      <ng-container *ngFor="let selectedCategory of selectedItems.slice(0,3)">
        <foodbank-article-category [articleCategory]="selectedCategory"/>
        <br/>
      </ng-container>
      <ng-container *ngIf="selectedItems.length > 3" i18n>... {{ selectedItems.length - 3 }} more item(s) selected
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!selectedItems?.length" i18n>Any</ng-container>
  </ng-template>

  <ng-template let-option pTemplate="item">
    <foodbank-article-category [articleCategory]="option"/>
  </ng-template>
</p-multiSelect>
