import { computed, inject, Injectable, Injector, Signal } from '@angular/core';
import { MovementType } from '@model/movement-type';
import { Warehouse } from '@model/warehouse';
import { OrganizationService } from './organization.service';
import { rxResource } from '@angular/core/rxjs-interop';
import { Article } from '@model/article';
import { Organization } from '@model/organization';
import { Stock } from '@model/stock';
import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Movement } from '@model/movement';
import { BulkFoodImportService, COLUMN_MAPPINGS, ImportRow } from './bulk-food-import.service';

export interface ExpeditionImportRow extends ImportRow<ExpeditionImportRow> {
  organizationName: string;
  organizationId: string;
  organizationRef: ReturnType<typeof rxResource<Organization | undefined, Error>>;
}

interface ExcelRow {
  __rowNum__: number;
  [key: string]: any;
}

export function createRowExpeditionIdentifier(row: Partial<ExpeditionImportRow>): string {
  return `${row.yearMonth}_${row.organizationId}_${row.productCode}`;
}

@Injectable({
  providedIn: 'root'
})
export class BulkFoodExpeditionImportService extends BulkFoodImportService<ExpeditionImportRow> {
  readonly #organizationService = inject(OrganizationService);

  protected override mapExcelToImportRows(data: ExcelRow[], warehouse: Warehouse, movementType: MovementType, injector: Injector): ExpeditionImportRow[] {
    return data.map(item => this.#mapExpeditionRow(item, warehouse, movementType, injector));
  }

  #mapExpeditionRow(item: ExcelRow, warehouse: Warehouse, movementType: MovementType, injector: Injector): ExpeditionImportRow {
    const rowNumber = item.__rowNum__;
    const yearMonth = this.cleanString(item[COLUMN_MAPPINGS.yearMonth]);
    const productId = this.cleanString(item[COLUMN_MAPPINGS.productId]);
    const postalCode = this.cleanString(item[COLUMN_MAPPINGS.postalCode]);
    const city = this.cleanString(item[COLUMN_MAPPINGS.city]);
    const productCode = this.cleanString(item[COLUMN_MAPPINGS.productCode]);
    const quantity = Number(this.cleanString(item[COLUMN_MAPPINGS.quantity]));
    const article = this.cleanString(item[COLUMN_MAPPINGS.article]);
    const unit = this.cleanString(item[COLUMN_MAPPINGS.unit]);
    const organizationId = this.cleanString(item[COLUMN_MAPPINGS.organizationId]);
    const organizationName = this.cleanString(item[COLUMN_MAPPINGS.organizationName]);

    const rowIdentifier = createRowExpeditionIdentifier({
      yearMonth,
      productCode,
      organizationId
    });

    if (rowNumber === 1) {
      this.loadImportMovements(yearMonth, warehouse, movementType, injector);
    }

    const partialRow: Omit<ExpeditionImportRow, 'articleRef' | 'stockRef' | 'organizationRef' | 'movementRef' | 'isValid'> = {
      rowNumber, 
      yearMonth, 
      productId, 
      postalCode, 
      city, 
      productCode, 
      quantity, 
      article, 
      unit,
      rowIdentifier,
      organizationName,
      organizationId,
      isModified: false,
      isDuplicate: false,
      movementType
    };

    return this.mapImportRowResources(partialRow, injector);
  }

  updateMovementRef$(warehouse: Warehouse | undefined, yearMonth: string, productId: string, organizationId: string, rowIdentifier: string, movementType: MovementType, injector: Injector): Observable<Movement | undefined> {
    if (!productId || !organizationId || !warehouse) return of(undefined);
    const movementRef = this.articleService.getArticle$(productId).pipe(
      switchMap(article => {
        if (!article) return of(undefined);
        return this.#organizationService.getOrganization$(Number(organizationId)).pipe(
          switchMap(organization => {
            if (!organization) return of(undefined);
            return this.movementService.findMovements$({
              organization: organization,
              supplierBatchName: this.computeBatchName(yearMonth),
              warehouse: warehouse,
              commentContains: `[${rowIdentifier}]`,
              movementTypes: [movementType]
            }, { page: 0, size: 1 }, injector).pipe(
              map(page => page.content[0])
            );
          })
        );
      })
    );

    movementRef.subscribe(movement => this.bulkFoodImportMovementService.updateMovement(movement));

    return movementRef;
  }

  override mapImportRowResources(
    item: Omit<ExpeditionImportRow, 'articleRef' | 'stockRef' | 'organizationRef' | 'movementRef' | 'isValid'>,
    injector: Injector
  ): ExpeditionImportRow {
    const articleRef = rxResource<Article | undefined, Error>({
      loader: () => this.articleService.getArticle$(item.productId!),
      injector
    });

    const stockRef = rxResource<Stock | undefined, Error>({
      loader: () => this.loadStock$(item.productId!, injector),
      injector
    });

    const organizationRef = rxResource<Organization | undefined, Error>({
      loader: () => this.#organizationService.getOrganization$(Number(item.organizationId!)),
      injector
    });

    const movementRef = rxResource<Movement | undefined, Error>({
      loader: () => this.bulkFoodImportMovementService.$findMovementForImportRow(item.rowIdentifier!),
      injector
    });

    const isValid = (row: ExpeditionImportRow, duplicates: Set<string>) => computed(() => {
      if (articleRef.isLoading() || organizationRef.isLoading()) {
        return undefined;
      }

      return articleRef.value() !== undefined && organizationRef.value() !== undefined && !duplicates.has(row.rowIdentifier!);
    });

    return { ...item, articleRef, stockRef, organizationRef, movementRef, isValid };
  }
} 