<p-table #dt
         [value]="(dependentPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="dependentPage.value()?.totalElements || 0"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Dependents</h2>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('title') {
            <th pSortableColumn="title">
              <span>{{ getColumnLabel(DependentColumn.title) }}</span>
            </th>
          }
          @case ('familyName') {
            <th pSortableColumn="familyName">
              <span>{{ getColumnLabel(DependentColumn.familyName) }}</span>
            </th>
          }
          @case ('firstName') {
            <th pSortableColumn="firstName">
              <span>{{ getColumnLabel(DependentColumn.firstName) }}</span>
            </th>
          }

          @case ('birthdate') {
            <th>
              <span>{{ getColumnLabel(DependentColumn.birthdate) }}</span>
            </th>
          }

          @case ('active') {
            <th>
              <span>{{ getColumnLabel(DependentColumn.active) }}</span>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('title') {
            <th>
            </th>
          }
          @case ('familyName') {
            <th>
            </th>
          }
          @case ('firstName') {
            <th>
            </th>
          }
          @case ('birthdate') {
            <th>
            </th>
          }
          @case ('active') {
            <th>
              <p-checkbox [binary]="true" [(ngModel)]="filterActive" checked ngDefaultControl/>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let dependent = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case('title') {
            <td>
              {{ dependent.title }}
            </td>
          }
          @case ('familyName') {
            <td>
              {{ dependent.lastName }}
            </td>
          }
          @case ('firstName') {
            <td>
              {{ dependent.firstName }}
            </td>
          }
          @case ('birthdate') {
            <td>
              {{ dependent.birthDate | date: 'dd/MM/yyyy'}}
            </td>
          }
          @case ('active') {
            <td>
              {{ dependent.active }}
            </td>
          }
        }
      }
    </tr>
  </ng-template>
</p-table>
