import {Component, computed, inject, Injector, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {RegionSearch} from '@model/search/region-search';
import {Region} from '@model/region';
import {RegionService} from '@services/region.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {RegionComponent} from "@components/region/region.component";
import {UserService} from "@services/user.service";

@Component({
  selector: 'foodbank-region-selection',
  exportAs: 'regionSelection',
  templateUrl: './region-selection.component.html',
  styleUrls: ['./region-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    RegionComponent
  ]
})
export class RegionSelectionComponent {

  ngModel = model<Region>();
  required = input(false);
  regionSearch = input<RegionSearch>({});

  regNameFilter = signal('');

  regions: Signal<Region[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);
  #userService = inject(UserService);
  #regionService = inject(RegionService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualRegionSearch: Signal<RegionSearch> = computed(() => ({
      ...this.regionSearch(),
      company: currentUserCompany(),
      regNameContains: this.regNameFilter(),
    }));

    const regionPage = loadSignalFromObservable(() => this.#regionService.findRegions$(actualRegionSearch(),  this.pagination()), this.loading);

    this.regions = computed(() => regionPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}

