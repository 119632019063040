import {inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserSignalService} from "@services/user-signal.service";

@Injectable({
  providedIn: 'root'
})
export class TransferRequestGuard implements CanActivate {

  #userService = inject(UserSignalService);

  canActivate(): boolean {
    return this.#userService.$canAccessTransferRequests()();
  }

}
