import {Component, input, model} from '@angular/core';
import {SupplyWeek} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationDistribWeekComponent} from "@components/organization-distrib-week/organization-distrib-week.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-distrib-week-selection',
  imports: [
    DropdownModule,
    OrganizationDistribWeekComponent,
    FormsModule
  ],
  templateUrl: './organization-distrib-week-selection.component.html',
  styleUrl: './organization-distrib-week-selection.component.scss'
})
export class OrganizationDistribWeekSelectionComponent {
  ngModel = model<SupplyWeek>();
  required = input(false);

  organizationDistribWeeks: SupplyWeek[];

  constructor() {

    this.organizationDistribWeeks = [  "WEEK_EVERY" , "WEEK_1" , "WEEK_2" , "WEEK_3" , "WEEK_4" , "WEEK_EVERY2" , "WEEK_12" , "WEEK_13" , "WEEK_14" , "WEEK_23" , "WEEK_24" , "WEEK_34" , "WEEKS_EVEN" , "WEEKS_UNEVEN"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}
