import {Component, inject, Injector, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Donor} from '@model/donor';
import {DonorService} from '@services/donor.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {UserSignalService} from "@services/user-signal.service";


@Component({
  selector: 'foodbank-donor-edit',
  templateUrl: './donor-edit.component.html',
  styleUrls: ['./donor-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule,  InputSwitchModule, Button,  MessageModule, DropdownModule]
})
export class DonorEditComponent {

  donor = model<Donor>();
  booIsCreate = false;

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Donor>);
  #donorService = inject(DonorService);
  #messageService = inject(MessageService);
  #userSignalService = inject(UserSignalService);
  #auditChangeService = inject(AuditChangeService);
  #injector = inject(Injector);


  constructor() {
    this.donor.set(this.config.data);
    this.booIsCreate = this.donor()?.familyName === undefined;
  }

  save(donor: Donor) {

    const action = this.booIsCreate ? 'CREATE' : 'UPDATE';
    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
    const donorName = `${donor.familyName} ${donor.firstName}`;
    const auditChange: AuditChangeCreationBase = {
      company: donor.company,
      organization: currentUserOrg,
      user: currentUser,
      entity: "DONOR",
      entityKey: donorName,
      action: action,
    }


    this.#donorService.updateDonor(donor)
      .subscribe(donor => {
        this.donor.set(donor);
        this.ref.close(donor);
        this.#messageService.add({severity: 'success', summary: 'Success', detail:  `Donor ${donorName}  ${action}`});
        this.#auditChangeService.createAuditChange(auditChange).subscribe();
      });
  }

  close() {
    this.ref.close({});
  }

}


