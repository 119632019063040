<div class="flex flex-col align-items-center gap-3 mb-4">
  <label for="fileUpload" class="block text-2xl font-medium">{{ title() }}</label>
  <div class="flex gap-2 align-items-center">
    <ng-container *ngIf="!hasData() && !isRestoringState(); else displayComponents">
      <foodbank-warehouse-selection [(ngModel)]="selectedWarehouse" ngDefaultControl />
      <foodbank-movement-type-single-selection [(ngModel)]="selectedMovementType" [movementTypeSearch]="movementTypeSearch" ngDefaultControl />
      <p-fileUpload mode="basic" chooseLabel="Choose" chooseIcon="pi pi-upload" name="demo[]"
        url="https://www.primefaces.org/cdn/api/upload.php" maxFileSize="1000000" (onUpload)="onUpload($event)"
        [disabled]="!selectedWarehouse() || !selectedMovementType() || isRestoringState()" 
        [pTooltip]="!selectedWarehouse() ? 'Please select a warehouse first' : !selectedMovementType() ? 'Please select a movement type' : isRestoringState() ? 'Restoring previous state...' : ''" 
        tooltipPosition="top" />
    </ng-container>
    <ng-template #displayComponents>
      <div class="flex flex-col gap-3 justify-center mt-1">
        <label class="text-base font-bold text-center">Warehouse:</label>
        <foodbank-warehouse *ngIf="!isRestoringState(); else loadingDisplayComponent" [ngModel]="selectedWarehouse()" [showId]="true" ngDefaultControl />
      </div>
      <div class="flex flex-col gap-3 justify-center mt-1">
        <label class="text-base font-bold text-center">Type:</label>
        <foodbank-movement-type *ngIf="!isRestoringState(); else loadingDisplayComponent" [ngModel]="selectedMovementType()" ngDefaultControl />
      </div>
      <ng-template #loadingDisplayComponent>
        <i class="pi pi-spin pi-spinner mr-2"></i>
      </ng-template>
      <p-button icon="pi pi-trash" severity="danger" (onClick)="clearData()" label="Clear data" [disabled]="isRestoringState()"></p-button>
      <p-button
        [label]="isSaving()
          ? 'Saving... ' + ((saveProgress()?.current || 0) + 1) + '/' + (saveProgress()?.total || 0)
          : 'Save' + (selectedRows().length ? ' (' + selectedRows().length + ' selected)' : '') + (modifiedRows().length ? ' (' + modifiedRows().length + ' unsaved)' : '')"
        icon="pi pi-save" [loading]="isSaving()" (onClick)="saveMovements()" [disabled]="!hasValidRows() || isSaving() || isRestoringState()">
      </p-button>
      <p-button
        [label]="isRecalling()
          ? 'Recalling... ' + ((recallProgress()?.current || 0) + 1) + '/' + (recallProgress()?.total || 0)
          : 'Recall' + (selectedRows().length ? ' (' + selectedRows().length + ' selected)' : '')"
        icon="pi pi-replay" severity="warning" [loading]="isRecalling()" (onClick)="recallMovements()" 
        [disabled]="!hasMovementsToRecall() || isRecalling() || isRestoringState()"
        pTooltip="Delete movements and reverse their effects on stock" tooltipPosition="top">
      </p-button>
    </ng-template>
  </div>
</div>
<div class="flex flex-col gap-2 mb-3" *ngIf="hasData()">
  <div class="text-lg" [class.text-red-500]="problemRows().length > 0">
    <ng-container *ngIf="isRestoringState()">
      <i class="pi pi-spin pi-spinner mr-2"></i>
      Restoring previous state...
    </ng-container>
    <ng-container *ngIf="!isRestoringState()">
      {{ statusMessage() }}
    </ng-container>
  </div>
  <div class="flex align-items-center gap-2" *ngIf="problemRows().length > 0">
    <p-checkbox [(ngModel)]="showOnlyProblems" [binary]="true" inputId="problems" [disabled]="isRestoringState()"></p-checkbox>
    <label for="problems" class="cursor-pointer">Show only rows with problems</label>
  </div>
</div>
<p-table loadingIcon="pi pi-spin pi-spinner big-loading-icon" [value]="filteredData() || []" [sortField]="'rowNumber'"
  [sortOrder]="1" [multiSortMeta]="[{field: 'rowNumber', order: 1}]" [sortMode]="'multiple'"
  class="p-datatable-gridlines p-datatable-sm" [totalRecords]="data().length || 0" [showCurrentPageReport]="true"
  [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10, 25, 50, 100]"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} items" [rowHover]="true"
  [resizableColumns]="true" columnResizeMode="expand" [autoLayout]="true" [selection]="selectedRows()"
  (selectionChange)="onSelectionChange($event)" dataKey="rowNumber" [loading]="isRestoringState()">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="rowNumber">#<p-sortIcon field="rowNumber"></p-sortIcon></th>
      <th pSortableColumn="rowIdentifier">
        <div class="flex align-items-center gap-2">
          <span>identifier</span>
          <i class="pi pi-question-circle cursor-help" 
             [pTooltip]="currentImportType() === 'RECEIPT' ? 
               '{Year month}_{Supplier id}_{Product code}' : 
               '{Year month}_{Organization id}_{Product code}'"
             tooltipPosition="top" [tooltipStyleClass]="currentImportType() === 'RECEIPT' ? 'bulkfood-identifier-tooltip-receipt' : 'bulkfood-identifier-tooltip-expedition'"></i>
          <p-sortIcon field="rowIdentifier"></p-sortIcon>
        </div>
      </th>
      <th pSortableColumn="yearMonth">Year month<p-sortIcon field="yearMonth"></p-sortIcon></th>
      <th *ngIf="currentImportType() === 'RECEIPT'" pSortableColumn="supplierName">Supplier Name<p-sortIcon field="supplierName"></p-sortIcon></th>
      <th *ngIf="currentImportType() === 'RECEIPT'" pSortableColumn="supplierId">Supplier Id<p-sortIcon field="supplierId"></p-sortIcon></th>
      <th *ngIf="currentImportType() === 'EXPEDITION'" pSortableColumn="organizationName">Organization Name<p-sortIcon field="organizationName"></p-sortIcon></th>
      <th *ngIf="currentImportType() === 'EXPEDITION'" pSortableColumn="organizationId">Organization Id<p-sortIcon field="organizationId"></p-sortIcon></th>
      <th pSortableColumn="postalCode">Postal code<p-sortIcon field="postalCode"></p-sortIcon></th>
      <th pSortableColumn="city">City<p-sortIcon field="city"></p-sortIcon></th>
      <th pSortableColumn="productCode">Product code<p-sortIcon field="productCode"></p-sortIcon></th>
      <th pSortableColumn="productId">Food id<p-sortIcon field="productId"></p-sortIcon></th>
      <th pSortableColumn="article">Article<p-sortIcon field="article"></p-sortIcon></th>
      <th pSortableColumn="unit">Unit<p-sortIcon field="unit"></p-sortIcon></th>
      <th pSortableColumn="quantity">Quantity<p-sortIcon field="quantity"></p-sortIcon></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-index="rowIndex">
    <tr [class.good]="!item.movementRef.isLoading() && item.isValid(item, duplicates())()" 
        [class.modified]="item.isModified"
        [class.invalid]="!item.isValid(item, duplicates())()"
        [class.loading]="item.articleRef.isLoading() || item.movementRef.isLoading() || (isReceptionRow(item) ? item.supplierRef.isLoading() : item.organizationRef.isLoading())">
      <td>
        <p-tableCheckbox [value]="item"></p-tableCheckbox>
      </td>
      <td>
        <div class="flex align-items-center">
          <ng-container *ngIf="!item.movementRef.isLoading(); else loadingMovement">
            <i *ngIf="!item.isDuplicate"
              [class]="item.movementRef.value() ? 'fa-solid fa-link text-green-500' : item.isValid(item, duplicates())() ? 'fa-solid fa-link-slash text-gray-500' : 'pi pi-times text-red-500'"
              [pTooltip]="item.movementRef.value() ? 'Movement exists' : item.isValid(item, duplicates())() ? 'Movement not found' : 'Row is invalid'" class="mr-2">
            </i>
          </ng-container>
          <ng-template #loadingMovement>
            <i class="pi pi-spin pi-spinner mr-2"></i>
          </ng-template>
          {{ item.rowNumber }}
        </div>
      </td>
      <td>
        <div class="flex align-items-center">
          <i *ngIf="item.isDuplicate" [class]="'pi pi-times text-red-500'"
            [pTooltip]="'Item is a duplicate, duplicate items are not allowed, saved or updated'" class="mr-2">
          </i>
          {{ item.rowIdentifier }}
        </div>
      </td>
      <td>{{ item.yearMonth }}</td>
      <!-- Reception fields -->
      <ng-container *ngIf="currentImportType() === 'RECEIPT'">
        <td>{{ item.supplierName }}</td>
        <td pEditableColumn pEditableColumnField="supplierId">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="flex align-items-center">
                <input pInputText type="text" [ngModel]="item.supplierId"
                  (ngModelChange)="onPartyRefIdChange(item, $event)" class="p-inputtext-sm w-8rem">
                <i [class]="item.supplierRef?.value() ? 'pi pi-check text-green-500' : 'fa-solid fa-link-slash text-red-500'"
                  [pTooltip]="item.supplierRef?.value() ? 'Supplier found' : 'Supplier not found'" class="ml-2">
                </i>
                <i *ngIf="!item.supplierRef?.value()" class="pi pi-plus cursor-pointer ml-2"
                  pTooltip="Create missing supplier" tooltipPosition="top" (click)="onCreatePartyRef(item)"></i>
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <div class="flex align-items-center editable">
                <ng-container *ngIf="item.supplierRef?.value(); else missingSupplierRef">
                  <foodbank-supplier [ngModel]="item.supplierRef.value()" [showId]="true" ngDefaultControl />
                </ng-container>
                <ng-template #missingSupplierRef>
                  <ng-container *ngIf="item.supplierId; else emptySupplierRef">
                    <i [class]="item.supplierId ? 'fa-solid fa-link-slash text-red-500 mr-2' : 'pi pi-times text-red-500 mr-2'"
                      pTooltip="Supplier not found"></i>
                    <span>{{ item.supplierId }}</span>
                  </ng-container>
                  <ng-template #emptySupplierRef>
                    <i class="pi pi-times text-red-500 mr-2" pTooltip="Supplier not found"></i>
                    <span class="italic text-gray-500">empty</span>
                  </ng-template>
                </ng-template>
                <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit"></i>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
      <!-- Expedition fields -->
      <ng-container *ngIf="currentImportType() === 'EXPEDITION'">
        <td>{{ item.organizationName }}</td>
        <td pEditableColumn pEditableColumnField="organizationId">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <div class="flex align-items-center">
                <input pInputText type="text" [ngModel]="item.organizationId"
                  (ngModelChange)="onPartyRefIdChange(item, $event)" class="p-inputtext-sm w-8rem">
                <i [class]="item.organizationRef?.value() ? 'pi pi-check text-green-500 mr-2' : 'fa-solid fa-link-slash text-red-500 mr-2'"
                  [pTooltip]="item.organizationRef?.value() ? 'Organization found' : 'Organization not found'" class="ml-2">
                </i>
                <!-- Not enough information to create organization, to sync with Alain -->
                <!-- <i *ngIf="!item.organizationRef?.value()" class="pi pi-plus cursor-pointer ml-2"
                  pTooltip="Create missing organization" tooltipPosition="top" (click)="onCreatePartyRef(item)"></i> -->
              </div>
            </ng-template>
            <ng-template pTemplate="output">
              <div class="flex align-items-center editable">
                <ng-container *ngIf="item.organizationRef?.value(); else missingOrganizationRef">
                  <foodbank-organization [ngModel]="item.organizationRef.value()" [showId]="true" ngDefaultControl />
                </ng-container>
                <ng-template #missingOrganizationRef>
                  <ng-container *ngIf="item.organizationId; else emptyOrganizationRef">
                    <i [class]="item.organizationId ? 'fa-solid fa-link-slash text-red-500 mr-2' : 'pi pi-times text-red-500 mr-2'"
                      pTooltip="Organization not found"></i>
                    <span>{{ item.organizationId }}</span>
                  </ng-container>
                  <ng-template #emptyOrganizationRef>
                    <i class="pi pi-times text-red-500 mr-2" pTooltip="Organization not found"></i>
                    <span class="italic text-gray-500">empty</span>
                  </ng-template>
                </ng-template>
                <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit"></i>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
      </ng-container>
      <td>{{ item.postalCode }}</td>
      <td>{{ item.city }}</td>
      <td>{{ item.productCode }}</td>
      <td pEditableColumn pEditableColumnField="productId">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <div class="flex align-items-center">
              <input pInputText type="text" [ngModel]="item.productId" (ngModelChange)="onFoodIdChange(item, $event)"
                class="p-inputtext-sm w-8rem">
              <i [class]="item.articleRef.value() ? 'pi pi-check text-green-500' : item.productId ? 'fa-solid fa-link-slash text-red-500' : 'pi pi-times text-red-500'"
                [pTooltip]="item.articleRef.value() ? 'Article found' : 'Article not found'" class="ml-2">
              </i>
            </div>
          </ng-template>
          <ng-template pTemplate="output">
            <div class="flex align-items-center editable">
              <foodbank-article *ngIf="item.articleRef.value(); else missingArticleRef"
                [ngModel]="item.articleRef.value()" [showId]="true" ngDefaultControl />
              <ng-template #missingArticleRef>
                <ng-container *ngIf="item.productId; else emptyProductId">
                  <i class="fa-solid fa-link-slash text-red-500 mr-2" pTooltip="Article not found">
                  </i>
                  <span>{{ item.productId }}</span>
                </ng-container>
                <ng-template #emptyProductId>
                  <i class="pi pi-times text-red-500 mr-2" pTooltip="Article not found">
                  </i>
                  <span class="italic text-gray-500">empty</span>
                </ng-template>
              </ng-template>
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn pEditableColumnField="article">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="text" [ngModel]="item.article" (ngModelChange)="onArticleChange(item, $event)"
              class="p-inputtext-sm w-full">
          </ng-template>
          <ng-template pTemplate="output">
            <div class="flex align-items-center editable">
              <span>{{ item.article }}</span>
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ item.unit }}</td>
      <td pEditableColumn pEditableColumnField="quantity">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input pInputText type="number" [ngModel]="item.quantity" (ngModelChange)="onQuantityChange(item, $event)"
              class="p-inputtext-sm w-8rem" min="0">
          </ng-template>
          <ng-template pTemplate="output">
            <div class="editable">
              <foodbank-number [value]="item.quantity" [decimals]="3" />
              <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit">
              </i>
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
    </tr>
  </ng-template>
</p-table>