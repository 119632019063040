import {log10} from "chart.js/helpers";

export enum WeightUnit {
  gram = 'g',
  kilogram = 'kg',
  ton = 't',
}

export const ALL_WEIGHT_UNITS = [WeightUnit.gram, WeightUnit.kilogram, WeightUnit.ton];
export const BIG_WEIGHT_UNITS = [WeightUnit.kilogram, WeightUnit.ton];

export function getWeightUnitInGram(weightUnit: WeightUnit): number {
  switch (weightUnit) {
    case WeightUnit.gram:
      return 1;
    case WeightUnit.kilogram:
      return 1000;
    case WeightUnit.ton:
      return 1000000;
  }
}

export function calcDisplayUnitFactor(inputWeightUnit: WeightUnit, displayWeightUnit: WeightUnit) {
  const inputWeightUnitInGram = getWeightUnitInGram(inputWeightUnit);
  const displayWeightUnitInGram = getWeightUnitInGram(displayWeightUnit);
  return displayWeightUnitInGram / inputWeightUnitInGram;
}

export function getWeightDecimalCount(inputWeightUnit: WeightUnit, displayWeightUnit: WeightUnit, maxDecimalCount = 3): number {
  const displayUnitFactor = calcDisplayUnitFactor(inputWeightUnit, displayWeightUnit);
  const exactRequiredDecimals = Math.max(0, log10(displayUnitFactor));

  return Math.min(exactRequiredDecimals, maxDecimalCount);
}
