<p-table #dt
         [value]="(bankPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="bankPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Banks</h2>
       <p-multiSelect
         ngDefaultControl
         [options]="ALL_COLUMNS"
         [(ngModel)]="displayedColumns"
         placeholder="Select columns"
       >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
    <div>
       <button pButton pRipple icon="pi pi-download" class="p-button-rounded p-button-primary mr-2" (click)="exportToExcel()"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="COMPANY_ID">
        <span i18n>Id</span>
        <p-sortIcon field="COMPANY_ID"/>
      </th>
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME"/>
      </th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('address') {
            <th>
              <span i18n>Address</span>
            </th>
          }
          @case ('zipCode') {
            <th>
              <span i18n>ZipCode</span>
            </th>
          }
          @case ('city') {
            <th>
              <span i18n>City</span>
            </th>
          }
          @case ('email') {
            <th>
              <span i18n>Email</span>
            </th>
          }
          @case ('phone') {
            <th>
              <span i18n>Phone</span>
            </th>
          }
          @case ('mobile') {
            <th>
              <span i18n>Mobile</span>
            </th>
          }

          @case ('active') {
            <th>
              <span i18n>Active</span>
            </th>
          }
        }
      }
      <th></th>
    </tr>
    <tr>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCompanyIdEquals" ngDefaultControl/>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterNameContains" ngDefaultControl/>
      </th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {

          @case ('address') {
            <th>
            </th>
          }
          @case ('zipCode') {
            <th>

            </th>
          }
          @case ('city') {
            <th>

            </th>
          }
          @case ('email') {
            <th>
            </th>
          }
          @case ('phone') {
            <th>
            </th>
          }

          @case ('mobile') {
            <th>
            </th>

          }
          @case ('active') {
            <th>

            </th>
          }
        }
      }
      <th></th>

    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bank>
    <ng-container *ngIf="identity(bank) as bank">
      <tr>
        <td>
          {{ bank.companyId }}
        </td>
        <td>
          {{ bank.name }}
        </td>
        @for (column of displayedColumns(); track column) {
          @switch (column) {
            @case ('address') {
              <td>
                <ng-container *ngIf="getAddress(bank) as completeAddress">
                  <a href="http://maps.google.com/maps?q={{ completeAddress }}" target="_blank">
                    {{ completeAddress }}
                  </a>
                </ng-container>
              </td>
            }
            @case ('zipCode') {
              <td>
                {{ bank.zipCode }}
              </td>
            }
            @case ('city') {
              <td>
                {{ bank.city }}
              </td>
            }

            @case ('email') {
              <td>
                {{ bank.mail }}
              </td>
            }
            @case ('phone') {
              <td>
                {{ bank.phone }}
              </td>
            }
            @case ('mobile') {
              <td>
                {{ bank.mobile }}
              </td>
            }

            @case ('active') {
              <td>
                <p-checkbox [binary]="true" [(ngModel)]="bank.active" checked ngDefaultControl/>
              </td>
            }
          }
        }
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(bank)"></button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{width: '50vw'}" />


