import {inject, Injectable, Injector} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {BankProgDto} from '@typedefs/stock-rest';
import {BankProg} from "@model/bankprog";
import {environment} from '@environments/environment';
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";
import {copyCommonFields} from "@model/mapping-utils";


@Injectable({
  providedIn: 'root'
})
export class BankProgService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getBankProg$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<BankProg> {
    return this.#httpClient.get<BankProgDto>(`${environment.apiUrl}/bankprogs/${id}`)
      .pipe(
        map(bankProgDto => this.mapToBankProg(bankProgDto, cache)),
        shareReplay(),
      );
  }





  updateBankProg(bankProg: BankProg ): Observable<BankProg> {
    const bankProgDto = this.mapToBankProgDto(bankProg);
    return this.#httpClient.put<BankProgDto>(`${environment.apiUrl}/bankprogs`, bankProgDto)
      .pipe(map(bankProgDto => this.mapToBankProg(bankProgDto, this.#foodbankCacheFactory.create(this.#injector))));

  }

  public mapToBankProg(bankProgDto: BankProgDto, cache: FoodbankCache): BankProg {
    const commonFields: BankProg | BankProgDto = copyCommonFields(bankProgDto, []) as BankProg;
    return {
      ...commonFields,

    }
  }

  mapToBankProgDto(bankProg: BankProg ): BankProgDto {
    const bankProgDto: BankProgDto = copyCommonFields(bankProg, []) as BankProgDto;
    return {
      ...bankProgDto,


    };
  }
}
