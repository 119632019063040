import {ColumnLabels} from "@components/columnLabels";
import {Component, computed, inject, Injector, input, model, Resource, signal, Signal, WritableSignal} from "@angular/core";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {MultiSelectModule} from "primeng/multiselect";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {OrganizationComponent} from "@components/organization/organization.component";
import {Organization} from "@model/organization";
import {OrganizationSearch} from "@model/search/organization-search";
import {BeneficiarySearch} from "@model/search/beneficiary-search";
import {Pagination} from "@services/pagination";
import {Beneficiary} from "@model/beneficiary";
import {Page} from "@typedefs/page";
import {BeneficiaryService} from "@services/beneficiary.service";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from "@services/pagination.service";
import {UserService} from "@services/user.service";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {rxResource} from "@angular/core/rxjs-interop";
import {DateSearch} from "@model/search/date-search";
import {DateSearchComponent} from "@components/date/date-search/date-search.component";
import {DatePipe} from "@angular/common";
import {BeneficiaryEditComponent} from "@components/beneficiary/beneficiary-edit/beneficiary-edit.component";
import {CheckboxModule} from "primeng/checkbox";

enum BeneficiaryColumn {
  firstName = 'firstName',
  familyName = 'familyName',
  organization = 'organization',
  address = 'address',
  phone = 'phone',
  email = 'email',
  birthdate = 'birthdate',
  zip = 'zip',
  city = 'city',
  coeff = 'coeff',
  active = 'active',
}

const COLUMN_LABELS: ColumnLabels<BeneficiaryColumn> = {
  firstName: 'First Name',
  familyName: 'Family Name',
  organization: 'Organization',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  birthdate: 'Birthdate',
  zip: 'Zip',
  city: 'City',
  coeff: 'Coeff',
  active: 'Active'
}

const COLUMN_PREFERENCE_KEY = 'preference_members_list_columns';

const ALL_COLUMNS: BeneficiaryColumn[] = Object.values(BeneficiaryColumn) as BeneficiaryColumn[];
const DEFAULT_COLUMNS: BeneficiaryColumn[] = [BeneficiaryColumn.firstName, BeneficiaryColumn.familyName, BeneficiaryColumn.organization, BeneficiaryColumn.phone, BeneficiaryColumn.email];

@Component({
  selector: 'foodbank-beneficiaries',
  templateUrl: './beneficiary-list.component.html',
  styleUrls: ['./beneficiary-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule,  ButtonModule, RippleModule,  MultiSelectModule, ChipModule, ChipsModule,OrganizationSingleSelectionComponent, OrganizationComponent,DateSearchComponent,DatePipe, CheckboxModule]
})
export class BeneficiariesComponent {
  // selected search filters
  filterFirstNameContains = model('');
  filterFamilyNameContains = model('');
  filterZipContains = model('');
  filterAddressContains = model('');
  filterCityContains = model('');
  filterBirthDateSearch = signal<DateSearch>({});
  filterOrganization = model<Organization>();
  filterCoeffExists = model<boolean>();
  filterActive = model<boolean>();

  // filter searches
  organizationSearch: Signal<OrganizationSearch>;

  // this view search
  beneficiarySearch: Signal<BeneficiarySearch>;
  pagination: WritableSignal<Pagination>

  // result page
  beneficiaryPage: Resource<Page<Beneficiary> | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #beneficiaryService =  inject(BeneficiaryService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<BeneficiaryColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();

    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
      active: true
    }));

    this.beneficiarySearch = computed(() => ({
      company: currentUserCompany(),
      addressContains: this.filterAddressContains(),
      organization: this.filterOrganization(),
      familyNameContains: this.filterFamilyNameContains(),
      firstNameContains: this.filterFirstNameContains(),
      birthDateSearch: this.filterBirthDateSearch(),
      zipContains: this.filterZipContains(),
      cityContains: this.filterCityContains(),
      coeffExists: this.filterCoeffExists(),
      active: this.filterActive()
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.beneficiaryPage = rxResource({
      request: () => ({
        beneficiarySearch: this.beneficiarySearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#beneficiaryService.findBeneficiaries$(this.beneficiarySearch(), this.#injector, this.pagination())
    })
  }

  identity(beneficiary: Beneficiary): Beneficiary {
    return beneficiary;
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  getColumnLabel(column: BeneficiaryColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;
  protected readonly ALL_COLUMNS = ALL_COLUMNS;

  protected readonly BeneficiaryColumn = BeneficiaryColumn;

  openEditDialog(beneficiary: Beneficiary) {
    this.#dialogRef = this.#dialogService.open(BeneficiaryEditComponent, {
      header: 'Edit Beneficiary',
      width: '80%',
      data: {...beneficiary}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.beneficiaryPage.reload()
    })
  }

}
