import {inject, Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {Pagination} from "@services/pagination";
import {map, Observable} from "rxjs";
import {Page} from "@typedefs/page";
import {DependentDto, DependentSearchDto} from "@typedefs/stock-rest";
import {environment} from "@environments/environment";
import {FoodbankCache} from "@services/foodabank-cache";
import {copyCommonFields} from "@model/mapping-utils";
import {Dependent} from "@model/dependent";
import {DependentSearch} from "@model/search/dependent-search";

@Injectable({
  providedIn: 'root'
})
export class DependentService {
  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);

  public findDependents$(dependentsSearch: DependentSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Dependent>> {
    const dependentsSearchDto = this.mapToDependentSearchDto(dependentsSearch);
    return this.#httpClient.post<Page<DependentDto>>(`${environment.apiUrl}/dependents`, dependentsSearchDto, {params: pagination})
      .pipe(map(dependentsPage => this.loadDependentPage(dependentsPage, cache)));
  }

  private loadDependentPage(dependentDtoPage: Page<DependentDto>, cache: FoodbankCache): Page<Dependent> {
    return {
      ...dependentDtoPage,
      content: this.mapToDependents(dependentDtoPage.content, cache)
    };
  }

  public mapToDependents(dependentDtos: DependentDto[], cache: FoodbankCache) {
    return dependentDtos.map(beneficiary => this.mapToDependent(beneficiary, cache));
  }

  public mapToDependent(dependentDto: DependentDto, cache: FoodbankCache): Dependent {
    const commonFields: Dependent | DependentDto = copyCommonFields(dependentDto, ['organizationId', 'companyId', 'regionId']);
    return {
      ...commonFields,
      organization: cache.organizationCache.get(dependentDto.organizationId),
      company: cache.companyCache.get(dependentDto.companyId),
      region: cache.regionCache.get(dependentDto.regionId)
    }
  }

  mapToDependentSearchDto(dependentSearch: DependentSearch): DependentSearchDto {
    return {
      beneficiaryId: dependentSearch?.beneficiary?.id,
      active: dependentSearch.active
    }
  }


}
