import { Component, HostListener, ViewChild, Input } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { CalendarPanelComponent } from './calendar-panel.component';

@Component({
  selector: 'foodbank-calendar-button',
  template: `
    <div [class.floating-button]="floating">
      <button pButton
              type="button"
              icon="pi pi-calendar"
              [class]="'p-button-rounded p-button-outlined calendar-button'"
              [size]="floating ? 'large' : undefined"
              (click)="toggleCalendar()">
      </button>
    </div>
    <foodbank-calendar-panel #calendarPanel></foodbank-calendar-panel>
  `,
  styles: [`
    .floating-button {
      position: fixed;
      bottom: 0;
      right: 0;
      margin: 2rem;
      z-index: 1000;
    }

    :host ::ng-deep .floating-button .calendar-button {
      width: 60px !important;
      height: 60px !important;
    }

    :host ::ng-deep .floating-button .calendar-button .p-button-icon {
      font-size: 1.8rem;
    }

    :host ::ng-deep :not(.floating-button) .calendar-button {
      width: 2.5rem !important;
      height: 2.5rem !important;
    }

    :host ::ng-deep :not(.floating-button) .calendar-button .p-button-icon {
      font-size: 1rem;
    }
  `],
  standalone: true,
  imports: [ ButtonModule, CalendarPanelComponent ]
})
export class CalendarButtonComponent {
  @Input() floating = true;

  @ViewChild('calendarPanel') calendarPanel!: CalendarPanelComponent;

  @HostListener('document:keydown.escape', ['$event'])
  handleEscapeKey(event: KeyboardEvent): void {
    if (this.calendarPanel) {
      this.calendarPanel.hide();
    }
  }

  toggleCalendar(): void {
    if (this.calendarPanel) {
      this.calendarPanel.toggle();
    }
  }
}
