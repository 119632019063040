import { Component, OnInit, ElementRef } from '@angular/core';
import { CalendarService } from '@services/calendar.service';
import { UserService } from '@services/user.service';
import { Calendar } from '@model/calendar';
import { WeekData } from '@model/week-data';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { take } from 'rxjs';

@Component({
  selector: 'foodbank-calendar-panel',
  templateUrl: './calendar-panel.component.html',
  styleUrls: ['./calendar-panel.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, SidebarModule, TableModule, DropdownModule]
})
export class CalendarPanelComponent implements OnInit {
  calendar: Calendar | null = null;
  visible = false;
  currentYear = new Date().getFullYear();
  firstDayOfWeek = 1; // Default to Monday
  firstDayOptions = [
    { label: 'Monday', value: 1 },
    { label: 'Tuesday', value: 2 },
    { label: 'Wednesday', value: 3 },
    { label: 'Thursday', value: 4 },
    { label: 'Friday', value: 5 },
    { label: 'Saturday', value: 6 },
    { label: 'Sunday', value: 7 }
  ];

  constructor(
    private calendarService: CalendarService,
    private userService: UserService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.userService.getCurrentUserCompany$().pipe(
      take(1)
    ).subscribe(company => {
      if (company.firstDayOfWeek) {
        this.firstDayOfWeek = company.firstDayOfWeek;
      }
      this.loadCalendar();
    });
  }

  loadCalendar(year: number = this.currentYear): void {
    this.calendarService.getCalendarForYearWithFirstDay(year, this.firstDayOfWeek).subscribe(calendar => {
      this.calendar = calendar;
    });
  }

  show(): void {
    this.visible = true;
  }

  hide(): void {
    this.visible = false;
  }

  toggle(): void {
    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  changeYear(year: number): void {
    this.currentYear = year;
    this.loadCalendar(year);
  }

  previousYear(): void {
    this.changeYear(this.currentYear - 1);
  }

  nextYear(): void {
    this.changeYear(this.currentYear + 1);
  }

  getMonthName(monthNumber: number): string {
    return new Date(this.currentYear, monthNumber - 1, 1)
      .toLocaleString('default', { month: 'long' });
  }

  getMonthNumbers(): number[] {
    if (!this.calendar) return [];
    return Array.from({ length: 12 }, (_, i) => i + 1)
      .filter(month => this.calendar?.yearCalendar[month.toString()]);
  }

  getWeeksForMonth(monthNumber: number): [string, WeekData][] {
    if (!this.calendar) return [];
    const monthData = this.calendar.yearCalendar[monthNumber.toString()];
    if (!monthData) return [];

    // Convert to array of [key, value] pairs and sort by the first day of the week
    return Object.entries(monthData)
      .sort((a, b) => {
        const dateA = new Date(a[1].days[0]);
        const dateB = new Date(b[1].days[0]);
        return dateA.getTime() - dateB.getTime();
      });
  }

  getDaysOfWeek(weekData: WeekData): Date[] {
    return weekData.days.map(day => new Date(day));
  }

  isOtherMonth(date: Date, monthNumber: number): boolean {
    return date.getMonth() + 1 !== monthNumber;
  }

  getWeekDisplay(weekData: WeekData): string {
    if (!this.calendar) return '';
    return weekData.isoWeek;
  }

  getEmptyDaysBefore(weekData: WeekData): number {
    const firstDay = new Date(weekData.days[0]);
    const dayOfWeek = firstDay.getDay();
    // Convert Sunday (0) to 7 for calculation
    return dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  }

  getEmptyDaysAfter(weekData: WeekData): number {
    const lastDay = new Date(weekData.days[weekData.days.length - 1]);
    const dayOfWeek = lastDay.getDay();
    // Convert Sunday (0) to 7 for calculation
    return dayOfWeek === 0 ? 0 : 7 - dayOfWeek;
  }

  onFirstDayChange(): void {
    this.loadCalendar();
  }
}
