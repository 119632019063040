import {Component, input} from '@angular/core'
import {DialogService} from "primeng/dynamicdialog";
import {WeightUnit} from "@model/weight.unit";

@Component({
  selector: 'foodbank-weight-unit',
  templateUrl: './weight-unit.component.html',
  styleUrls: ['./weight-unit.component.scss'],
  providers: [DialogService],
  imports: []
})
export class WeightUnitComponent {

  ngModel = input<WeightUnit>();

}

