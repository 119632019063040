import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {combineLatest, map, Observable, of, shareReplay, switchMap} from 'rxjs';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {MovementDto, MovementSearchDto} from '@typedefs/stock-rest';
import {Movement} from '@model/movement';
import {Page} from '@typedefs/page';
import {ReceptionService} from '@services/reception.service';
import {UserService} from '@services/user.service';
import {MovementSearch} from '@model/search/movement-search';
import {WarehouseService} from '@services/warehouse.service';
import {OrganizationService} from '@services/organization.service';
import {User} from "@model/user";
import {Reception} from "@model/reception";
import {MovementTypeService} from "@services/movement-type.service";
import {MovementType} from "@model/movement-type";
import {Article} from "@model/article";
import {ArticleService} from "@services/article.service";
import {SupplierService} from "@services/supplier.service";
import {Supplier} from "@model/supplier";
import {Company} from "@model/company";
import {Warehouse} from "@model/warehouse";
import {StockService} from "@services/stock.service";
import {StockPalletService} from "@services/stock-pallet.service";
import {CompanyService} from "@services/company.service";
import {Stock} from "@model/stock";
import {StockPallet} from "@model/stock-pallet";
import {Organization} from "@model/organization";
import {DateService} from "@services/date.service";

export type MovementPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class MovementService {

  private httpClient = inject(HttpClient);
  private articleService = inject(ArticleService);
  private userService = inject(UserService);
  private receptionService = inject(ReceptionService);
  private warehouseService = inject(WarehouseService);
  private organizationService = inject(OrganizationService);
  private companyService = inject(CompanyService);
  private movementTypeService = inject(MovementTypeService);
  private supplierService = inject(SupplierService);
  private stockService = inject(StockService);
  private dateService = inject(DateService);
  private injector = inject(Injector);

  findMovements$(movementSearch: MovementSearch, pagination: MovementPagination, injector: Injector): Observable<Page<Movement>> {
    const movementSearchDto = this.mapToMovementSearchDto(movementSearch);
    return this.httpClient.post<Page<MovementDto>>(`${environment.apiUrl}/movements/search`, movementSearchDto, {
      params: pagination
    })
      .pipe(
        map(movementPage => {
          const content = movementPage.content.map(movementDto => this.mapToMovement(movementDto, injector));
          return ({
            ...movementPage,
            content: content
          });
        }),
        shareReplay()
      );
  }

  getMovement$(movementId: number, injector: Injector): Observable<Movement> {
    return this.httpClient.get<MovementDto>(`${environment.apiUrl}/movements/${movementId}`)
      .pipe(
        map(movementDto => this.mapToMovement(movementDto, injector)),
        shareReplay()
      );
  }

  mapToMovementSearchDto(movementSearch: MovementSearch): MovementSearchDto {
    const dateSearch = movementSearch.dateSearch;
    return {
      palletId: movementSearch.pallet?.id,
      receptionId: movementSearch.reception?.id,
      articleId: movementSearch.article?.id,
      organizationId: movementSearch.organization?.id,
      dateSearch: !dateSearch ? undefined : this.dateService.mapToDateSearchDto(dateSearch),
      movementKinds: movementSearch.movementTypes?.map(movementKind => movementKind.movementKind),
      excludedMovementKinds: movementSearch.excludedMovementTypes?.map(movementKind => movementKind.movementKind),
      internalBatchName: movementSearch.internalBatchName,
      internalBatchNameContains: movementSearch.internalBatchNameContains,
      supplierBatchName: movementSearch.supplierBatchName,
      supplierBatchNameContains: movementSearch.supplierBatchNameContains,
      deliveryTicketContains: movementSearch.deliveryTicketContains,
      articleCategoryId: movementSearch.articleCategory?.id,
      userId: movementSearch.user?.id,
      commentContains: movementSearch.commentContains,
      supplierId: movementSearch.supplier?.id,
      warehouseId: movementSearch.warehouse?.id,
      warehouseSearchDto: movementSearch.warehouseSearch && this.warehouseService.mapToWarehouseSearchDto(movementSearch.warehouseSearch),
      companyId: movementSearch.company?.id,
    }
  }

  saveMovement(movement: Movement, injector: Injector): Observable<Movement> {
    return this.mapToMovementDto$(movement).pipe(
      switchMap(movementDto => this.httpClient.post<MovementDto>(`${environment.apiUrl}/movements`, movementDto)),
      map(updatedMovementDto => this.mapToMovement(updatedMovementDto, injector)),
      shareReplay(),
    );
  }

  deleteMovement(movementId: number, injector: Injector): Observable<void> {
    return this.httpClient.delete<void>(`${environment.apiUrl}/movements/${movementId}`).pipe(
      shareReplay()
    );
  }

  mapToMovement(movementDto: MovementDto, injector: Injector): Movement {
    return {
      ...movementDto,
      company$: this.loadCompany$(movementDto),
      organization$: this.loadOrganization$(movementDto),
      stock$: this.loadStock$(movementDto, injector),
      stockPallet$: this.loadStockPallet$(movementDto),
      warehouse$: this.loadWarehouse$(movementDto),
      article$: this.loadArticle$(movementDto),
      movementType$: this.loadMovementType$(movementDto),
      user$: this.loadUser$(movementDto),
      reception$: this.loadReception$(movementDto),
      supplier$: this.loadSupplier$(movementDto)
    }
  };

  mapToMovementDto$(movement: Movement): Observable<MovementDto> {
    return combineLatest([movement.movementType$, movement.user$, movement.reception$, movement.organization$, movement.company$, movement.warehouse$, movement.supplier$, movement.article$, movement.stock$, movement.stockPallet$]).pipe(
      map(([movementType, user, reception, organization, company, warehouse, supplier, article, stock, stockPallet]) => ({
          id: movement.id,
          movementKind: movementType.movementKind,
          quantity: movement.quantity,
          userId: user?.id,
          deliveryTicket: movement.deliveryTicket,
          receptionId: reception?.id,
          dateTime: movement.dateTime,
          date: movement.date,
          timestamp: movement.timestamp,
          organizationId: organization?.id,
          companyId: company.id,
          warehouseId: warehouse.id,
          supplierId: supplier?.id,
          articleId: article.id,
          stockId: stock?.id,
          stockPalletId: stockPallet.id,
          location: movement.location,
          internalBatchName: movement.internalBatchName,
          supplierBatchName: movement.supplierBatchName,
          comment: movement.comment,
          deliverBeforeDate: movement.deliverBeforeDate,
          unitWeight: movement.unitWeight,
          fullDescription: movement.fullDescription,
          preparation: movement.preparation,
          unitGrossWeight: movement.unitGrossWeight,
          unitsPerParcel: movement.unitsPerParcel,
          demand: movement.demand,
          additionalInfo: movement.additionalInfo,
        })
      )
    );
  }

  private loadArticle$(movementDto: MovementDto): Observable<Article> {
    const articleId = movementDto.articleId;
    return this.articleService.getArticle$(articleId);
  }

  private loadCompany$(movementDto: MovementDto): Observable<Company> {
    const companyId = movementDto.companyId;
    return this.companyService.getCompany$(companyId);
  }

  private loadOrganization$(movementDto: MovementDto): Observable<Organization | undefined> {
    const organizationId = movementDto.organizationId;
    return organizationId ? this.organizationService.getOrganization$(organizationId) : of(undefined);
  }

  private loadWarehouse$(movementDto: MovementDto): Observable<Warehouse> {
    const warehouseId = movementDto.warehouseId;
    return this.warehouseService.getWarehouse$(warehouseId);
  }

  private loadStock$(stockDto: MovementDto, injector: Injector): Observable<Stock | undefined> {
    const stockId = stockDto.stockId;
    return stockId ? this.stockService.getStock$(stockId, injector) : of(undefined);
  }

  private loadStockPallet$(stockPalletDto: MovementDto): Observable<StockPallet> {
    const stockPalletId = stockPalletDto.stockPalletId;
    // done dynamically to avoid circular dependency
    const stockPalletService = this.injector.get(StockPalletService);
    return stockPalletService.getStockPallet$(stockPalletId);
  }

  private loadUser$(movementDto: MovementDto): Observable<User | undefined> {
    const userId = movementDto.userId;
    if (!userId) {
      return of(undefined);
    }

    return this.userService.getUser$(userId);
  }

  private loadMovementType$(movementDto: MovementDto): Observable<MovementType> {
    const movementKind = movementDto.movementKind;
    return this.movementTypeService.getMovementType$(movementKind);
  }

  private loadReception$(movementDto: MovementDto): Observable<Reception | undefined> {
    const receptionId = movementDto.receptionId;

    return receptionId ? this.receptionService.getReception$(receptionId) : of(undefined);
  }

  private loadSupplier$(movementDto: MovementDto): Observable<Supplier | undefined> {
    const supplierId = movementDto.supplierId;

    return supplierId ? this.supplierService.getSupplier$(supplierId) : of(undefined);
  }
}
