<span class="integer-part">{{ integerPartStr }}</span>
<span class="decimal-part"
      [class.has-decimals]="decimalPart"
      [class.no-decimals]="!decimalPart"
      [style.color]="decimalsColor"
      *ngIf="decimals > 0 && decimalPartStr"
>.{{ decimalPartStr }}
</span>
<span *ngIf="!decimals">
  <!-- empty span for styling (space after, ...)-->
</span>
