<div class="card p-fluid" *ngIf="member() as member">
  <div *ngIf="userIds.length > 0" >
    <p i18n="@@MemberUsers">Users : {{ userIds }}</p>
  </div>
  <form #memberForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="familyName" class="col-12 mb-2 md:col-2 md:mb-0" i18n> FamilyName</label>
      <div class="col-12 md:col-10">
        <input #familyName="ngModel"  id ="familyName" name="familyName" type="text" pInputText [(ngModel)]="member.familyName"
               minlength="2" maxlength="50" required ngDefaultControl>
        <p-message i18n-text="@@FamilyNameIsMandatory" *ngIf="familyName.invalid && memberForm.touched" severity="error"
                   text="Family Name is required and must be minimum 2 chars long"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="firstName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>First Name</label>
      <div class="col-12 md:col-10">
        <input #firstName="ngModel"  id="firstName" name="firstName" type="text" pInputText [(ngModel)]="member.firstName"
               required minlength="2" maxlength="40"  ngDefaultControl>
        <p-message i18n-text="@@FirstNameIsMandatory" *ngIf="firstName.invalid && memberForm.touched" severity="error"
                   text="First Name is required and must be minimum 2 chars long"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@Active" for="active" class="col-12 mb-2 md:col-2 md:mb-0">Active</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="active" name="active" [(ngModel)]="member.active"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Organization</label>
      <div class="col-12 md:col-10">
        <foodbank-organization-selection name="organization" ngDefaultControl id="organization" [(ngModel)]="member.organization.value"/>
      </div>
    </div>
    <div class="field grid">
      <label for="warehouse" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Warehouse</label>
      <div class="col-12 md:col-10">
        <foodbank-warehouse-selection name="warehouse" ngDefaultControl id="warehouse"
                                      [(ngModel)]="member.warehouse.value"
                                      *ngIf="warehouseSearch$ | async as warehouseSearch"
                                      [warehouseSearch]="warehouseSearch"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="role" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Role</label>
      <div class="col-12 md:col-10">
        <foodbank-role-selection name="role" ngDefaultControl id="role" [(ngModel)]="member.role.value"/>
      </div>
    </div>
    <div class="field grid">
      <label for="address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
      <div class="col-12 md:col-10">
        <input id="address" type="text" name="address" pInputText [(ngModel)]="member.address" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
      <div class="col-12 md:col-10">
        <input id="zip" type="text" name="zip" pInputText [(ngModel)]="member.zip" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
      <div class="col-12 md:col-10">
        <input id="city" type="text" name="city" pInputText [(ngModel)]="member.city" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
      <div class="col-12 md:col-10">
        <input #email="ngModel"  id="email" type="text" name="email" pInputText [(ngModel)]="member.email"
               required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$" maxlength="50" ngDefaultControl>
        <p-message i18n-text="@@EmailIsMandatory" *ngIf="email.invalid && memberForm.touched" severity="error"
                   text="E-mail is required and must be lowercase and valid"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="phone" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Phone</label>
      <div class="col-12 md:col-10">
        <input id="phone" type="text" pInputText name="phone" [(ngModel)]="member.phone" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="mobile" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Mobile</label>
      <div class="col-12 md:col-10">
        <input id="mobile" type="text" pInputText name="mobile" [(ngModel)]="member.mobile" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@Gender" for="civilite">Gender</label>
      <div class="col-12 md:col-10">
        <p-dropdown [options]="genders" id="civilite" name="civilite" [(ngModel)]="member.civilite"></p-dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="language" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Language</label>
      <div class="col-12 md:col-10">
        <p-dropdown [options]="languages" id="language" optionLabel="language" optionValue="language" name="language" [(ngModel)]="selectedLanguage" ngDefaultControl></p-dropdown>
      </div>
    </div>
    <div class="field grid">
      <label for="remarks" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Remarks</label>
      <div class="col-12 md:col-10">
        <input id="remarks" type="text" pInputText name="remarks" [(ngModel)]="member.remarks" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="nnat" class="col-12 mb-2 md:col-2 md:mb-0" i18n>National Number</label>
      <div class="col-12 md:col-10">
        <input id="nnat" type="text" pInputText name="nnat" [(ngModel)]="member.nnat" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MemberBirthDate" for="birthDate" class="col-12 mb-2 md:col-2 md:mb-0">Birth Date</label>
      <div class="col-12 md:col-10">
        <input #birthDate="ngModel" pInputText id="birthDate" name="birthDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.birthDate"/>
        <p-message i18n-text="@@BirthDateMustBeValid" *ngIf="birthDate.invalid && memberForm.touched" severity="error" text="Birth date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="veh" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle</label>
      <div class="col-12 md:col-10">
        <input id="veh" type="text" pInputText name="veh" [(ngModel)]="member.veh" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="vehPlate" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle Plate</label>
      <div class="col-12 md:col-10">
        <input id="vehPlate" type="text" name="vehPlate" pInputText [(ngModel)]="member.vehPlate" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="vehType" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Vehicle Type</label>
      <div class="col-12 md:col-10">
        <input id="vehType" type="text" pInputText name="vehType" [(ngModel)]="member.vehType" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MgtComittee" for="ca" class="col-12 mb-2 md:col-2 md:mb-0">Mgt Comittee</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="ca" name="ca" [(ngModel)]="member.ca"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@GenAssembly" for="ag" class="col-12 mb-2 md:col-2 md:mb-0">Gen Assembly</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="ag" name="ag" [(ngModel)]="member.ag"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@SteeringComittee" for="cg" class="col-12 mb-2 md:col-2 md:mb-0">Steering Comittee</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="cg" name="cg" [(ngModel)]="member.cg"></p-inputSwitch>
      </div>
    </div>

    <div class="field grid">
      <label i18n="@@MemberStartDate" for="startDate" class="col-12 mb-2 md:col-2 md:mb-0">Start Date</label>
      <div class="col-12 md:col-10">
        <input #startDate="ngModel" pInputText id="startDate" name="startDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.startDate"/>
        <p-message i18n-text="@@startDateInvalid" *ngIf="startDate.invalid && memberForm.touched" severity="error" text="Start Date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@MemberEndDate" for="endDate" class="col-12 mb-2 md:col-2 md:mb-0">End Date</label>
      <div class="col-12 md:col-10">
        <input #datfin="ngModel" pInputText id="endDate" name="endDate" pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy" [(ngModel)]="member.endDate"/>
        <p-message i18n-text="@@EndDateInvalid" *ngIf="datfin.invalid && memberForm.touched" severity="error" text="End Date must in format dd/mm/yyyy"></p-message>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary"  [disabled] = "memberForm.invalid" (onClick)="save(member)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>

