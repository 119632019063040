<p-dropdown [(ngModel)]="ngModel"
            [options]="warehousesPage.value()?.content || []"
            optionLabel="name"
            placeholder="None"
            [filter]="true"
            [required]="required()"
            filterMatchMode="contains"
            filterBy="id,name"
            [showClear]="showClear()"
            [loading]="loading()"
            dataKey="id"
            appendTo="body">
    <ng-template pTemplate="selectedItem">
        <foodbank-warehouse ngDefaultControl [ngModel]="ngModel()" [showCompany]="showCompany()" [showId]="showId()" />
    </ng-template>
    <ng-template let-warehouse pTemplate="item">
        <foodbank-warehouse ngDefaultControl [ngModel]="warehouse" [showCompany]="showCompany()" [showId]="showId()" />
    </ng-template>
</p-dropdown>
