<p-table #dt
         [value]="(organizationPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="organizationPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Organizations</h2>
       <p-multiSelect
         ngDefaultControl
         [options]="ALL_COLUMNS"
         [(ngModel)]="displayedColumns"
         placeholder="Select columns"
       >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
    <div>
      <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" (click)="openCreateDialog()"></button>
      <button pButton pRipple icon="pi pi-download" class="p-button-rounded p-button-primary mr-2" (click)="exportToExcel()"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ID">
        <span i18n>Id</span>
        <p-sortIcon field="ID"/>
      </th>
      <th pSortableColumn="NAME">
        <span i18n>Name</span>
        <p-sortIcon field="NAME"/>
      </th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('refInt') {
            <th>
              <span i18n>Internal Ref</span>
            </th>
          }
          @case ('code') {
            <th>
              <span i18n>'ESF+ Code</span>
            </th>
          }
          @case ('email') {
            <th>
              <span i18n>Email</span>
            </th>
          }
          @case ('phone') {
            <th>
              <span i18n>Phone</span>
            </th>
          }
          @case ('address') {
            <th>
              <span i18n>Address</span>
            </th>
          }
          @case ('zip') {
            <th>
              <span i18n>Zip</span>
            </th>
          }
          @case ('city') {
            <th>
              <span i18n>City</span>
            </th>
          }
          @case ('warehouse') {
            <th>
              <span i18n>Warehouse</span>
            </th>
          }
          @case ('active') {
            <th>
              <span i18n>Active</span>
            </th>
          }
        }
      }
      <th></th>
    </tr>
    <tr>
      <th>
        <input pInputText type="number" [placeholder]="'Search...'" [(ngModel)]="filterIdEquals" ngDefaultControl/>
      <th>
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterNameContains" ngDefaultControl/>
      </th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('refInt') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterInternalRefContains" ngDefaultControl/>
            </th>
          }
          @case ('code') {
            <th>
              <input pInputText type="number" [placeholder]="'Search...'" [(ngModel)]="filterCodeEquals" ngDefaultControl/>
            </th>
          }
          @case ('email') {
            <th>
            </th>
          }
          @case ('phone') {
            <th>
            </th>
          }
          @case ('address') {
            <th>
            </th>
          }
          @case ('zip') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterZipContains" ngDefaultControl/>
            </th>
          }
          @case ('city') {
            <th>

              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
            </th>
          }
          @case ('warehouse') {
            <th>

            </th>
          }
          @case ('active') {
            <th>
              <p-checkbox [binary]="true" [(ngModel)]="filterActive" checked ngDefaultControl/>
            </th>
          }
        }
      }
      <th></th>

    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-organization>
    <ng-container *ngIf="identity(organization) as organization">
      <tr>
        <td>
          {{ organization.id }}
        </td>
        <td>
          {{ organization.name }}
        </td>
        @for (column of displayedColumns(); track column) {
          @switch (column) {
            @case ('refInt') {
              <td>
                {{ organization.refInt }}
              </td>
            }
            @case ('code') {
              <td>
                {{ organization.code }}
              </td>
            }
            @case ('email') {
              <td>
                {{ organization.email }}
              </td>
            }
            @case ('phone') {
              <td>
                {{ generateTelGsm(organization) }}

              </td>
            }
            @case ('address') {
              <td>
                <ng-container *ngIf="getAddress(organization) as completeAddress">
                  <a href="http://maps.google.com/maps?q={{ completeAddress }}" target="_blank">
                    {{ completeAddress }}
                  </a>
                </ng-container>
              </td>
            }
            @case ('zip') {
              <td>
                {{ organization.zip }}
              </td>
            }
            @case ('city') {
              <td>
                {{ organization.city }}
              </td>
            }
            @case ('warehouse') {              <td>

                <foodbank-warehouse ngDefaultControl [ngModel]="organization.warehouse.value()" [showId]="false"/>
              </td>
            }
            @case ('active') {
              <td>
              {{ organization.active ? 'Yes' : 'No' }}
              </td>
            }
          }
        }
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(organization)"></button>
        </td>
        <td>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="delete(organization)"></button>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{width: '50vw'}" />

