<div class="card p-fluid" *ngIf="beneficiary() as beneficiary">
  <form #beneficiaryForm="ngForm" style="padding: 1em">
    <p-tabView>
      <p-tabPanel header="Beneficiary Info">
        <div class="field grid">
          <label i18n="@@Gender" class="col-12 mb-2 md:col-2 md:mb-0" for="gender">Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="gender" name="gender" [(ngModel)]="beneficiary.gender"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="familyName" class="col-12 mb-2 md:col-2 md:mb-0" i18n> Family Name</label>
          <div class="col-12 md:col-10">
            <input #familyName="ngModel"  id ="familyName" name="familyName" type="text" pInputText [(ngModel)]="beneficiary.lastName"
                   minlength="2" maxlength="50" required ngDefaultControl>
            <p-message i18n-text="@@FamilyNameIsMandatory" *ngIf="familyName.invalid && beneficiaryForm.touched" severity="error"
                       text="Family Name is required and must be minimum 2 chars long"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="firstName" class="col-12 mb-2 md:col-2 md:mb-0" i18n>First Name</label>
          <div class="col-12 md:col-10">
            <input #firstName="ngModel"  id="firstName" name="firstName" type="text" pInputText [(ngModel)]="beneficiary.firstName"
                   required minlength="2" maxlength="40"  ngDefaultControl>
            <p-message i18n-text="@@FirstNameIsMandatory" *ngIf="firstName.invalid && beneficiaryForm.touched" severity="error"
                       text="First Name is required and must be minimum 2 chars long"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@MemberBirthDate" for="birthDate" class="col-12 mb-2 md:col-2 md:mb-0">Birth Date</label>
          <div class="col-12 md:col-10">
            <input #birthDate="ngModel" pInputText id="birthDate" name="birthDate" pattern="^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$" placeholder="yyyy-mm-dd" [(ngModel)]="beneficiary.birthDate"/>
            <p-message i18n-text="@@BirthDateMustBeValid" *ngIf="birthDate.invalid && beneficiaryForm.touched" severity="error" text="Birth date must in format yyyy-mm-dd"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@Gender" class="col-12 mb-2 md:col-2 md:mb-0" for="genderPartner">Gender Partner</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="genderPartner" name="genderPartner" [(ngModel)]="beneficiary.partnerGender"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="familyNamePartner" class="col-12 mb-2 md:col-2 md:mb-0" i18n> Family Name Partner</label>
          <div class="col-12 md:col-10">
            <input #familyNamePartner="ngModel"  id ="familyNamePartner" name="familyNamePartner" type="text" pInputText [(ngModel)]="beneficiary.partnerLastName"
                   minlength="2" maxlength="50" ngDefaultControl>
            <p-message i18n-text="@@FamilyNameIsMandatory" *ngIf="familyNamePartner.invalid && beneficiaryForm.touched" severity="error"
                       text="Partner Family Name must be minimum 2 chars long"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="firstNamePartner" class="col-12 mb-2 md:col-2 md:mb-0" i18n>First Name Partner</label>
          <div class="col-12 md:col-10">
            <input #firstNamePartner="ngModel"  id="firstNamePartner" name="firstNamePartner" type="text" pInputText [(ngModel)]="beneficiary.partnerFirstName"
                   minlength="2" maxlength="40"  ngDefaultControl>
            <p-message i18n-text="@@FirstNameIsMandatory" *ngIf="firstNamePartner.invalid && beneficiaryForm.touched" severity="error"
                       text="Partner First Name must be minimum 2 chars long"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@MemberBirthDate" for="birthDatePartner" class="col-12 mb-2 md:col-2 md:mb-0">Birth Date Partner</label>
          <div class="col-12 md:col-10">
            <input #birthDatePartner="ngModel" pInputText id="birthDatePartner" name="birthDatePartner" pattern="^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$" placeholder="yyyy-mm-dd" [(ngModel)]="beneficiary.partnerBirthDate"/>
            <p-message i18n-text="@@BirthDateMustBeValid" *ngIf="birthDatePartner.invalid && beneficiaryForm.touched" severity="error" text="Birth date must in format yyyy-mm-dd"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@Active" for="active" class="col-12 mb-2 md:col-2 md:mb-0">Active</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="active" name="active" [(ngModel)]="beneficiary.active"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Organization</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-selection name="organization" ngDefaultControl id="organization" [(ngModel)]="beneficiary.organization.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
          <div class="col-12 md:col-10">
            <input id="address" type="text" name="address" pInputText [(ngModel)]="beneficiary.address" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
          <div class="col-12 md:col-10">
            <input id="zip" type="text" name="zip" pInputText [(ngModel)]="beneficiary.zip" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
          <div class="col-12 md:col-10">
            <input id="city" type="text" name="city" pInputText [(ngModel)]="beneficiary.city" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="email" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
          <div class="col-12 md:col-10">
            <input #email="ngModel"  id="email" type="text" name="email" pInputText [(ngModel)]="beneficiary.email"
                   pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$" maxlength="50" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="phone" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Phone</label>
          <div class="col-12 md:col-10">
            <input id="phone" type="text" pInputText name="phone" [(ngModel)]="beneficiary.phone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="mobile" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Mobile</label>
          <div class="col-12 md:col-10">
            <input id="mobile" type="text" pInputText name="mobile" [(ngModel)]="beneficiary.mobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@Coeff" class="col-12 mb-2 md:col-2 md:mb-0" for="coeff">Coeff</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="coeffs" id="coeff" name="coeff" [(ngModel)]="beneficiary.coeff"></p-dropdown>
          </div>
        </div>


        <div class="field grid">
          <div class="col-offset-3 col-3">
            <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary"  [disabled] = "beneficiaryForm.invalid" (onClick)="save(beneficiary)"/>
          </div>
          <div class="col-3">
            <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
          </div>
        </div>
      </p-tabPanel>

      <p-tabPanel i18n="@@Dependents" header="Dependents">
        <foodbank-dependent-list ngDefaultControl [beneficiary]="beneficiary"/>
      </p-tabPanel>
    </p-tabView>
  </form>
</div>

