import {inject, Injectable, Injector} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {Pagination} from "@services/pagination";
import {map, Observable} from "rxjs";
import {Page} from "@typedefs/page";
import {BeneficiaryDto, BeneficiarySearchDto, MemberDto} from "@typedefs/stock-rest";
import {environment} from "@environments/environment";
import {FoodbankCache} from "@services/foodabank-cache";
import {Beneficiary, BeneficiaryCreationBase} from "@model/beneficiary";
import {copyCommonFields} from "@model/mapping-utils";
import {BeneficiarySearch} from "@model/search/beneficiary-search";
import {Member, MemberCreationBase} from "@model/member";

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryService {
  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public findBeneficiaries$(beneficiarySearch: BeneficiarySearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Beneficiary>> {
    const beneficiarySearchDto = this.mapToBeneficiarySearchDto(beneficiarySearch);
    return this.#httpClient.post<Page<BeneficiaryDto>>(`${environment.apiUrl}/beneficiaries/search`, beneficiarySearchDto, {params: pagination})
      .pipe(map(beneficiaryPage => this.loadBeneficiaryPage(beneficiaryPage, cache)));
  }

  private loadBeneficiaryPage(beneficiaryDtoPage: Page<BeneficiaryDto>, cache: FoodbankCache): Page<Beneficiary> {
    return {
      ...beneficiaryDtoPage,
      content: this.loadBeneficiaryDetailsList(beneficiaryDtoPage.content, cache)
    };
  }

  public loadBeneficiaryDetailsList(beneficiaryDtos: BeneficiaryDto[], cache: FoodbankCache) {
    return beneficiaryDtos.map(beneficiary => this.mapToBeneficiary(beneficiary, cache));
  }

  saveBeneficiary(beneficiary: Beneficiary | BeneficiaryCreationBase): Observable<Beneficiary> {
    const beneficiaryDto = this.mapToBeneficiaryDto(beneficiary);
    return this.#httpClient.post<BeneficiaryDto>(`${environment.apiUrl}/beneficiaries`, beneficiaryDto)
      .pipe(map(beneficiaryDto => this.mapToBeneficiary(beneficiaryDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  public mapToBeneficiary(beneficiaryDto: BeneficiaryDto, cache: FoodbankCache): Beneficiary {
    const commonFields: Beneficiary | BeneficiaryDto = copyCommonFields(beneficiaryDto, ['companyId', 'organizationId']);
    return {
      ...commonFields,
      company: cache.companyCache.get(beneficiaryDto.companyId),
      organization: cache.organizationCache.getIfDefined(beneficiaryDto.organizationId)
    }
  }
  mapToBeneficiaryDto(beneficiary: BeneficiaryCreationBase): BeneficiaryDto {
    const commonFields: BeneficiaryDto | BeneficiaryCreationBase | Beneficiary = copyCommonFields(beneficiary, ['company', 'organization']) as BeneficiaryDto;

    return {
      ...commonFields,
      companyId: beneficiary.company.value()?.id!,
      organizationId: beneficiary.organization.value()?.id!,
    };
  }

  mapToBeneficiarySearchDto(beneficiarySearch: BeneficiarySearch): BeneficiarySearchDto {
    const commonFields: BeneficiarySearch | BeneficiarySearchDto = copyCommonFields(beneficiarySearch, ['company', 'organization']);
    return {
      ...commonFields,
      companyId: beneficiarySearch.company?.id,
      organizationId: beneficiarySearch.organization?.id
    }
  }


}
