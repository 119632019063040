import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {AuditDto, AuditSearchDto} from '@typedefs/stock-rest';
import {Audit} from "@model/audit";
import {AuditSearch} from "@model/search/audit-search";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getAudits$(): Observable<Audit[]> {
    return this.#httpClient.get<Audit[]>(`${environment.apiUrl}/audits`)
      .pipe(shareReplay());
  }

  public getAudit$(id: string, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Audit> {
    return this.#httpClient.get<AuditDto>(`${environment.apiUrl}/audits/${id}`)
      .pipe(
        map(auditDto => this.mapToAudit(auditDto, cache)),
        shareReplay(),
      );
  }

  public findAudits$(auditSearch: AuditSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Audit>> {
    const auditSearchDto = this.mapToAuditSearchDto(auditSearch);
    return this.#httpClient.post<Page<AuditDto>>(`${environment.apiUrl}/audits/search`, auditSearchDto, {params: pagination})
      .pipe(map(auditPage => this.loadAuditPage(auditPage, cache)));
  }

  private loadAuditPage(auditDtoPage: Page<AuditDto>, cache: FoodbankCache): Page<Audit> {
    return {
      ...auditDtoPage,
      content: this.loadAuditDetailsList(auditDtoPage.content, cache)
    };
  }

  public loadAuditDetailsList(auditDtos: AuditDto[], cache: FoodbankCache) {
    return auditDtos.map(audit => this.mapToAudit(audit, cache));
  }

  updateAudit(audit: Audit): Observable<Audit> {
    const auditDto = this.mapToAuditDto(audit);
    return this.#httpClient.put<AuditDto>(`${environment.apiUrl}/audits/${audit.id}`, auditDto)
      .pipe(map(auditDto => this.mapToAudit(auditDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  public mapToAudit(auditDto: AuditDto, cache: FoodbankCache): Audit {
    const commonFields: Audit | AuditDto = copyCommonFields(auditDto, ['userId',  'organizationId', 'companyId']);
    return {
      ...commonFields,
      user: cache.userCache.get(auditDto.userId),
      organization: cache.organizationCache.getIfDefined(auditDto.organizationId),
      company: cache.companyCache.getIfDefined(auditDto.companyId)

    }
  }

  mapToAuditDto(audit: Audit): AuditDto {
    const auditDto: AuditDto = copyCommonFields(audit, ['user',  'organization','company' ]) as AuditDto;

    return {
      ...auditDto,
      userId: audit.user.value()?.id!,
      organizationId: audit.organization.value()?.id!,
      companyId: audit.company.value()?.id!

    };
  }

  mapToAuditSearchDto(auditSearch: AuditSearch): AuditSearchDto {
    const auditSearchDto: AuditSearch | AuditSearchDto = copyCommonFields(auditSearch, ['user', 'organization','company']);
    return {
      ...auditSearchDto,
      userId: auditSearch.user?.id,
      organizationId: auditSearch.organization?.id,
      companyId: auditSearch.company?.id
    }
  }

}
