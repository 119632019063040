import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Bank} from '@model/bank';
import {BankService} from '@services/bank.service';
import {UserSignalService} from "@services/user-signal.service";
import {BankSearch} from "@model/search/bank-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {BankEditComponent} from "@components/bank/bank-edit/bank-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {rxResource} from "@angular/core/rxjs-interop";
import {formatDate, NgIf} from "@angular/common";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {AuditChangeService} from "@services/audit-change.service";
import {ExcelService} from "@services/excel.service";
import {CheckboxModule} from "primeng/checkbox";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipsModule} from "primeng/chips";
import {joinWhenPresent} from "@util/string-utils";


enum BankColumn {
  address = 'address',
  phone = 'phone',
  mobile = 'mobile',
  email = 'email',
  zip = 'zipCode',
  city = 'city',
  active = 'active'
}

const COLUMN_LABELS: ColumnLabels<BankColumn> = {
  address: 'Address',
  phone: 'Phone',
  mobile: 'Mobile',
  email: 'Email',
  zipCode: 'Zip Code',
  city: 'City',
  active: 'Active'
}

const COLUMN_PREFERENCE_KEY = 'preference_banks_list_columns';

const ALL_COLUMNS: BankColumn[] = Object.values(BankColumn) as BankColumn[];
const DEFAULT_COLUMNS: BankColumn[] = [BankColumn.address,  BankColumn.mobile, BankColumn.phone];


@Component({
  selector: 'foodbank-banks',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.scss'],
  providers: [DialogService,ExcelService],
  imports: [TableModule, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipsModule, NgIf, ConfirmDialogModule, CheckboxModule,]
})
export class BankListComponent {

  // selected search filters for this view
  filterCompanyIdEquals = model('');
  filterNameContains= model('');
  bankSearch: Signal<BankSearch>;

  pagination: WritableSignal<Pagination>;

  // results
  bankPage: Resource<Page<Bank> | undefined>;
  banks: Resource<Bank[] | undefined>;
  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #bankService = inject(BankService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #confirmationService = inject(ConfirmationService);
  #messageService = inject(MessageService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  #injector = inject(Injector);
  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<BankColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});


  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;
    this.bankSearch = computed(() => ({
      companyId: this.filterCompanyIdEquals(),
      nameContains: this.filterNameContains(),
    }));



    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.bankPage = rxResource({
      request: () => ({
        bankSearch: this.bankSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#bankService.findBanks$(this.bankSearch(), this.#injector, this.pagination())
    });
    this.banks = rxResource({
      request: () => ({
        bankSearch: this.bankSearch(),
      }),
      loader: () => this.#bankService.findAllBanks$(this.bankSearch(), this.#injector)
    })
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(bank: Bank): Bank {
    return bank;
  }



  openEditDialog(bank: Bank) {
    console.log('opening edit dialog for bank:', bank);
    this.#dialogRef = this.#dialogService.open(BankEditComponent, {
      header: 'Edit Bank',
      width: '60%',
      data: {...bank}
    });

    this.#dialogRef.onClose.subscribe(bank => {
      if (bank) {
        bank.bankProg.reload();
      }

      this.bankPage.reload(); // reloading page does not reload the bankProg objects
    })
  }




  exportToExcel() {
    const banks = this.banks.value();
    if (!banks) {
      throw new Error('Banks are missing!')
    }
    const cleanedList: any[] = [];
    banks.map((bank) => {
      const region = bank.region.value();
      const regionName = region ? region.regName : '';
      const cleanedItem: any = {};
      cleanedItem[$localize`:@@CompanyId:CompanyId`] = bank.companyId;
      cleanedItem[$localize`:@@Name:Name`] = bank.name;
      cleanedItem[$localize`:@@Region:Region`] = regionName;
      cleanedItem[$localize`:@@Address:Address`] = bank.address
      cleanedItem[$localize`:@@Phone:Phone`] = bank.phone;
      cleanedItem[$localize`:@@Mobile:Mobile`] = bank.mobile;
      cleanedItem[$localize`:@@Email:Email`] = bank.mail;
      cleanedItem[$localize`:@@ZipCode:Zip Code`] = bank.zipCode;
      cleanedItem[$localize`:@@City:City`] = bank.city;
      cleanedItem[$localize`:@@Active:Active`] = bank.active;
      cleanedList.push(cleanedItem);
    });
    const excelFileName = 'foodit.banks.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }

  getColumnLabel(column: BankColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;


  getAddress(bank: Bank) {
    const location = joinWhenPresent(" ", [bank.zipCode, bank.city]);
    return joinWhenPresent(" ,", [bank.address, location]);
  }
}





