import {Component, computed, inject, Injector, model, Resource, ResourceRef, Signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Organization} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {FormsModule} from '@angular/forms';
import {TabViewModule} from 'primeng/tabview';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {NgIf} from "@angular/common";
import {UserService} from "@services/user.service";
import {User, UserS} from "@model/user";
import {MessageService} from "primeng/api";
import {DropdownModule} from "primeng/dropdown";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {rxResource, toSignal} from "@angular/core/rxjs-interop";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {WarehouseService} from "@services/warehouse.service";
import {InputNumberModule} from "primeng/inputnumber";
import {TooltipModule} from "primeng/tooltip";
import {RegionSelectionComponent} from "@components/region/selection/region-selection.component";
import {OrganizationStatusSelectionComponent} from "@components/organization-status/selection/organization-status-selection/organization-status-selection.component";
import {OrganizationActivitySelectionComponent} from "@components/organization-activity/selection/organization-activity-selection/organization-activity-selection.component";
import {OrganizationCategorySelectionComponent} from "@components/organization-category/selection/organization-category-selection.component";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {UserSignalService} from "@services/user-signal.service";
import {AuthenticationService} from "@services/authentication.service";
import {MessageModule} from "primeng/message";
import {DbChangeAction, Language} from "@typedefs/stock-rest";
import {DateSearchComponent} from "@components/date/date-search/date-search.component";
import {OrganizationDistribDaySelectionComponent} from "@components/organization-distrib-day/selection/organization-distrib-day-selection.component";
import {OrganizationDistribWeekSelectionComponent} from "@components/organization-distrib-week/selection/organization-distrib-week-selection.component";
import {OrganizationDistribMonthSelectionComponent} from "@components/organization-distrib-month/selection/organization-distrib-month-selection.component";
import {LanguageInfo} from "@model/languageInfo";
import {LanguageService} from "@services/language.service";
import {CpasSelectionComponent} from "@components/cpas/selection/cpas-selection.component";
import {CpasSearch} from "@model/search/cpas-search";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";

@Component({
  selector: 'foodbank-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.scss'],
  imports: [FormsModule, InputTextModule, InputSwitchModule, Button, NgIf, DropdownModule, TabViewModule,
    WarehouseSingleSelectionComponent, InputNumberModule, TooltipModule, CpasSelectionComponent, RegionSelectionComponent,
    OrganizationStatusSelectionComponent, OrganizationActivitySelectionComponent, OrganizationCategorySelectionComponent,
    MessageModule, OrganizationDistribDaySelectionComponent, OrganizationDistribWeekSelectionComponent, OrganizationDistribMonthSelectionComponent, OrganizationSingleSelectionComponent,
  ]
})
export class OrganizationEditComponent {

  organization = model<Organization>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Organization>);
  #organizationService = inject(OrganizationService);
  #warehouseService = inject(WarehouseService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #languageService = inject(LanguageService);
  #auditChangeService = inject(AuditChangeService);
  readonly #authenticationService = inject(AuthenticationService);
  #injector = inject(Injector);
  currentUser: Signal<User | undefined>;

  genders: any[];
  languages: LanguageInfo[];
  selectedLanguage?: String;

  warehouseSearch: Signal<WarehouseSearch>;
  pickupWarehouseSearch: Signal<WarehouseSearch>;
  cpasSearch: Signal<CpasSearch>;
  readonly #$organization: ResourceRef<Organization>;
  parentOrganization = model<Organization | undefined>();

  constructor() {
    this.organization.set(this.config.data);
    const antenne = this.organization()?.antenne;
    if (antenne) {
      const antenne$ = this.#organizationService.getOrganization$(antenne)
        .subscribe(org => this.parentOrganization.set(org));
    }

    this.currentUser = this.#userService.getCurrentUser();
    const company$ = this.#userService.getCurrentUserCompany$();
    const company = toSignal(company$);
    this.#$organization= this.#organizationService.$$getOrganization(this.organization()?.id!);
    this.warehouseSearch = computed<WarehouseSearch>(() => ({
        company: company(),
        active: true,
      })
    );
    this.pickupWarehouseSearch = computed<WarehouseSearch>(() => ({
        company: company(),
        active: true,
      })
    );
    this.cpasSearch = computed<CpasSearch>(() => ({})
    );

    this.genders = [{label: $localize`:@@PersonTitleNone:None.`, value: null},
      {label: $localize`:@@PersonTitleMister:Mr.`, value: 'MR'},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 'MRS'},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 'MISS'}
    ];

    this.languages = this.#languageService.getLanguageOptions();
    const language = this.languages.find(language => language.language === this.organization()?.language);

    if (language) {
      this.selectedLanguage = language.language;
    }

  }

  save(organization: Organization) {
    const currentUserAsResourceRef = this.#userSignalService.$$getCurrentUser(this.#injector);
    const language = this.languages.find(language => language.language === this.selectedLanguage);
    if (language) {
      organization.language = this.selectedLanguage as Language;
    }
    const company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);
    organization.lastUpdateUserName = this.currentUser()!.name;
    let changeAction: DbChangeAction = "CREATE";
    if (this.organization()?.id) {
      changeAction = "UPDATE";
    }
    const parent =this.parentOrganization()?.id;
    organization.antenne = parent? parent : 0;

    this.#organizationService.updateOrganization(organization)
      .subscribe(organization => {
        this.organization.set(organization);
        const entityKey = organization.id + ' ' + organization.name;
        const auditChange: AuditChangeCreationBase = {
          company: company,
          organization: this.#$organization,
          user: currentUserAsResourceRef,
          entity: "ORG",
          entityKey:entityKey,
          action: changeAction,
        }
        this.#messageService.add({severity: 'success', summary: 'Success', detail: `Organization ${entityKey} ${changeAction} Successful`});
        this.ref.close(organization)
        this.#auditChangeService.createAuditChange(auditChange).subscribe();
      });
  }

  close() {
    this.ref.close({});
  }

  generateTooltipFEADManagedByCPAS() {
    return $localize`:@@OrgCpasCooperation:Does the public authority validate the access of beneficiaries to FEAD ?`;
  }

  generateTooltipEffectiveBeneficiaries() {
    return $localize`:@@OrgEffectiveBeneficiaries:Some beneficiaries benefit only partially of their allocated food( case of split families)`;
  }

  generateTooltipEsfBeneficiaryCount() {
    return $localize`:@@EsfBeneficiaryCount:Number of individuals approved for ESF+ support`;
  }
}
