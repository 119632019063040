import {Component, inject, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Beneficiary, BeneficiaryCreationBase} from '@model/beneficiary';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {LanguageService} from "@services/language.service";
import {LanguageInfo} from "@model/languageInfo";
import {BeneficiaryService} from "@services/beneficiary.service";
import {TabViewModule} from "primeng/tabview";
import {DEFAULT_ROWS_PER_PAGE} from "@services/pagination.service";
import {TableModule} from "primeng/table";
import {MultiSelectModule} from "primeng/multiselect";
import {CheckboxModule} from "primeng/checkbox";
import {DependentListComponent} from "@components/beneficiary/dependents/dependent-list.component";

@Component({
  selector: 'foodbank-beneficiary-edit',
  templateUrl: './beneficiary-edit.component.html',
  styleUrls: ['./beneficiary-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, InputSwitchModule, Button, OrganizationSingleSelectionComponent, MessageModule, DropdownModule, TabViewModule, TableModule, MultiSelectModule, CheckboxModule, DependentListComponent]
})
export class BeneficiaryEditComponent {

  beneficiary = model<Beneficiary | BeneficiaryCreationBase>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Beneficiary>);
  #beneficiaryService = inject(BeneficiaryService);
  #messageService = inject(MessageService);
  #languageService = inject(LanguageService);
  languages: LanguageInfo[];
  genders: any[];
  coeffs: any[];


  constructor() {
    this.beneficiary.set(this.config.data);
    this.languages = this.#languageService.getLanguageOptions();
    this.genders = [{label: $localize`:@@PersonTitleMister:Mr.`, value: 1},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 2},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 3}
    ];
    this.coeffs = [
      {label: $localize`:@@Coeff100:100%`, value: "COEFF_100"},
      {label: $localize`:@@Coeff50:50%`, value: "COEFF_50"},
      {label: $localize`:@@Coeff33:33%`, value: "COEFF_33"},
      {label: $localize`:@@Coeff25:25%`, value: "COEFF_25"},
      {label: $localize`:@@CoeffInvalid:Invalid`, value: "COEFF_INVALID"},
    ];


  }

  save(partialBeneficiary: Beneficiary | BeneficiaryCreationBase) {
    // why? we shouldn't have field in this class for this, just use a language selection component to set beneficiary.language as any other property
    const beneficiary: Beneficiary = <Beneficiary>{
      ...partialBeneficiary,
    };

    this.#beneficiaryService.saveBeneficiary(beneficiary)
      .subscribe(beneficiary => {
        this.beneficiary.set(beneficiary);
        this.ref.close(beneficiary);
        this.#messageService.add({severity: 'success', summary: 'Success', detail: 'Beneficiary updated'});
      });
  }

  close() {
    this.ref.close({});
  }



  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

}
