import {Reception} from "@model/reception";
import {Article} from "@model/article";
import {Supplier} from "@model/supplier";
import {PalletType} from "@model/pallet-type";
import {StockGroup} from "@model/stock-group";
import {TransferRequest} from "@model/transfer-request";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {ArticleCategory} from "@model/article-category";
import {ArticleStorageCondition} from "@model/article-storage-condition";
import {CachedResource} from "@util/cached-resource";
import {inject, Injectable, Injector} from "@angular/core";
import {ArticleService} from "@services/article.service";
import {ArticleCategoryService} from "@services/article-category.service";
import {ArticleStorageConditionService} from "@services/article-storage-condition.service";
import {SupplierService} from "@services/supplier.service";
import {ReceptionService} from "@services/reception.service";
import {PalletTypeService} from "@services/pallet-type.service";
import {StockGroupService} from "@services/stock-group.service";
import {WarehouseService} from "@services/warehouse.service";
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {FoodbankCache} from "@services/foodabank-cache";
import {UserS} from "@model/user";
import {UserService} from "@services/user.service";
import {Company} from "@model/company";
import {Organization} from "@model/organization";
import {CompanyService} from "@services/company.service";
import {OrganizationService} from "@services/organization.service";
import {Role} from "@model/role";
import {RoleService} from "@services/role.service";
import {Warehouse} from "@model/warehouse";
import {FoodbankCacheLongLivedCache} from "@services/foodbank-long-lived-cache";
import {Donor} from "@model/donor";
import {DonorService} from "@services/donor.service";
import {Region} from "@model/region";
import {RegionService} from "@services/region.service";
import {Member} from "@model/member";
import {MemberService} from "@services/member.service";
import {CpasService} from "@services/cpas.service";
import {Cpas} from "@model/cpas";
import {BankService} from "@services/bank.service";
import {Bank} from "@model/bank";
import {BankProgService} from "@services/bankprog.service";
import {BankProg} from "@model/bankprog";

@Injectable({
  providedIn: 'root'
})
export class FoodbankCacheFactory {

  #longLivedCache?: FoodbankCacheLongLivedCache;
  #injector = inject(Injector);


  getLongLivedCache(): FoodbankCacheLongLivedCache {
    if (this.#longLivedCache) {
      return this.#longLivedCache;
    }
    return this.#createLongLivedCache();
  }

  #createLongLivedCache() {
    const articleService = this.#injector.get(ArticleService);
    const articleCategoryService = this.#injector.get(ArticleCategoryService);
    const articleStorageConditionService = this.#injector.get(ArticleStorageConditionService);
    const supplierService = this.#injector.get(SupplierService);
    const palletTypeService = this.#injector.get(PalletTypeService);
    const warehouseService = this.#injector.get(WarehouseService);
    const companyService = this.#injector.get(CompanyService);
    const organizationService = this.#injector.get(OrganizationService);
    const roleService = this.#injector.get(RoleService);
    const userService = this.#injector.get(UserService);
    const donorService = this.#injector.get(DonorService);
    const memberService = this.#injector.get(MemberService);
    const cpasService = this.#injector.get(CpasService);


    this.#longLivedCache = {
      articleCache: new CachedResource<string, Article>(this.#injector, id => articleService.getArticle$(id)),
      articleCategoryCache: new CachedResource<string, ArticleCategory>(this.#injector, id => articleCategoryService.findArticleCategory$(id)),
      articleStorageConditionCache: new CachedResource<string, ArticleStorageCondition>(this.#injector, id => articleStorageConditionService.findArticleStorageCondition$(id)),
      companyCache: new CachedResource<string, Company>(this.#injector, id => companyService.getCompany$(id)),
      supplierCache: new CachedResource<string, Supplier>(this.#injector, id => supplierService.getSupplier$(id)),
      userCache: new CachedResource<string, UserS>(this.#injector, id => userService.getUserS$(id, this.#injector)),
      warehouseCache: new CachedResource<number, Warehouse>(this.#injector, id => warehouseService.getWarehouse$(id)),
      palletTypeCache: new CachedResource<string, PalletType>(this.#injector, id => palletTypeService.getPalletType$(id)),
      organizationCache: new CachedResource<number, Organization>(this.#injector, id => organizationService.getOrganization$(id)),
      roleCache: new CachedResource<number, Role>(this.#injector, id => roleService.getRole$(id, this.#injector)),
      donorCache: new CachedResource<number, Donor>(this.#injector, id => donorService.getDonor$(id)),
      memberCache: new CachedResource<number, Member>(this.#injector, id => memberService.getMember$(id)),
      cpasCache: new CachedResource<number, Cpas>(this.#injector, id => cpasService.getCpas$(id)),
    };

    return this.#longLivedCache;
  }

  create(injector: Injector): FoodbankCache {
    const receptionService = this.#injector.get(ReceptionService);
    const stockGroupService = this.#injector.get(StockGroupService);
    const transferRequestService = this.#injector.get(TransferRequestService);
    const transferRequestTargetService = this.#injector.get(TransferRequestTargetService);
    const regionService = this.#injector.get(RegionService);
    const bankService = this.#injector.get(BankService);
    const bankProgService = this.#injector.get(BankProgService);
    const longLivedCache = this.getLongLivedCache();

    const cache: FoodbankCache = {
      ...longLivedCache,
      receptionCache: new CachedResource<number, Reception>(injector, id => receptionService.getReception$(id)),
      stockGroupCache: new CachedResource<number, StockGroup>(injector, id => stockGroupService.getStockGroup$(id, injector, cache)),
      transferRequestCache: new CachedResource<number, TransferRequest>(injector, id => transferRequestService.getTransferRequest$(id, injector)),
      transferRequestTargetCache: new CachedResource<number, TransferRequestTarget>(injector, id => transferRequestTargetService.getTransferRequestTarget$(id, injector, cache)),
      regionCache: new CachedResource<number, Region>(injector, id => regionService.getRegion$(id, injector, cache)),
      bankCache: new CachedResource<number, Bank>(this.#injector, id => bankService.getBank$(id)),
      bankProgCache: new CachedResource<number, BankProg>(this.#injector, id => bankProgService.getBankProg$(id)),
    };
    return cache;
  }

}
