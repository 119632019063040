<p-dropdown [(ngModel)]="ngModel"
            [options]="userRoles"
            optionLabel="role"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-user-role ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-userRole pTemplate="item">
    <foodbank-user-role ngDefaultControl [ngModel]="userRole" />
  </ng-template>
</p-dropdown>
