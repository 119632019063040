import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {RegionDto, RegionSearchDto} from '@typedefs/stock-rest';
import {Region} from "@model/region";
import {RegionSearch} from "@model/search/region-search";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {copyCommonFields} from "@model/mapping-utils";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getRegions$(): Observable<Region[]> {
    return this.#httpClient.get<Region[]>(`${environment.apiUrl}/regions`)
      .pipe(shareReplay());
  }

  public getRegion$(id: number, injector: Injector, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Region> {
    return this.#httpClient.get<RegionDto>(`${environment.apiUrl}/regions/${id}`)
      .pipe(
        map(regionDto => this.mapToRegion(regionDto, cache)),
        shareReplay(),
      );
  }

  public findRegions$(regionSearch: RegionSearch, pagination: Pagination, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Page<Region>> {
    const regionSearchDto = this.mapToRegionSearchDto(regionSearch);
    return this.#httpClient.post<Page<RegionDto>>(`${environment.apiUrl}/regions/search`, regionSearchDto, {params: pagination})
      .pipe(map(regionPage => this.loadRegionPage(regionPage, cache)));
  }

  private loadRegionPage(regionDtoPage: Page<RegionDto>, cache: FoodbankCache): Page<Region> {
    return {
      ...regionDtoPage,
      content: this.loadRegionDetailsList(regionDtoPage.content, cache)
    };
  }

  public loadRegionDetailsList(regionDtos: RegionDto[], cache: FoodbankCache) {
    return regionDtos.map(region => this.mapToRegion(region, cache));
  }

  updateRegion(region: Region): Observable<RegionDto> {
    // FIXME: convert to RegionDto
    return this.#httpClient.put<RegionDto>(`${environment.apiUrl}/regions/${region.id}`, region)
  }

  public mapToRegion(regionDto: RegionDto, cache: FoodbankCache): Region {
    const commonFields: Region | RegionDto = copyCommonFields(regionDto, ['companyId']);
    return {
      ...commonFields,
      company: cache.companyCache.get(regionDto.companyId),
    }
  }

  mapToRegionDto(region: Region): RegionDto {
    const regionDto: RegionDto = copyCommonFields(region, ['company']) as RegionDto;

    return {
      ...regionDto,
      companyId: region.company.value()?.id!,
    };
  }

    mapToRegionSearchDto(regionSearch: RegionSearch): RegionSearchDto {
      const regionSearchDto: RegionSearch | RegionSearchDto = copyCommonFields(regionSearch, ['company']);
      return {
        ...regionSearchDto,
        companyId: regionSearch.company?.id,

      }
    }

}
