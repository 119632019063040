import {Component, computed, inject, Injector, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {CpasSearch} from '@model/search/cpas-search';
import {Cpas} from '@model/cpas';
import {CpasService} from '@services/cpas.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {CpasComponent} from "@components/cpas/cpas.component";
import {SupplierComponent} from "@components/supplier/supplier.component";

@Component({
  selector: 'foodbank-cpas-selection',
  exportAs: 'cpasSelection',
  templateUrl: './cpas-selection.component.html',
  styleUrls: ['./cpas-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    CpasComponent
  ]
})
export class CpasSelectionComponent {

  ngModel = model<Cpas>();
  required = input(false);
  cpasSearch = input<CpasSearch>({});

  nameFilter = signal('');

  cpass: Signal<Cpas[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #cpasService = inject(CpasService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualCpasSearch: Signal<CpasSearch> = computed(() => ({
      ...this.cpasSearch(),
      nameContains: this.nameFilter(),
    }));

    const cpasPage = loadSignalFromObservable(() => this.#cpasService.findCpass$(actualCpasSearch(), this.pagination()), this.loading);

    this.cpass = computed(() => cpasPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}

