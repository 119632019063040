import { computed, inject, Injectable, Injector } from '@angular/core';
import { MovementType } from '@model/movement-type';
import { Warehouse } from '@model/warehouse';
import { SupplierService } from './supplier.service';
import { rxResource } from '@angular/core/rxjs-interop';
import { Article } from '@model/article';
import { Supplier } from '@model/supplier';
import { Stock } from '@model/stock';
import { Observable, of } from 'rxjs';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import { Movement } from '@model/movement';
import { BulkFoodImportService, COLUMN_MAPPINGS, ImportRow } from './bulk-food-import.service';

export interface ReceptionImportRow extends ImportRow<ReceptionImportRow> {
  supplierName: string;
  supplierId: string;
  supplierRef: ReturnType<typeof rxResource<Supplier | undefined, Error>>;
}

interface ExcelRow {
  __rowNum__: number;
  [key: string]: any;
}

export function createRowReceptionIdentifier(row: Partial<ReceptionImportRow>): string {
  return `${row.yearMonth}_${row.supplierId}_${row.productCode}`;
}

@Injectable({
  providedIn: 'root'
})
export class BulkFoodReceptionImportService extends BulkFoodImportService<ReceptionImportRow> {
  readonly #supplierService = inject(SupplierService);

  protected override mapExcelToImportRows(data: ExcelRow[], warehouse: Warehouse, movementType: MovementType, injector: Injector): ReceptionImportRow[] {
    return data.map(item => this.#mapReceptionRow(item, warehouse, movementType, injector));
  }

  #mapReceptionRow(item: ExcelRow, warehouse: Warehouse, movementType: MovementType, injector: Injector): ReceptionImportRow {
    const rowNumber = item.__rowNum__;
    const yearMonth = this.cleanString(item[COLUMN_MAPPINGS.yearMonth]);
    const productId = this.cleanString(item[COLUMN_MAPPINGS.productId]);
    const postalCode = this.cleanString(item[COLUMN_MAPPINGS.postalCode]);
    const city = this.cleanString(item[COLUMN_MAPPINGS.city]);
    const productCode = this.cleanString(item[COLUMN_MAPPINGS.productCode]);
    const quantity = Number(this.cleanString(item[COLUMN_MAPPINGS.quantity]));
    const article = this.cleanString(item[COLUMN_MAPPINGS.article]);
    const unit = this.cleanString(item[COLUMN_MAPPINGS.unit]);
    const supplierId = this.cleanString(item[COLUMN_MAPPINGS.supplierId]);
    const supplierName = this.cleanString(item[COLUMN_MAPPINGS.supplierName]);

    const rowIdentifier = createRowReceptionIdentifier({
      yearMonth,
      productCode,
      supplierId
    });

    if (rowNumber === 1) {
      this.loadImportMovements(yearMonth, warehouse, movementType, injector);
    }

    const partialRow: Omit<ReceptionImportRow, 'articleRef' | 'stockRef' | 'supplierRef' | 'movementRef' | 'isValid'> = {
      rowNumber, 
      yearMonth, 
      productId, 
      postalCode, 
      city, 
      productCode, 
      quantity, 
      article, 
      unit,
      rowIdentifier,
      supplierName,
      supplierId,
      isModified: false,
      isDuplicate: false,
      movementType
    };

    return this.mapImportRowResources(partialRow, injector);
  }

  updateMovementRef$(warehouse: Warehouse | undefined, yearMonth: string, productId: string, supplierId: string, rowIdentifier: string, movementType: MovementType, injector: Injector): Observable<Movement | undefined> {
    if (!productId || !supplierId || !warehouse) return of(undefined);
    const movementRef = this.articleService.getArticle$(productId).pipe(
      switchMap(article => {
        if (!article) return of(undefined);
        return this.#supplierService.getSupplier$(supplierId).pipe(
          switchMap(supplier => {
            if (!supplier) return of(undefined);
            return this.movementService.findMovements$({
              supplier: supplier,
              supplierBatchName: this.computeBatchName(yearMonth),
              warehouse: warehouse,
              commentContains: `[${rowIdentifier}]`,
              movementTypes: [movementType]
            }, { page: 0, size: 1 }, injector).pipe(
              map(page => page.content[0])
            );
          })
        );
      }),
      shareReplay()
    );

    movementRef.subscribe(movement => this.bulkFoodImportMovementService.updateMovement(movement));

    return movementRef;
  }

  override mapImportRowResources(
    item: Omit<ReceptionImportRow, 'articleRef' | 'stockRef' | 'supplierRef' | 'movementRef' | 'isValid'>,
    injector: Injector
  ): ReceptionImportRow {
    const articleRef = rxResource<Article | undefined, Error>({
      loader: () => this.articleService.getArticle$(item.productId!),
      injector
    });

    const stockRef = rxResource<Stock | undefined, Error>({
      loader: () => this.loadStock$(item.productId!, injector),
      injector
    });

    const supplierRef = rxResource<Supplier | undefined, Error>({
      loader: () => this.#supplierService.getSupplier$(item.supplierId!),
      injector
    });

    const movementRef = rxResource<Movement | undefined, Error>({
      loader: () => this.bulkFoodImportMovementService.$findMovementForImportRow(item.rowIdentifier!),
      injector
    });

    const isValid = (row: ReceptionImportRow, duplicates: Set<string>) => computed(() => {
      if (articleRef.isLoading() || supplierRef.isLoading()) {
        return undefined;
      }

      return articleRef.value() !== undefined && supplierRef.value() !== undefined && !duplicates.has(row.rowIdentifier!);
    });

    return { ...item, articleRef, stockRef, supplierRef, movementRef, isValid };
  }
} 