import {Component, model} from '@angular/core';
import {OrganizationStatus} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-status',
  templateUrl: './organization-status.component.html',
  styleUrls: ['./organization-status.component.scss'],
  imports: [NgIf]
})
export class OrganizationStatusComponent {

  ngModel = model<OrganizationStatus>();

}
