<div class="card p-fluid" *ngIf="organization() as organization">
  <form #organizationForm="ngForm" style="padding: 1em">
    <p-tabView>
      <p-tabPanel i18n="@@CompanyInfo" header="Company Info">
        <div class="field grid">
          <label for="name" class="col-12 mb-2 md:col-2 md:mb-0">Name</label>
          <div class="col-12 md:col-10">
            <input #name="ngModel" id="name" type="text" required name="name" pInputText minlength = "4" maxlength="100"
                   [(ngModel)]="organization.name" ngDefaultControl>
            <p-message i18n-text="@@OrganisationIsMandatory" *ngIf="name.invalid && organizationForm.touched"
                       severity="error" text="Organisation Name is required and must be minimum 4 chars long"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-internal-ref" class="col-12 mb-2 md:col-2 md:mb-0">Internal Reference</label>
          <div class="col-12 md:col-10">
            <input id="organization-internal-ref" type="text" name="legalName" pInputText [(ngModel)]="organization.refInt" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-active" class="col-12 mb-2 md:col-2 md:mb-0">Active ?</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="organization-active" name="organization-active" [(ngModel)]="organization.active"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label  for="status" class="col-12 mb-2 md:col-2 md:mb-0" >Status</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-status-selection name="status" ngDefaultControl id="status" [(ngModel)]="organization.status"/>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-agreed" class="col-12 mb-2 md:col-2 md:mb-0">Agreed</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="organization-agreed" name="organization-agreed" [(ngModel)]="organization.suppliedNonMember"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label  for="ReceivesESF" class="col-12 mb-2 md:col-2 md:mb-0">Org Receives ESF+ ?</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="ReceivesESF" name="ReceivesESF" [(ngModel)]="organization.organizationReceivesESF"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="organization.organizationReceivesESF" class="field grid">
          <label for="organization-code" class="col-12 mb-2 md:col-2 md:mb-0"> ESF+ Number</label>
          <div class="col-12 md:col-10">
            <input id="organization-code" type="text" name="organization-code" pInputText [(ngModel)]="organization.code" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-isCPasInvolved" class="col-12 mb-2 md:col-2 md:mb-0">Is Social House Involved ?</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="organization-isCPasInvolved" name="organization-isCPasInvolved" [(ngModel)]="organization.cpasInvolved"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="organization.cpasInvolved" class="field grid">
          <label  for="organization-cpas" class="col-12 mb-2 md:col-2 md:mb-0">Involved Social House</label>
          <div class="col-12 md:col-10">
            <ng-container *ngIf="cpasSearch() as cpasSearch">
              <foodbank-cpas-selection
                ngDefaultControl
                id="organization-cpas"
                name="organization-cpas"
                [(ngModel)]="organization.involvedCpas.value"
                [cpasSearch]="cpasSearch"
              />
            </ng-container>
          </div>
        </div>
        <div class="field grid">
          <label for="region" class="col-12 mb-2 md:col-2 md:mb-0" >Region</label>
          <div class="col-12 md:col-10">
            <foodbank-region-selection name="region" ngDefaultControl id="region" [(ngModel)]="organization.region.value"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="organizationIsAWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">Organization is a Warehouse ?</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="organizationIsAWarehouse" name="organizationIsAWarehouse" [(ngModel)]="organization.organizationIsAWarehouse"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="organization.organizationIsAWarehouse" class="field grid">
          <label  for="mainWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">Main Warehouse ?</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="mainWarehouse" name="mainWarehouse" [(ngModel)]="organization.mainWarehouse"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label for="warehouse" class="col-12 mb-2 md:col-2 md:mb-0">Warehouse</label>
          <div class="col-12 md-10">
            <ng-container *ngIf="warehouseSearch() as warehouseSearch">
              <foodbank-warehouse-selection
                ngDefaultControl
                id="warehouse"
                name="warehouse"
                [(ngModel)]="organization.warehouse.value"
                [required]="true"
                #warehouse="ngModel"
                [warehouseSearch]="warehouseSearch"
              />
              <p-message i18n-text="@@WarehouseIsMandatory" *ngIf="warehouse.invalid && organizationForm.touched"
                         severity="error" text="Warehouse Specification is required"></p-message>
            </ng-container>


          </div>
        </div>
        <div class="field grid">
          <label for="pickupWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">Pickup Warehouse</label>
          <div class="col-12 md-10">
            <ng-container *ngIf="pickupWarehouseSearch() as pickupWarehouseSearch">
              <foodbank-warehouse-selection
                ngDefaultControl
                id="pickupWarehouse"
                name="pickupWarehouse"
                [(ngModel)]="organization.pickupWarehouse.value"
                [required]="true"
                #warehousePickup="ngModel"
                [warehouseSearch]="pickupWarehouseSearch"
              />
              <p-message i18n-text="@@WarehousePickupIsMandatory" *ngIf="warehousePickup.invalid && organizationForm.touched"
                         severity="error" text="Warehouse Pickup Specification is required"></p-message>
            </ng-container>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-address" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Address</label>
          <div class="col-12 md:col-10">
            <input id="organization-address" type="text" name="address" pInputText  maxlength="100" [(ngModel)]="organization.address" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-zip" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Zip Code</label>
          <div class="col-12 md:col-10">
            <input id="organization-zip" type="text" pInputText name="zip" [(ngModel)]="organization.zip" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-city" class="col-12 mb-2 md:col-2 md:mb-0">Distribution City</label>
          <div class="col-12 md:col-10">
            <input id="organization-city" type="text" pInputText name="city" [(ngModel)]="organization.city" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-address2" class="col-12 mb-2 md:col-2 md:mb-0">Headquarters Address</label>
          <div class="col-12 md:col-10">
            <input id="organization-address2" type="text" name="address2" pInputText  maxlength="100" [(ngModel)]="organization.address2" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-zip2" class="col-12 mb-2 md:col-2 md:mb-0">Headquarters Zip Code</label>
          <div class="col-12 md:col-10">
            <input id="organization-zip2" type="text" pInputText name="zip2" [(ngModel)]="organization.zip2" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-city2" class="col-12 mb-2 md:col-2 md:mb-0">Headquarters City</label>
          <div class="col-12 md:col-10">
            <input id="organization-city2" type="text" pInputText name="city2" [(ngModel)]="organization.city2" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-email" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
          <div class="col-12 md:col-10">
            <input id="organization-email" type="text" pInputText name="email" [(ngModel)]="organization.email" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-phone" class="col-12 mb-2 md:col-2 md:mb-0">Phone</label>
          <div class="col-12 md:col-10">
            <input id="organization-phone" type="text" pInputText name="phone" [(ngModel)]="organization.phone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-mobile" class="col-12 mb-2 md:col-2 md:mb-0">Mobile</label>
          <div class="col-12 md:col-10">
            <input id="organization-mobile" type="text" pInputText name="mobile" [(ngModel)]="organization.mobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-vatnumber" class="col-12 mb-2 md:col-2 md:mb-0">VAT Number</label>
          <div class="col-12 md:col-10">
            <input id="organization-vatnumber" type="text" pInputText name="vatnumber" [(ngModel)]="organization.vatNumber" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-bank" class="col-12 mb-2 md:col-2 md:mb-0">BANK</label>
          <div class="col-12 md:col-10">
            <input id="organization-bank" type="text" pInputText name="nank" [(ngModel)]="organization.banque" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-iban" class="col-12 mb-2 md:col-2 md:mb-0">IBAN</label>
          <div class="col-12 md:col-10">
            <input id="organization-iban" type="text" pInputText name="iban" [(ngModel)]="organization.iban" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-bic" class="col-12 mb-2 md:col-2 md:mb-0">BIC</label>
          <div class="col-12 md:col-10">
            <input id="organization-bic" type="text" pInputText name="bic" [(ngModel)]="organization.bic" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="language" class="col-12 mb-2 md:col-2 md:mb-0" >Language</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="languages" id="language" optionLabel="language" optionValue="language" name="language" [(ngModel)]="selectedLanguage" ngDefaultControl></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-website" class="col-12 mb-2 md:col-2 md:mb-0">Website</label>
          <div class="col-12 md:col-10">
            <input id="organization-website" type="text" pInputText name="website" [(ngModel)]="organization.website" ngDefaultControl>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Management" header="Management">
        <div class="field grid">
          <label for="presidentTitle" class="col-12 mb-2 md:col-2 md:mb-0">President Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="presidentTitle" name="presidentTitle" [(ngModel)]="organization.presidentTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentFirstName" class="col-12 mb-2 md:col-2 md:mb-0">President First Name</label>
          <div class="col-12 md-10">
            <input id="presidentFirstName" type="text" pInputText name="presidentFirstName" [(ngModel)]="organization.presidentFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">President Family Name</label>
          <div class="col-12 md-10">
            <input id="presidentFamilyName" type="text" pInputText name="presidentFamilyName" [(ngModel)]="organization.presidentFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentEmail" class="col-12 mb-2 md:col-2 md:mb-0">President Email</label>
          <div class="col-12 md-10">
            <input id="presidentEmail" type="text" pInputText name="presidentEmail" [(ngModel)]="organization.presidentEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="presidentPhone" class="col-12 mb-2 md:col-2 md:mb-0">President Phone</label>
          <div class="col-12 md-10">
            <input id="presidentPhone" type="text" pInputText name="presidentPhone" [(ngModel)]="organization.presidentPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentTitle" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="vicePresidentTitle" name="vicePresidentTitle" [(ngModel)]="organization.vicePresidentTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Vice President First Name</label>
          <div class="col-12 md-10">
            <input id="vicePresidentFirstName" type="text" pInputText name="vicePresidentFirstName" [(ngModel)]="organization.vicePresidentFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Family Name</label>
          <div class="col-12 md-10">
            <input id="vicePresidentFamilyName" type="text" pInputText name="vicePresidentFamilyName" [(ngModel)]="organization.vicePresidentFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentEmail" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Email</label>
          <div class="col-12 md-10">
            <input id="vicePresidentEmail" type="text" pInputText name="vicePresidentEmail" [(ngModel)]="organization.vicePresidentEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentPhone" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Phone</label>
          <div class="col-12 md-10">
            <input id="vicePresidentPhone" type="text" pInputText name="vicePresidentPhone" [(ngModel)]="organization.vicePresidentPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="vicePresidentMobile" class="col-12 mb-2 md:col-2 md:mb-0">Vice President Mobile</label>
          <div class="col-12 md-10">
            <input id="vicePresidentMobile" type="text" pInputText name="vicePresidentMobile" [(ngModel)]="organization.vicePresidentMobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryTitle" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="secretaryTitle" name="secretaryTitle" [(ngModel)]="organization.secretaryTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Secretary First Name</label>
          <div class="col-12 md-10">
            <input id="secretaryFirstName" type="text" pInputText name="secretaryFirstName" [(ngModel)]="organization.secretaryFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Family Name</label>
          <div class="col-12 md-10">
            <input id="secretaryFamilyName" type="text" pInputText name="secretaryFamilyname" [(ngModel)]="organization.secretaryFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryEmail" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Email</label>
          <div class="col-12 md-10">
            <input id="secretaryEmail" type="text" pInputText name="secretaryEmail" [(ngModel)]="organization.secretaryEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryPhone" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Phone</label>
          <div class="col-12 md-10">
            <input id="secretaryPhone" type="text" pInputText name="secretaryPhone" [(ngModel)]="organization.secretaryPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="secretaryMobile" class="col-12 mb-2 md:col-2 md:mb-0">Secretary Mobile</label>
          <div class="col-12 md-10">
            <input id="secretaryMobile" type="text" pInputText name="secretaryMobile" [(ngModel)]="organization.secretaryMobile" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerTitle" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Gender</label>
          <div class="col-12 md:col-10">
            <p-dropdown [options]="genders" id="treasurerTitle" name="treasurerTitle" [(ngModel)]="organization.treasurerTitle"></p-dropdown>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerFirstName" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer First Name</label>
          <div class="col-12 md-10">
            <input id="treasurerFirstName" type="text" pInputText name="treasurerFirstName" [(ngModel)]="organization.treasurerFirstName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerFamilyName" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Family Name</label>
          <div class="col-12 md-10">
            <input id="treasurerFamilyName" type="text" pInputText name="treasurerFamilyName" [(ngModel)]="organization.treasurerFamilyName" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerEmail" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Email</label>
          <div class="col-12 md-10">
            <input id="treasurerEmail" type="text" pInputText name="treasurerEmail" [(ngModel)]="organization.treasurerEmail" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerPhone" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Phone</label>
          <div class="col-12 md-10">
            <input id="treasurerPhone" type="text" pInputText name="treasurerPhone" [(ngModel)]="organization.treasurerPhone" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurerMobile" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer Mobile</label>
          <div class="col-12 md-10">
            <input id="treasurerMobile" type="text" pInputText name="treasurerMobile" [(ngModel)]="organization.treasurerMobile" ngDefaultControl>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="ExtendedInfo">
        <div class="field grid">
          <label for="organization-parent" class="col-12 mb-2 md:col-2 md:mb-0">Parent Organization</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-selection
              ngDefaultControl
              id="organization-parent"
              name="organization-parent"
              [(ngModel)]="parentOrganization"
            />
          </div>
        </div>
        <div class="field grid">
          <label for="organization-afsca" class="col-12 mb-2 md:col-2 md:mb-0"> AFSCA affiliation number</label>
          <div class="col-12 md:col-10">
            <input id="organization-afsca" type="text" name="organization-afsca" pInputText [(ngModel)]="organization.afsca" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-afsca1" class="col-12 mb-2 md:col-2 md:mb-0"> AFSCA additional affiliation number</label>
          <div class="col-12 md:col-10">
            <input id="organization-afsca1" type="text" name="organization-afsca1" pInputText [(ngModel)]="organization.afsca1" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-afsca2" class="col-12 mb-2 md:col-2 md:mb-0"> AFSCA additional affiliation number</label>
          <div class="col-12 md:col-10">
            <input id="organization-afsca2" type="text" name="organization-afsca2" pInputText [(ngModel)]="organization.afsca2" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="organization-afsca3" class="col-12 mb-2 md:col-2 md:mb-0"> AFSCA additional affiliation number</label>
          <div class="col-12 md:col-10">
            <input id="organization-afsca3" type="text" name="organization-afsca3" pInputText [(ngModel)]="organization.afsca3" ngDefaultControl>
          </div>
        </div>

        <div class="field grid">
          <label  for="organizationIsAGuestHouse" class="col-12 mb-2 md:col-2 md:mb-0">Organization is a Guest House ? ?</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="organizationIsAGuestHouse" name="organizationIsAGuestHouse" [(ngModel)]="organization.msonac"></p-inputSwitch>
          </div>
        </div>
        <div *ngIf="organization.msonac" class="field grid">
          <label  for="guestHouseCapacity" class="col-12 mb-2 md:col-2 md:mb-0">Capacity of the Guest House ?</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbrefix" id="guestHouseCapacity" name="guestHouseCapacity" mode="decimal" [showButtons]="true" inputId="nbPers" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label  for="category1" class="col-12 mb-2 md:col-2 md:mb-0" >Category</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-category-selection name="category1" ngDefaultControl id="category1" [(ngModel)]="organization.classeFbba1"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="category2" class="col-12 mb-2 md:col-2 md:mb-0" >Category</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-category-selection name="category2" ngDefaultControl id="category2" [(ngModel)]="organization.classeFbba2"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="category3" class="col-12 mb-2 md:col-2 md:mb-0" >Category</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-category-selection name="category3" ngDefaultControl id="category3" [(ngModel)]="organization.classeFbba3"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity1" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity1" ngDefaultControl id="activity1" [(ngModel)]="organization.actComp1"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity2" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity2" ngDefaultControl id="activity2" [(ngModel)]="organization.actComp2"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity3" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity3" ngDefaultControl id="activity3" [(ngModel)]="organization.actComp3"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity4" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity4" ngDefaultControl id="activity4" [(ngModel)]="organization.actComp4"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity5" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity5" ngDefaultControl id="activity5" [(ngModel)]="organization.actComp5"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity6" class="col-12 mb-2 md:col-2 md:mb-0" >Activity</label>
          <div class="col-12 md:col-10">
            <foodbank-organization-activity-selection name="activity6" ngDefaultControl id="activity6" [(ngModel)]="organization.actComp6"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="activity7" class="col-12 mb-2 md:col-2 md:mb-0" > Other Activity</label>
          <div class="col-12 md:col-10">
            <input id="activity7" type="text" name="activity7" pInputText [(ngModel)]="organization.actComp7" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label  for="suspended" class="col-12 mb-2 md:col-2 md:mb-0" >Suspended</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="suspended" name="suspended" [(ngModel)]="organization.suspended"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label  for="suspendedEndDate" class="col-12 mb-2 md:col-2 md:mb-0" >Suspended End Date</label>
          <div class="col-12 md:col-10">
            <input id="suspendedEndDate" type="text" name="suspendedEndDate" pInputText
                   pattern="^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$" placeholder="dd/mm/yyyy"
                   [(ngModel)]="organization.stopSusp" ngDefaultControl>
          </div>
        </div>

      </p-tabPanel>
      <p-tabPanel header="Beneficiaries">
        <div class="field grid">
          <label  for="beneficiariesManagement" class="col-12 mb-2 md:col-2 md:mb-0">Manage Beneficiaries ?</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="beneficiariesManagement" name="beneficiariesManagement" [(ngModel)]="organization.beneficiariesManagement"></p-inputSwitch>
          </div>
        </div>

        <div *ngIf="organization.organizationReceivesESF" class="field grid">
          <label i18n="@@ESFNbBeneficiaries" for="EsfBeneficiaryCount" pTooltip="{{generateTooltipEsfBeneficiaryCount()}}" class="col-12 mb-2 md:col-2 md:mb-0">ESF Nb of Beneficiaries</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nrFead" id="EsfBeneficiaryCount" name="EsfBeneficiaryCount" mode="decimal" [showButtons]="true" inputId="beneficiaryCount" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@EffectiveNbBeneficiaries" for="beneficiaryCount" pTooltip="{{generateTooltipEffectiveBeneficiaries()}}" class="col-12 mb-2 md:col-2 md:mb-0">Effective Foodbanks Nb of Beneficiaries</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.beneficiaryCount" id="beneficiaryCount" name="beneficiaryCount" mode="decimal" [showButtons]="true" inputId="beneficiaryCount" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationNbBeneficiaries" for="nbPers" class="col-12 mb-2 md:col-2 md:mb-0">Nb of Beneficiaries</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbPers" id="nbPers" name="nbPers" mode="decimal" [showButtons]="true" inputId="nbPers" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationbFamilies" for="nbFam" class="col-12 mb-2 md:col-2 md:mb-0">Families</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbFam" id="nbFam" name="nbFam" mode="decimal" [showButtons]="true" inputId="nbFam" [min]="0">
            </p-inputNumber>
          </div>
        </div>

        <div class="field grid">
          <label i18n="@@organizationInfants" for="nbNour" class="col-12 mb-2 md:col-2 md:mb-0">Infants (< 6 months)</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbNour" id="nbNour" name="nbNour" mode="decimal" [showButtons]="true" inputId="nbNour" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationBabies" for="nbBebe" class="col-12 mb-2 md:col-2 md:mb-0">Babies (> 6 months and < 24 months)</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbBebe" id="nbBebe" name="nbBebe" mode="decimal" [showButtons]="true" inputId="nbBebe" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationChildren" for="nbEnf" class="col-12 mb-2 md:col-2 md:mb-0">Children (from 2 to 14 years)</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbEnf" id="nbEnf" name="nbEnf" mode="decimal" [showButtons]="true" inputId="nbEnf" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationTeens" for="nbAdo" class="col-12 mb-2 md:col-2 md:mb-0">Teens (from 14 to 18 years)</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbAdo" id="nbAdo" name="nbAdo" mode="decimal" [showButtons]="true" inputId="nbAdo" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationYoungAdults" for="nb1824" class="col-12 mb-2 md:col-2 md:mb-0">Young Adults( 18-24) </label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nb1824" id="nb1824" name="nb1824" mode="decimal" [showButtons]="true" inputId="nb1824" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label i18n="@@organizationbSeniors" for="nSen" class="col-12 mb-2 md:col-2 md:mb-0">Seniors</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nbSenior" id="nSen" name="nSen" mode="decimal" [showButtons]="true" inputId="nSen" [min]="0">
            </p-inputNumber>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Distribution Program" header="Distribution">
        <div class="field grid">
          <label  for="preferredSupplyDay" class="col-12 mb-2 md:col-2 md:mb-0">Day</label>
          <div class="col-12 md-10">
            <foodbank-organization-distrib-day-selection name="preferredSupplyDay" ngDefaultControl id="preferredSupplyDay" [(ngModel)]="organization.preferredSupplyDay"/>
            </div>
        </div>
        <div class="field grid">
          <label  for="preferredSupplyWeek" class="col-12 mb-2 md:col-2 md:mb-0">Week</label>
          <div class="col-12 md-10">
            <foodbank-organization-distrib-week-selection name="preferredSupplyWeek" ngDefaultControl id="preferredSupplyWeek" [(ngModel)]="organization.preferredSupplyWeek"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="preferredSupplyMonth" class="col-12 mb-2 md:col-2 md:mb-0">Month</label>
          <div class="col-12 md-10">
            <foodbank-organization-distrib-month-selection name="preferredSupplyMonth" ngDefaultControl id="preferredSupplyMonth" [(ngModel)]="organization.preferredSupplyMonth"/>
          </div>
        </div>
        <div class="field grid">
          <label  for="distributionRemarks" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Remarks</label>
          <div class="col-12 md-10">
            <input id="distributionRemarks" type="text" name="distributionRemarks" pInputText [(ngModel)]="organization.remLivr" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label  for="distributionRouteCode" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Route Code</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.nrTournee" id="distributionRouteCode" name="distributionRouteCode" mode="decimal" [showButtons]="true" inputId="distributionRouteCode" [min]="0"/>
          </div>
        </div>

      </p-tabPanel>
      <p-tabPanel i18n="@@Membership" header="Membership">
        <div class="field grid">
          <label  for="annualcontribution" class="col-12 mb-2 md:col-2 md:mb-0">Annual Contribution</label>
          <div class="col-12 md-10">
            <p-inputSwitch id="annualcontribution" name="annualcontribution" [(ngModel)]="organization.annualContribution"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label  for="additionalContribution" class="col-12 mb-2 md:col-2 md:mb-0">Additional Contribution Amount</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.additionalContribution" id="additionalContribution" name="additionalContribution" mode="decimal" [showButtons]="true" inputId="additionalContribution" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label  for="numberOfMonthsProRataContribution" class="col-12 mb-2 md:col-2 md:mb-0">Number Of Months ProRataContribution</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.numberOfMonthsProRataContribution" id="numberOfMonthsProRataContribution" name="numberOfMonthsProRataContribution" mode="decimal" [showButtons]="true" inputId="additionalContribution" [min]="0">
            </p-inputNumber>
          </div>
        </div>
        <div class="field grid">
          <label  for="numberOfMonthsAdditionalContribution" class="col-12 mb-2 md:col-2 md:mb-0">Number Of Months Additional Contribution</label>
          <div class="col-12 md-10">
            <p-inputNumber [(ngModel)]="organization.numberOfMonthsAdditionalContribution" id="numberOfMonthsAdditionalContribution" name="numberOfMonthsAdditionalContribution" mode="decimal" [showButtons]="true" inputId="additionalContribution" [min]="0">
            </p-inputNumber>
          </div>
        </div>

      </p-tabPanel>
    </p-tabView>
    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" [disabled] = "organizationForm.invalid" (onClick)="save(organization)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>

