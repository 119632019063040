<div class="card p-fluid" *ngIf="bank() as bank">
  <form #bankForm="ngForm" style="padding: 1em">
    <p-tabView>
      <p-tabPanel i18n="@@CompanyInfo" header="Bank Info">

        <div class="field grid">
          <label for="active" class="col-12 mb-2 md:col-2 md:mb-0">Active ?</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="active" name="active" [(ngModel)]="bank.active"></p-inputSwitch>
          </div>
        </div>

        <div class="field grid">
          <label for="region" class="col-12 mb-2 md:col-2 md:mb-0">Region</label>
          <div class="col-12 md:col-10">
            <foodbank-region-selection name="region" ngDefaultControl id="region" [(ngModel)]="bank.region.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="address" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Address</label>
          <div class="col-12 md:col-10">
            <input id="address" type="text" name="address" pInputText required maxlength="100" [(ngModel)]="bank.address" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="zipCode" class="col-12 mb-2 md:col-2 md:mb-0">Distribution Zip Code</label>
          <div class="col-12 md:col-10">
            <input id="zipCode" type="text" pInputText name="zipCode" required [(ngModel)]="bank.zipCode" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="city" class="col-12 mb-2 md:col-2 md:mb-0">Distribution City</label>
          <div class="col-12 md:col-10">
            <input #bankcity="ngModel" id="city" type="text" pInputText name="city" required [(ngModel)]="bank.city" ngDefaultControl>
            <p-message i18n-text="@@EmailIsMandatory" *ngIf="bankcity.invalid && bankForm.touched" severity="error" text="City is required"></p-message>
          </div>
        </div>

        <div class="field grid">
          <label for="mail" class="col-12 mb-2 md:col-2 md:mb-0">Email</label>
          <div class="col-12 md:col-10">
            <input #bankmail="ngModel" pInputText id="mail" name="mail"  required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" maxLength="50" [(ngModel)]="bank.mail"  ngDefaultControl/>
            <p-message i18n-text="@@EmailIsMandatory" *ngIf="bankmail.invalid && bankForm.touched" severity="error" text="E-mail is required and must be lowercase and valid"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="phone" class="col-12 mb-2 md:col-2 md:mb-0">Phone</label>
          <div class="col-12 md:col-10">
            <input  #bankphone="ngModel" id="phone" type="text" pInputText name="phone" required [(ngModel)]="bank.phone" ngDefaultControl>
            <p-message i18n-text="@@PhoneIsMandatory" *ngIf="bankphone.invalid && bankForm.touched" severity="error" text="Phone is required"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="mobile" class="col-12 mb-2 md:col-2 md:mb-0">Mobile</label>
          <div class="col-12 md:col-10">
            <input #bankmobile="ngModel" id="mobile" type="text" pInputText name="mobile" required [(ngModel)]="bank.mobile" ngDefaultControl>
            <p-message i18n-text="@@PhoneIsMandatory" *ngIf="bankmobile.invalid && bankForm.touched" severity="error" text="Mobile is required"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="headQuartersAdress" class="col-12 mb-2 md:col-2 md:mb-0">HeadQuarters Address</label>
          <div class="col-12 md:col-10">
            <input id="headQuartersAdress" type="text" name="headQuartersAdress" pInputText maxlength="100" [(ngModel)]="bank.headQuartersAdress" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="headQuartersZipCode" class="col-12 mb-2 md:col-2 md:mb-0">HeadQuarters Zip Code</label>
          <div class="col-12 md:col-10">
            <input id="headQuartersZipCode" type="text" pInputText name="headQuartersZipCode" [(ngModel)]="bank.headQuartersZipCode" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="headQuartersCity" class="col-12 mb-2 md:col-2 md:mb-0">HeadQuarters City</label>
          <div class="col-12 md:col-10">
            <input id="headQuartersCity" type="text" pInputText name="headQuartersCity" [(ngModel)]="bank.headQuartersCity" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="headQuartersPhone" class="col-12 mb-2 md:col-2 md:mb-0">HeadQuarters Phone</label>
          <div class="col-12 md:col-10">
            <input id="headQuartersPhone" type="text" name="headQuartersPhone" pInputText maxlength="100" [(ngModel)]="bank.headQuartersPhone" ngDefaultControl>
          </div>
        </div>

        <div class="field grid">
          <label for="companyRegistrationNr" class="col-12 mb-2 md:col-2 md:mb-0">Company Registration Nr</label>
          <div class="col-12 md:col-10">
            <input #registrationnr="ngModel" id="companyRegistrationNr" type="text" name="companyRegistrationNr" required pInputText maxlength="20" [(ngModel)]="bank.companyRegistrationNr" ngDefaultControl>
            <p-message i18n-text="@@RegistrationNrIsMandatory" *ngIf="registrationnr.invalid && bankForm.touched" severity="error" text="Registration Nr is required"></p-message>
          </div>
        </div>
        <div class="field grid">
          <label for="mainWarehouseAddress" class="col-12 mb-2 md:col-2 md:mb-0">Main Warehouse Address</label>
          <div class="col-12 md:col-10">
            <input id="mainWarehouseAddress" type="text" name="mainWarehouseAddress" pInputText maxlength="100" [(ngModel)]="bank.mainWarehouseAddress" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="mainWarehouseZip" class="col-12 mb-2 md:col-2 md:mb-0">Main Warehouse Zip Code</label>
          <div class="col-12 md:col-10">
            <input id="mainWarehouseZip" type="text" name="mainWarehouseZip" pInputText maxlength="20" [(ngModel)]="bank.mainWarehouseZipCode" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="mainWarehouseCity" class="col-12 mb-2 md:col-2 md:mb-0">Main Warehouse City</label>
          <div class="col-12 md:col-10">
            <input id="mainWarehouseCity" type="text" name="mainWarehouseCity" pInputText maxlength="100" [(ngModel)]="bank.mainWarehouseCity" ngDefaultControl>
          </div>
        </div>
        <div class="field grid">
          <label for="bank" class="col-12 mb-2 md:col-2 md:mb-0">BANK</label>
          <div class="col-12 md:col-10">
            <input id="bank" type="text" pInputText name="nank" [(ngModel)]="bank.bank" ngDefaultControl>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel i18n="@@Management" header="Management">
        <div class="field grid">
          <label for="hasMgtComittee" class="col-12 mb-2 md:col-2 md:mb-0">Has Mgt Committee ?</label>
          <div class="col-12 md:col-10">
            <p-inputSwitch id="hasMgtComittee" name="hasMgtComittee" [(ngModel)]="bank.hasMgtComittee"></p-inputSwitch>
          </div>
        </div>
        <div class="field grid">
          <label for="president" class="col-12 mb-2 md:col-2 md:mb-0">President</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="president" [required]="false" ngDefaultControl id="president" [(ngModel)]="bank.president.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="vice-president" class="col-12 mb-2 md:col-2 md:mb-0">Vice President</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="vice-president" [required]="false" ngDefaultControl id="vice-president" [(ngModel)]="bank.vicePresident.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="treasurer" class="col-12 mb-2 md:col-2 md:mb-0">Treasurer</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="treasurer" [required]="false" ngDefaultControl id="treasurer" [(ngModel)]="bank.treasurer.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="secretary" class="col-12 mb-2 md:col-2 md:mb-0">Secretary</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="secretary" [required]="false" ngDefaultControl id="secretary" [(ngModel)]="bank.secretary.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="ceo" class="col-12 mb-2 md:col-2 md:mb-0">CEO</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="ceo" [required]="false" ngDefaultControl id="ceo" [(ngModel)]="bank.ceo.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="logisticsManager" class="col-12 mb-2 md:col-2 md:mb-0">Logistics Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="logisticsManager" [required]="false" ngDefaultControl id="logisticsManager" [(ngModel)]="bank.logisticsManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="itmanager" class="col-12 mb-2 md:col-2 md:mb-0">IT Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="itmanager" [required]="false" ngDefaultControl id="itmanager" [(ngModel)]="bank.itmanager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="qualityManager" class="col-12 mb-2 md:col-2 md:mb-0">Quality Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="qualityManager" [required]="false" ngDefaultControl id="qualityManager" [(ngModel)]="bank.qualityManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="hrmanager" class="col-12 mb-2 md:col-2 md:mb-0">HR Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="hrmanager" [required]="false" ngDefaultControl id="hrmanager" [(ngModel)]="bank.hrmanager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="publicRelationsManager" class="col-12 mb-2 md:col-2 md:mb-0">Public Relations Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="publicRelationsManager" [required]="false" ngDefaultControl id="publicRelationsManager" [(ngModel)]="bank.publicRelationsManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="securityAndHealthManager" class="col-12 mb-2 md:col-2 md:mb-0">Security And Health Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="securityAndHealthManager" [required]="false" ngDefaultControl id="securityAndHealthManager" [(ngModel)]="bank.securityAndHealthManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="associationManager" class="col-12 mb-2 md:col-2 md:mb-0">Association Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="associationManager" [required]="false" ngDefaultControl id="associationManager" [(ngModel)]="bank.associationManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="supplyManager" class="col-12 mb-2 md:col-2 md:mb-0">Supply Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="supplyManager" [required]="false" ngDefaultControl id="supplyManager" [(ngModel)]="bank.supplyManager.value"/>
          </div>
        </div>
        <div class="field grid">
          <label for="esfmanager" class="col-12 mb-2 md:col-2 md:mb-0">ESF+ Manager</label>
          <div class="col-12 md:col-10">
            <foodbank-member-selection name="esfmanager" [required]="false" ngDefaultControl id="esfmanager" [(ngModel)]="bank.esfmanager.value"/>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel *ngIf="bank.bankProg.value()" i18n="@@Program" header="Program">
        <foodbank-bank-edit-bank-program-tab name="bank-program" [(bankProg)]="bank.bankProg.value" ngDefaultControl/>
      </p-tabPanel>
      <p-tabPanel *ngIf="bank.bankProg.value()" i18n="@@MemberShip" header="MemberShip">
        <foodbank-bank-edit-membership-tab name="bank-membership" [(bankProg)]="bank.bankProg.value" ngDefaultControl/>
      </p-tabPanel>
    </p-tabView>
    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" [disabled]="bankForm.invalid" (onClick)="save(bank, bank.bankProg.value())"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>


