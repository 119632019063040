import {Component, input, model} from '@angular/core';
import {OrganizationStatus} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationStatusComponent} from "@components/organization-status/organization-status.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-status-selection',
  imports: [
    DropdownModule,
    OrganizationStatusComponent,
    FormsModule
  ],
  templateUrl: './organization-status-selection.component.html',
  styleUrl: './organization-status-selection.component.scss'
})
export class OrganizationStatusSelectionComponent {
  ngModel = model<OrganizationStatus>();
  required = input(false);

  organizationStatuses: OrganizationStatus[];

  constructor() {

    this.organizationStatuses = [  "NONE" , "NON_PROFIT" , "UNREGISTERED" , "CPAS" , "PUBLIC_AUXILIARY" , "ONE_PERSON"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}

