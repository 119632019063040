import {Component, input, model} from '@angular/core';
import {User} from "@model/user";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'foodbank-deprecated-user',
  templateUrl: './deprecated-user.component.html',
  imports: [
    TooltipModule
  ],
  styleUrls: ['./deprecated-user.component.scss']
})
/**
 * @deprecated avoid, User should be migrated to UserS.
 * TODO: Delete this component once every dependency has migrated away from it.
 */
export class UserComponent {
  ngModel = model<User>();
  showId = input(false);
  showName = input(true);
}
