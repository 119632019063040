import {Component, computed, inject, Injector, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {MemberSearch} from '@model/search/member-search';
import {Member} from '@model/member';
import {MemberService} from '@services/member.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {MemberComponent} from "@components/member/member.component";
import {SupplierComponent} from "@components/supplier/supplier.component";
import {UserService} from "@services/user.service";

@Component({
  selector: 'foodbank-member-selection',
  exportAs: 'memberSelection',
  templateUrl: './member-selection.component.html',
  styleUrls: ['./member-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    MemberComponent
  ]
})
export class MemberSelectionComponent {

  ngModel = model<Member>();
  required = input(false);
  memberSearch = input<MemberSearch>({});

  nameFilter = signal('');

  members: Signal<Member[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #memberService = inject(MemberService);
  #userService = inject(UserService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualMemberSearch: Signal<MemberSearch> = computed(() => ({
      ...this.memberSearch(),
      company: currentUserCompany(),
      familyNameContains: this.nameFilter(),
    }));

    const memberPage = loadSignalFromObservable(() => this.#memberService.findMembers$(actualMemberSearch(), this.#injector, this.pagination()), this.loading);

    this.members = computed(() => memberPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}
