import {Component, inject, Injector, model, resource} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Member, MemberCreationBase} from '@model/member';
import {MemberService} from '@services/member.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {RoleSelectionComponent} from "@components/role/selection/role-selection.component";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {map, Observable, shareReplay} from "rxjs";
import {Warehouse} from "@model/warehouse";
import {UserService} from "@services/user.service";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {DropdownModule} from "primeng/dropdown";
import {LanguageService} from "@services/language.service";
import {LanguageInfo} from "@model/languageInfo";
import {AuditChangeDto, DbChangeAction, Language} from "@typedefs/stock-rest";
import {UserSignalService} from "@services/user-signal.service";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChange, AuditChangeCreationBase} from "@model/audit-change";

@Component({
  selector: 'foodbank-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, RoleSelectionComponent, InputSwitchModule, Button, OrganizationSingleSelectionComponent, MessageModule, AsyncPipe, WarehouseSingleSelectionComponent, DropdownModule]
})
export class MemberEditComponent {

  member = model<Member | MemberCreationBase>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Member>);
  #memberService = inject(MemberService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #languageService = inject(LanguageService);
  #auditChangeService = inject(AuditChangeService);
  languages: LanguageInfo[];
  warehouse$: Observable<Warehouse | undefined>;
  warehouseSearch$: Observable<WarehouseSearch>
  genders: any[];
  selectedLanguage?: String;
  userIds: string = '';
  #injector = inject(Injector);

  constructor() {
    this.member.set(this.config.data);
    this.warehouse$ = this.config.data?.warehouse$ || this.#userService.getDefaultWarehouse$();
    const currentUserCompany$ = this.#userService.getCurrentUserCompany$().pipe(shareReplay());
    this.warehouseSearch$ = currentUserCompany$.pipe(
      map(company => ({company}))
    );
    this.languages = this.#languageService.getLanguageOptions();
    this.genders = [{label: $localize`:@@PersonTitleMister:Mr.`, value: 1},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 2},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 3}
    ];
    const language = this.languages.find(language => language.language === this.member()?.language);

    if (language) {
      this.selectedLanguage = language.language;
    }
    const memberUsers = this.#userSignalService.findUsersOfMember$(<number>this.member()!.id)
      .subscribe(users => {
         users.map((user) => {
          this.userIds += user.id + ' ';
        })
      });

  }

  save(partialMember: Member | MemberCreationBase) {
    // why? we shouldn't have field in this class for this, just use a language selection component to set member.language as any other property
    const language = this.languages.find(language => language.language === this.selectedLanguage);
    if (language) {
      partialMember.language = this.selectedLanguage as Language;
    }

    const firstName = partialMember.firstName;
    const familyName = partialMember.familyName;

    if (!firstName || !familyName) {
      return;
    }

    const member: Member = {
      ...partialMember,
      // add mandatory fields, as we now are sure they are set (constrained by `if` above)
      firstName,
      familyName,
    };
    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    let changeAction: DbChangeAction = "CREATE";
    if (this.member()?.id) {
       changeAction = "UPDATE";
    }
    this.#memberService.saveMember(member)
      .subscribe(member => {
        this.member.set(member);
        const auditChange: AuditChangeCreationBase = {
          company: member.company,
          organization: member.organization,
          user: currentUser,
          entity: "MEMBER",
          entityKey: member.familyName + ' ' + member.firstName,
          action: changeAction,
        }

        this.#messageService.add({severity: 'success', summary: 'Success', detail: `Member ${member.familyName} ${member.firstName}  ${changeAction} successful`});
        this.ref.close(member);
        this.#auditChangeService.createAuditChange(auditChange).subscribe();
      });
  }

  close() {
    this.ref.close({});
  }

}
