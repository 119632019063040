import { Component, computed, model } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { EditorModule } from "primeng/editor";
import { BankProg } from "src/app/model/bankprog";
import { InputNumberModule } from "primeng/inputnumber";
import { NgIf } from "@angular/common";

@Component({
    selector: 'foodbank-bank-edit-membership-tab',
    templateUrl: './bank-edit-membership-tab.component.html',
    imports: [FormsModule, InputSwitchModule, InputTextModule, EditorModule, InputNumberModule, NgIf]
})
export class BankEditMembershipTabComponent {

    bankProg = model<BankProg>(); // strange bug if I rename this to ngModel, the property two-way bindings push value into the model instead of the property
    membershipMailingTextIsCustom = computed(() => this.bankProg()?.membershipMailingTextIsCustom ?? false);

    mailingTextNl: string;
    mailingTextFr: string;


    constructor() {
        const bankProg = this.bankProg();

        this.mailingTextFr = this.initializeMemberShipMailingFr(bankProg);
        this.mailingTextNl = this.initializeMemberShipMailingNl(bankProg);
    }

    initializeMemberShipMailingFr(bankProg: BankProg | undefined) {
        if (bankProg && (bankProg.membershipMailingTextFr != null && bankProg.membershipMailingTextFr.length > 0)) {
            return bankProg.membershipMailingTextFr;
        }

        let mailingTextDefaultFr = `<Strong>NOTE DE DEBIT<br>{{Nom Organisation}}</strong><br>{{Adresse Organisation}}<br>{{Code Postal Organisation}}<br>{{Commune Organisation}}<br><br>`;
        mailingTextDefaultFr += `Ce mail vous est adressé afin de vous demander de bien vouloir règler votre {{Type Cotisation}}`;
        mailingTextDefaultFr += ` de votre association soit {{Montant Cotisation}}  Euro pour {{Nb de Mois}} mois par bénéficiaire`;
        mailingTextDefaultFr += `<br>La moyenne des bénéficiaires pour l'année écoulée pour votre association était de {{Nb de Personnes}} personnes`;
        mailingTextDefaultFr += `<br>Merci de verser le montant de  {{Montant dû}} € sur le compte {{Numéro Compte Bancaire}} au plus tard le <b> {{Date échéance}} </b> avec la mention <b>"COTISATION MEMBRES{{Supplémentaire}}{{Année de Cotisation}}".</b><br>`;
        mailingTextDefaultFr += `<br>Avec nos remerciements anticipés.<br><br>Le trésorier,<br>{{Trésorier}}<br>{{Nom Banque Alimentaire}}<br>N° Entreprise: {{N° Entreprise Banque Alimentaire}} `;
        mailingTextDefaultFr += `Adresse: {{Adresse Banque Alimentaire}} {{Code Postal Banque Alimentaire}} {{Commune Banque Alimentaire}} {{Téléphone Banque Alimentaire}}`;
        mailingTextDefaultFr += '<br><br><i>>Note: Facture sur demande</i>';
        return mailingTextDefaultFr;
    }

    initializeMemberShipMailingNl(bankProg: BankProg | undefined) {
        if (bankProg && (bankProg.membershipMailingTextNl != null && bankProg.membershipMailingTextNl.length > 0)) {
            return bankProg.membershipMailingTextNl;
        }

        let mailingTextDefaultNl = `<Strong>DEBETNOTA<br>{{organisatieNaam}}</strong><br>{{organisatieAdres}}<br>{{organisatiePostCode}}<br>{{organisatieGemeente}}<br><br>`;
        mailingTextDefaultNl += `Geachte mevrouw/mijnheer,<br>Hierbij vindt u het verzoek tot betaling van de {{Type Bijdrage}}`;
        mailingTextDefaultNl += ` van uw liefdadigheidsvereniging aan onze Voedselbank. De basis bijdrage bedraagt {{BijdrageBedrag}}  Euro voor {{Aantal Maanden}} maand per minderbedeelde`;
        mailingTextDefaultNl += `<br>Het gemiddeld aantal begunstigden voor het voorbije jaar voor uw vereniging bedroeg {{Aantal Personen}}`;
        mailingTextDefaultNl += `<br>Gelieve het bedrag van {{Verschuldigd Bedrag}} € te willen storten op ons  rekeningnr {{Bank Rekening Nummer}} ten laatste tegen <b> {{Verval Datum}} </b> met melding <b>"LEDENBIJDRAGE{{Extra}}{{Jaar Bijdrage}}"</b>.<br>`;
        mailingTextDefaultNl += `<br>Met dank bij voorbaat.<br><br>De Penningmeester,<br>{{Schatbewaarder}}<br>{{Naam Voedselbank}}<br>Bedrijfsnummer: {{BedrijfsNummer Voedselbank}} `;
        mailingTextDefaultNl += `Adres: {{Adres Voedselbank}} {{PostCode Voedselbank}} {{Gemeente Voedselbank}} {{Telefoon Voedselbank}}`;
        mailingTextDefaultNl += '<br><br><i>Nota: Factuur te verkrijgen op aanvraag</i>';
        return mailingTextDefaultNl;
    }
}

