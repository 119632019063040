import {Component, input, model} from '@angular/core';
import {Region} from "@model/region";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-region',
  imports: [
    NgIf
  ],
  templateUrl: './region.component.html',
  styleUrl: './region.component.scss'
})
export class RegionComponent {
  ngModel = model<Region>();
  showId = input(true);

}
