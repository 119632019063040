import { Component, computed, inject, Injector, model, OnInit, Signal, signal } from '@angular/core';
import { Movement } from "@model/movement";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { EMPTY, filter, firstValueFrom, forkJoin, map, Observable, of, shareReplay, switchMap, take } from "rxjs";
import { MovementService } from "@services/movement.service";
import { WarehouseSearch, warehouseSearchByCompany } from "@model/search/warehouse-search";
import { UserService } from "@services/user.service";
import { FilterMetadata, MessageService } from "primeng/api";
import { Warehouse } from "@model/warehouse";
import { Article } from "@model/article";
import { MovementType } from "@model/movement-type";
import { Supplier } from "@model/supplier";
import { FoodbankDatePickerEvent } from "@components/date/date-picker/date-picker.component";
import { MovementTypeService } from "@services/movement-type.service";
import { User } from "@model/user";
import { Stock } from "@model/stock";
import { Reception } from "@model/reception";
import { Organization } from "@model/organization";
import { StockPallet } from "@model/stock-pallet";
import { StockService } from "@services/stock.service";
import { MovementTypeSearch } from "@model/search/movement-type-search";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { MovementTypeSingleSelectionComponent } from '../../movement-type/selection/single/movement-type-single-selection.component';
import { FormsModule } from '@angular/forms';
import { SupplierSelectionComponent } from '../../supplier/selection/single/supplier-selection.component';
import { ArticleSingleSelectionComponent } from '../../article/selection/single/article-single-selection.component';
import { InputTextModule } from 'primeng/inputtext';
import { WarehouseSingleSelectionComponent } from '../../warehouse/selection/single/warehouse-single-selection.component';
import { AsyncPipe, formatDate, NgIf } from '@angular/common';
import { OrganizationSingleSelectionComponent } from '../../organization/selection/single/organization-single-selection.component';
import { PalletSingleSelectionComponent } from '../../pallet/selection/single/pallet-single-selection.component';
import { DatePickerComponent } from '../../date/date-picker/date-picker.component';
import { PalletComponent } from '../../pallet/pallet.component';
import { Button } from 'primeng/button';
import { StockGroup } from "@model/stock-group";
import { MovementKind } from "@typedefs/stock-rest";

type TableFilters = { [p: string]: FilterMetadata | FilterMetadata[] };

export type NewMovementDialogType = {
  movement?: Partial<Movement>,
  stockGroup?: StockGroup
  articleChangeTarget?: 'ARTICLE' | 'FULL_DESCRIPTION' | 'DLD'
}

@Component({
  selector: 'foodbank-new-movement-dialog',
  templateUrl: './movement-dialog.component.html',
  styleUrl: './movement-dialog.component.scss',
  imports: [MovementTypeSingleSelectionComponent, FormsModule, SupplierSelectionComponent, ArticleSingleSelectionComponent, InputTextModule, WarehouseSingleSelectionComponent, NgIf, OrganizationSingleSelectionComponent, PalletSingleSelectionComponent, DatePickerComponent, PalletComponent, Button, AsyncPipe]
})
export class MovementDialogComponent implements OnInit {

  movementInput = model<Partial<Movement> | undefined>(undefined, {alias: 'movement'});
  date = model<Date>();
  bestBeforeDate = model<Date>();
  expirationDate = model<Date>();
  deliverBeforeDate = model<Date>();

  movement: Signal<Partial<Movement> | undefined>;

  private movementService = inject(MovementService);
  private userService = inject(UserService);
  private movementTypeService = inject(MovementTypeService);
  private stockService = inject(StockService);
  private messageService = inject(MessageService);
  #injector = inject(Injector);


  warehouseSearch: Signal<WarehouseSearch | undefined>;

  filters: TableFilters = {};
  movementTypeSearch = signal<MovementTypeSearch>({
    movementKinds: MovementTypeService.ALLOWED_MOVEMENT_KINDS
  });

  stockGroup: StockGroup | undefined;

  onlyUpdateQuantity: Signal<boolean>;

  isArticleChange = computed(() => this.movementKind() === 'ARTICLE_CHANGE');
  canUpdateMovementType = computed(() => !this.canUpdateArticle() && !this.canUpdateDescription() && !this.canUpdateDeliverBeforeDate());
  canUpdateQuantity= computed(() => !this.canUpdateArticle() && !this.canUpdateDescription() && !this.canUpdateDeliverBeforeDate());
  canUpdateSupplier = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateComment = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateWarehouse = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateOrganization = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateLocation = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdatePallet = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateDate = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateSupplierBatch = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateSupplierBestBeforeDate = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateSupplierExpirationDate = computed(() => !this.onlyUpdateQuantity() && !this.isArticleChange());
  canUpdateArticle: Signal<boolean>;
  canUpdateDescription: Signal<boolean>;
  canUpdateDeliverBeforeDate: Signal<boolean>
  movementKind: Signal<MovementKind | undefined>;

  constructor(private ref: DynamicDialogRef, config: DynamicDialogConfig<NewMovementDialogType>) {
    this.movement = computed(() => this.movementInput() ?? config.data?.movement);
    this.stockGroup = config.data?.stockGroup;
    const articleChangeTarget = config.data?.articleChangeTarget;

    const currentUserCompany = this.userService.getCurrentUserCompany();
    this.warehouseSearch = computed(() => warehouseSearchByCompany(currentUserCompany()));

    this.movementKind = toSignal(toObservable(this.movement).pipe(
      switchMap(movement => movement?.movementType$ ?? EMPTY),
      map(movementType => movementType?.movementKind),
      shareReplay())
    );

    this.onlyUpdateQuantity = computed(() => this.movementKind() !== undefined &&
      ['INVENTORY_PLUS', 'INVENTORY_MINUS', 'KITCHEN_OUT', 'DESTRUCTION', 'DONTATION_TO_VOLUNTEERS'].includes(this.movementKind()!))

    this.canUpdateArticle = computed(() => this.isArticleChange() && articleChangeTarget === 'ARTICLE')
    this.canUpdateDescription = computed(() => this.isArticleChange() && articleChangeTarget === 'FULL_DESCRIPTION')
    this.canUpdateDeliverBeforeDate = computed(() => this.isArticleChange() && articleChangeTarget === 'DLD')
  }

  ngOnInit() {
    const defaultMovementType$ = this.movementTypeService.getMovementType$('INVENTORY_PLUS');
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$().pipe(
      filter((warehouse): warehouse is Warehouse => warehouse !== undefined)
    );

    const movement: Partial<Movement> = this.movement() || {
      movementType$: defaultMovementType$,
      warehouse$: defaultWarehouse$,
      company$: currentUserCompany$,
      article$: of({} as Article),
      supplier$: of({} as Supplier),
      user$: of({} as User),
      stock$: of({} as Stock),
      reception$: of({} as Reception),
      organization$: of({} as Organization),
      stockPallet$: of({} as StockPallet),
    };
    this.movementInput.set(movement);

  }

  saveAndClose(movement: Partial<Movement>) {
    this.save(movement as Movement, this.#injector)
      .subscribe({
        next: _ => {
          console.log('should close now');
          this.ref.close(movement)
        },
        error: error => this.messageService.add({severity: 'error', summary: 'Error', detail: error?.error?.message ?? error?.message})
      });
  }

  close() {
    this.ref.close({});
  }

  save(movement: Movement, injector: Injector): Observable<Movement> {
    if (this.canUpdateArticle() || this.canUpdateDescription() || this.canUpdateDeliverBeforeDate()) {
      const stockSearch = { stockGroupSearch: { stockGroup: this.stockGroup } };
      const stocks$ = this.stockService.findStock(stockSearch, { page: 0, size: 1000}, this.#injector)
        .pipe(map(page => page.content));

      return stocks$.pipe(switchMap(stocks => {
        const now = new Date();
        const movements: Partial<Movement>[] = stocks.map(stock => ({
          ...movement,
          quantity: 0,
          company$: stock.company$,
          warehouse$: stock.warehouse$,
          stock$: of(stock),
          stockPallet$: stock.pallet$,
          date: now,
          timestamp: now,
          dateTime: now
        } as Partial<Movement>));
        const saveMovement$ = movements.map(m =>
          this.movementService.saveMovement(m as Movement, injector).pipe(take(1))
        )

        return forkJoin(saveMovement$).pipe(
          map(savedMovement => {
            const updatedMovement = savedMovement.find(sm => sm.id === movement.id);

            this.movementInput.set(updatedMovement);

            return updatedMovement!;
          }
        ))}));
    } else {
      const updatedMovement$ = this.movementService.saveMovement(movement, injector)

      updatedMovement$.subscribe(movement => {
        this.movementInput.set(movement);
      })

      return updatedMovement$;
    }
  }

  handleMovementTypeSelected(movement: Partial<Movement>, movementType?: MovementType) {
    const movementType$ = movementType ? of(movementType) : undefined;
    const updatedMovement = {
      ...movement,
      movementType$: movementType$,
      comment: undefined
    };
    this.movementInput.set(updatedMovement);
  }

  handleSupplierSelected(movement: Partial<Movement>, supplier?: Supplier) {
    const updatedMovement = {
      ...movement,
      supplier$: of(supplier)
    };
    this.movementInput.set(updatedMovement);
  }

  async handleArticleSelected(movement: Partial<Movement>, article?: Article) {
    if (article && movement.movementType$) {
      const movementType = await firstValueFrom(movement.movementType$);
      const stockGroupArticleId = this.stockGroup?.article.value()?.id;
      const comment = movementType.movementKind === 'ARTICLE_CHANGE' && stockGroupArticleId !== undefined && stockGroupArticleId !== article.id
        ? `Article changed to ${article.id} from ${stockGroupArticleId}`.slice(0, 60)
        : movement.comment;
      const updatedMovement = {
        ...movement,
        article$: of(article),
        comment
      };
      this.movementInput.set(updatedMovement);
    }
  }

  handleWarehouseSelected(movement: Partial<Movement>, warehouse?: Warehouse) {
    const warehouse$ = warehouse ? of(warehouse) : undefined;
    const updatedMovement = {
      ...movement,
      warehouse$: warehouse$
    };
    this.movementInput.set(updatedMovement);
  }

  handleOrganizationSelected(movement: Partial<Movement>, organization: Organization) {
    const updatedMovement = {
      ...movement,
      organization$: of(organization)
    };
    this.movementInput.set(updatedMovement);
  }

  handleDateSelected(movement: Partial<Movement>, date: FoodbankDatePickerEvent) {
    const updatedMovement = {
      ...movement,
      date: this.date()
    };
    this.movementInput.set(updatedMovement);
  }

  async handleFullDescriptionChanged(movement: Partial<Movement>, fullDescription: string) {
    if (fullDescription && movement.movementType$) {
      const movementType = await firstValueFrom(movement.movementType$);
      const newFullDescription = fullDescription;
      const oldFullDescription = this.stockGroup?.fullDescription;

      const comment = movementType.movementKind === 'ARTICLE_CHANGE' && newFullDescription !== undefined && newFullDescription !== oldFullDescription
        ? `Full description changed to '${newFullDescription}' from '${oldFullDescription}'`.slice(0, 60)
        : movement.comment;
      const updatedMovement = {
        ...movement,
        fullDescription: newFullDescription,
        comment
      };
      this.movementInput.set(updatedMovement);
    }
  }

  async handleDeliverBeforeDateSelected(movement: Partial<Movement>, deliverBeforeDate: Date | Date[] | undefined) {
    if (deliverBeforeDate && movement.movementType$) {
      const movementType = await firstValueFrom(movement.movementType$);
      const newDeliverBeforeDate = deliverBeforeDate as Date;
      const stockGroupDeliverBeforeDate = this.stockGroup?.deliverBeforeDate;
      this.deliverBeforeDate.set(newDeliverBeforeDate as Date);
      const newDate = newDeliverBeforeDate ? formatDate(newDeliverBeforeDate as Date, 'dd-MM-yyyy', 'en') : undefined;
      const oldDate = stockGroupDeliverBeforeDate ? formatDate(stockGroupDeliverBeforeDate, 'dd-MM-yyyy', 'en') : undefined;
      const comment = movementType.movementKind === 'ARTICLE_CHANGE' && newDate !== undefined && newDate !== oldDate
        ? `Deliver before date changed to ${newDate} from ${oldDate}`
        : movement.comment;
      const updatedMovement = {
        ...movement,
        deliverBeforeDate: this.deliverBeforeDate(),
        comment
      };
      this.movementInput.set(updatedMovement);
    }
  }

  handleBestBeforeDateSelected(movement: Partial<Movement>, bestBeforeDate: FoodbankDatePickerEvent) {
    this.bestBeforeDate.set(bestBeforeDate.value as Date);
    const updatedMovement = {
      ...movement,
      bestBeforeDate: this.bestBeforeDate()
    };
    this.movementInput.set(updatedMovement);
  }

  handleExpirationDateSelected(movement: Partial<Movement>, expirationDate: FoodbankDatePickerEvent) {
    this.expirationDate.set(expirationDate.value as Date);
    const updatedMovement = {
      ...movement,
      expirationDate: this.expirationDate(),
    };
    this.movementInput.set(updatedMovement);
  }

  handlePalletSelected(movement: Partial<Movement>, stockPallet: StockPallet) {
    const stock$ = stockPallet ? this.stockService.getStockByPallet$(stockPallet, this.#injector) : of(undefined);
    const updatedMovement: Partial<Movement> = {
      ...movement,
      stockPallet$: of(stockPallet),
      stock$: stock$,
    };
    this.movementInput.set(updatedMovement);
  }
}
