import {Component, input, model, Signal} from '@angular/core';
import {UserRole} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {UserRoleComponent} from "@components/user-type/user-role.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-user-role-selection',
  imports: [
    DropdownModule,
    UserRoleComponent,
    FormsModule
  ],
  templateUrl: './user-role-selection.component.html',
  styleUrl: './user-role-selection.component.scss'
})
export class UserRoleSelectionComponent {
  ngModel = model<UserRole>();
  required = input(false);

  userRoles: UserRole[];

  constructor() {

    this.userRoles = [ "ORGANIZATION_USER" , "ORGANIZATION_ADMIN" , "BANK_USER" , "BANK_ADMIN" , "CPAS_ADMIN" ,"EXT_ADMIN"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}
