import {Component, model} from '@angular/core';
import {DbChangeEntity} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-audit-change-entity',
  imports: [NgIf],
  templateUrl: './audit-change-entity.component.html',
  styleUrl: './audit-change-entity.component.scss'
})
export class AuditChangeEntityComponent {
  ngModel = model<DbChangeEntity>();

}
