import {inject, Injectable, Injector, ResourceRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {MovementSnapshotDto, MovementSnapshotGroupSummaryDto, MovementSnapshotSearchDto, MovementSnapshotSummaryDto} from '@typedefs/stock-rest';
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {MovementSnapshot} from "@model/movement-snapshot";
import {MovementSnapshotSearch} from "@model/movement-snapshot-search";
import {ArticleService} from "@services/article.service";
import {YearMonthService} from "@services/year-month.service";
import {MovementSnapshotGroupSummary} from "@model/movement-snapshot-group-summary";
import {MovementSnapshotSummary} from "@model/movement-snapshot-summary";
import {Article} from "@model/article";
import {ArticleCategory} from "@model/article-category";
import {Company} from "@model/company";
import {Warehouse} from "@model/warehouse";

@Injectable({
  providedIn: 'root'
})
export class MovementSnapshotService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);
  #articleService = inject(ArticleService);
  #yearMonthService = inject(YearMonthService);

  public getMovementSnapshot$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<MovementSnapshot> {
    return this.#httpClient.get<MovementSnapshotDto>(`${environment.apiUrl}/movements/snapshots/${id}`)
      .pipe(
        map(movementSnapshotDto => this.mapToMovementSnapshot(movementSnapshotDto, cache)),
        shareReplay(),
      );
  }

  public findMovementSnapshots$(movementSnapshotSearch: MovementSnapshotSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<MovementSnapshot>> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post<Page<MovementSnapshotDto>>(`${environment.apiUrl}/movements/snapshots/search`, movementSnapshotSearchDto, {params: pagination})
      .pipe(
        map(movementSnapshotPage => this.loadMovementSnapshotPage(movementSnapshotPage, cache))
      );
  }

  public findMovementSnapshotsByArticle$(movementSnapshotSearch: MovementSnapshotSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<MovementSnapshotGroupSummary<Article>>> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post<Page<MovementSnapshotGroupSummaryDto<string>>>(`${environment.apiUrl}/movements/snapshots/group/article`, movementSnapshotSearchDto, {params: pagination})
      .pipe(
        map(movementSnapshotGroupSummaryDtoPage => this.loadMovementSnapshotGroupSummaryPage(movementSnapshotGroupSummaryDtoPage, articleId => cache.articleCache.get(articleId))),
      );
  }

  public exportMovementSnapshotsByArticle$(movementSnapshotSearch: MovementSnapshotSearch): Observable<Blob> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post(`${environment.apiUrl}/movements/snapshots/group/article/xls`, movementSnapshotSearchDto, {
      responseType: 'blob'
    });
  }

  public findMovementSnapshotsByArticleCategory$(movementSnapshotSearch: MovementSnapshotSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<MovementSnapshotGroupSummary<ArticleCategory>>> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post<Page<MovementSnapshotGroupSummaryDto<string>>>(`${environment.apiUrl}/movements/snapshots/group/article/category`, movementSnapshotSearchDto, {params: pagination})
      .pipe(
        map(movementSnapshotGroupSummaryDtoPage => this.loadMovementSnapshotGroupSummaryPage(movementSnapshotGroupSummaryDtoPage, articleCategoryId => cache.articleCategoryCache.get(articleCategoryId))),
      );
  }

  public exportMovementSnapshotsByArticleCategory$(movementSnapshotSearch: MovementSnapshotSearch): Observable<Blob> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post(`${environment.apiUrl}/movements/snapshots/group/article/category/xls`, movementSnapshotSearchDto, {
      responseType: 'blob'
    });
  }

  public findMovementSnapshotsByCompany$(movementSnapshotSearch: MovementSnapshotSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<MovementSnapshotGroupSummary<Company>>> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post<Page<MovementSnapshotGroupSummaryDto<string>>>(`${environment.apiUrl}/movements/snapshots/group/company`, movementSnapshotSearchDto, {params: pagination})
      .pipe(
        map(movementSnapshotGroupSummaryDtoPage => this.loadMovementSnapshotGroupSummaryPage(movementSnapshotGroupSummaryDtoPage, companyId => cache.companyCache.get(companyId))),
      );
  }

  public exportMovementSnapshotsByCompany$(movementSnapshotSearch: MovementSnapshotSearch): Observable<Blob> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post(`${environment.apiUrl}/movements/snapshots/group/company/xls`, movementSnapshotSearchDto, {
      responseType: 'blob'
    });
  }

  public findMovementSnapshotsByWarehouse$(movementSnapshotSearch: MovementSnapshotSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<MovementSnapshotGroupSummary<Warehouse>>> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post<Page<MovementSnapshotGroupSummaryDto<number>>>(`${environment.apiUrl}/movements/snapshots/group/warehouse`, movementSnapshotSearchDto, {params: pagination})
      .pipe(
        map(movementSnapshotGroupSummaryDtoPage => this.loadMovementSnapshotGroupSummaryPage(movementSnapshotGroupSummaryDtoPage, warehouseId => cache.warehouseCache.get(warehouseId))),
      );
  }

  public exportMovementSnapshotsByWarehouse$(movementSnapshotSearch: MovementSnapshotSearch): Observable<Blob> {
    const movementSnapshotSearchDto = this.mapToMovementSnapshotSearchDto(movementSnapshotSearch);
    return this.#httpClient.post(`${environment.apiUrl}/movements/snapshots/group/warehouse/xls`, movementSnapshotSearchDto, {
      responseType: 'blob'
    });
  }

  private loadMovementSnapshotGroupSummaryPage<T, U>(movementSnapshotGroupSummaryDtoPage: Page<MovementSnapshotGroupSummaryDto<T>>, groupIdConverter: (groupId: T) => ResourceRef<U>): Page<MovementSnapshotGroupSummary<U>> {
    return {
      ...movementSnapshotGroupSummaryDtoPage,
      content: movementSnapshotGroupSummaryDtoPage.content.map(movementSnapshotGroupSummaryDto => this.mapToMovementSnapshotGroupSummary(movementSnapshotGroupSummaryDto, groupIdConverter)),
    };
  }

  private loadMovementSnapshotPage(movementSnapshotDtoPage: Page<MovementSnapshotDto>, cache: FoodbankCache): Page<MovementSnapshot> {
    return {
      ...movementSnapshotDtoPage,
      content: this.loadMovementSnapshotDetailsList(movementSnapshotDtoPage.content, cache)
    };
  }

  public loadMovementSnapshotDetailsList(movementSnapshotDtos: MovementSnapshotDto[], cache: FoodbankCache) {
    return movementSnapshotDtos.map(movementSnapshot => this.mapToMovementSnapshot(movementSnapshot, cache));
  }

  saveMovementSnapshot(movementSnapshot: MovementSnapshot): Observable<MovementSnapshot> {
    const movementSnapshotDto = this.mapToMovementSnapshotDto(movementSnapshot);
    return this.#httpClient.post<MovementSnapshotDto>(`${environment.apiUrl}/movementSnapshots`, movementSnapshotDto)
      .pipe(map(movementSnapshotDto => this.mapToMovementSnapshot(movementSnapshotDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  mapToMovementSnapshot(movementSnapshotDto: MovementSnapshotDto, cache: FoodbankCache): MovementSnapshot {
    const commonFields: MovementSnapshot | MovementSnapshotDto = copyCommonFields(movementSnapshotDto, ['articleId', 'warehouseId']);
    return {
      ...commonFields,
      article: cache.articleCache.get(movementSnapshotDto.articleId),
      warehouse: cache.warehouseCache.getIfDefined(movementSnapshotDto.warehouseId)
    }
  }

  mapToMovementSnapshotGroupSummary<T, U>(movementSnapshotGroupSummaryDto: MovementSnapshotGroupSummaryDto<T>, groupIdConverter: (groupId: T) => ResourceRef<U>): MovementSnapshotGroupSummary<U> {
    return {
      group: groupIdConverter(movementSnapshotGroupSummaryDto.groupId),
      summary: this.mapToMovementSnapshotSummary(movementSnapshotGroupSummaryDto.summaryDto),
    };
  }

  mapToMovementSnapshotSummary(movementSnapshotSummaryDto: MovementSnapshotSummaryDto): MovementSnapshotSummary {
    return {
      correctionQuantity1: movementSnapshotSummaryDto.correctionQuantity1,
      correctionQuantity2: movementSnapshotSummaryDto.correctionQuantity2,
      endStockQuantity: movementSnapshotSummaryDto.endStockQuantity,
      incomingQuantity: movementSnapshotSummaryDto.incomingQuantity,
      incomingQuantitySameBankWarehouses: movementSnapshotSummaryDto.incomingQuantitySameBankWarehouses,
      incomingQuantityAuction: movementSnapshotSummaryDto.incomingQuantityAuction,
      incomingQuantityCollect: movementSnapshotSummaryDto.incomingQuantityCollect,
      incomingQuantityDistribution: movementSnapshotSummaryDto.incomingQuantityDistribution,
      incomingQuantityEsf: movementSnapshotSummaryDto.incomingQuantityEsf,
      incomingQuantityFederation: movementSnapshotSummaryDto.incomingQuantityFederation,
      incomingQuantityIndustry: movementSnapshotSummaryDto.incomingQuantityIndustry,
      incomingQuantityOther: movementSnapshotSummaryDto.incomingQuantityOther,
      incomingQuantityPurchase: movementSnapshotSummaryDto.incomingQuantityPurchase,
      outgoingQuantity: movementSnapshotSummaryDto.outgoingQuantity,
      outgoingQuantityOrganization: movementSnapshotSummaryDto.outgoingQuantityOrganization,
      outgoingQuantityTransferToFederation: movementSnapshotSummaryDto.outgoingQuantityTransferToFederation,
      outgoingQuantityTransferToOtherBank: movementSnapshotSummaryDto.outgoingQuantityTransferToOtherBank,
      outgoingQuantityWaste: movementSnapshotSummaryDto.outgoingQuantityWaste,
      startStockQuantity: movementSnapshotSummaryDto.startStockQuantity,
    }
  }

  mapToMovementSnapshotDto(movementSnapshot: MovementSnapshot): MovementSnapshotDto {
    const commonFields: MovementSnapshot | MovementSnapshotDto = copyCommonFields(movementSnapshot, ['article', 'warehouse']);

    return {
      ...commonFields,
      articleId: movementSnapshot.article.value()?.id!,
      warehouseId: movementSnapshot.warehouse.value()?.id!,
    };
  }

  mapToMovementSnapshotSearchDto(movementSnapshotSearch: MovementSnapshotSearch): MovementSnapshotSearchDto {
    const movementSnapshotSearchDto: MovementSnapshotSearch | MovementSnapshotSearchDto = copyCommonFields(movementSnapshotSearch, ['articleSearch', 'yearMonthSearch', 'warehouse', 'article']);
    return {
      ...movementSnapshotSearchDto,
      articleId: movementSnapshotSearch.article?.id,
      warehouseId: movementSnapshotSearch.warehouse?.id,
      articleSearchDto: movementSnapshotSearch.articleSearch ? this.#articleService.mapToArticleSearchDto(movementSnapshotSearch.articleSearch) : undefined,
      yearMonthSearchDto: movementSnapshotSearch.yearMonthSearch ? this.#yearMonthService.mapToYearMonthSearchDto(movementSnapshotSearch.yearMonthSearch) : undefined,
    }
  }
}
