import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {DonorDto, DonorSearchDto} from '@typedefs/stock-rest';
import {Donor} from "@model/donor";
import {DonorSearch} from "@model/search/donor-search";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class DonorService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getDonors$(): Observable<Donor[]> {
    return this.#httpClient.get<Donor[]>(`${environment.apiUrl}/donors`)
      .pipe(shareReplay());
  }

  public getDonor$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Donor> {
    return this.#httpClient.get<DonorDto>(`${environment.apiUrl}/donors/${id}`)
      .pipe(
        map(donorDto => this.mapToDonor(donorDto, cache)),
        shareReplay(),
      );
  }

  public findDonors$(donorSearch: DonorSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Donor>> {
    const donorSearchDto = this.mapToDonorSearchDto(donorSearch);
    return this.#httpClient.post<Page<DonorDto>>(`${environment.apiUrl}/donors/search`, donorSearchDto, {params: pagination})
      .pipe(map(donorPage => this.loadDonorPage(donorPage, cache)));
  }
  public findAllDonors$(donorSearch: DonorSearch, injector: Injector,cache = this.#foodbankCacheFactory.create(injector)) : Observable< Donor[]> {
    const donorSearchDto = this.mapToDonorSearchDto(donorSearch);
    return this.#httpClient.post<DonorDto[]>(`${environment.apiUrl}/donors/searchAll`, donorSearchDto)
      .pipe(map(donorDtos => this.loadDonorDetailsList(donorDtos, cache)));
  }

  private loadDonorPage(donorDtoPage: Page<DonorDto>, cache: FoodbankCache): Page<Donor> {
    return {
      ...donorDtoPage,
      content: this.loadDonorDetailsList(donorDtoPage.content, cache)
    };
  }

  public loadDonorDetailsList(donorDtos: DonorDto[], cache: FoodbankCache) {
    return donorDtos.map(donor => this.mapToDonor(donor, cache));
  }

  updateDonor(donor: Donor): Observable<Donor> {
    const donorDto = this.mapToDonorDto(donor);
    return this.#httpClient.put<DonorDto>(`${environment.apiUrl}/donors/${donor.id}`, donorDto)
      .pipe(map(donorDto => this.mapToDonor(donorDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  deleteDonor(donor: Donor) {
    return this.#httpClient.delete(`${environment.apiUrl}/donors/${donor.id}`);
  }

  public mapToDonor(donorDto: DonorDto, cache: FoodbankCache): Donor {
    const commonFields: Donor | DonorDto = copyCommonFields(donorDto, ['companyId']);
    return {
      ...commonFields,
      company: cache.companyCache.get(donorDto.companyId),
    }
  }

  mapToDonorDto(donor: Donor): DonorDto {
    const donorDto: DonorDto = copyCommonFields(donor, ['company']) as DonorDto;

    return {
      ...donorDto,
      companyId: donor.company.value()?.id!,
    };
  }

  mapToDonorSearchDto(donorSearch: DonorSearch): DonorSearchDto {
    const donorSearchDto: DonorSearch | DonorSearchDto = copyCommonFields(donorSearch, ['company']);
    return {
      ...donorSearchDto,
      companyId: donorSearch.company?.id,
    }
  }

  findDonationsOfDonor(id: number) {

  }
}
