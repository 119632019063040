import {Component, input, model} from '@angular/core';
import {OrganizationCategory} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationCategoryComponent} from "@components/organization-category/organization-category.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-category-selection',
  imports: [
    DropdownModule,
    OrganizationCategoryComponent,
    FormsModule
  ],
  templateUrl: './organization-category-selection.component.html',
  styleUrl: './organization-category-selection.component.scss'
})
export class OrganizationCategorySelectionComponent {
  ngModel = model<OrganizationCategory>();
  required = input(false);

  organizationCategories: OrganizationCategory[];

  constructor() {

    this.organizationCategories = ["NONE", "UNASSIGNED", "SHELTER_YOUNG", "SHELTER_HANDICAPPED", "SHELTER_WOMEN", "SHELTER_MEN", "SOCIAL_CENTER", "COMMUNITY", "NEIGHBOURHOOD", "URBAN", "RURAL", "REGIONAL", "HOLIDAY", "RESTAURANT", "ELDERLY", "PRISON", "REFUGEE"];

  }

  clear() {
    this.ngModel.set(undefined);
  }
}

