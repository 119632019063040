import {Component, input, model} from '@angular/core';
import {DbChangeAction} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AuditChangeActionComponent} from "@components/audit-change-action/audit-change-action.component";

@Component({
  selector: 'foodbank-audit-change-action-selection',
  imports: [DropdownModule,
    AuditChangeActionComponent,
    FormsModule],
  templateUrl: './audit-change-action-selection.component.html',
  styleUrl: './audit-change-action-selection.component.scss'
})

export class AuditChangeActionSelectionComponent {
  ngModel = model<DbChangeAction>();
  required = input(false);

  dbchangeActions: DbChangeAction[];

  constructor() {

    this.dbchangeActions = ["UPDATE", "CREATE", "DELETE"];
  }

  clear() {
    this.ngModel.set(undefined);
  }
}
