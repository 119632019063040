import {afterNextRender, Component, computed, inject, Injector, input, model, Resource, ResourceRef, signal, Signal, WritableSignal} from '@angular/core'
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";
import {CheckboxModule} from "primeng/checkbox";
import {UserSignalService} from "@services/user-signal.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {Article} from "@model/article";
import {Warehouse} from "@model/warehouse";
import {MovementSnapshotSearch} from "@model/movement-snapshot-search";
import {YearMonthSearch} from "@model/search/year-month-search";
import {MovementSnapshotService} from "@services/movement-snapshot.service";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {MovementSnapshotGroupSummary} from "@model/movement-snapshot-group-summary";
import {ArticleCategory} from "@model/article-category";
import {Company} from "@model/company";
import {Observable} from "rxjs";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {CompanyComponent} from "@components/company/company.component";
import {ArticleComponent} from "@components/article/article.component";
import {ArticleCategoryComponent} from "@components/article-category/article-category.component";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {CompanySingleSelectionComponent} from "@components/company/selection/single/company-single-selection.component";
import {ArticleSingleSelectionComponent} from "@components/article/selection/single/article-single-selection.component";
import {ArticleCategorySelectionComponent} from "@components/article-category/selection/multi/article-category-selection.component";
import {StyleClassModule} from "primeng/styleclass";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {SelectButtonModule} from "primeng/selectbutton";
import {BIG_WEIGHT_UNITS, WeightUnit} from "@model/weight.unit";
import {WeightComponent} from "@components/weight/weight.component";
import {WeightUnitSelectionComponent} from "@components/weight/weight-unit-selection.component";

enum Grouping {
  warehouse = 'warehouse',
  company = 'company',
  article = 'article',
  articleCategory = 'articleCategory',
}

enum MovementSnapshotColumn {
  group = 'group',
  startStockQuantity = 'startStockQuantity',
  incomingQuantity = 'incomingQuantity',
  outgoingQuantity = 'outgoingQuantity',
  endStockQuantity = 'endStockQuantity',
  incomingQuantityAuction = 'incomingQuantityAuction',
  incomingQuantityEsf = 'incomingQuantityEsf',
  incomingQuantityIndustry = 'incomingQuantityIndustry',
  incomingQuantityDistribution = 'incomingQuantityDistribution',
  incomingQuantityCollect = 'incomingQuantityCollect',
  incomingQuantityFederation = 'incomingQuantityFederation',
  incomingQuantityPurchase = 'incomingQuantityPurchase',
  incomingQuantityOther = 'incomingQuantityOther',
  incomingQuantitySameBankWarehouses = 'incomingQuantitySameBankWarehouses',
  outgoingQuantityOrganization = 'outgoingQuantityOrganization',
  outgoingQuantityTransferToOtherBank = 'outgoingQuantityTransferToOtherBank',
  outgoingQuantityTransferToFederation = 'outgoingQuantityTransferToFederation',
  outgoingQuantityWaste = 'outgoingQuantityWaste',
  correctionQuantity1 = 'correctionQuantity1',
  correctionQuantity2 = 'correctionQuantity2',
}

const COLUMN_LABELS: ColumnLabels<MovementSnapshotColumn> = {
  group: 'Group',
  startStockQuantity: 'Start stock',
  incomingQuantity: '← Incoming',
  outgoingQuantity: '→ Outgoing',
  endStockQuantity: 'End stock',
  incomingQuantityAuction: '← Auction',
  incomingQuantityEsf: '← ESF+',
  incomingQuantityIndustry: '← Industry',
  incomingQuantityDistribution: '← Distribution',
  incomingQuantityCollect: '← Collect',
  incomingQuantityFederation: '← Federation',
  incomingQuantityPurchase: '← Purchase',
  incomingQuantityOther: '← Other banks',
  incomingQuantitySameBankWarehouses: '← Bank warehouses',
  outgoingQuantityOrganization: '→ Organization',
  outgoingQuantityTransferToOtherBank: '→ Other banks',
  outgoingQuantityTransferToFederation: '→ Federation',
  outgoingQuantityWaste: '→ Waste',
  correctionQuantity1: '→ Correction 1',
  correctionQuantity2: '→ Correction 2',
}

const GROUPING_LABELS: ColumnLabels<Grouping> = {
  warehouse: 'Warehouse',
  company: 'Company',
  article: 'Article',
  articleCategory: 'Article category',
};

const COLUMN_PREFERENCE_KEY = 'preference_movement_snapshot_list_columns';

const ALL_COLUMNS: MovementSnapshotColumn[] = Object.values(MovementSnapshotColumn) as MovementSnapshotColumn[];
const ALL_GROUPINGS: Grouping[] = Object.values(Grouping) as Grouping[];
const DEFAULT_COLUMNS: MovementSnapshotColumn[] = ALL_COLUMNS;

@Component({
  selector: 'foodbank-movement-snapshot-list',
  templateUrl: './movement-snapshot-list.component.html',
  styleUrls: ['./movement-snapshot-list.component.scss'],
  providers: [DialogService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, CheckboxModule, ConfirmDialogModule, WarehouseComponent, CompanyComponent, ArticleComponent, ArticleCategoryComponent, WarehouseSingleSelectionComponent, CompanySingleSelectionComponent, ArticleSingleSelectionComponent, ArticleCategorySelectionComponent, StyleClassModule, CalendarModule, DropdownModule, SelectButtonModule, WeightComponent, WeightUnitSelectionComponent,]
})
export class MovementSnapshotListComponent {

  // selected search filters for this view
  filterMonthDate = model<Date>();
  filterYearDate = model<Date>();
  filterArticle = model<Article>();
  filterArticleCategory = model<ArticleCategory>();
  filterWarehouse = model<Warehouse>();
  filterCompany = model<Company>();

  weightUnits = signal(BIG_WEIGHT_UNITS);

  // searches for filters

  warehouseSearch: Signal<WarehouseSearch>;
  // this view search
  movementSnapshotSearch: Signal<MovementSnapshotSearch>;
  grouping = signal<Grouping>(Grouping.article);
  weightUnit = signal<WeightUnit>(WeightUnit.ton);

  pagination: WritableSignal<Pagination>;

  // results

  movementSnapshotGroupSummaryPage: Resource<Page<MovementSnapshotGroupSummary<Article | Warehouse | ArticleCategory | Company>>>;
  // internal state
  // services
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);

  #movementSnapshotService = inject(MovementSnapshotService);
  #injector = inject(Injector);
  // columns view selection

  showColumnSelector = input(true);

  displayedColumns = injectLocalStorage<MovementSnapshotColumn[]>(COLUMN_PREFERENCE_KEY, {
    storageSync: true,
    defaultValue: DEFAULT_COLUMNS
  });

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;
    this.warehouseSearch = computed(() => ({
      company: currentUserCompany(),
      active: true,
    }));

    afterNextRender(() => {
      if (!this.filterYearDate()) {
        this.filterYearDate.set(this.getLastYearDate());
      }
    });

    const yearMonthSearch: Signal<YearMonthSearch> = computed(() => {
      const monthDate = this.filterMonthDate();
      if (monthDate) {
        return {
          exactYearMonth: monthDate,
        }
      } else {
        const minYearDate = this.filterYearDate() ?? this.getLastYearDate();
        const maxYearDate = this.addYearsToDateUtc(minYearDate, 1);
        return {
          minYearMonth: minYearDate,
          maxYearMonth: maxYearDate,
        };
      }
    });

    this.movementSnapshotSearch = computed(() => ({
      article: this.filterArticle(),
      warehouse: this.filterWarehouse(),
      yearMonthSearch: yearMonthSearch(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal();

    this.movementSnapshotGroupSummaryPage = rxResource({
      request: () => ({
        movementSnapshotSearch: this.movementSnapshotSearch(),
        pagination: this.pagination(),
        grouping: this.grouping(),
      }),
      loader: params => {
        return this.#findGroupedMovementSnapshots(params.request.grouping, params.request.pagination, params.request.movementSnapshotSearch);
      },
    });

  }

  getWarehouseGroup(movementSnapshotGroupSummary: MovementSnapshotGroupSummary<Warehouse>): ResourceRef<Warehouse> {
    return movementSnapshotGroupSummary.group;
  }

  getCompanyGroup(movementSnapshotGroupSummary: MovementSnapshotGroupSummary<Company>): ResourceRef<Company> {
    return movementSnapshotGroupSummary.group;
  }

  getArticleGroup(movementSnapshotGroupSummary: MovementSnapshotGroupSummary<Article>): ResourceRef<Article> {
    return movementSnapshotGroupSummary.group;
  }

  getArticleCategoryGroup(movementSnapshotGroupSummary: MovementSnapshotGroupSummary<ArticleCategory>): ResourceRef<ArticleCategory> {
    return movementSnapshotGroupSummary.group;
  }

  #findGroupedMovementSnapshots(grouping: Grouping, pagination: Pagination, movementSnapshotSearch: MovementSnapshotSearch): Observable<Page<MovementSnapshotGroupSummary<Warehouse | Company | Article | ArticleCategory>>> {
    switch (grouping) {
      case Grouping.warehouse:
        return this.#movementSnapshotService.findMovementSnapshotsByWarehouse$(movementSnapshotSearch, this.#injector, pagination);
      case Grouping.company:
        return this.#movementSnapshotService.findMovementSnapshotsByCompany$(movementSnapshotSearch, this.#injector, pagination);
      case Grouping.article:
        return this.#movementSnapshotService.findMovementSnapshotsByArticle$(movementSnapshotSearch, this.#injector, pagination);
      case Grouping.articleCategory:
      default:
        return this.#movementSnapshotService.findMovementSnapshotsByArticleCategory$(movementSnapshotSearch, this.#injector, pagination);
    }
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity<T extends MovementSnapshotGroupSummary<Warehouse | Company | Article | ArticleCategory>>(movementSnapshotGroupSummary: T): T {
    return movementSnapshotGroupSummary;
  }

  getColumnLabel(column: MovementSnapshotColumn): string {
    return COLUMN_LABELS[column];
  }

  getGroupingLabel(grouping: Grouping) {
    return GROUPING_LABELS[grouping];
  }

  getLastYearDate() {
    const todayDate = new Date();
    return this.addYearsToDateUtc(todayDate, -1);
  }

  addYearsToDateUtc(date: Date, yearsToAdd: number): Date {
    const fullYear = date.getFullYear() + yearsToAdd;
    const utcDate = Date.UTC(fullYear);
    return new Date(utcDate);
  }

  exportToExcel() {
    const movementSnapshotSearch = this.movementSnapshotSearch();
    let excelBlob$: Observable<Blob>;
    const grouping = this.grouping();
    switch (grouping) {
      case Grouping.warehouse:
        excelBlob$ = this.#movementSnapshotService.exportMovementSnapshotsByWarehouse$(movementSnapshotSearch);
        break;
      case Grouping.company:
        excelBlob$ = this.#movementSnapshotService.exportMovementSnapshotsByCompany$(movementSnapshotSearch);
        break;
      case Grouping.article:
        excelBlob$ = this.#movementSnapshotService.exportMovementSnapshotsByArticle$(movementSnapshotSearch);
        break;
      case Grouping.articleCategory:
      default:
        excelBlob$ = this.#movementSnapshotService.exportMovementSnapshotsByArticleCategory$(movementSnapshotSearch);
    }
    excelBlob$.subscribe(blob => this.openExcelFile(blob));
  }

  private openExcelFile(blob: Blob) {
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = 'snapshots.xlsx';
    link.click();
    window.URL.revokeObjectURL(downloadURL);
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;

  protected readonly ALL_GROUPINGS = ALL_GROUPINGS;
  protected readonly MovementSnapshotColumn = MovementSnapshotColumn;
  protected readonly Grouping = Grouping;

  protected readonly WeightUnit = WeightUnit;
}


