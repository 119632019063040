import { Injectable, Injector, inject } from "@angular/core";
import { MovementService } from "./movement.service";
import { Movement } from "@model/movement";
import { MovementType } from "@model/movement-type";
import { Page } from "@typedefs/page";
import { BehaviorSubject, combineLatest, filter, map } from "rxjs";
import { Warehouse } from "@model/warehouse";

@Injectable({
  providedIn: 'root'
})
export class BulkFoodImportMovementService {
  
  #movementService = inject(MovementService);
  #injector = inject(Injector);

  movementMap$ = new BehaviorSubject<Map<string, Movement>>(new Map<string, Movement>());
  movementMapLoaded$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  importMovementsForImport(supplierBatchName: string, warehouse: Warehouse, movementType: MovementType, injector = this.#injector) {
    this.movementMapLoaded$.next(false);
    console.debug('Importing movements for import ', supplierBatchName, movementType);
    const movements = this.#movementService.findMovements$(
        { supplierBatchName, warehouse,movementTypes: [ movementType ] }, 
        { page: 0, size: 1000 }, 
        injector
    );
    movements.pipe(
      map((page: Page<Movement>) => {
        const movementMap = new Map<string, Movement>();
        page.content.forEach((movement: Movement) => {
          const id = this.#extractRowIdentifierFromComment(movement.comment);
          if (id) {
            movementMap.set(id, movement);
          }
        });
        console.debug('Loaded movements ', movementMap);
        return movementMap;
      })
    )
    .subscribe(movementMap => {
      console.debug('Loaded movements ', movementMap.size);
      this.movementMap$.next(movementMap);
      this.movementMapLoaded$.next(true);
    });
  }

  updateMovement(movement: Movement | undefined) {
    if (!movement) return;
    const map = this.movementMap$.getValue();
    const id = this.#extractRowIdentifierFromComment(movement.comment);
    if (id) {
      map.set(id, movement);
    }
  }


  $findMovementForImportRow(rowIdentifier: string) {
    return combineLatest([this.movementMap$, this.movementMapLoaded$]).pipe(
      filter(([_, loaded]) => loaded === true),
      map(([map, _]) => map.get(rowIdentifier))
    );
  }

  removeMovementForImportRow(rowIdentifier: string) {
    const map = this.movementMap$.getValue();
    map.delete(rowIdentifier);
  }

  clearMovementMap() {
    this.movementMapLoaded$.next(false);
    this.movementMap$.next(new Map<string, Movement>());
  }

  #extractRowIdentifierFromComment(comment: string | undefined): string | undefined {
    if (!comment) return undefined;
    const match = comment.match(/^\[(.*?)\]/);
    return match ? match[1] : undefined;
  }
}