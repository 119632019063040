import {Component, inject, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Cpas} from '@model/cpas';
import {CpasService} from '@services/cpas.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";


@Component({
  selector: 'foodbank-cpas-edit',
  templateUrl: './cpas-edit.component.html',
  styleUrls: ['./cpas-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule,  InputSwitchModule, Button,  MessageModule, DropdownModule]
})
export class CpasEditComponent {

  cpas = model<Cpas>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Cpas>);
  #cpasService = inject(CpasService);
  #messageService = inject(MessageService);

  genders: any[];

  constructor() {
    this.cpas.set(this.config.data);

    this.genders = [{label: $localize`:@@PersonTitleMister:Mr.`, value: 1},
      {label: $localize`:@@PersonTitleLady:Mrs.`, value: 2},
      {label: $localize`:@@PersonTitleMiss:Miss`, value: 3}
    ];

  }

  save(cpas: Cpas) {


    this.#cpasService.updateCpas(cpas)
      .subscribe(cpas => {
        this.cpas.set(cpas);
        this.ref.close(cpas);
        this.#messageService.add({severity: 'success', summary: 'Success', detail: 'Cpas updated'});
      });
  }

  close() {
    this.ref.close({});
  }

}

