import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Member, MemberCreationBase} from '@model/member';
import {MemberService} from '@services/member.service';
import {MemberSearch} from "@model/search/member-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {RoleComponent} from "@components/role/role.component";
import {MemberEditComponent} from "@components/member/member-edit/member-edit.component";
import {ButtonModule} from "primeng/button";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {Organization} from "@model/organization";
import {OrganizationComponent} from "@components/organization/organization.component";
import {OrganizationSearch} from "@model/search/organization-search";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";
import {CheckboxModule} from "primeng/checkbox";
import {UserSignalService} from "@services/user-signal.service";
import {Language} from "@typedefs/stock-rest";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {AuditChangeCreationBase} from "@model/audit-change";
import {AuditChangeService} from "@services/audit-change.service";
import {ExcelService} from "@services/excel.service";
import {formatDate} from "@angular/common";

enum MemberColumn {
  firstName = 'firstName',
  familyName = 'familyName',
  organization = 'organization',
  address = 'address',
  phone = 'phone',
  email = 'email',
  role = 'role',
  zip = 'zip',
  city = 'city',
  active = 'active'
}

const COLUMN_LABELS: ColumnLabels<MemberColumn> = {
  firstName: 'First Name',
  familyName: 'Family Name',
  organization: 'Organization',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  role: 'Role',
  zip: 'Zip',
  city: 'City',
  active: 'Active'
}

const COLUMN_PREFERENCE_KEY = 'preference_members_list_columns';

const ALL_COLUMNS: MemberColumn[] = Object.values(MemberColumn) as MemberColumn[];
const DEFAULT_COLUMNS: MemberColumn[] = [MemberColumn.firstName, MemberColumn.familyName, MemberColumn.organization, MemberColumn.phone, MemberColumn.email];

@Component({
  selector: 'foodbank-members',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
  providers: [DialogService, ExcelService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, OrganizationSingleSelectionComponent, OrganizationComponent, CheckboxModule, RoleComponent, ConfirmDialogModule,]
})
export class MembersComponent {

  // selected search filters for this view
  filterFirstNameContains = model('');
  filterFamilyNameContains = model('');
  filterZipContains = model('');
  filterCityContains = model('');
  filterOrganization = model<Organization>();
  filterActive = model(true);

  // searches for filters (e.g. organization)
  organizationSearch: Signal<OrganizationSearch>;

  // this view search
  memberSearch: Signal<MemberSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  memberPage: Resource<Page<Member> | undefined>;
  members: Resource<Member[] | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #memberService = inject(MemberService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
  #messageService = inject(MessageService);
  #confirmationService = inject(ConfirmationService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);


  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<MemberColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;
    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
      active: true,
    }));

    this.memberSearch = computed(() => ({
      company: currentUserCompany(),
      active: this.filterActive(),
      organization: this.filterOrganization(),
      familyNameContains: this.filterFamilyNameContains(),
      firstNameContains: this.filterFirstNameContains(),
      zipContains: this.filterZipContains(),
      cityContains: this.filterCityContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.memberPage = rxResource({
      request: () => ({
        memberSearch: this.memberSearch(),
        pagination: this.pagination(),
      }),
      loader: () => this.#memberService.findMembers$(this.memberSearch(), this.#injector, this.pagination())
    });
    this.members = rxResource({
      request: () => ({
        memberSearch: this.memberSearch(),
      }),
      loader: () => this.#memberService.findAllMembers$(this.memberSearch(), this.#injector)
    });

  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }
  exportToExcel() {
    const members = this.members.value!;
    const cleanedList: any[] = [];
    members()!.map((member) => {
      const cleanedItem: any = {};
      const organization = member.organization.value();
      const organizationName = organization ? organization.id + ' ' + organization.name : '';
      const role = member.role.value();
      const roleName = role ? role.name : '';
      cleanedItem[$localize`:@@Name:Name`] = member.familyName + ' ' + member.firstName;
      cleanedItem[$localize`:@@Organization:Organization`] = organizationName;
      cleanedItem[$localize`:@@Address:Address`] = member.address;
      cleanedItem[$localize`:@@Zip:Zip`] = member.zip;
      cleanedItem[$localize`:@@City:City`] = member.city;
      cleanedItem[$localize`:@@Mobile:Mobile`] = member.mobile;
      cleanedItem[$localize`:@@Phone:Phone`] = member.phone;
      cleanedItem[$localize`:@@Email:Email`] = member.email;
      cleanedItem[$localize`:@@Role:Role`] =  roleName;
      cleanedItem[$localize`:@@Active:Active`] = member.active;
      cleanedItem[$localize`:@@NationalNr:NatNr`] = member.nnat;
      cleanedList.push(cleanedItem);

    });
    const excelFileName = 'foodit.members.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(member: Member): Member {
    return member;
  }

  openEditDialog(member: Member) {
    this.#dialogRef = this.#dialogService.open(MemberEditComponent, {
      header: 'Edit Member',
      width: '40%',
      data: {...member}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.memberPage.reload()
    })
  }

  openCreateDialog() {
    const $$company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);

    const member: MemberCreationBase = {
      company: $$company,
      organization: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      role: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      warehouse: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      active: true

    }
    this.#dialogRef = this.#dialogService.open(MemberEditComponent, {
      header: 'New Member',
      width: '40%',
      data: {...member}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.memberPage.reload()
    });
  }

  getAddress(member: Member) {
    const location = joinWhenPresent(" ", [member.zip, member.city]);
    return joinWhenPresent(" ,", [member.address, location]);
  }

  getColumnLabel(column: MemberColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly MemberColumn = MemberColumn;

  delete(member: Member) {
    const memberUsers = this.#userSignalService.findUsersOfMember$(<number>member.id)
      .subscribe(
        users => {
          if (users.length > 0) {
            this.#messageService.add({severity: 'error', summary: 'Member has users', detail: `Member ${member.familyName} ${member.firstName} has ${users.length} users and cannot be deleted.`});
          } else {
            this.deleteMember(member);
          }
        }
      )
  }
  deleteMember(member: Member) {
    this.#confirmationService.confirm({
      message: `Are you sure that you want to delete member ${member.familyName} ${member.firstName} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
        const auditChange: AuditChangeCreationBase = {
          company: member.company,
          organization: member.organization,
          user: currentUser,
          entity: "MEMBER",
          entityKey: member.familyName + ' ' + member.firstName,
          action: "DELETE",
        }
        const memberName = member.familyName + ' ' + member.firstName;
        this.#memberService.deleteMember(member)
          .subscribe(() => {
            this.#messageService.add({severity: 'info', summary: 'Deleted', detail: `Member ${memberName} has been deleted.`});
            this.memberPage.reload();
            this.#auditChangeService.createAuditChange(auditChange).subscribe();
          });
      }
    });
  }
}


