import {Injectable} from '@angular/core';
import {YearMonthSearchDto} from '@typedefs/stock-rest';
import {copyCommonFields} from "@model/mapping-utils";
import {YearMonthSearch} from "@model/search/year-month-search";

@Injectable({
  providedIn: 'root'
})
export class YearMonthService {

  mapToYearMonthSearchDto(yearMonthSearch: YearMonthSearch): YearMonthSearchDto {
    const commonFields: YearMonthSearchDto | YearMonthSearch = copyCommonFields(yearMonthSearch, []);
    return {
      ...commonFields,
    }
  }

}
