import {Component, input, model} from '@angular/core';
import {DbChangeEntity} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AuditChangeEntityComponent} from "@components/audit-change-entity/audit-change-entity.component";

@Component({
  selector: 'foodbank-audit-change-entity-selection',
  imports: [DropdownModule,
    AuditChangeEntityComponent,
    FormsModule],
  templateUrl: './audit-change-entity-selection.component.html',
  styleUrl: './audit-change-entity-selection.component.scss'
})

export class AuditChangeEntitySelectionComponent {
  ngModel = model<DbChangeEntity>();
  required = input(false);

  dbchangeEntities: DbChangeEntity[];

  constructor() {

    this.dbchangeEntities = ["USER" , "MEMBER" , "ORG" , "ORG_BENEFICIARIES" , "BANK" , "DEPOT" , "CLIENT" , "ZIP_CODE" , "DONOR" , "GIFT" , "EMAIL"];
  }

  clear() {
    this.ngModel.set(undefined);
  }
}

