<p-sidebar [(visible)]="visible" [modal]="false" [showCloseIcon]="true" [closeOnEscape]="true" [dismissible]="true" position="right" [style]="{width: '600px'}" [styleClass]="'calendar-drawer'">
  <ng-template pTemplate="header">
    <div class="flex flex-col gap-3 justify-center mt-1">
      <div class="flex align-items-center justify-content-between">
        <button class="p-button p-button-icon p-button-rounded p-button-text" (click)="previousYear()">
          <i class="pi pi-chevron-left"></i>
        </button>
        <h2 class="mt-3">{{ currentYear }}</h2>
        <button class="p-button p-button-icon p-button-rounded p-button-text" (click)="nextYear()">
          <i class="pi pi-chevron-right"></i>
        </button>
      </div>
      <div class="flex align-items-center justify-content-center">
        <p-dropdown 
          [options]="firstDayOptions" 
          [(ngModel)]="firstDayOfWeek" 
          (onChange)="onFirstDayChange()"
          optionLabel="label"
          optionValue="value"
          placeholder="First day of week"
          [style]="{'width': '200px'}"
          styleClass="p-inputtext-sm">
        </p-dropdown>
      </div>
    </div>
  </ng-template>

  <div class="p-sidebar-content">
    <div *ngFor="let monthNumber of getMonthNumbers()" class="bg-white border-round p-4 mb-4 shadow-2">
      <h3 class="p-card-title p-text-center">{{ getMonthName(monthNumber) }}</h3>
      
      <p-table [value]="getWeeksForMonth(monthNumber)" [scrollable]="true" scrollHeight="400px" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 3rem">WoY</th>
            <th style="width: 3rem">WoM</th>
            <th style="width: 3rem">Mo</th>
            <th style="width: 3rem">Tu</th>
            <th style="width: 3rem">We</th>
            <th style="width: 3rem">Th</th>
            <th style="width: 3rem">Fr</th>
            <th style="width: 3rem">Sa</th>
            <th style="width: 3rem">Su</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-weekEntry>
          <tr>
            <td class="week-number">{{ weekEntry[1].isoWeek }}</td>
            <td class="week-of-month">{{ weekEntry[1].weekOfMonth }}</td>
            
            <!-- Empty cells before the first day of the week -->
            <ng-container *ngFor="let _ of [].constructor(getEmptyDaysBefore(weekEntry[1]))">
              <td class="empty-day"></td>
            </ng-container>
            
            <!-- Days of the week -->
            <ng-container *ngFor="let day of getDaysOfWeek(weekEntry[1])">
              <td [class.other-month]="isOtherMonth(day, monthNumber)">
                {{ day.getDate() }}
              </td>
            </ng-container>
            
            <!-- Empty cells after the last day of the week -->
            <ng-container *ngFor="let _ of [].constructor(getEmptyDaysAfter(weekEntry[1]))">
              <td class="empty-day"></td>
            </ng-container>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-sidebar> 