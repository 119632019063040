import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, shareReplay} from "rxjs";
import { WarehouseDto, WarehouseSearchDto} from '@typedefs/stock-rest';
import {environment} from '@environments/environment';
import {Page} from '@typedefs/page';
import {Pagination} from './pagination';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import {CompanyService} from '@services/company.service';
import {PreparationService} from '@services/preparation.service';
import {StockPrevisionService} from "@services/stock-prevision.service";
import {TransferRequestService} from "@services/transfer-request.service";
import {StockSearchService} from "@services/stock-search.service";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {copyCommonFields} from "@model/mapping-utils";


type warehousePagination = Pagination;

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {
  readonly #foodbankCacheFactory = inject(FoodbankCacheFactory);
  readonly #injector = inject(Injector);
  readonly #httpClient = inject(HttpClient);
  readonly #companyService = inject(CompanyService);

  public getWarehouse$(id: number): Observable<Warehouse> {
    return this.#httpClient.get<WarehouseDto>(`${environment.apiUrl}/warehouses/${id}`)
      .pipe(
        map(warehouse => this.loadWarehouse(warehouse)),
        shareReplay()
      );
  }

  findWarehouses$(warehouseSearch: WarehouseSearch, pagination?: warehousePagination): Observable<Page<Warehouse>> {
    const warehouseSearchDto = this.mapToWarehouseSearchDto(warehouseSearch);
    return this.#httpClient.post<Page<WarehouseDto>>(`${environment.apiUrl}/warehouses/search`, warehouseSearchDto, {params: pagination}).pipe(
      map(warehousesPage => {
        const warehouses: Warehouse[] = warehousesPage.content.map(warehouse => this.loadWarehouse(warehouse));
        return {
          ...warehousesPage,
          content: warehouses
        }
      }),
      shareReplay()
    );
  }

  private loadWarehouse(warehouseDto: WarehouseDto): Warehouse {
    return {
      ...warehouseDto,
      company$: this.#companyService.getCompany$(warehouseDto.companyId)
    }
  }

  public mapToWarehouseSearchDto(warehouseSearch: WarehouseSearch): WarehouseSearchDto {
    const preparationService = this.#injector.get(PreparationService);
    const stockSearchService = this.#injector.get(StockSearchService);
    const stockPrevisionService = this.#injector.get(StockPrevisionService);
    const transferRequestService = this.#injector.get(TransferRequestService);
    return {
      companyId: warehouseSearch.company?.id,
      active: warehouseSearch.active,
      preparationSearchDto: warehouseSearch.preparationSearch && preparationService.mapToPreparationSearchDto(warehouseSearch.preparationSearch),
      warehouseIds: warehouseSearch.warehouses?.map(warehouse => warehouse.id),
      stockSearchDto: warehouseSearch.stockSearch && stockSearchService.mapToStockSearchDto(warehouseSearch.stockSearch),
      sourceWarehouseFromStockPrevisionSearchDto: warehouseSearch.sourceStockPrevisionSearch && stockPrevisionService.mapToStockPrevisionSearchDto(warehouseSearch.sourceStockPrevisionSearch),
      destinationWarehouseFromStockPrevisionSearchDto: warehouseSearch.destinationStockPrevisionSearch && stockPrevisionService.mapToStockPrevisionSearchDto(warehouseSearch.destinationStockPrevisionSearch),
      sourceWarehouseFromTransferRequestSearchDto: warehouseSearch.sourceWarehouseFromTransferRequestSearch && transferRequestService.mapToTransferRequestSearchDto(warehouseSearch.sourceWarehouseFromTransferRequestSearch),
    }

  }

  updateWarehouse(warehouse: Warehouse, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Warehouse> {
    const warehouseDto = this.mapToWarehouseDto(warehouse);
    return this.#httpClient.put<WarehouseDto>(`${environment.apiUrl}/warehouses`, warehouseDto)
      .pipe(map(warehouseDto => this.loadWarehouse(warehouseDto)));
  }

  mapToWarehouseDto(warehouse: Warehouse): WarehouseDto {
    const commonFields: Warehouse | WarehouseDto = copyCommonFields(warehouse, ['company$']);
    return commonFields;
  }

}
