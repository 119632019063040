import { Component, computed, EventEmitter, Input, input, OnChanges, Output, Signal, signal } from '@angular/core';
import { FeadPlanningByOrganization } from '@model/fead-planning-by-organization';
import { Article } from "@model/article";
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { TableModule } from 'primeng/table';
import { PrimeTemplate } from 'primeng/api';
import { TableSizeComponent } from '../../../table-size/table-size.component';
import { Button } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { OrganizationComponent } from '../../../organization/organization.component';
import { FeadPlanningQuantityCellEditorComponent } from '../../fead-planning-quantity-cell-editor/fead-planning-quantity-cell-editor.component';
import { FeadPlanningPeriodCellEditorComponent, PeriodUpdatedEvent } from "@components/fead-planning/fead-planning-period-cell-editor/fead-planning-period-cell-editor.component";

interface FeadArticlePlanningEntrySummary {
  unitsPerParcel: Signal<number | undefined>;
  unitWeight: Signal<number | undefined>;
  saldo: number;
  total: number;
}

interface FeadArticlePlanningEntryWithSummary extends FeadPlanningByOrganization {
  summary: FeadArticlePlanningEntrySummary;
}

interface FeadArticlePlanningSummary {
  unitsPerParcel: Signal<number | undefined>;
  plannedQuantity: number;
  shippedQuantity: number;
  total: number;
  saldo: number;
  periodQuantityMap: { [index: string]: number };
}

@Component({
  selector: 'foodbank-fead-planning-article-table',
  templateUrl: './fead-planning-article-table.component.html',
  styleUrls: ['./fead-planning-article-table.component.scss'],
  imports: [NgIf, TableModule, PrimeTemplate, TableSizeComponent, NgFor, Button, FormsModule, InputTextModule, OrganizationComponent, NgClass, FeadPlanningQuantityCellEditorComponent, AsyncPipe, DecimalPipe, FeadPlanningPeriodCellEditorComponent]
})
export class FeadPlanningArticleTableComponent implements OnChanges {

  article = input<Article>();
  @Input()
  planningData!: FeadPlanningByOrganization[];
  @Input()
  year!: number;
  @Output()
  dataChange = new EventEmitter<FeadPlanningByOrganization[]>();

  periods: string[] = [];
  entryWithSummaryList: FeadArticlePlanningEntryWithSummary[] = [];
  generalSummary: FeadArticlePlanningSummary | undefined;
  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';

  constructor() {
  }

  ngOnChanges(): void {
    this.load();
  }

  updateEntryPeriodQuantity(feadArticlePlanningEntryWithSummary: FeadArticlePlanningEntryWithSummary, period: string, quantity: number) {
    feadArticlePlanningEntryWithSummary.periodQuantityMap[period] = quantity;
    feadArticlePlanningEntryWithSummary.summary = this.getSummary(feadArticlePlanningEntryWithSummary);
    this.calcGeneralSummary();
    this.dataChange.emit(this.entryWithSummaryList);
  }

  addPeriod() {
    const newPeriod = this.year + '/';
    if (!this.periods.includes(newPeriod)) {
      this.periods.push(newPeriod);
      this.periods.sort(this.comparePeriods);
    }
  }

  onPeriodUpdated(event: PeriodUpdatedEvent) {
    const { previousValue, newValue } = event;
    const existingPeriodIndex = this.periods.findIndex(period => period === previousValue);

    if (existingPeriodIndex >= 0) {
      // Update the period in the periods array
      this.periods[existingPeriodIndex] = newValue;

      // Update all entries' periodQuantityMap keys
      this.entryWithSummaryList.forEach(entry => {
        if (previousValue in entry.periodQuantityMap) {
          const value = entry.periodQuantityMap[previousValue];
          entry.periodQuantityMap[newValue] = value;
          delete entry.periodQuantityMap[previousValue];
        }
      });

      // Update the general summary periodQuantityMap
      if (this.generalSummary && previousValue in this.generalSummary.periodQuantityMap) {
        const value = this.generalSummary.periodQuantityMap[previousValue];
        this.generalSummary.periodQuantityMap[newValue] = value;
        delete this.generalSummary.periodQuantityMap[previousValue];
      }

      // Resort periods
      this.periods.sort(this.comparePeriods);

      // Emit changes
      this.dataChange.emit(this.entryWithSummaryList);
    }
  }

  private load() {
    this.getPeriods();
    this.getSummaries();
    this.calcGeneralSummary();
  }

  private getPeriods() {
    const rawPeriods = this.planningData
      .map(entry => Object.keys(entry.periodQuantityMap))
      .flat()
      .reduce((periods: string[], period: string) => [...periods, period], [])
      .sort(this.comparePeriods);
    this.periods = Array.from(new Set(rawPeriods));
  }

  private getSummaries() {
    this.entryWithSummaryList = this.planningData.map(entry => this.getFeadArticlePlanningEntrySummary(entry));
  }

  private calcGeneralSummary() {
    this.generalSummary = this.entryWithSummaryList
      .reduce((currentSummary, entryWithSummary) =>
        this.summarizeEntry(currentSummary, entryWithSummary), this.createInitialSummary());
  }

  private createInitialSummary(): FeadArticlePlanningSummary {
    return {
      unitsPerParcel: signal(0),
      plannedQuantity: 0,
      shippedQuantity: 0,
      total: 0,
      saldo: 0,
      periodQuantityMap: {},
    };
  }

  private summarizeEntry(summary: FeadArticlePlanningSummary, entry: FeadArticlePlanningEntryWithSummary): FeadArticlePlanningSummary {
    summary.unitsPerParcel = computed(() => this.article()?.feadUnitsPerParcel)
    summary.plannedQuantity += entry.plannedQuantity;
    summary.shippedQuantity += entry.shippedQuantity;

    summary.total += entry.summary.total;
    summary.saldo += entry.summary.saldo;

    for (const period in entry.periodQuantityMap) {
      let summaryPeriodQuantity = summary.periodQuantityMap[period] || 0;
      summaryPeriodQuantity += entry.periodQuantityMap[period];
      summary.periodQuantityMap[period] = summaryPeriodQuantity;
    }

    return summary;
  }

  private getFeadArticlePlanningEntrySummary(feadArticlePlanningEntry: FeadPlanningByOrganization): FeadArticlePlanningEntryWithSummary {
    return {
      ...feadArticlePlanningEntry,
      summary: this.getSummary(feadArticlePlanningEntry)
    };
  }

  private getSummary(feadArticlePlanningEntry: FeadPlanningByOrganization): FeadArticlePlanningEntrySummary {
    const total = this.getTotal(feadArticlePlanningEntry);
    return {
      unitsPerParcel: computed(() => this.article()?.feadUnitsPerParcel),
      unitWeight: computed(() => this.article()?.feadUnitWeight),
      saldo: feadArticlePlanningEntry.plannedQuantity - total,
      total: total
    }
  }

  private getTotal(feadArticlePlanningEntry: FeadPlanningByOrganization): number {
    const periodQuantityMap = feadArticlePlanningEntry.periodQuantityMap;
    const quantitySum = Object.values(periodQuantityMap)
      .reduce((runningSum, quantity) => runningSum + quantity, 0);
    const shippedQuantity = feadArticlePlanningEntry.shippedQuantity;
    return quantitySum + shippedQuantity;
  }

  private comparePeriods(period1: string, period2: string): number {
    // extract year and week from the strings
    const [year1, week1] = period1.split("/");
    const [year2, week2] = period2.split("/");

    // compare years
    const yearComparison = parseInt(year1) - parseInt(year2);

    if (yearComparison !== 0) {
      // years are different, done
      return yearComparison;
    }

    // compare weeks
    return parseInt(week1) - parseInt(week2);
  }

  typed(entryWithSummary: FeadArticlePlanningEntryWithSummary): FeadArticlePlanningEntryWithSummary {
    return entryWithSummary;
  }
}
