import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { CalendarDto } from '@typedefs/stock-rest';
import { Calendar } from '@model/calendar';
import { environment } from '@environments/environment';

/**
 * Service for fetching calendar data from the backend.
 */
@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(private http: HttpClient) { }

  /**
   * Gets a calendar for the current year with Monday as the first day of the week.
   * 
   * @returns An Observable of Calendar for the current year
   */
  getCurrentYearCalendar(): Observable<Calendar> {
    return this.http.get<CalendarDto>(environment.apiUrl)
      .pipe(map(dto => this.mapToCalendar(dto)));
  }

  /**
   * Gets a calendar for the specified year with Monday as the first day of the week.
   * 
   * @param year The year to get the calendar for
   * @returns An Observable of Calendar for the specified year
   */
  getCalendarForYear(year: number): Observable<Calendar> {
    return this.http.get<CalendarDto>(`${environment.apiUrl}/calendar/${year}`)
      .pipe(map(dto => this.mapToCalendar(dto)));
  }

  /**
   * Gets a calendar for the specified year with the specified first day of the week.
   * 
   * @param year The year to get the calendar for
   * @param firstDayOfWeek The first day of the week to use (1=Monday, 7=Sunday)
   * @returns An Observable of Calendar for the specified year
   */
  getCalendarForYearWithFirstDay(year: number, firstDayOfWeek: number): Observable<Calendar> {
    return this.http.get<CalendarDto>(`${environment.apiUrl}/calendar/${year}/firstDay/${firstDayOfWeek}`)
      .pipe(map(dto => this.mapToCalendar(dto)));
  }

  /**
   * Maps a CalendarDto to a Calendar business object.
   * 
   * @param dto The DTO to map
   * @returns The mapped business object
   */
  private mapToCalendar(dto: CalendarDto): Calendar {
    const calendar: Calendar = {
      ...dto,
      getWeekOfMonthForDate: (date: Date) => this.getWeekOfMonthForDate(date, dto)
    };
    
    return calendar;
  }

  /**
   * Gets the week of month for a given date in the calendar.
   * 
   * @param date The date to find the week for
   * @param dto The calendar DTO containing the week data
   * @returns The week of month as a string
   * @throws {Error} If the date is not found in the calendar
   */
  private getWeekOfMonthForDate(date: Date, dto: CalendarDto): string {
    const month = date.getMonth() + 1; // JavaScript months are 0-based
    const monthData = dto.yearCalendar[month.toString()];
    
    if (!monthData) {
      throw new Error(`No data found for month ${month} in the calendar`);
    }

    // Find the week that contains the date
    for (const weekKey in monthData) {
      const week = monthData[weekKey];
      const found = week.days.some((day) => {
        const dayDate = new Date(day);
        return dayDate.getDate() === date.getDate() && 
              dayDate.getMonth() === date.getMonth() && 
              dayDate.getFullYear() === date.getFullYear();
      });

      if (found) {
        return week.weekOfMonth;
      }
    }

    throw new Error(`No week found for date ${date.toISOString()} in the calendar`);
  }
} 