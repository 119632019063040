import {Component, inject, Injector, model} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Warehouse} from '@model/warehouse';
import {WarehouseService} from '@services/warehouse.service';
import { NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {UserSignalService} from "@services/user-signal.service";
import {StickerType} from "@typedefs/stock-rest";


@Component({
  selector: 'foodbank-warehouse-edit',
  templateUrl: './warehouse-edit.component.html',
  styleUrls: ['./warehouse-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule,  InputSwitchModule, Button,  MessageModule, DropdownModule]
})
export class WarehouseEditComponent {

  warehouse = model<Warehouse>();
  booIsCreate = false;

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Warehouse>);
  #warehouseService = inject(WarehouseService);
  #messageService = inject(MessageService);
  #userSignalService = inject(UserSignalService);
  #auditChangeService = inject(AuditChangeService);
  #injector = inject(Injector);
  stickerTypes: StickerType[];

  constructor() {
    this.warehouse.set(this.config.data);
    this.booIsCreate = this.warehouse()?.id === undefined;
    this.stickerTypes = [ "NONE" , "BOTH" , "A4" , "A7_ZEBRA"];
  }

  save(warehouse: Warehouse) {

    const action = this.booIsCreate ? 'CREATE' : 'UPDATE';
    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
    const currentUserCompany = this.#userSignalService.$$getCurrentUserCompany(this.#injector);
    const warehouseName = `${warehouse.id} ${warehouse.name}`;
    const auditChange: AuditChangeCreationBase = {
      company: currentUserCompany,
      organization: currentUserOrg,
      user: currentUser,
      entity: "DEPOT",
      entityKey: warehouseName,
      action: action,
    }


    this.#warehouseService.updateWarehouse(warehouse)
      .subscribe(warehouse => {
        this.warehouse.set(warehouse);
        this.ref.close(warehouse);
        this.#messageService.add({severity: 'success', summary: 'Success', detail:  `Warehouse ${warehouseName}  ${action}`});
        this.#auditChangeService.createAuditChange(auditChange).subscribe();
      });
  }

  close() {
    this.ref.close({});
  }

}



