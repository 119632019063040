import {Component, computed, model, Signal} from '@angular/core'
import {DialogService} from "primeng/dynamicdialog";
import {TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {CheckboxModule} from "primeng/checkbox";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {StyleClassModule} from "primeng/styleclass";
import {CalendarModule} from "primeng/calendar";
import {DropdownModule} from "primeng/dropdown";
import {SelectButtonModule} from "primeng/selectbutton";
import {NumberComponent} from "@components/number/number.component";
import {calcDisplayUnitFactor, getWeightDecimalCount, WeightUnit} from "@model/weight.unit";
import {WeightUnitComponent} from "@components/weight/weight-unit.component";

@Component({
  selector: 'foodbank-weight',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.scss'],
  providers: [DialogService],
  imports: [TableModule, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, CheckboxModule, ConfirmDialogModule, StyleClassModule, CalendarModule, DropdownModule, SelectButtonModule, NumberComponent, WeightUnitComponent,]
})
export class WeightComponent {

  ngModel = model<number>();
  // input weight unit
  inputUnit = model<WeightUnit>();
  // display weight unit
  unit = model(WeightUnit.kilogram);
  // show weight unit?
  showUnit = model(true);
  // decimals to show, will be
  decimals = model<number>();
  maxDecimals = model(3);

  actualDecimals: Signal<number>;
  scaledValue: Signal<number | undefined>;

  constructor() {
    const actualInputUnit = computed(() => this.inputUnit() ?? this.unit());
    const displayUnitFactor = computed(() => calcDisplayUnitFactor(actualInputUnit(), this.unit()));
    this.actualDecimals = computed(() => this.decimals() ?? getWeightDecimalCount(actualInputUnit(), this.unit(), this.maxDecimals()));
    this.scaledValue = computed(() => this.calcScaledValue(this.ngModel(), displayUnitFactor()));
  }

  private calcScaledValue(value: number | undefined, displayUnitFactor: number) {
    return value && value / displayUnitFactor;
  }
}

