<p-table #dt
         [value]="stockGroupWithSummaryPage()?.content || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="stockGroupWithSummaryPage()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="loadStockGroupList($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="multiple"
         [(selection)]="selectedStockGroups"
         [multiSortMeta]="[{ field: 'ARTICLE_CATEGORY_ID', order: 1 }, { field: 'ARTICLE_ID', order: 1 }]"
         [styleClass]="tableSizeStyleClass()"
         [scrollable]="true"
         [scrollHeight]="'calc(100vh - 350px)'"
>

  <ng-template pTemplate="caption" *ngIf="!embedded()">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Stock Groups</h2>
    </div>
    <div class="flex flex-column md:flex-row mt-5 justify-content-between">
      <p-multiSelect
        ngDefaultControl
        [options]="ALL_COLUMNS"
        [(ngModel)]="displayedColumns"
        placeholder="Select columns"
      >
        <ng-template let-selectedColumns pTemplate="selectedItems">
          <p-chips [ngModel]="selectedColumns" [disabled]="true">
            <ng-template let-selectedColumn pTemplate="item">
              {{ getColumnLabel(selectedColumn) }}
            </ng-template>
          </p-chips>
        </ng-template>
        <ng-template let-column pTemplate="item">
          {{ getColumnLabel(column) }}
        </ng-template>
        <ng-template let-country pTemplate="footer">
          <div class="py-2 px-3">
            <strong>{{ displayedColumns().length }} item(s) selected.</strong>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
    <div class="flex flex-column md:flex-row mt-5 justify-content-between">
      <div class="field">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th></th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('category') {
            <th [pSortableColumn]="sortFieldName('ARTICLE_CATEGORY_ID')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.category) }}</span>
              <p-sortIcon [field]="sortFieldName('ARTICLE_CATEGORY_ID')"/>
            </th>
          }
          @case ('article') {
            <th [pSortableColumn]="sortFieldName('ARTICLE_ID')" pResizableColumn>
              <span i18n>{{ getColumnLabel(StockGroupColumn.article) }}</span>
              <p-sortIcon [field]="sortFieldName('ARTICLE_ID')"/>
            </th>
          }
          @case ('supplier') {
            <th [pSortableColumn]="sortFieldName('SUPPLIER_ID')" pResizableColumn>
              <span i18n>{{ getColumnLabel(StockGroupColumn.supplier) }}</span>
              <p-sortIcon [field]="sortFieldName('SUPPLIER_ID')"/>
            </th>
          }
          @case ('reception') {
            <th [pSortableColumn]="sortFieldName('RECEPTION_ID')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.reception) }}</span>
              <p-sortIcon [field]="sortFieldName('RECEPTION_ID')"/>
            </th>
          }
          @case ('palletType') {
            <th [pSortableColumn]="sortFieldName('PALLET_TYPE')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.palletType) }}</span>
              <p-sortIcon [field]="sortFieldName('PALLET_TYPE')"/>
            </th>
          }
          @case ('pallets') {
            <th [pSortableColumn]="sortFieldName('STOCK_COUNT')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.pallets) }}</span>
              <p-sortIcon [field]="sortFieldName('STOCK_COUNT')"/>
            </th>
          }
          @case ('totalWeight') {
            <th [pSortableColumn]="sortFieldName('STOCK_WEIGHT')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.totalWeight) }}</span>
              <p-sortIcon [field]="sortFieldName('STOCK_WEIGHT')"/>
            </th>
          }
          @case ('deliverBeforeDate') {
            <th [pSortableColumn]="sortFieldName('DELIVER_BEFORE_DATE')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.deliverBeforeDate) }}</span>
              <p-sortIcon [field]="sortFieldName('DELIVER_BEFORE_DATE')"/>
            </th>
          }
          @case ('fullDescription') {
            <th [pSortableColumn]="sortFieldName('FULL_DESCRIPTION')">
              <span i18n>{{ getColumnLabel(StockGroupColumn.fullDescription) }}</span>
              <p-sortIcon [field]="sortFieldName('FULL_DESCRIPTION')"/>
            </th>
          }
        }
      }
    </tr>
    <tr>
      <th></th>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('category') {
            <th>
              <p-columnFilter field="articleCategories" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <foodbank-article-category-selection ngDefaultControl
                                                       [(selectedArticleCategories)]="selectedArticleCategoriesFilter"
                                                       [articleCategorySearch]="articleCategorySearchForFilter()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('article') {
            <th>
              <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <foodbank-article-multi-selection ngDefaultControl
                                                    [(ngModel)]="selectedArticlesFilter"
                                                    [articleSearch]="articleSearchForFilter()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('supplier') {
            <th>
              <p-columnFilter field="suppliers" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <foodbank-supplier-selection ngDefaultControl
                                               [(ngModel)]="selectedSupplierFilter"
                                               [supplierSearch]="supplierSearchForFilter()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('reception') {
            <th>
              <p-columnFilter field="reception" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <foodbank-reception-selection ngDefaultControl
                                                [(ngModel)]="selectedReceptionFilter"
                                                [receptionSearch]="receptionSearchForFilter()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('palletType') {
            <th>
              <p-columnFilter field="palletType" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-filter="filterCallback">
                  <foodbank-pallet-type-single-selection
                    ngDefaultControl
                    [(ngModel)]="selectedPalletTypeFilter"
                    [palletTypeSearch]="palletTypeSearchForFilter()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('pallets') {
            <th></th>
          }
          @case ('totalWeight') {
            <th></th>
          }
          @case ('deliverBeforeDate') {
            <th></th>
          }
          @case ('fullDescription') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="fullDescriptionContains"/>
            </th>
          }
        }
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-stockGroupWithSummary>
    <ng-container *ngIf="typing(stockGroupWithSummary) as stockGroupWithSummary">
      @let stockGroup = stockGroupWithSummary.stockGroup;
      <tr pDraggable (onDragStart)="notifyStockGroupDragStart($event, stockGroup)" (onDragEnd)="notifyStockGroupDragEnd($event)">
        <td>
          <p-tableCheckbox [value]="stockGroup"/>
        </td>
        @for (column of displayedColumns(); track column) {
          @switch (column) {
            @case ('category') {
              <td>
                <!-- TODO: any should go, it's there because article$ is not always defined correctly (undefined?), still passes ?. but then fails type checking -->
                <foodbank-article-category ngDefaultControl
                                           [articleCategory]="stockGroup.article.value()?.articleCategory$ | async"/>
              </td>

            }
            @case ('article') {
              <td class="white-space-normal" (click)="showMovementDialog(stockGroup, 'ARTICLE')">
                <div class="editable">
                  <foodbank-article ngDefaultControl [ngModel]="stockGroup.article.value()"/>
                  <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit"></i>
                </div>
              </td>
            }
            @case ('supplier') {
              <td class="white-space-normal">
                <foodbank-supplier ngDefaultControl [ngModel]="stockGroup.supplier.value()"/>
              </td>
            }
            @case ('reception') {
              <td>
                @let reception = stockGroup.reception.value();
                <foodbank-reception ngDefaultControl [ngModel]="reception"/>
              </td>
            }
            @case ('palletType') {
              <td>
                <foodbank-pallet-type ngDefaultControl
                                      [ngModel]="stockGroup.palletType.value()"
                />
              </td>
            }
            @case ('pallets') {
              <td class="aligned-quantity">
                {{ stockGroupWithSummary.stockSummary.numberOfPallets }}
              </td>
            }
            @case ('totalWeight') {
              <td class="aligned-quantity">
                <foodbank-weight ngDefaultControl [ngModel]="stockGroupWithSummary.stockSummary.totalWeight" [unit]="WeightUnit.kilogram" [decimals]="2"/>
              </td>
            }
            @case ('deliverBeforeDate') {
              <td class="white-space-normal" (click)="showMovementDialog(stockGroup, 'DLD')">
                <div class="editable">
                  {{ stockGroup.deliverBeforeDate | date: 'dd/MM/yyyy' }}
                  <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit"></i>
                </div>
              </td>
            }
            @case ('fullDescription') {
              <td class="white-space-normal" (click)="showMovementDialog(stockGroup, 'FULL_DESCRIPTION')">
                <div class="editable">
                  {{ stockGroup.fullDescription }}
                  <i class="fa-regular fa-pen-to-square ml-2 edit-icon" pTooltip="Click to edit"></i>
                </div>
              </td>
            }
          }
        }
      </tr>
    </ng-container>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="17"><span i18n>{{ emptyMessage() }}</span></td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{width: '50vw'}" />
