@let movementSnapshotList = movementSnapshotGroupSummaryPage.value();
<p-table #dt [value]="movementSnapshotList?.content || []" responsiveLayout="scroll" [rows]="DEFAULT_ROWS_PER_PAGE"
  [totalRecords]="movementSnapshotList?.totalElements ?? 0" [globalFilterFields]="['depot']" [paginator]="true"
  [rowsPerPageOptions]="[15,25,50,100]" [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [lazy]="true"
  (onLazyLoad)="handleLazyLoad($event)" [rowHover]="true" [resizableColumns]="true" columnResizeMode="expand"
  [autoLayout]="true" sortMode="multiple" dataKey="id" [loading]="movementSnapshotGroupSummaryPage.isLoading()"
  [showLoader]="true">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">
        Stock movement report
        <p-button label="Export" icon="pi pi-cloud-download" styleClass="p-button-primary"
          (onClick)="exportToExcel()" />
      </h2>
    </div>
    <div>
      <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center mt-5 mb-3">
        <p-multiSelect ngDefaultControl [options]="ALL_COLUMNS" [(ngModel)]="displayedColumns"
          placeholder="Select columns">
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
            {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
              <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </div>
      <div class="flex-auto flex-column md:flex-row md:justify-content-start md:align-items-center mb-3 ml-2">
        <div class="grid mb-3">
          <label class="col-fixed" style="width: 200px;" i18n>Month and/or Year</label>
          <div class="col-auto">
            <span>
              <p-calendar [(ngModel)]="filterMonthDate" (ngModelChange)="filterYearDate.set(undefined)" view="month"
                dateFormat="mm/yy" placeholder="Monthly report" />
              <p-calendar [(ngModel)]="filterYearDate" (ngModelChange)="filterMonthDate.set(undefined)" view="year"
                dateFormat="yy" placeholder="Yearly report" />
            </span>
          </div>
        </div>
        <div class="grid mb-3">
          <label class="col-fixed" style="width: 200px;" i18n>Grouping</label>
          <div class="col-auto">
            <p-selectButton [(ngModel)]="grouping" [options]="ALL_GROUPINGS">
              <ng-template let-grouping pTemplate="item">
                {{ getGroupingLabel(grouping) }}
              </ng-template>
            </p-selectButton>
          </div>
        </div>
        <div class="grid mb-3">
          <label class="col-fixed" style="width: 200px;" i18n>Unit</label>
          <div class="col-auto">
            <foodbank-weight-unit-selection ngDefaultControl [(ngModel)]="weightUnit" [allowedUnits]="weightUnits()" />
          </div>
        </div>

      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
      @switch (column) {
      @case ('group') {
      @switch (grouping()) {
      @case (Grouping.warehouse) {
      <th pSortableColumn="warehouse">
        <span i18n>Warehouse</span>
        <p-sortIcon field="warehouse" />
      </th>
      }
      @case (Grouping.company) {
      <th pSortableColumn="company">
        <span i18n>Company</span>
        <p-sortIcon field="company" />
      </th>
      }
      @case (Grouping.article) {
      <th pSortableColumn="article">
        <span i18n>Article</span>
        <p-sortIcon field="article" />
      </th>
      }
      @case (Grouping.articleCategory) {
      <th pSortableColumn="category">
        <span i18n>Category</span>
        <p-sortIcon field="category" />
      </th>
      }
      }
      }
      @case ('startStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <th pSortableColumn="startStockQuantity" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.startStockQuantity) }}</span>
        <p-sortIcon field="startStockQuantity" />
      </th>
      }
      }
      @case ('incomingQuantity') {
      <th pSortableColumn="incomingQuantity" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantity) }}</span>
        <p-sortIcon field="incomingQuantity" />
      </th>
      }
      @case ('outgoingQuantity') {
      <th pSortableColumn="outgoingQuantitystartStockQuantity" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.outgoingQuantity) }}</span>
        <p-sortIcon field="outgoingQuantitytity" />
      </th>
      }
      @case ('endStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <th pSortableColumn="endStockQuantitystartStockQuantity" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.endStockQuantity) }}</span>
        <p-sortIcon field="endStockQuantitytity" />
      </th>
      }
      }
      @case ('incomingQuantityAuction') {
      <th pSortableColumn="incomingQuantityAuction" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityAuction) }}</span>
        <p-sortIcon field="startStockQuantityincomingQuantityAuction" />
      </th>
      }
      @case ('incomingQuantityEsf') {
      <th pSortableColumn="incomingQuantityEsf" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityEsf) }}</span>
        <p-sortIcon field="incomingQuantityEsf" />
      </th>
      }
      @case ('incomingQuantityIndustry') {
      <th pSortableColumn="incomingQuantityIndustry" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityIndustry) }}</span>
        <p-sortIcon field="incomingQuantityIndustry" />
      </th>
      }
      @case ('incomingQuantityDistribution') {
      <th pSortableColumn="incomingQuantityDistribution" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityDistribution) }}</span>
        <p-sortIcon field="incomingQuantityDistribution" />
      </th>
      }
      @case ('incomingQuantityCollect') {
      <th pSortableColumn="incomingQuantityCollect" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityCollect) }}</span>
        <p-sortIcon field="incomingQuantityCollect" />
      </th>
      }
      @case ('incomingQuantityFederation') {
      <th pSortableColumn="incomingQuantityFederation" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityFederation) }}</span>
        <p-sortIcon field="incomingQuantityFederation" />
      </th>
      }
      @case ('incomingQuantityPurchase') {
      <th pSortableColumn="incomingQuantityPurchase" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityPurchase) }}</span>
        <p-sortIcon field="incomingQuantityPurchase" />
      </th>
      }
      @case ('incomingQuantityOther') {
      <th pSortableColumn="incomingQuantityOther" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantityOther) }}</span>
        <p-sortIcon field="incomingQuantityOther" />
      </th>
      }
      @case ('incomingQuantitySameBankWarehouses') {
      <th pSortableColumn="incomingQuantitySameBankWarehouses" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.incomingQuantitySameBankWarehouses) }}</span>
        <p-sortIcon field="incomingQuantitySameBankWarehouses" />
      </th>
      }
      @case ('outgoingQuantityOrganization') {
      <th pSortableColumn="utgoingQuantityOrganization" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.outgoingQuantityOrganization) }}</span>
        <p-sortIcon field="outgoingQuantityOrganization" />
      </th>
      }
      @case ('outgoingQuantityTransferToOtherBank') {
      <th pSortableColumn="startStockQuantityoutgoingQuantityTransferToOtherBank" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.outgoingQuantityTransferToOtherBank) }}</span>
        <p-sortIcon field=" outgoingQuantityTransferToOtherBank" />
      </th>
      }
      @case ('outgoingQuantityTransferToFederation') {
      <th pSortableColumn="outgoingQuantityTransferToFederation" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.outgoingQuantityTransferToFederation) }}</span>
        <p-sortIcon field="outgoingQuantityTransferToFederation" />
      </th>
      }
      @case ('outgoingQuantityWaste') {
      <th pSortableColumn="outgoingQuantityWaste" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.outgoingQuantityWaste) }}</span>
        <p-sortIcon field="outgoingQuantityWaste" />
      </th>
      }
      @case ('correctionQuantity1') {
      <th pSortableColumn="correctionQuantity1" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.correctionQuantity1) }}</span>
        <p-sortIcon field="correctionQuantity1" />
      </th>
      }
      @case ('correctionQuantity2') {
      <th pSortableColumn="correctionQuantity2" class="report-number-column">
        <span i18n>{{ getColumnLabel(MovementSnapshotColumn.correctionQuantity2) }}</span>
        <p-sortIcon field="correctionQuantity2" />
      </th>
      }
      }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
      @switch (column) {
      @case ('group') {
      @switch (grouping()) {
      @case (Grouping.warehouse) {
      <th>
        <foodbank-warehouse-selection ngDefaultControl [(ngModel)]="filterWarehouse" />
      </th>
      }
      @case (Grouping.company) {
      <th>
        <foodbank-company-selection ngDefaultControl [(ngModel)]="filterCompany" />
      </th>
      }
      @case (Grouping.article) {
      <th>
        <foodbank-article-selection ngDefaultControl [(ngModel)]="filterArticle" />
      </th>
      }
      @case (Grouping.articleCategory) {
      <th>
        <foodbank-article-category-selection ngDefaultControl [(ngModel)]="filterArticleCategory" />
      </th>
      }
      }
      }
      @case ('startStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <th></th>
      }
      }
      @case ('incomingQuantity') {
      <th></th>
      }
      @case ('outgoingQuantity') {
      <th></th>
      }
      @case ('endStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <th></th>
      }
      }
      @case ('incomingQuantityAuction') {
      <th></th>
      }
      @case ('incomingQuantityEsf') {
      <th></th>
      }
      @case ('incomingQuantityIndustry') {
      <th></th>
      }
      @case ('incomingQuantityDistribution') {
      <th></th>
      }
      @case ('incomingQuantityCollect') {
      <th></th>
      }
      @case ('incomingQuantityFederation') {
      <th></th>
      }
      @case ('incomingQuantityPurchase') {
      <th></th>
      }
      @case ('incomingQuantityOther') {
      <th></th>
      }
      @case ('incomingQuantitySameBankWarehouses') {
      <th></th>
      }
      @case ('outgoingQuantityOrganization') {
      <th></th>
      }
      @case ('outgoingQuantityTransferToOtherBank') {
      <th></th>
      }
      @case ('outgoingQuantityTransferToFederation') {
      <th></th>
      }
      @case ('outgoingQuantityWaste') {
      <th></th>
      }
      @case ('correctionQuantity1') {
      <th></th>
      }
      @case ('correctionQuantity2') {
      <th></th>
      }
      }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let movementSnapshotGroupSummary = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
      @let summary = movementSnapshotGroupSummary.summary;
      @switch (column) {
      @case ('group') {
      @switch (grouping()) {
      @case (Grouping.warehouse) {
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="getWarehouseGroup(movementSnapshotGroupSummary).value()" />
      </td>
      }
      @case (Grouping.company) {
      <td>
        <foodbank-company ngDefaultControl [ngModel]="getCompanyGroup(movementSnapshotGroupSummary).value()" />
      </td>
      }
      @case (Grouping.article) {
      <td>
        <foodbank-article ngDefaultControl [ngModel]="getArticleGroup(movementSnapshotGroupSummary).value()" />
      </td>
      }
      @case (Grouping.articleCategory) {
      <td>
        <foodbank-article-category ngDefaultControl
          [articleCategory]="getArticleCategoryGroup(movementSnapshotGroupSummary).value()" />
      </td>
      }
      }
      }
      @case ('startStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.startStockQuantity" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      }
      @case ('incomingQuantity') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantity" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('outgoingQuantity') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.outgoingQuantity" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('endStockQuantity') {
      @if (filterMonthDate()) {
      <!--FIXME: display when numbers are right-->
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.endStockQuantity" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      }
      @case ('incomingQuantityAuction') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityAuction" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityEsf') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityEsf" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityIndustry') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityIndustry" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityDistribution') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityDistribution" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityCollect') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityCollect" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityFederation') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityFederation" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityPurchase') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityPurchase" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantityOther') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantityOther" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('incomingQuantitySameBankWarehouses') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.incomingQuantitySameBankWarehouses" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('outgoingQuantityOrganization') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.outgoingQuantityOrganization" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('outgoingQuantityTransferToOtherBank') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.outgoingQuantityTransferToOtherBank" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('outgoingQuantityTransferToFederation') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.outgoingQuantityTransferToFederation" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('outgoingQuantityWaste') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.outgoingQuantityWaste" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('correctionQuantity1') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.correctionQuantity1" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      @case ('correctionQuantity2') {
      <td class="aligned-snapshot-quantity">
        <foodbank-weight ngDefaultControl [ngModel]="summary.correctionQuantity2" [unit]="weightUnit()"
          [inputUnit]="WeightUnit.kilogram" [maxDecimals]="1" />
      </td>
      }
      }
      }
      <td>
        <!-- TODO: add actions -->
      </td>
    </tr>
  </ng-template>
</p-table>