@if (bankProg()!) {
<div class="field grid">
    <label for="mondayAm" class="col-12 mb-2 md:col-2 md:mb-0">Monday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="mondayAm" name="mondayAm" [(ngModel)]="bankProg()!.mondayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activityMondayAm" class="col-12 mb-2 md:col-2 md:mb-0">Monday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activityMondayAm" type="text" pInputText name="activityMondayAm" [(ngModel)]="bankProg()!.activityMondayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="mondayPm" class="col-12 mb-2 md:col-2 md:mb-0">Monday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="mondayPm" name="mondayAm" [(ngModel)]="bankProg()!.mondayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activityMondayPm" class="col-12 mb-2 md:col-2 md:mb-0">Monday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activityMondayPm" type="text" pInputText name="activityMondayPm" [(ngModel)]="bankProg()!.activityMondayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="tuesdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Tuesday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="tuesdayAm" name="tuesdayAm" [(ngModel)]="bankProg()!.tuesdayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityTuesdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Tuesday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activityTuesdayAm" type="text" pInputText name="activityTuesdayAm" [(ngModel)]="bankProg()!.activityTuesdayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="tuesdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Tuesday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="tuesdayPm" name="tuesdayPm" [(ngModel)]="bankProg()!.tuesdayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activityTuesdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Tuesday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activityTuesdayPm" type="text" pInputText name="activityTuesdayPm" [(ngModel)]="bankProg()!.activityTuesdayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="wednesdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Wednesday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="wednesdayAm" name="wednesdayAm" [(ngModel)]="bankProg()!.wednesdayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityWednesdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Wednesday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activityWednesdayAm" type="text" pInputText name="activityWednesdayAm" [(ngModel)]="bankProg()!.activityWednesdayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="wednesdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Wednesday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="wednesdayPm" name="wednesdayPm" [(ngModel)]="bankProg()!.wednesdayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityWednesdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Wednesday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activityWednesdayPm" type="text" pInputText name="activityWednesdayPm" [(ngModel)]="bankProg()!.activityWednesdayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="thursdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Thursday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="thursdayAm" name="thursdayAm" [(ngModel)]="bankProg()!.thursdayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityThursdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Thursday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activityThursdayAm" type="text" pInputText name="activityThursdayAm" [(ngModel)]="bankProg()!.activityThursdayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="thursdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Thursday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="thursdayPm" name="thursdayPm" [(ngModel)]="bankProg()!.thursdayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityThursdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Thursday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activityThursdayPm" type="text" pInputText name="activityThursdayPm" [(ngModel)]="bankProg()!.activityThursdayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="fridayAm" class="col-12 mb-2 md:col-2 md:mb-0">Friday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="fridayAm" name="fridayAm" [(ngModel)]="bankProg()!.fridayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activityFridayAm" class="col-12 mb-2 md:col-2 md:mb-0">Friday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activityFridayAm" type="text" pInputText name="activityFridayAm" [(ngModel)]="bankProg()!.activityFridayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="fridayPm" class="col-12 mb-2 md:col-2 md:mb-0">Friday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="fridayPm" name="fridayPm" [(ngModel)]="bankProg()!.fridayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activityFridayPm" class="col-12 mb-2 md:col-2 md:mb-0">Friday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activityFridayPm" type="text" pInputText name="activityFridayPm" [(ngModel)]="bankProg()!.activityFridayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="saturdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Saturday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="saturdayAm" name="saturdayAm" [(ngModel)]="bankProg()!.saturdayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activitySaturdayAm" class="col-12 mb-2 md:col-2 md:mb-0">Saturday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activitySaturdayAm" type="text" pInputText name="activitySaturdayAm" [(ngModel)]="bankProg()!.activitySaturdayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="saturdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Saturday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="saturdayPm" name="saturdayPm" [(ngModel)]="bankProg()!.saturdayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activitySaturdayPm" class="col-12 mb-2 md:col-2 md:mb-0">Saturday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activitySaturdayPm" type="text" pInputText name="activitySaturdayPm" [(ngModel)]="bankProg()!.activitySaturdayPm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="sundayAm" class="col-12 mb-2 md:col-2 md:mb-0">Sunday Morning</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="sundayAm" name="sundayAm" [(ngModel)]="bankProg()!.sundayAm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div  class="field grid">
    <label for="activitySundayAm" class="col-12 mb-2 md:col-2 md:mb-0">Sunday Morning</label>
    <div class="col-12 md:col-10">
      <input id="activitySundayAm" type="text" pInputText name="activitySundayAm" [(ngModel)]="bankProg()!.activitySundayAm" ngDefaultControl>
    </div>
  </div>
  <div class="field grid">
    <label for="sundayPm" class="col-12 mb-2 md:col-2 md:mb-0">Sunday Afternoon</label>
    <div class="col-12 md:col-10">
      <p-inputSwitch id="sundayPm" name="sundayPm" [(ngModel)]="bankProg()!.sundayPm" ngDefaultControl></p-inputSwitch>
    </div>
  </div>
  <div class="field grid">
    <label for="activitySundayPm" class="col-12 mb-2 md:col-2 md:mb-0">Sunday Afternoon</label>
    <div class="col-12 md:col-10">
      <input id="activitySundayPm" type="text" pInputText name="activitySundayPm" [(ngModel)]="bankProg()!.activitySundayPm" ngDefaultControl>
    </div>
  </div>
}