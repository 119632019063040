<p-table #dt
         [value]="(auditChangePage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="auditChangePage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         [multiSortMeta]="[{ field: 'AUDIT_DATE', order: -1 }, { field: 'ID', order: 1 }]"
         dataKey="id">

  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="USERNAME">
        <span i18n>UserId</span>
        <p-sortIcon field="USERNAME" />
      </th>
      <th pSortableColumn="ORGANIZATION_NAME">
        <span i18n>Organization</span>
        <p-sortIcon field="ORGANIZATION_NAME" />
      </th>
      <th pSortableColumn="AUDIT_DATE">
        <span i18n>Time Update</span>
        <p-sortIcon field="AUDIT_DATE" />
      </th>
      <th>
        <span i18n>Entity</span>
      </th>
      <th>
        <span i18n>Entity Key</span>
      </th>
      <th>
        <span i18n>Action</span>
      </th>
    </tr>
    <tr>
      <th>
        <p-columnFilter field="users" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-user>
            <foodbank-user-selection ngDefaultControl
                                     [(ngModel)]="filterUser"
                                     [userSearch]="userSearch()"
            />
          </ng-template>
        </p-columnFilter>

      </th>
      <th>
        <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-organization>
            <foodbank-organization-selection ngDefaultControl
                                             [(ngModel)]="filterOrganization"
                                             [organizationSearch]="organizationSearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
<th>
      <p-columnFilter field="auditChangeDateSearch" [showMenu]="false" style="width: 15em; display: inline-block">
        <ng-template pTemplate="filter">
          <foodbank-date-search
            ngDefaultControl
            [(ngModel)]="auditChangeDateSearch"
            [required]="false"
            [showButtonBar]="true"
          />
        </ng-template>
      </p-columnFilter>
</th>
      <th >
        <p-columnFilter field="filterEntity" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-entity>
            <foodbank-audit-change-entity-selection ngDefaultControl
                                                    [(ngModel)]="filterEntity"

            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th >
        <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterEntityKeyContains" ngDefaultControl/>
      </th>
      <th>
        <p-columnFilter field="filterAction" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-action>
            <foodbank-audit-change-action-selection ngDefaultControl
                                                    [(ngModel)]="filterAction"

            />
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-auditChange>
    <ng-container *ngIf="identity(auditChange) as auditChange">
      <tr>
        <td>
          {{ auditChange.user.value()?.name }}
        </td>
        <td>
          {{ auditChange.organization.value()?.name }}
        </td>

        <td>
          {{ formatDate(auditChange.dateIn, 'dd-MM-yyyy HH:mm','en') }}
        </td>
        <td>
          {{ auditChange.entity}}
        </td>
        <td>
          {{ auditChange.entityKey}}
        </td>
        <td>
          {{ auditChange.action}}
        </td>

      </tr>
    </ng-container>
  </ng-template>
</p-table>



