import {Component, model} from '@angular/core'
import {DialogService} from "primeng/dynamicdialog";
import {ALL_WEIGHT_UNITS, WeightUnit} from "@model/weight.unit";
import {SelectButtonModule} from "primeng/selectbutton";
import {FormsModule} from "@angular/forms";
import {WeightUnitComponent} from "@components/weight/weight-unit.component";

@Component({
  selector: 'foodbank-weight-unit-selection',
  templateUrl: './weight-unit-selection.component.html',
  styleUrls: ['./weight-unit-selection.component.scss'],
  providers: [DialogService],
  imports: [
    SelectButtonModule,
    FormsModule,
    WeightUnitComponent
  ]
})
export class WeightUnitSelectionComponent {

  ngModel = model<WeightUnit>();
  allowedUnits = model(ALL_WEIGHT_UNITS);

}

