import {Component, inject, input, model} from '@angular/core';
import {LanguageService} from '@services/language.service';
import {Member} from '@model/member';
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss'],
  imports: [
    NgIf
  ]
})
export class MemberComponent {

  ngModel = model<Member>();
  showId = input(true);

  private languageService = inject(LanguageService);

  constructor() {
  }

}
