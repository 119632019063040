import {Component, input, model} from '@angular/core';
import {OrganizationActivity} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationActivityComponent} from "@components/organization-activity/organization-activity.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-activity-selection',
  imports: [
    DropdownModule,
    OrganizationActivityComponent,
    FormsModule
  ],
  templateUrl: './organization-activity-selection.component.html',
  styleUrl: './organization-activity-selection.component.scss'
})
export class OrganizationActivitySelectionComponent {
  ngModel = model<OrganizationActivity>();
  required = input(false);

  organizationActivities: OrganizationActivity[];

  constructor() {

    this.organizationActivities = [  "NONE" ,"SECOND_HAND_CLOTHES" , "SOCIAL_GROCERY" , "SECOND_HAND_FURNITURE" , "HOMEWORK_CLUB" , "SOCIAL_RESTAURANT" , "COMMUNITY_KITCHEN"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}
