import {Component, OnInit} from '@angular/core';
import {Warehouse} from '@model/warehouse';
import {Organization} from '@model/organization';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {map, mergeMap, Observable} from 'rxjs';
import {UserService} from '@services/user.service';
import {Company} from '@model/company';
import {OrganizationSearch} from '@model/search/organization-search';
import {FeadPlanningByArticle} from '@model/fead-planning-by-article';
import {OrganizationFeadPlanningService} from '@services/organization-fead-planning.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import { NgIf, AsyncPipe } from '@angular/common';
import { WarehouseSingleSelectionComponent } from '../../warehouse/selection/single/warehouse-single-selection.component';
import { FormsModule } from '@angular/forms';
import { FeadCampaignSelectionComponent } from '../../fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import { OrganizationSingleSelectionComponent } from '../../organization/selection/single/organization-single-selection.component';
import { Button } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FeadPlanningOrganizationTableComponent } from './table/fead-planning-organization-table.component';
import { CalendarPanelComponent } from '../../calendar/calendar-panel.component';
import { CalendarButtonComponent } from '../../calendar/calendar-button.component';

@Component({
    selector: 'foodbank-fead-planning-organization',
    templateUrl: './fead-planning-organization.component.html',
    styleUrls: ['./fead-planning-organization.component.scss'],
    imports: [
      NgIf, 
      WarehouseSingleSelectionComponent, 
      FormsModule, 
      FeadCampaignSelectionComponent, 
      OrganizationSingleSelectionComponent, 
      Button, 
      ConfirmDialogModule, 
      FeadPlanningOrganizationTableComponent, 
      CalendarPanelComponent,
      CalendarButtonComponent,
      AsyncPipe
    ]
})
export class FeadPlanningOrganizationComponent implements OnInit {

  // user selected or entered values
  warehouseSearch$: Observable<WarehouseSearch> | undefined;
  organizationSearch: OrganizationSearch | undefined;

  warehouse: Warehouse | undefined;
  campaign: FeadCampaignIdentifier | undefined;
  organization: Organization | undefined;

  // loaded data
  planningData$: Observable<FeadPlanningByArticle[]> | undefined;

  updatedData: FeadPlanningByArticle[] | undefined;

  constructor(
    private userService: UserService,
    private organizationFeadPlanningService: OrganizationFeadPlanningService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
  }

  ngOnInit(): void {
    const currentUser$ = this.userService.getCurrentUser$();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    defaultWarehouse$.subscribe(defaultWarehouse => this.selectWarehouse(defaultWarehouse!));
    this.warehouseSearch$ = currentUser$.pipe(
      mergeMap(user => user.company$),
      map(company => this.getWarehouseSearch(company))
    );
    this.createOrganizationSearch();
  }

  selectWarehouse(warehouse?: Warehouse) {
    this.warehouse = warehouse;
    this.organization = undefined;
    this.createOrganizationSearch();
    this.loadPlanningData();
  }

  selectOrganization(organization: Organization) {
    this.organization = organization;
    this.loadPlanningData()
  }

  selectCampaign(campaign: FeadCampaignIdentifier) {
    this.campaign = campaign;
    this.loadPlanningData();
  }

  save() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to save this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Save',
      rejectLabel: 'Cancel',
      accept: () => {
        this.organizationFeadPlanningService.saveOrganizationFeadPlanning$(this.warehouse!, this.campaign!.year, this.organization!, this.updatedData!)
          .subscribe(() => {
            this.messageService.add({severity: 'success', summary: 'Saved', detail: 'Planning data has been saved.'});
            this.loadPlanningData();
          });
      }
    });
  }

  cancel() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to cancel this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Reload',
      rejectLabel: 'Stay',
      accept: () => {
        this.loadPlanningData();
        this.messageService.add({severity: 'warn', summary: 'Canceled', detail: 'Planning data has been reset.'});
      }
    });
  }

  delete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this planning?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.organizationFeadPlanningService.deleteOrganizationFeadPlanning$(this.warehouse!, this.campaign!.year, this.organization!)
          .subscribe(() => {
            this.messageService.add({severity: 'info', summary: 'Deleted', detail: 'Planning data has been reset.'});
            this.loadPlanningData();
          });
      }
    });
  }

  private createOrganizationSearch() {
    this.organizationSearch = this.getOrganizationSearch(this.warehouse);
  }

  private getWarehouseSearch(company: Company): WarehouseSearch {
    return {
      company: company
    }
  }

  private getOrganizationSearch(warehouse?: Warehouse): OrganizationSearch {
    return {
      warehouse: warehouse,
      bigBirbCode: true
    }
  }

  private loadPlanningData() {
    if (this.warehouse && this.campaign && this.organization) {
      this.planningData$ = this.organizationFeadPlanningService.getOrganizationFeadPlanning$(this.warehouse, this.campaign.year, this.organization);
      this.planningData$.subscribe(data => this.planningDataUpdates(data));
    } else {
      this.planningData$ = undefined;
      this.updatedData = undefined;
    }
  }


  planningDataUpdates(planningData: FeadPlanningByArticle[]) {
    this.updatedData = planningData;
  }
}
