import {Component, computed, inject, Injector, model, Resource, signal, Signal, WritableSignal} from '@angular/core'
import {Audit} from '@model/audit';
import {AuditService} from '@services/audit.service';
import {UserService} from "@services/user.service";
import {AuditSearch} from "@model/search/audit-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {rxResource} from "@angular/core/rxjs-interop";
import {Organization} from "@model/organization";
import {OrganizationSearch} from "@model/search/organization-search";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {DateSearchComponent} from "@components/date/date-search/date-search.component";
import {DateSearch} from "@model/search/date-search";
import {UserSelectionComponent} from "@components/users/selection/user-selection.component";
import {UserS} from "@model/user";
import {UserSearch} from "@model/search/user-search";

@Component({
  selector: 'foodbank-audits',
  templateUrl: './audit-list.component.html',
  styleUrls: ['./audit-list.component.scss'],

  imports: [TableModule, InputTextModule, ButtonModule, MultiSelectModule, OrganizationSingleSelectionComponent, NgIf, PaginatorModule, DateSearchComponent, UserSelectionComponent,]
})
export class AuditListComponent {

  // selected search filters for this view
  filterOrganization = model<Organization>();
  filterUser = model<UserS>();

  // searches for filters (e.g. user)
  userSearch: Signal<UserSearch>;

  // searches for filters (e.g. organization)
  organizationSearch: Signal<OrganizationSearch>;
  // this view search
  auditSearch: Signal<AuditSearch>;

  auditDateSearch = signal<DateSearch>({});
  pagination: WritableSignal<Pagination>;

  // results
  auditPage: Resource<Page<Audit> | undefined>;

  // services
  #auditService = inject(AuditService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
    }));
    this.userSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.auditSearch = computed(() => ({
      company: currentUserCompany(),
      organization: this.filterOrganization(),
      user: this.filterUser(),
      dateSearchDto: this.auditDateSearch(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.auditPage = rxResource({
      request: () => ({
        auditSearch: this.auditSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#auditService.findAudits$(this.auditSearch(), this.#injector, this.pagination())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  identity(audit: Audit): Audit {
    return audit;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

}





