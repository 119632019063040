import {Component, computed, inject, Injector, model, Signal} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UserS} from '@model/user';
import {UserService} from '@services/user.service';
import {NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {InputSwitchModule} from 'primeng/inputswitch';
import {Button} from 'primeng/button';
import {MessageService} from "primeng/api";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {MemberSearch} from "@model/search/member-search";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {MemberSelectionComponent} from "@components/member/selection/single/member-selection.component";
import {UserSignalService} from "@services/user-signal.service";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {CpasSelectionComponent} from "@components/cpas/selection/cpas-selection.component";
import {CpasSearch} from "@model/search/cpas-search";
import {UserRoleSelectionComponent} from "@components/user-type/selection/user-role-selection.component";
import {DbChangeAction} from "@typedefs/stock-rest";
import {AuditChangeCreationBase} from "@model/audit-change";
import {AuditChangeService} from "@services/audit-change.service";

@Component({
  selector: 'foodbank-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, InputSwitchModule, Button, MessageModule, DropdownModule, OrganizationSingleSelectionComponent, MemberSelectionComponent, WarehouseSingleSelectionComponent, CpasSelectionComponent, UserRoleSelectionComponent]
})
export class UserEditComponent {

  booIsCreate = false;
  user = model<UserS>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<UserS>);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #auditChangeService = inject(AuditChangeService);
  #messageService = inject(MessageService);
  #injector = inject(Injector);

  memberSearch: Signal<MemberSearch>;
  warehouseSearch: Signal<WarehouseSearch>;
  cpasSearch: Signal<CpasSearch>;

  constructor() {
    this.user.set(this.config.data);
    this.booIsCreate = this.user()?.password === undefined;
    const currentUserCompany = this.#userService.getCurrentUserCompany();

    this.memberSearch = computed(() => ({
      company: currentUserCompany()!,
      active: true,
    }));
    this.warehouseSearch = computed<WarehouseSearch>(() => ({
        company: currentUserCompany()!,
        active: true,
      })
    );
    this.cpasSearch = computed<CpasSearch>(() => ({})
    );

  }

  save(user: UserS) {
    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    // synchronize language and email and user name from member
    const member = user.member.value();
    user.language = 'DUTCH';
    if (member && member.language) {
      user.language = member.language!;
    }
    user.email = "";
    if (member && member.email) {
      user.email = member.email;
    }
    user.name = "";
    if (member && member.familyName) {
      user.name = member.familyName + " " + member.firstName;
    }
    let changeAction: DbChangeAction = "UPDATE";
    if (this.booIsCreate) {
      changeAction = "CREATE";
    }
    const auditChange: AuditChangeCreationBase = {
      company: user.company,
      organization: user.organization,
      user: currentUser,
      entity: "USER",
      entityKey: user.name,
      action: changeAction,
    }
    if (this.booIsCreate) {
        const userExists = this.#userSignalService.userExists(user.id).subscribe(exists => {
        if (exists) {
          this.#messageService.add({severity: 'error', summary: 'Error', detail: `User ${user.id} already exists`});
          return;
        } else {
          this.#userSignalService.save(user, this.booIsCreate)
            .subscribe(user => {
              this.user.set(user);
              this.#messageService.add({severity: 'success', summary: 'Success', detail: `User ${user.id} created`});
              this.ref.close(user);
              this.#auditChangeService.createAuditChange(auditChange).subscribe();
            });
        }
      });
    } else {

      this.#userSignalService.save(user, this.booIsCreate)
        .subscribe(user => {
          this.user.set(user);
          this.#messageService.add({severity: 'success', summary: 'Success', detail: `User ${user.id} updated`});
          this.ref.close(user);
          this.#auditChangeService.createAuditChange(auditChange).subscribe();
        });
    }

  }

  close() {
    this.ref.close({});
  }

}

