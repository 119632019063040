<p-table #dt [value]="(((feadPlanningLinePage$ | async)?.content) || [])"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="((feadPlanningLinePage$ | async)?.totalElements || 0)"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[50,100,200]"
         [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         dataKey="id"
         [lazy]="true"
         (onLazyLoad)="loadFeadPlanning($event)"
         rowGroupMode="rowspan"
         groupRowsBy="article.id,warehouse.id,organization.id"
         [loading]="!(feadPlanningLinePage$ | async)"
         loadingIcon="pi pi-spin pi-spinner big-loading-icon">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h2 class="m-0">Fead Plannings</h2>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pResizableColumn>
        <span i18n>Article</span>
      </th>
      <th>
        <span i18n>Net weight</span>
      </th>
      <th>
        <span i18n>Units per parcel</span>
      </th>
      <th>
        <span i18n>Parcels per pallet</span>
      </th>
      <th>
        <span i18n>Warehouse</span>
      </th>
      <th pResizableColumn>
        <span i18n>Organization</span>
      </th>
      <th>
        <span i18n>Year</span>
      </th>
      <th>
        <span i18n>Period</span>
      </th>
      <th>
        <span i18n>Quantity</span>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-feadPlanningLine let-rowgroup="rowgroup" let-rowspan="rowspan">
    <ng-container *ngIf="identity(feadPlanningLine) as feadPlanningLine">
      <ng-container *ngIf="((feadPlanningLine.article$ | async)) as article">
        <tr>
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan" class="white-space-normal">
            <foodbank-article ngDefaultControl [ngModel]="(feadPlanningLine.article$ | async) || undefined" [showId]="true" />
          </td>
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
            {{ article.feadUnitGrossWeight }}
          </td>
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
            {{ article.feadUnitsPerParcel }}
          </td>
          <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
            {{ article.feadParcelsPerPallet }}
          </td>
          <td>
            <foodbank-warehouse ngDefaultControl [attr.rowspan]="rowspan" [ngModel]="(feadPlanningLine.warehouse$ | async) || undefined" [showId]="true" />
          </td>
          <td class="white-space-normal">
            <foodbank-organization ngDefaultControl [attr.rowspan]="rowspan" [ngModel]="(feadPlanningLine.organization$ | async) || undefined" [showId]="true" />
          </td>
          <td>
            {{ feadPlanningLine.year }}
          </td>
          <td>
            {{ feadPlanningLine.weekOrDay }}
          </td>
          <td class="decimal_quantity">
            {{ feadPlanningLine.quantity | number: '1.2-2' }}
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </ng-template>
</p-table>
