import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Donor, DonorCreationBase} from '@model/donor';
import {DonorService} from '@services/donor.service';
import {DonorSearch} from "@model/search/donor-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {DonorEditComponent} from "@components/donor/donor-edit/donor-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";
import {UserSignalService} from "@services/user-signal.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DonationService} from "@services/donation.service";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {Organization} from "@model/organization";
import {ExcelService} from "@services/excel.service";
import {formatDate} from "@angular/common";

enum DonorColumn {
  familyName = 'familyName',
  firstName = 'firstName',
  address = 'address',
  zip = 'zip',
  city = 'city',
  email = 'email',
}

const COLUMN_LABELS: ColumnLabels<DonorColumn> = {
  familyName: 'Family Name',
  firstName: 'First Name',
  address: 'Address',
  zip: 'Zip',
  city: 'City',
  email: 'Email',
}

const FOODBANK_PREFERENCE_DONORS_LIST_COLUMNS = 'preference_donors_list_columns';

const ALL_COLUMNS: DonorColumn[] = Object.values(DonorColumn) as DonorColumn[];
const DEFAULT_COLUMNS: DonorColumn[] = [DonorColumn.familyName, DonorColumn.firstName, DonorColumn.address];

@Component({
  selector: 'foodbank-donors',
  templateUrl: './donor-list.component.html',
  styleUrls: ['./donor-list.component.scss'],
  providers: [DialogService, ExcelService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, ConfirmDialogModule,]
})
export class DonorListComponent {

  // selected search filters for this view
  filterFamilyNameContains = model('');
  filterFirstNameContains = model('');
  filterZipEquals = model(0);
  filterCityContains = model('');
  // this view search
  donorSearch: Signal<DonorSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  donorPage: Resource<Page<Donor> | undefined>;
  donors: Resource<Donor[] | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #donorService = inject(DonorService);
  #donationService = inject(DonationService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #confirmationService = inject(ConfirmationService);
  #messageService = inject(MessageService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  #injector = inject(Injector);
// columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<DonorColumn[]>(FOODBANK_PREFERENCE_DONORS_LIST_COLUMNS, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;

    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
      familyNameContains: this.filterFamilyNameContains(),
      firstNameContains: this.filterFirstNameContains(),
      zipEquals: this.filterZipEquals(),
      cityContains: this.filterCityContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.donorPage = rxResource({
      request: () => ({
        donorSearch: this.donorSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#donorService.findDonors$(this.donorSearch(), this.#injector, this.pagination())
    });
    this.donors = rxResource({
      request: () => ({
        donorSearch: this.donorSearch(),
      }),
      loader: () => this.#donorService.findAllDonors$(this.donorSearch(), this.#injector)
    })
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(donor: Donor): Donor {
    return donor;
  }

  openEditDialog(donor: Donor) {
    this.#dialogRef = this.#dialogService.open(DonorEditComponent, {
      header: 'Edit Donor',
      width: '40%',
      data: {...donor}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donorPage.reload()
    })
  }
  openCreateDialog() {
    const $$company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);

    const donor: DonorCreationBase = {
      company: $$company,
    }
    this.#dialogRef = this.#dialogService.open(DonorEditComponent, {
      header: 'New Donor',
      width: '40%',
      data: {...donor}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donorPage.reload()
    });
  }
  delete(donor: Donor) {
    const donorDonations = this.#donationService.findNbOfDonationsOfDonor(<number>donor.id)
      .subscribe(
        nbDonations => {
          if (nbDonations > 0) {
            this.#messageService.add({severity: 'error', summary: 'Donor has donations', detail: `Donor ${donor.familyName} ${donor.firstName} has ${nbDonations} donations and cannot be deleted.`});
          } else {
            this.deleteDonor(donor);
          }
        }
      )
  }
  deleteDonor(donor: Donor) {
    const donorName = `${donor.familyName} ${donor.firstName}`;
    this.#confirmationService.confirm({
      message: `Are you sure that you want to delete donor ${donorName} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
        const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
        const auditChange: AuditChangeCreationBase = {
          company: donor.company,
          organization: currentUserOrg,
          user: currentUser,
          entity: "DONOR",
          entityKey: donor.familyName + ' ' + donor.firstName,
          action: "DELETE",
        }


        this.#donorService.deleteDonor(donor)
          .subscribe(() => {
            this.#messageService.add({severity: 'info', summary: 'Deleted', detail: `Donor ${donorName} has been deleted.`});
            this.donorPage.reload();
            this.#auditChangeService.createAuditChange(auditChange).subscribe();
          });
      }
    });
  }
  exportToExcel() {
    const donors = this.donors.value!;
    const cleanedList: any[] = [];
    donors()!.map((donor) => {
      const cleanedItem: any = {};
      cleanedItem[$localize`:@@Title:Title`] = donor.title;
      cleanedItem[$localize`:@@Name:Name`] = donor.familyName + ' ' + donor.firstName;
      cleanedItem[$localize`:@@Address:Address`] = donor.address;
      cleanedItem[$localize`:@@Zip:Zip`] = donor.zip;
      cleanedItem[$localize`:@@City:City`] = donor.city;
      cleanedItem[$localize`:@@Email:Email`] = donor.email;
      cleanedList.push(cleanedItem);

    });
    const excelFileName = 'foodit.donors.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }
  getAddress(donor: Donor) {
    const location = joinWhenPresent(" ", [donor.zip.toString(), donor.city]);
    return joinWhenPresent(" ,", [donor.address, location]);
  }

  getColumnLabel(column: DonorColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly DonorColumn = DonorColumn;
}


