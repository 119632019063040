import {Component, Input, model} from '@angular/core';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import {UserRole} from "@typedefs/stock-rest";

@Component({
    selector: 'foodbank-user-role',
    templateUrl: './user-role.component.html',
    styleUrls: ['./user-role.component.scss'],
    imports: [NgSwitch, TooltipModule, NgSwitchCase, NgSwitchDefault]
})
export class UserRoleComponent {

  ngModel = model<UserRole>();

}
