import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Organization, OrganizationCreationBase} from '@model/organization';
import {OrganizationService} from '@services/organization.service';
import {UserService} from "@services/user.service";
import {OrganizationSearch} from "@model/search/organization-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {formatDate, NgIf} from "@angular/common";
import {OrganizationEditComponent} from "@components/organization/organization-edit/organization-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {joinWhenPresent} from "@util/string-utils";
import {rxResource} from "@angular/core/rxjs-interop";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {CheckboxModule} from "primeng/checkbox";
import {UserSignalService} from "@services/user-signal.service";
import {ExcelService} from "@services/excel.service";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipsModule} from "primeng/chips";
import {MemberService} from "@services/member.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {AuditChangeService} from "@services/audit-change.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";

enum OrganizationColumn {
  refInt = 'refInt',
  code = 'code',
  address = 'address',
  phone = 'phone',
  email = 'email',
  zip = 'zip',
  city = 'city',
  warehouse = 'warehouse',
  active = 'active'
}

const COLUMN_LABELS: ColumnLabels<OrganizationColumn> = {
  refInt: 'Internal Ref',
  code: 'ESF+ Code',
  address: 'Address',
  phone: 'Phone',
  email: 'Email',
  zip: 'Zip',
  city: 'City',
  warehouse: 'Warehouse',
  active: 'Active'
}

const COLUMN_PREFERENCE_KEY = 'preference_organizations_list_columns';

const ALL_COLUMNS: OrganizationColumn[] = Object.values(OrganizationColumn) as OrganizationColumn[];
const DEFAULT_COLUMNS: OrganizationColumn[] = [OrganizationColumn.refInt,  OrganizationColumn.code, OrganizationColumn.phone];



@Component({
  selector: 'foodbank-organizations',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  providers: [DialogService,ExcelService],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, NgIf, ButtonModule, RippleModule, MultiSelectModule, WarehouseComponent, CheckboxModule, ChipsModule, ConfirmDialogModule]

})

export class OrganizationListComponent {


// selected search filters for this view
  filterNameContains = model('');
  filterInternalRefContains = model('');
  filterZipContains = model('');
  filterCityContains = model('');
  filterActive = model(true);
  filterIdEquals= model(0);
  filterCodeEquals = model('');

  // this view search
  organizationSearch: Signal<OrganizationSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  organizationPage: Resource<Page<Organization> | undefined>;
  organizations: Resource<Organization[] | undefined>;
  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #organizationService = inject(OrganizationService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #memberService = inject(MemberService);
  #dialogService = inject(DialogService);
  #messageService = inject(MessageService);
  #confirmationService = inject(ConfirmationService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  #injector = inject(Injector);
// columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<OrganizationColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {

    const currentUserCompany = this.#userService.getCurrentUserCompany();

    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
      active: this.filterActive(),
      id: this.filterIdEquals(),
      name: this.filterNameContains(),
      zip: this.filterZipContains(),
      city: this.filterCityContains(),
      refInt: this.filterInternalRefContains(),
      birbCode: this.filterCodeEquals()
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.organizationPage = rxResource({
      request: () => ({
        organizationSearch: this.organizationSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#organizationService.findOrganizations$(param.request.organizationSearch, param.request.pagination),
      injector: this.#injector,
    });
    this.organizations = rxResource({
      request: () => ({
        organizationSearch: this.organizationSearch(),
      }),
      loader: () => this.#organizationService.findAllOrganizations$(this.organizationSearch(), this.#injector)
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;


  identity(organization: Organization): Organization {
    return organization;
  }

  generateTelGsm(organization: Organization) {
    let telgsm = "";
    if (organization.phone && organization.phone != "") {
      telgsm += organization.phone.trim() + " ";
    }
    if (organization.mobile && organization.mobile != "") {
      telgsm += organization.mobile.trim() + " ";
    }
    return telgsm;

  }

  openEditDialog(organization: Organization) {
    this.#dialogRef = this.#dialogService.open(OrganizationEditComponent, {
      header: 'Edit Organization',
      width: '60%',
      data: {...organization}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.organizationPage.reload()
    })
  }
  openCreateDialog() {
    const $$company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);

    const organization: OrganizationCreationBase = {
      company: $$company,
      warehouse: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      pickupWarehouse: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      region:  resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      involvedCpas:  resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      preferredWeekForDelivery: () => false,
      countryId: 1,
      countryId2: 1,
      active: true,
      mainWarehouse:false,
      beneficiaryCount: 0,
      msonac: false,
      nbrefix: 0,
      beneficiariesManagement: false,
      annualContribution: false,
      additionalContribution: 0,
      numberOfMonthsProRataContribution: 0,
      presidentDistrList: false,
      vicePresidentDistrList: false,
      secretaryDistrList: false,
      treasurerDistrList: false,
      actComp7 : '',
      address : '',
      address2 : '',
      afsca : '',
      afsca1 : '',
      afsca2 : '',
      afsca3 : '',
      antenne : 0,
      antenneOrgname : '',
      banque : '',
      bic : '',
      code : '',
      presidentTitle : 'NONE',
      secretaryTitle : 'NONE',
      treasurerTitle : 'NONE',
      vicePresidentTitle : 'NONE',
      classique : '',
      coldis : 0,
      zip : '',
      zip2 : '',
      cpasInvolved : false,
      registrationDate : '',
      organizationReceivesESF : false,
      organizationIsAWarehouse : false,
      email : '',
      presidentEmail : '',
      secretaryEmail : '',
      treasurerEmail : '',
      vicePresidentEmail : '',
      deliverThroughBank : false,
      mobile : '',
      presidentMobile : '',
      secretaryMobile : '',
      treasurerMobile : '',
      vicePresidentMobile : '',
      iban : '',
      language : 'NONE',
      lastVisit : '',
      lienGd : 0,
      lienGs : 0,
      city : '',
      city2 : '',
      organizationLogsESF : false,
      montCot : 0,
      nbAdo : 0,
      nb1824 : 0,
      nbBebe : 0,
      nbEnf : 0,
      nbFam : 0,
      nbNour : 0,
      nbPers : 0,
      nbSenior : 0,
      presidentFamilyName : '',
      secretaryFamilyName : '',
      treasurerFamilyName : '',
      vicePresidentFamilyName : '',
      nrFead : 0,
      nrTournee : 0,
      presidentFirstName : '',
      secretaryFirstName : '',
      treasurerFirstName : '',
      vicePresidentFirstName : '',
      refInt : '',
      rem : '',
      remLivr : '',
      name : '',
      status : 'NONE',
      stopSusp : '',
      suspended : false,
      phone : '',
      presidentPhone : '',
      secretaryPhone : '',
      treasurerPhone : '',
      vicePresidentPhone : '',
      preferredSupplyDay : "EVERYDAY", // every day see enmSupplyDay
      preferredSupplyMonth : "EVERY", // every month see enmSupplyMonth
      preferredSupplyWeek : "WEEK_EVERY",  // every week see enmSupplyWeek
      vatNumber : '',
      webAuthority : true,
      website : ''
    }
    this.#dialogRef = this.#dialogService.open(OrganizationEditComponent, {
      header: 'New Organization',
      width: '60%',
      data: {...organization}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.organizationPage.reload()
    });
  }
  delete(organization: Organization) {
    console.log("deleting organization", organization);
    const organizationMembers = this.#memberService.findMembersOfOrganization(<number>organization.id, this.#injector)
      .subscribe(
        members => {
          if (members.length > 0) {
            this.#messageService.add({severity: 'error', summary: 'Organization has members', detail: `Organization ${organization.id} ${organization.name} has ${members.length} members and cannot be deleted.`});
          } else {
            this.deleteOrganization(organization);
          }
        }
      )
  }
  deleteOrganization(organization: Organization) {

    this.#confirmationService.confirm({
      message: `Are you sure that you want to delete organization ${organization.id} ${organization.name} ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        const company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);
        const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
        const auditChange: AuditChangeCreationBase = {
          company: company,
          organization: this.#organizationService.$$getOrganization(organization.id),
          user: currentUser,
          entity: "ORG",
          entityKey: organization.id + ' ' + organization.name,
          action: "DELETE",
        }
        const organizationName = organization.id + ' ' + organization.name;
        this.#organizationService.deleteOrganization(organization)
          .subscribe(() => {
            this.#messageService.add({severity: 'info', summary: 'Deleted', detail: `Organization ${organizationName} has been deleted.`});
            this.organizationPage.reload();
            this.#auditChangeService.createAuditChange(auditChange).subscribe();
          });
      }
    });
  }
  exportToExcel() {

    const organizations = this.organizations.value!;
    const cleanedList: any[] = [];
    organizations()!.map((organization) => {
      const cleanedItem: any = {};
      const region = organization.region.value();
      const regionName = region ? region.regName : '';
      cleanedItem[$localize`:@@Id:Id`] = organization.id;
      cleanedItem[$localize`:@@Name:Name`] = organization.name;
      cleanedItem[$localize`:@@InternalRef:Internal Ref`] = organization.refInt;
      cleanedItem[$localize`:@@ESF+Code:ESF+Code`] = organization.code;
      cleanedItem[$localize`:@@Warehouse:Warehouse`] = organization.warehouse.value()?.name;
      cleanedItem[$localize`:@@Address:Address`] = organization.address;
      cleanedItem[$localize`:@@Zip:Zip`] = organization.zip;
      cleanedItem[$localize`:@@City:City`] = organization.city;
      cleanedItem[$localize`:@@Mobile:Mobile`] = organization.mobile;
      cleanedItem[$localize`:@@Phone:Phone`] = organization.phone;
      cleanedItem[$localize`:@@Email:Email`] = organization.email;
      cleanedItem[$localize`:@@Active:Active`] = organization.active;
      cleanedItem[$localize`:@@Language:Language`] =organization.language;
      cleanedItem[$localize`:@@Region:Region`] = regionName;
      cleanedItem[$localize`:@@Agreed:Agreed`] = organization.suppliedNonMember ? 'No' : 'Yes';
      cleanedItem[$localize`:@@FeadBeneficiaries:Fead Beneficiaries`] =organization.nrFead;
      cleanedItem[$localize`:@@OrganisationPopulation:Registered population`] =organization.nbPers;
      cleanedItem[$localize`:@@OrganisationFamilies:Families`] =organization.nbFam;
      cleanedList.push(cleanedItem);

    });
    const excelFileName = 'foodit.organizations.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }
  getAddress(organization: Organization) {
    const location = joinWhenPresent(" ", [organization.zip, organization.city]);
    return joinWhenPresent(" ,", [organization.address, location]);
  }
  getColumnLabel(column: OrganizationColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly OrganizationColumn = OrganizationColumn;
}


