import {Component, computed, inject, Injector, input, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import { UserS, UserSCreationBase} from '@model/user';
import {UserSearch} from "@model/search/user-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {Ripple, RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {UserEditComponent} from "@components/users/user-edit/user-edit.component";
import {ButtonDirective, ButtonModule} from "primeng/button";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {Organization} from "@model/organization";
import {OrganizationSearch} from "@model/search/organization-search";
import {MultiSelectModule} from "primeng/multiselect";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {ColumnLabels} from "@components/columnLabels";
import {ChipModule} from "primeng/chip";
import {ChipsModule} from "primeng/chips";
import {rxResource} from "@angular/core/rxjs-interop";
import {CheckboxModule} from "primeng/checkbox";
import {UserSignalService} from "@services/user-signal.service";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {Member} from "@model/member";
import {MemberSearch} from "@model/search/member-search";
import {MemberSelectionComponent} from "@components/member/selection/single/member-selection.component";
import {MemberComponent} from "@components/member/member.component";
import {OrganizationComponent} from '../organization/organization.component';
import {LanguageComponent} from '../language/language.component';
import {UserRoleComponent} from '../user-type/user-role.component';
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {ExcelService} from "@services/excel.service";
import {formatDate} from "@angular/common";

enum UserColumn {
  id = 'id',
  name = 'name',
  member = 'member',
  organization = 'organization',
  active = 'active',
  language = 'language',
  role = 'role',
}

const COLUMN_LABELS: ColumnLabels<UserColumn> = {
  id: 'User Id',
  name: 'User Name',
  member: 'Member Name',
  organization: 'Organization',
  active: 'Active',
  language: 'Language',
  role: 'Role',
}

const COLUMN_PREFERENCE_KEY = 'preference_users_list_columns';

const ALL_COLUMNS: UserColumn[] = Object.values(UserColumn) as UserColumn[];
const DEFAULT_COLUMNS: UserColumn[] = [UserColumn.id, UserColumn.name, UserColumn.member, UserColumn.organization];

@Component({
  selector: 'foodbank-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  imports: [TableModule, PrimeTemplate, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, ChipModule, ChipsModule, OrganizationComponent, CheckboxModule, ConfirmDialogModule, MemberSelectionComponent, OrganizationSingleSelectionComponent, MemberComponent, TableModule, PrimeTemplate, InputTextModule, FormsModule, OrganizationComponent, LanguageComponent, UserRoleComponent, ButtonDirective, Ripple],
  providers: [DialogService, ExcelService],
})
export class UserListComponent {

  // selected search filters for this view
  filterIdContains = model('');
  filterNameContains = model('');
  filterMember = model<Member>();
  filterOrganization = model<Organization>();
  filterActive = model(true);

  // searches for filters (e.g. organization)
  organizationSearch: Signal<OrganizationSearch>;
  memberSearch: Signal<MemberSearch>;

  // this view search
  userSearch: Signal<UserSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  userPage: Resource<Page<UserS> | undefined>;
  users: Resource<UserS[] | undefined>;

  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #userService = inject(UserSignalService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #injector = inject(Injector);
  #messageService = inject(MessageService);
  #confirmationService = inject(ConfirmationService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  // columns view selection
  showColumnSelector = input(true);
  displayedColumns = injectLocalStorage<UserColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: DEFAULT_COLUMNS});

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;
    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
      active: true,
    }));
    this.memberSearch = computed(() => ({
      company: currentUserCompany(),
      active: true,
    }));

    this.userSearch = computed(() => ({
      company: currentUserCompany(),
      active: this.filterActive(),
      organization: this.filterOrganization(),
      member: this.filterMember(),
      idContains: this.filterIdContains(),
      nameContains: this.filterNameContains(),

    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.userPage = rxResource({
      request: () => ({
        userSearch: this.userSearch(),
        pagination: this.pagination(),
      }),
      loader: () => this.#userSignalService.findUsers$(this.userSearch(), this.#injector, this.pagination())
    });
    this.users = rxResource({
      request: () => ({
        userSearch: this.userSearch(),
      }),
      loader: () => this.#userSignalService.findAllUsers$(this.userSearch())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(user: UserS): UserS {
    return user;
  }

  openEditDialog(user: UserS) {
    this.#dialogRef = this.#dialogService.open(UserEditComponent, {
      header: `Edit User ${user.id}`,
      width: '40%',
      data: {...user}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.userPage.reload()
    })
  }

  openCreateDialog() {
    const $$company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);

    const user: UserSCreationBase = {
      company: $$company,
      organization: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      member: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      warehouse: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      cpas: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
      active: true
    }

    this.#dialogRef = this.#dialogService.open(UserEditComponent, {
      header: 'New User',
      width: '40%',
      data: {...user}
    });

    this.#dialogRef?.onClose.subscribe(() => {
      this.userPage.reload()
    });

  }

  getColumnLabel(column: UserColumn): string {
    return COLUMN_LABELS[column];
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly UserColumn = UserColumn;

  delete(user: UserS) {
    this.#confirmationService.confirm({
      message: `Are you sure that you want to delete user ${user.id}?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
        const auditChange: AuditChangeCreationBase = {
          company: user.company,
          organization: user.organization,
          user: currentUser,
          entity: "USER",
          entityKey: user.id + '(' + user.name + ')',
          action: "DELETE",
        }
        const userName =  user.id + '(' + user.name + ')'
        this.#userService.deleteUser(user)
          .subscribe(() => {
            this.#messageService.add({severity: 'info', summary: 'Deleted', detail: `User ${userName} has been deleted.`});
            this.userPage.reload();
            this.#auditChangeService.createAuditChange(auditChange).subscribe();
          });
      }
    });
  }

  exportToExcel() {
    const users = this.users.value!;
    const cleanedList: any[] = [];
    users()!.map((user) => {
      const cleanedItem: any = {};
      const organization = user.organization.value();
      const organizationName = organization ? organization.id + ' ' + organization.name : '';
      cleanedItem[$localize`:@@UserId:UserId`] = user.id;
      cleanedItem[$localize`:@@Name:Name`] = user.name;
      cleanedItem[$localize`:@@Organization:Organization`] = organizationName;
      cleanedItem[$localize`:@@Language:Language`] = user.language;
      cleanedItem[$localize`:@@Role:Role`] = user.userRole;
      cleanedItem[$localize`:@@Active:Active`] = user.active
      cleanedItem[$localize`:@@Email:Email`] = user.email;
      cleanedList.push(cleanedItem);

    });
    const excelFileName = 'foodit.users.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }
}


