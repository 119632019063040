import {Component, computed, inject, Injector, model, resource, Resource, Signal, WritableSignal} from '@angular/core'
import {Donation, DonationCreationBase} from '@model/donation';
import {DonationService} from '@services/donation.service';
import {UserSignalService} from "@services/user-signal.service";
import {DonationSearch} from "@model/search/donation-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {DonationEditComponent} from "@components/donation/donation-edit/donation-edit.component";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {rxResource} from "@angular/core/rxjs-interop";
import {Donor} from "@model/donor";
import {DonorSearch} from "@model/search/donor-search";
import {DonorSelectionComponent} from "@components/donor/selection/donor-selection.component";
import {DonorComponent} from "@components/donor/donor.component";
import {formatDate, NgIf} from "@angular/common";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {AuditChangeService} from "@services/audit-change.service";
import {AuditChangeCreationBase} from "@model/audit-change";
import {ExcelService} from "@services/excel.service";

@Component({
  selector: 'foodbank-donations',
  templateUrl: './donation-list.component.html',
  styleUrls: ['./donation-list.component.scss'],
  providers: [DialogService,ExcelService],
    imports: [TableModule, InputTextModule, FormsModule, ButtonModule, RippleModule, MultiSelectModule, DonorSelectionComponent, DonorComponent, NgIf, ConfirmDialogModule,]
})
export class DonationListComponent {

  // selected search filters for this view
  filterDonor = model<Donor>();

  // searches for filters (e.g. donor)
  donorSearch: Signal<DonorSearch>;
  // this view search
  donationSearch: Signal<DonationSearch>;
  pagination: WritableSignal<Pagination>;

  // results
  donationPage: Resource<Page<Donation> | undefined>;
  donations: Resource<Donation[] | undefined>;
  // internal state
  #dialogRef?: DynamicDialogRef;

  // services
  #donationService = inject(DonationService);
  #paginationService = inject(PaginationService);
  #userSignalService = inject(UserSignalService);
  #dialogService = inject(DialogService);
  #confirmationService = inject(ConfirmationService);
  #messageService = inject(MessageService);
  #auditChangeService = inject(AuditChangeService);
  #excelService = inject(ExcelService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userSignalService.currentUserCompany;
    this.donorSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.donationSearch = computed(() => ({
      company: currentUserCompany(),
      donor: this.filterDonor(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.donationPage = rxResource({
      request: () => ({
        donationSearch: this.donationSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#donationService.findDonations$(this.donationSearch(), this.#injector, this.pagination())
    });
    this.donations = rxResource({
      request: () => ({
        donationSearch: this.donationSearch(),
      }),
      loader: () => this.#donationService.findAllDonations$(this.donationSearch(), this.#injector)
    })
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(donation: Donation): Donation {
    return donation;
  }



  openEditDialog(donation: Donation) {
    this.#dialogRef = this.#dialogService.open(DonationEditComponent, {
      header: 'Edit Donation',
      width: '40%',
      data: {...donation}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donationPage.reload()
    })
  }

  openCreateDialog() {
    const $$company = this.#userSignalService.$$getCurrentUserCompany(this.#injector);

    const donation: DonationCreationBase = {
      company: $$company,
      donor: resource({loader: () => Promise.resolve(undefined), injector: this.#injector}),
    }
    this.#dialogRef = this.#dialogService.open(DonationEditComponent, {
      header: 'New Donation',
      width: '40%',
      data: {...donation}
    });
    this.#dialogRef?.onClose.subscribe(() => {
      this.donationPage.reload()
    });
  }

  delete(donation: Donation) {
    const donorName = `${donation.donor.value()?.familyName} ${donation.donor.value()?.firstName}`;
    this.#confirmationService.confirm({
      message: `Are you sure that you want to delete this donation from ${donorName}  ?`,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
        const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
        const auditChange: AuditChangeCreationBase = {
          company: donation.company,
          organization: currentUserOrg,
          user: currentUser,
          entity: "GIFT",
          entityKey: donorName,
          action: "DELETE",
        }
        this.#donationService.deleteDonation(donation)
          .subscribe(() => {
            this.#messageService.add({severity: 'info', summary: 'Deleted', detail: `Donation from ${donorName} has been deleted.`});
            this.donationPage.reload();
            this.#auditChangeService.createAuditChange(auditChange).subscribe();
          });
      }
    });
  }
  exportToExcel() {
    const donations = this.donations.value();
    if (!donations) {
       throw new Error('Donations are missing!')
    }
    const cleanedList: any[] = [];
    donations.map((donation) => {
      const donor = donation.donor.value();
      const donorName = donor ? donor.familyName + ' ' + donor.firstName : '';
      const cleanedItem: any = {};
      cleanedItem[$localize`:@@Name:Name`] = donorName;
      cleanedItem[$localize`:@@Date:Date`] = donation.dateDonation;
      cleanedItem[$localize`:@@Amount:Amount`] = donation.amount;
      cleanedList.push(cleanedItem);

    });
    const excelFileName = 'foodit.donations.' + formatDate(new Date(), 'ddMMyyyy.HHmm', 'en-US') + '.xlsx';
    this.#excelService.exportAsExcelFile(cleanedList, excelFileName);
  }


}




