import {Component, computed, inject, Injector, input, model, Resource, Signal, WritableSignal} from "@angular/core";
import {DatePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {InputSwitchModule} from "primeng/inputswitch";
import {MessageModule} from "primeng/message";
import {DropdownModule} from "primeng/dropdown";
import {TabViewModule} from "primeng/tabview";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {MultiSelectModule} from "primeng/multiselect";
import {CheckboxModule} from "primeng/checkbox";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from "@services/pagination.service";
import {DependentSearch} from "@model/search/dependent-search";
import {Pagination} from "@services/pagination";
import {injectLocalStorage} from "ngxtension/inject-local-storage";
import {DependentService} from "@services/dependent.service";
import {Page} from "@typedefs/page";
import {Dependent} from "@model/dependent";
import {rxResource} from "@angular/core/rxjs-interop";
import {BeneficiaryBase} from "@model/beneficiary";
import {ColumnLabels} from "@components/columnLabels";

enum DependentColumn {
  title = 'title',
  familyName = 'familyName',
  firstName = 'firstName',
  birthdate = 'birthdate',
  active = 'active',
}

const COLUMN_LABELS: ColumnLabels<DependentColumn> = {
  title: 'Title',
  familyName: 'Family Name',
  firstName: 'First Name',
  birthdate: 'Birthdate',
  active: 'Active'
}
const COLUMN_PREFERENCE_KEY = 'preference_dependents_list_columns';
const ALL_COLUMNS: DependentColumn[] = Object.values(DependentColumn) as DependentColumn[];

@Component({
  selector: 'foodbank-dependent-list',
  templateUrl: './dependent-list.component.html',
  styleUrls: ['./dependent-list.component.scss'],
  imports: [FormsModule, InputTextModule, InputSwitchModule, MessageModule, DropdownModule, TabViewModule, DatePipe, TableModule, MultiSelectModule, CheckboxModule]
})
export class DependentListComponent {
  beneficiary = input.required<BeneficiaryBase>();

  #dependentService = inject(DependentService);
  dependentPage: Resource<Page<Dependent> | undefined>;
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  dependentSearch: Signal<DependentSearch>;
  pagination: WritableSignal<Pagination>;
  filterActive = model(true);
  displayedColumns = injectLocalStorage<DependentColumn[]>(COLUMN_PREFERENCE_KEY, {storageSync: true, defaultValue: ALL_COLUMNS});

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);
    this.dependentSearch = computed(() => ({
      beneficiary: this.beneficiary(),
      active: this.filterActive()
    }))

    this.dependentPage = rxResource({
      request: () => ({
        dependentSearch: this.dependentSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#dependentService.findDependents$(this.dependentSearch(), this.#injector, this.pagination())
    })
  }

  identity(dependent: Dependent): Dependent {
    return dependent;
  }

  getColumnLabel(column: DependentColumn): string {
    return COLUMN_LABELS[column];
  }
  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  protected readonly ALL_COLUMNS = ALL_COLUMNS;
  protected readonly DependentColumn = DependentColumn;
  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

}
