<p-table #dt
         [value]="(userPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="userPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Users</h2>
        <p-multiSelect
          ngDefaultControl
          [options]="ALL_COLUMNS"
          [(ngModel)]="displayedColumns"
          placeholder="Select columns"
        >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
          <ng-template let-country pTemplate="footer">
            <div class="py-2 px-3">
                <strong>{{ displayedColumns().length }} item(s) selected.</strong>
            </div>
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
    <div>
      <button pButton pRipple icon="pi pi-plus" class="p-button-rounded p-button-primary mr-2" (click)="openCreateDialog()"></button>
      <button pButton pRipple icon="pi pi-download" class="p-button-rounded p-button-primary mr-2" (click)="exportToExcel()"></button>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('id') {
            <th pSortableColumn="ID">
              <span i18n>{{ getColumnLabel(UserColumn.id) }}</span>
              <p-sortIcon field="ID"/>
            </th>
          }
          @case ('name') {
            <th pSortableColumn="NAME">
              <span i18n>{{ getColumnLabel(UserColumn.name) }}</span>
              <p-sortIcon field="NAME"/>
            </th>
          }
          @case ('member') {
            <th>
              <span i18n>{{ getColumnLabel(UserColumn.member) }}</span>
            </th>
          }
          @case ('organization') {
            <th>
              <span i18n>{{ getColumnLabel(UserColumn.organization) }}</span>
            </th>
          }
          @case ('active') {
            <th>
              <span i18n>{{ getColumnLabel(UserColumn.active) }}</span>
            </th>
          }
          @case ('language') {
            <th>
              <span i18n>{{ getColumnLabel(UserColumn.role) }}</span>
            </th>
          }
          @case ('role') {
            <th>
              <span i18n>{{ getColumnLabel(UserColumn.role) }}</span>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('id') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterIdContains" ngDefaultControl/>
            </th>
          }
          @case ('name') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterNameContains" ngDefaultControl/>
            </th>
          }
          @case ('member') {
            <th>
              <p-columnFilter field="members" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-organization>
                  <foodbank-member-selection ngDefaultControl
                                             [(ngModel)]="filterMember"
                                             [memberSearch]="memberSearch()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('organization') {
            <th>
              <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-organization>
                  <foodbank-organization-selection ngDefaultControl
                                                   [(ngModel)]="filterOrganization"
                                                   [organizationSearch]="organizationSearch()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }

          @case ('active') {
            <th>
              <p-checkbox [binary]="true" [(ngModel)]="filterActive" checked ngDefaultControl/>
            </th>
          }
          @case ('language') {
            <th>
            </th>
          }
          @case ('role') {
            <th>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let user = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('id') {
            <td>
              {{ user.id }}
            </td>
          }
          @case ('name') {
            <td>
              {{ user.name }}
            </td>
          }
          @case ('member') {
            <td>
              <foodbank-member ngDefaultControl [ngModel]="user.member.value()" [showId]="false"/>
            </td>
          }
          @case ('organization') {
            <td>
              <foodbank-organization ngDefaultControl [ngModel]="user.organization.value()" [showId]="false"/>
            </td>
          }
          @case ('active') {
            <td>
              {{ user.active ? 'Yes' : 'No' }}
            </td>
          }
          @case ('language') {
            <td>
              <foodbank-language ngDefaultControl [language]="user.language" />
            </td>
          }
          @case ('role') {
            <td>
              <foodbank-user-role ngDefaultControl [ngModel]="user.userRole" />
            </td>
          }
        }
      }
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(user)"></button>
      </td>
      <td>
        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger" (click)="delete(user)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<p-confirmDialog [style]="{width: '50vw'}" />
