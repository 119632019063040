import {Component, computed, inject, Injector, input, model, Signal, signal, WritableSignal} from '@angular/core';
import {UserSearch} from '@model/search/user-search';
import {User, UserS} from '@model/user';
import {UserService} from '@services/user.service';
import {PaginationService} from "@services/pagination.service";
import {Pagination} from "@services/pagination";
import {loadSignalFromObservable} from "@util/foodbanks-signal-rxjs-interop";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {AutoFocus} from "primeng/autofocus";
import {UserComponent} from "@components/users/user.component";
import {UserSignalService} from "@services/user-signal.service";

@Component({
  selector: 'foodbank-user-selection',
  exportAs: 'userSelection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    InputTextModule,
    AutoFocus,
    UserComponent
  ]
})
export class UserSelectionComponent {

  ngModel = model<UserS>();
  required = input(false);
  userSearch = input<UserSearch>({});

  nameFilter = signal('');

  users: Signal<UserS[] | undefined>;
  pagination: WritableSignal<Pagination>;
  loading = signal(true);

  #userService = inject(UserSignalService);
  #paginationService = inject(PaginationService);
  #injector = inject(Injector);


  constructor() {
    this.pagination = this.#paginationService.getDefaultComponentPaginationSignal();

    const actualUserSearch: Signal<UserSearch> = computed(() => ({
      ...this.userSearch(),
      nameContains: this.nameFilter(),
    }));

    const userPage = loadSignalFromObservable(() => this.#userService.findUsers$(actualUserSearch(), this.#injector, this.pagination()), this.loading);

    this.users = computed(() => userPage()?.content);
  }

  clear() {
    this.ngModel.set(undefined);
  }

}

