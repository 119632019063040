<p-table #dt
         [value]="(beneficiaryPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="beneficiaryPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         dataKey="id">
  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
       <span class="flex">
        <h2 class="m-0">Beneficiaries</h2>
        <p-multiSelect
          ngDefaultControl
          [options]="ALL_COLUMNS"
          [(ngModel)]="displayedColumns"
          placeholder="Select columns"
        >
          <ng-template let-selectedColumns pTemplate="selectedItems">
            <p-chips [ngModel]="selectedColumns" [disabled]="true">
              <ng-template let-selectedColumn pTemplate="item">
                {{ getColumnLabel(selectedColumn) }}
              </ng-template>
            </p-chips>
          </ng-template>
          <ng-template let-column pTemplate="item">
                {{ getColumnLabel(column) }}
          </ng-template>
        </p-multiSelect>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th pSortableColumn="familyName">
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.familyName) }}</span>
              <p-sortIcon field="familyName"/>
            </th>
          }
          @case ('firstName') {
            <th pSortableColumn="firstName">
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.firstName) }}</span>
              <p-sortIcon field="firstName"/>
            </th>
          }
          @case ('organization') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.organization) }}</span>
            </th>
          }
          @case ('address') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.address) }}</span>
            </th>
          }
          @case ('zip') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.zip) }}</span>
              <p-sortIcon field="zip"/>
            </th>
          }
          @case ('city') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.city) }}</span>
              <p-sortIcon field="city"/>
            </th>
          }
          @case ('birthdate') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.birthdate) }}</span>
            </th>
          }
          @case ('coeff') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.coeff) }}</span>
            </th>
          }
          @case ('active') {
            <th>
              <span i18n>{{ getColumnLabel(BeneficiaryColumn.active) }}</span>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFamilyNameContains" ngDefaultControl/>
            </th>
          }
          @case ('firstName') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterFirstNameContains" ngDefaultControl/>
            </th>
          }
          @case ('organization') {
            <th>
              <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-organization>
                  <foodbank-organization-selection ngDefaultControl
                                                   [(ngModel)]="filterOrganization"
                                                   [organizationSearch]="organizationSearch()"
                  />
                </ng-template>
              </p-columnFilter>
            </th>
          }
          @case ('address') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterAddressContains" ngDefaultControl/>
            </th>
          }
          @case ('zip') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterZipContains" ngDefaultControl/>
            </th>
          }
          @case ('city') {
            <th>
              <input pInputText type="text" [placeholder]="'Search...'" [(ngModel)]="filterCityContains" ngDefaultControl/>
            </th>
          }
          @case ('birthdate') {
            <th>
              <foodbank-date-search
                ngDefaultControl
                [(ngModel)]="filterBirthDateSearch"
                [required]="false"
                [showButtonBar]="true"
              />
            </th>
          }
          @case ('coeff') {
            <th>
              <p-checkbox [binary]="true" [(ngModel)]="filterCoeffExists" checked ngDefaultControl/>
            </th>
          }
          @case ('active') {
            <th>
              <p-checkbox [binary]="true" [(ngModel)]="filterActive" checked ngDefaultControl/>
            </th>
          }
        }
      }
      <!-- actions -->
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-row>
    @let beneficiary = identity(row);
    <tr>
      @for (column of displayedColumns(); track column) {
        @switch (column) {
          @case ('familyName') {
            <td>
              {{ beneficiary.lastName }}
            </td>
          }
          @case ('firstName') {
            <td>
              {{ beneficiary.firstName }}
            </td>
          }
          @case ('organization') {
            <td>
              <foodbank-organization ngDefaultControl [ngModel]="beneficiary.organization.value()" [showId]="false"/>
            </td>
          }
          @case ('address') {
            <td>
              <a href="http://maps.google.com/maps?q={{ beneficiary.address }}" target="_blank">
                {{ beneficiary.address }}
              </a>
            </td>
          }
          @case ('zip') {
            <td>
              {{ beneficiary.zip }}
            </td>
          }
          @case ('city') {
            <td>
              {{ beneficiary.city }}
            </td>
          }
          @case ('birthdate') {
            <td>
              {{ beneficiary.birthDate | date: 'dd/MM/yyyy'}}
            </td>
          }
          @case ('coeff') {
            <td>
              {{ beneficiary.coeff }}
            </td>
          }
          @case ('active') {
            <td>
              {{ beneficiary.active }}
            </td>
          }
        }
      }
      <td>
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditDialog(beneficiary)"></button>
      </td>
    </tr>
  </ng-template>
</p-table>
