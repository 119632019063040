@let userRole = ngModel();
@if (userRole) {
  <span [ngSwitch]="userRole" [class]="userRole" [pTooltip]="userRole">
  <!-- these icons don't really make sense, find better ones from https://fonts.google.com/icons -->
  <ng-container *ngSwitchCase="'BANK_USER'">
    <span class="material-icons">account_balance</span>
  </ng-container>
  <ng-container *ngSwitchCase="'BANK_ADMIN'">
    <span class="material-icons">admin_panel_settings</span>
  </ng-container>
  <ng-container *ngSwitchCase="'ORGANIZATION_USER'">
    <span class="material-icons">diversity_1</span>
  </ng-container>
  <ng-container *ngSwitchCase="'ORGANIZATION_ADMIN'">
    <span class="material-icons">diversity_2</span>
  </ng-container>
  <ng-container *ngSwitchCase="'FEDERATION_BANK'">
    <span class="material-icons">diversity_3</span>
  </ng-container>
  <ng-container *ngSwitchCase="'FEDERATION_ADMIN'">
    <span class="material-icons">bolt</span>
  </ng-container>
  <ng-container *ngSwitchCase="'CPAS_ADMIN'">
    <span class="material-icons">volunteer_activism</span>
  </ng-container>
  <ng-container *ngSwitchCase="'FEAD_ADMIN'">
    <span class="material-icons">waving_hand</span>
  </ng-container>
  <ng-container *ngSwitchCase="'EXT_ADMIN'">
    <span class="material-icons">clean_hands</span>
  </ng-container>
  <ng-container *ngSwitchCase="'ADMIN'">
    <span class="material-icons">plumbing</span>
  </ng-container>
  <ng-container *ngSwitchDefault>{{ userRole }}</ng-container>
</span>
  <i class="fa-light fa-chevron-down"></i>
}
