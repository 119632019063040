import { Component, inject, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, shareReplay, switchMap} from "rxjs";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import {Page} from '@typedefs/page';

import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import {Article} from '@model/article';
import {ArticleCategory} from '@model/article-category';
import {DEFAULT_ROWS_PER_PAGE, isSamePagination, PaginationService} from '@services/pagination.service';
import {Pagination} from '@services/pagination';
import {PreparationPagination} from '@services/preparation.service';
import {ArticleStorageCondition} from '@model/article-storage-condition';
import {UserService} from '@services/user.service';
import {ArticleCategorySearch} from '@model/search/article-category-search';
import {ArticleStorageConditionSearch} from '@model/search/article-storage-condition-search';
import {StockPrevisionService} from '@services/stock-prevision.service';
import {StockPrevision} from '@model/stock-prevision';
import {ArticleSearch} from '@model/search/article-search';
import {StockPrevisionSearch} from '@model/search/stock-prevision-search';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import {ArticleCategorySearchDto} from "@typedefs/stock-rest";
import {Company} from "@model/company";
import {Location} from "@model/location";
import {LocationSearch} from "@model/search/location-search";
import { PrimeTemplate } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Button, ButtonDirective } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TableSizeComponent } from '../table-size/table-size.component';
import { Ripple } from 'primeng/ripple';
import {NgIf, AsyncPipe, DecimalPipe, DatePipe} from '@angular/common';
import { ArticleCategorySelectionComponent } from '../article-category/selection/multi/article-category-selection.component';
import { ArticleMultiSelectionComponent } from '../article/selection/multi/article-multi-selection.component';
import { WarehouseMultipleSelectionComponent } from '../warehouse/selection/multiple/warehouse-multiple-selection.component';
import { ArticleCategoryComponent } from '../article-category/article-category.component';
import { ArticleComponent } from '../article/article.component';
import { WarehouseComponent } from '../warehouse/warehouse.component';
import { LocationSingleSelectionComponent } from '../location/single/location-single-selection.component';
import { PalletComponent } from "@components/pallet/pallet.component";

@Component({
    selector: 'foodbank-stock-prevision-list',
    templateUrl: './stock-prevision-list.component.html',
    styleUrl: './stock-prevision-list.component.scss',
  imports: [TableModule, PrimeTemplate, InputSwitchModule, FormsModule, InputTextModule, Button, TooltipModule, TableSizeComponent, ButtonDirective, Ripple, NgIf, ArticleCategorySelectionComponent, ArticleMultiSelectionComponent, WarehouseMultipleSelectionComponent, ArticleCategoryComponent, ArticleComponent, WarehouseComponent, LocationSingleSelectionComponent, AsyncPipe, DecimalPipe, PalletComponent, DatePipe]
})
export class StockPrevisionListComponent implements OnInit, OnChanges {

  readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  @Input()
  sourceWarehouse?: Warehouse;
  @Input()
  targetWarehouse?: Warehouse;

  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';
  pagination$!: BehaviorSubject<Pagination>;

  // searches for stock page
  // user selection
  selectedSourceWarehouses$!: BehaviorSubject<Warehouse[] | undefined>;
  // selection when defaults are applied, empty array if nothing selected
  effectiveSourceWarehouses$!: Observable<Warehouse[]>;
  // user selection
  selectedTargetWarehouses$!: BehaviorSubject<Warehouse[] | undefined>;
  // selection when defaults are applied, empty array if nothing selected
  effectiveTargetWarehouses$!: Observable<Warehouse[]>;
  selectedArticles$!: BehaviorSubject<Article[]>;
  selectedArticleCategories$!: BehaviorSubject<ArticleCategory[]>;
  selectedArticleStorageConditions$!: BehaviorSubject<ArticleStorageCondition[]>;

  receptionIdStartsWith$!: BehaviorSubject<string | undefined>;

  // search including all user filters
  stockPrevisionSearch$!: Observable<StockPrevisionSearch>;
  // searches for filters
  filterArticleStorageConditionSearch$!: BehaviorSubject<ArticleStorageConditionSearch>;
  filterArticleCategorySearch$!: BehaviorSubject<ArticleCategorySearch>;
  filterTargetWarehouseSearch$!: Observable<WarehouseSearch>;
  filterSourceWarehouseSearch$!: Observable<WarehouseSearch>;

  filterArticleSearch$!: Observable<ArticleSearch>;
  // we keep manually edited (prevalidated, missing quantity, ...) stock previsions  here
  editedStockPrevisions: StockPrevision[] = [];

  editedStockPrevisionsSubject$!: BehaviorSubject<StockPrevision[]>;
  stockPrevisionPage$!: Observable<Page<StockPrevision>>;
  acceptedStockPrevisionCount$!: Observable<number>;
  editedStockPrevisionPage$!: Observable<Page<StockPrevision>>;
  showValidated$!: BehaviorSubject<boolean>;

  selectedStockPrevisions: StockPrevision[] = [];

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  private stockPrevisionService = inject(StockPrevisionService);
  private paginationService = inject(PaginationService);
  private userService = inject(UserService);
  #injector = inject(Injector);

  ngOnInit() {
    const sourceWarehouses = this.wrapInArray(this.sourceWarehouse);
    this.selectedSourceWarehouses$ = new BehaviorSubject<Warehouse[] | undefined>(sourceWarehouses);
    this.effectiveSourceWarehouses$ = this.selectedSourceWarehouses$.pipe(
      map(selectedWarehouses => selectedWarehouses || []),
      shareReplay(),
    );

    const targetWarehouses = this.wrapInArray(this.targetWarehouse);
    this.selectedTargetWarehouses$ = new BehaviorSubject<Warehouse[] | undefined>(targetWarehouses);
    // compute effective selection based on default warehouse if nothing is selected
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    this.effectiveTargetWarehouses$ = combineLatest([this.selectedTargetWarehouses$, defaultWarehouse$]).pipe(
      map(([selectedWarehouses, defaultWarehouse]) => {
        const defaultWarehouses = this.wrapInArray(defaultWarehouse);
        return (selectedWarehouses ?? defaultWarehouses) || [];
      }),
      shareReplay(),
    );

    this.selectedArticles$ = new BehaviorSubject<Article[]>([]);
    this.selectedArticleCategories$ = new BehaviorSubject<ArticleCategory[]>([]);
    this.selectedArticleStorageConditions$ = new BehaviorSubject<ArticleStorageCondition[]>([]);
    this.showValidated$ = new BehaviorSubject<boolean>(false);

    const pagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<PreparationPagination>(pagination);

    // simple filters that don't depend on others
    this.receptionIdStartsWith$ = new BehaviorSubject<string | undefined>(undefined);
    this.filterArticleCategorySearch$ = new BehaviorSubject<ArticleCategorySearchDto>({});
    this.filterArticleStorageConditionSearch$ = new BehaviorSubject<ArticleStorageConditionSearch>({});

    // target warehouse filter search depends on all other filters
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    this.filterTargetWarehouseSearch$ = combineLatest([this.showValidated$, this.receptionIdStartsWith$, this.effectiveSourceWarehouses$, this.effectiveTargetWarehouses$, this.selectedArticles$, this.selectedArticleCategories$, this.selectedArticleStorageConditions$, currentUserCompany$]).pipe(
      map(([showValidated, receptionIdStartsWith, sourceWarehouses, targetWarehouses, articles, articleCategories, articleStorageConditions, company]) =>
        this.getStockPrevisionSearch(showValidated, receptionIdStartsWith, undefined, sourceWarehouses, articles, articleCategories, articleStorageConditions, company)
      ),
      map(stockPrevisionSearch => ({
          destinationStockPrevisionSearch: stockPrevisionSearch,
        })
      ),
    );

    // source warehouse filter search depends on all other filters
    this.filterSourceWarehouseSearch$ = combineLatest([this.showValidated$, this.receptionIdStartsWith$, this.effectiveSourceWarehouses$, this.effectiveTargetWarehouses$, this.selectedArticles$, this.selectedArticleCategories$, this.selectedArticleStorageConditions$, currentUserCompany$]).pipe(
      map(([showValidated, receptionIdStartsWith, sourceWarehouses, targetWarehouses, articles, articleCategories, articleStorageConditions, company]) =>
        this.getStockPrevisionSearch(showValidated, receptionIdStartsWith, targetWarehouses, undefined, articles, articleCategories, articleStorageConditions, company)
      ),
      map(stockPrevisionSearch => ({
          sourceStockPrevisionSearch: stockPrevisionSearch
        })
      ),
    );

    // article filter search depends on all other filters
    this.filterArticleSearch$ = combineLatest([this.showValidated$, this.receptionIdStartsWith$, this.effectiveSourceWarehouses$, this.effectiveTargetWarehouses$, this.selectedArticles$, this.selectedArticleCategories$, this.selectedArticleStorageConditions$, currentUserCompany$]).pipe(
      map(([showValidated, receptionIdStartsWith, sourceWarehouses, targetWarehouses, articles, articleCategories, articleStorageConditions, company]) =>
        this.getStockPrevisionSearch(showValidated, receptionIdStartsWith, targetWarehouses, sourceWarehouses, undefined, articleCategories, articleStorageConditions, company)
      ),
      map(stockPrevisionSearch => ({
        stockPrevisionSearch: {
          ...stockPrevisionSearch
        },
      }))
    );

    // ... and this is the combination of all filters
    this.stockPrevisionSearch$ = combineLatest([this.showValidated$, this.receptionIdStartsWith$, this.effectiveSourceWarehouses$, this.effectiveTargetWarehouses$, this.selectedArticles$, this.selectedArticleCategories$, this.selectedArticleStorageConditions$, currentUserCompany$]).pipe(
      map(([showValidated, receptionIdStartsWith, sourceWarehouses, targetWarehouses, articles, articleCategories, articleStorageConditions, company]) => {
          return this.getStockPrevisionSearch(showValidated, receptionIdStartsWith, targetWarehouses, sourceWarehouses, articles, articleCategories, articleStorageConditions, company);
        }
      )
    );

    // load stock previsions from search
    const pagination$ = this.pagination$.pipe(
      distinctUntilChanged(isSamePagination)
    );

    this.editedStockPrevisionsSubject$ = new BehaviorSubject<StockPrevision[]>(this.editedStockPrevisions);

    this.stockPrevisionPage$ = combineLatest([pagination$, this.stockPrevisionSearch$, this.refreshTrigger$]).pipe(
      switchMap(([pagination, stockPrevisionSearch]) => this.stockPrevisionService.findStockPrevisions(stockPrevisionSearch, pagination, this.#injector)),
      shareReplay(),
    );
    // count accepted stock previsions
    const acceptedStockPrevisionSearch$ = this.stockPrevisionSearch$.pipe(
      map(stockPrevisionSearch => ({
        ...stockPrevisionSearch,
        readyForValidation: true,
        validated: false,
      })),
      shareReplay(),
    );

    this.acceptedStockPrevisionCount$ = combineLatest([acceptedStockPrevisionSearch$, this.editedStockPrevisionsSubject$]).pipe(
      switchMap(([stockPrevisionSearch, editedStockPrevisions]) => this.stockPrevisionService.countStockPrevisions(stockPrevisionSearch)),
      shareReplay(),
    );

    this.editedStockPrevisionPage$ = combineLatest([this.stockPrevisionPage$, this.editedStockPrevisionsSubject$]).pipe(
      map(([stockPrevisionPage, editedStockPrevisions]) => this.getEditedStockPrevisionPage(stockPrevisionPage, editedStockPrevisions)),
      shareReplay(),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedSourceWarehouses$) {
      const inputSourceWarehouses = this.wrapInArray(this.sourceWarehouse);
      this.selectedSourceWarehouses$.next(inputSourceWarehouses)
    }
    if (this.selectedTargetWarehouses$) {
      const inputTargetWarehouses = this.wrapInArray(this.targetWarehouse);
      this.selectedTargetWarehouses$.next(inputTargetWarehouses);
    }
    if (this.editedStockPrevisionsSubject$) {
      this.editedStockPrevisions = [];
      this.fireEditedStockPrevisionsChange();
    }
  }

  private wrapInArray<T>(item?: T): T[] | undefined {
    return item ? [item] : undefined;
  }

  private getStockPrevisionSearch(showValidated?: boolean,
                                  receptionIdStartsWith?: string,
                                  warehouses?: Warehouse[],
                                  sourceWarehouses?: Warehouse[],
                                  articles?: Article[],
                                  articleCategories?: ArticleCategory[],
                                  articleStorageConditions?: ArticleStorageCondition[],
                                  company?: Company,
  ): StockPrevisionSearch {
    return {
      validated: showValidated,
      receptionIdStartsWith: receptionIdStartsWith,
      warehouseSearch: {
        warehouses: warehouses,
        company: company,
      },
      sourceWarehouseSearch: {
        warehouses: sourceWarehouses,
      },
      articleSearch: {
        articles: articles,
        articleCategorySearch: {
          articleCategories: articleCategories,
        },
        articleStorageConditionSearch: {
          articleStorageConditions: articleStorageConditions,
        },
      }
    };
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$.next(pagination);
  }

  validate() {
    this.stockPrevisionSearch$.pipe(
      switchMap(stockPrevisionSearch => this.stockPrevisionService.validateStockPrevisions(this.selectedStockPrevisions)),
    ).subscribe(() => this.refresh(true));

  }

  makSelectionVerified(verified: boolean) {
    for (const selectedStockPrevision of this.selectedStockPrevisions) {
      this.markVerified(selectedStockPrevision, verified);
    }
  }

  markVerified(stockPrevision: StockPrevision, verified: boolean) {
    const updatedStockPrevision: StockPrevision = {
      ...stockPrevision,
      // validated = 0 missing parcel ; undefined = we don't know how many, validation pending
      missingParcels: verified ? 0 : undefined,
    };

    this.stockPrevisionService.save(updatedStockPrevision, this.#injector)
      .subscribe(updatedStockPrevision => {
        this.updateStockPrevision(updatedStockPrevision);
      });
  }

  handleMissingParcelsEdited(stockPrevision: StockPrevision) {
    const updatedStockPrevision: StockPrevision = {
      ...stockPrevision,
      missingParcels: stockPrevision.missingParcels,
    };

    this.stockPrevisionService.save(updatedStockPrevision, this.#injector)
      .subscribe(updatedStockPrevision => this.updateStockPrevision(updatedStockPrevision));
  }

  handleLocationEdited(location: Location, stockPrevision: StockPrevision) {
    const updatedStockPrevision: StockPrevision = {
      ...stockPrevision,
      location: location?.name,
    };

    this.stockPrevisionService.save(updatedStockPrevision, this.#injector)
      .subscribe(updatedStockPrevision => this.updateStockPrevision(updatedStockPrevision));
  }

  handleSelectionChange() {
    this.selectedStockPrevisions = this.selectedStockPrevisions.filter(stockPrevision => !stockPrevision.validated);
  }

  filterByWarehouses(warehouses: Warehouse[]) {
    this.selectedTargetWarehouses$.next(warehouses);
  }

  filterBySourceWarehouses(sourceWarehouses: Warehouse[]) {
    this.selectedSourceWarehouses$.next(sourceWarehouses);
  }

  filterByArticles(articles: Article[]) {
    this.selectedArticles$.next(articles);
  }

  filterByArticleCategories(articleCategories: ArticleCategory[]) {
    this.selectedArticleCategories$.next(articleCategories);
  }

  filterByArticleStorageCondition(articleStorageConditions: ArticleStorageCondition[]) {
    this.selectedArticleStorageConditions$.next(articleStorageConditions);
  }

  filterByReceptionIdStartsWith($event: string) {
    this.receptionIdStartsWith$.next($event);
  }

  filterByValidated($event: InputSwitchChangeEvent) {
    this.showValidated$.next($event.checked);
  }

  createLocationSearch$(stockPrevision: StockPrevision): Observable<LocationSearch> {
    return stockPrevision.warehouse$.pipe(
      map(warehouse => ({
        warehouse
      }))
    );
  }

  identity(stockPrevision: any): StockPrevision {
    return stockPrevision;
  }

  private updateStockPrevision(updatedStockPrevision: StockPrevision) {
    // remove old value
    this.editedStockPrevisions = this.editedStockPrevisions.filter(previousStockPrevision => !this.isSameStockPrevision(previousStockPrevision, updatedStockPrevision));
    // add new one
    this.editedStockPrevisions.push(updatedStockPrevision);
    this.fireEditedStockPrevisionsChange();
  }

  private isSameStockPrevision(stockPrevision1: StockPrevision, stockPrevision2: StockPrevision) {
    return stockPrevision1?.id === stockPrevision2?.id;
  }

  private fireEditedStockPrevisionsChange() {
    this.editedStockPrevisionsSubject$.next(this.editedStockPrevisions);
  }

  private getEditedStockPrevisionPage(stockPrevisionPage: Page<StockPrevision>, editedStockPrevisions: StockPrevision[]): Page<StockPrevision> {
    // for each stock prevision item, find an updated value if there is one and use it
    const augmentedStockPrevisions: StockPrevision[] = stockPrevisionPage.content.map(stockPrevision => {
      const editedStockPrevision = editedStockPrevisions.find(editedStockPrevision => this.isSameStockPrevision(editedStockPrevision, stockPrevision));
      return editedStockPrevision ?? stockPrevision;
    });
    // create a page out of that
    return {
      ...stockPrevisionPage,
      content: augmentedStockPrevisions
    }
  }

  private refresh(resetSelection: boolean) {
    if (resetSelection) {
      this.selectedStockPrevisions = [];
    }
    this.editedStockPrevisions = [];
    this.fireEditedStockPrevisionsChange();
    this.refreshTrigger$.next();
  }
}
