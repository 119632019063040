import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {AuditChangeDto, AuditChangeSearchDto} from '@typedefs/stock-rest';
import {AuditChange, AuditChangeCreationBase} from "@model/audit-change";
import {AuditChangeSearch} from "@model/search/audit-change-search";
import {copyCommonFields} from "@model/mapping-utils";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class AuditChangeService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getAuditChanges$(): Observable<AuditChange[]> {
    return this.#httpClient.get<AuditChange[]>(`${environment.apiUrl}/auditchanges`)
      .pipe(shareReplay());
  }

  public getAuditChange$(id: string, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<AuditChange> {
    return this.#httpClient.get<AuditChangeDto>(`${environment.apiUrl}/auditchanges/${id}`)
      .pipe(
        map(auditChangeDto => this.mapToAuditChange(auditChangeDto, cache)),
        shareReplay(),
      );
  }

  public findAuditChanges$(auditChangeSearch: AuditChangeSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<AuditChange>> {
    const auditChangeSearchDto = this.mapToAuditChangeSearchDto(auditChangeSearch);
    return this.#httpClient.post<Page<AuditChangeDto>>(`${environment.apiUrl}/auditchanges/search`, auditChangeSearchDto, {params: pagination})
      .pipe(map(auditChangePage => this.loadAuditChangePage(auditChangePage, cache)));
  }

  private loadAuditChangePage(auditChangeDtoPage: Page<AuditChangeDto>, cache: FoodbankCache): Page<AuditChange> {
    return {
      ...auditChangeDtoPage,
      content: this.loadAuditChangeDetailsList(auditChangeDtoPage.content, cache)
    };
  }

  public loadAuditChangeDetailsList(auditChangeDtos: AuditChangeDto[], cache: FoodbankCache) {
    return auditChangeDtos.map(auditChange => this.mapToAuditChange(auditChange, cache));
  }

  public createAuditChange(auditChange: AuditChangeCreationBase): Observable<AuditChange> {
    const auditChangeDto = this.mapToAuditChangeDto(auditChange);
    return this.#httpClient.post<AuditChangeDto>(`${environment.apiUrl}/auditchanges/create`, auditChangeDto)
      .pipe(map(auditChangeDto => this.mapToAuditChange(auditChangeDto, this.#foodbankCacheFactory.create(this.#injector))));
  }

  public mapToAuditChange(auditChangeDto: AuditChangeDto, cache: FoodbankCache): AuditChange {
    const commonFields: AuditChange | AuditChangeDto = copyCommonFields(auditChangeDto, ['userId',  'organizationId', 'companyId']);
    return {
      ...commonFields,
      user: cache.userCache.get(auditChangeDto.userId),
      organization: cache.organizationCache.getIfDefined(auditChangeDto.organizationId),
      company: cache.companyCache.get(auditChangeDto.companyId)

    }
  }

  mapToAuditChangeDto(auditChange: AuditChangeCreationBase): AuditChangeDto {
    const auditChangeDto: AuditChangeDto = copyCommonFields(auditChange, ['user',  'organization','company' ]) as AuditChangeDto;

    return {
      ...auditChangeDto,
      userId: auditChange.user.value()?.id!,
      organizationId: auditChange.organization.value()?.id!,
      companyId: auditChange.company.value()?.id!

    };
  }

  mapToAuditChangeSearchDto(auditChangeSearch: AuditChangeSearch): AuditChangeSearchDto {
    const auditChangeSearchDto: AuditChangeSearch | AuditChangeSearchDto = copyCommonFields(auditChangeSearch, ['user', 'organization','company']);
    return {
      ...auditChangeSearchDto,
      userId: auditChangeSearch.user?.id,
      organizationId: auditChangeSearch.organization?.id,
      companyId: auditChangeSearch.company?.id
    }
  }

}
