import {trim} from "lodash";

export function notEmpty(value: any) {
  return value != undefined; // no !!, you want to handle 0 / false etc.
}

export function joinWhenPresent(separator: string = " ", values: (string|undefined)[]) {
  return values
    .filter(notEmpty)
    .map(x => trim(x))
    .filter(notEmpty)
    .join(separator);
}
