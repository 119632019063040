import {Component, model} from '@angular/core';
import {OrganizationActivity, SupplyWeek} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-distrib-week',
  imports: [NgIf],
  templateUrl: './organization-distrib-week.component.html',
  styleUrl: './organization-distrib-week.component.scss'
})
export class OrganizationDistribWeekComponent {
  ngModel = model<SupplyWeek>();

}
