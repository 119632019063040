import {Component, model} from '@angular/core';
import {DbChangeAction} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-audit-change-action',
  imports: [NgIf],
  templateUrl: './audit-change-action.component.html',
  styleUrl: './audit-change-action.component.scss'
})
export class AuditChangeActionComponent {
  ngModel = model<DbChangeAction>();

}
