<p-dropdown [(ngModel)]="ngModel"
            [options]="users()"
            optionLabel="name"
            placeholder="None"
            [required]="required()"
            [filter]="true"
            (onClear)="clear()"
            [showClear]="true"
            dataKey="id"
            appendTo="body">
  <ng-template pTemplate="filter">
    <input type="text" pInputText pAutoFocus
           [autofocus]="true"
           placeholder="search"
           [(ngModel)]="nameFilter"
    />
  </ng-template>
  <ng-template pTemplate="selectedItem">
    <foodbank-user ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-user pTemplate="item">
    <foodbank-user ngDefaultControl [ngModel]="user" />
  </ng-template>
</p-dropdown>
