import { Component, computed, effect, inject, Injector, linkedSignal, OnInit, signal, Signal, untracked, WritableSignal } from "@angular/core";
import { FileUploadModule } from "primeng/fileupload";
import { NgIf } from "@angular/common";
import { TableModule } from "primeng/table";
import { ArticleService } from "@services/article.service";
import { TooltipModule } from "primeng/tooltip";
import { rxResource, toObservable, toSignal } from "@angular/core/rxjs-interop";
import { combineLatest, debounceTime, filter, firstValueFrom, forkJoin, map, Observable, of, Subject, take } from "rxjs";
import { CheckboxModule } from "primeng/checkbox";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { InputNumberModule } from "primeng/inputnumber";
import { UserService } from "@services/user.service";
import { User } from "@model/user";
import { ButtonModule } from "primeng/button";
import { BulkFoodReceptionImportService, ReceptionImportRow, createRowReceptionIdentifier } from "@services/bulk-food-reception-import.service";
import { BulkFoodExpeditionImportService, ExpeditionImportRow, createRowExpeditionIdentifier } from "@services/bulk-food-expedition-import.service";
import { NumberComponent } from "@components/number/number.component";
import { ArticleComponent } from "@components/article/article.component";
import { SupplierService } from "@services/supplier.service";
import { SupplierComponent } from "@components/supplier/supplier.component";
import { MovementService } from '@services/movement.service';
import { MovementTypeService } from '@services/movement-type.service';
import { Movement } from '@model/movement';
import { MessageService } from 'primeng/api';
import { Language, MovementKind, OrganizationDto, SupplierDto } from '@typedefs/stock-rest';
import { Warehouse } from '@model/warehouse';
import { StockPallet } from '@model/stock-pallet';
import { PalletType } from '@model/pallet-type';
import { Stock } from "@model/stock";
import { WarehouseSingleSelectionComponent } from "@components/warehouse/selection/single/warehouse-single-selection.component";
import { MovementTypeSingleSelectionComponent } from "@components/movement-type/selection/single/movement-type-single-selection.component";
import { MovementType } from "@model/movement-type";
import { MovementTypeSearch } from "@model/search/movement-type-search";
import { Supplier } from "@model/supplier";
import { Article } from "@model/article";
import { OrganizationService } from "@services/organization.service";
import { Organization } from "@model/organization";
import { copyCommonFields } from "@model/mapping-utils";
import { OrganizationComponent } from "@components/organization/organization.component";
import { WarehouseComponent } from "@components/warehouse/warehouse.component";
import { MovementTypeComponent } from "@components/movement-type/movement-type.component";
import { WarehouseService } from "@services/warehouse.service";
import { ImportService } from "@services/bulk-food-import.service";

// Constants
const STORAGE_KEY = 'FOODBANK_BULK_IMPORT_STATE';
const DEBOUNCE_SAVE_MS = 500;
const DEBOUNCE_FOOD_ID_MS = 300;

type ImportRow = ReceptionImportRow | ExpeditionImportRow;
type ImportRowType = 'RECEIPT' | 'EXPEDITION';

function isReceptionRow(row: ImportRow): row is ReceptionImportRow {
  return 'supplierId' in row;
}

function isExpeditionRow(row: ImportRow): row is ExpeditionImportRow {
  return 'organizationId' in row;
}

// Type guard for arrays
function isReceptionRowArray(rows: ImportRow[]): rows is ReceptionImportRow[] {
  return rows.length === 0 || isReceptionRow(rows[0]);
}

function isExpeditionRowArray(rows: ImportRow[]): rows is ExpeditionImportRow[] {
  return rows.length === 0 || isExpeditionRow(rows[0]);
}

interface BulkFoodImportPreferences {
  data: Array<Omit<ReceptionImportRow | ExpeditionImportRow, 'articleRef' | 'supplierRef' | 'organizationRef' | 'stockRef' | 'movementRef'>>;
  showOnlyProblems: boolean;
  selectedWarehouseId?: string;
  selectedMovementKind?: 'RECEIPT' | 'EXPEDITION' | string;
}

@Component({
  selector: 'foodbank-bulk-food-import',
  templateUrl: './bulk-food-import.component.html',
  styleUrl: './bulk-food-import.component.scss',
  imports: [
    FileUploadModule,
    NgIf,
    TableModule,
    TooltipModule,
    CheckboxModule,
    FormsModule,
    InputTextModule,
    InputNumberModule,
    ButtonModule,
    NumberComponent,
    ArticleComponent,
    SupplierComponent,
    WarehouseSingleSelectionComponent,
    MovementTypeSingleSelectionComponent,
    OrganizationComponent,
    WarehouseComponent,
    MovementTypeComponent
  ]
})
export class BulkFoodImportComponent {

  readonly #articleService = inject(ArticleService);
  readonly #userService = inject(UserService);
  readonly #bulkFoodReceptionImportService = inject(BulkFoodReceptionImportService);
  readonly #bulkFoodExpeditionImportService = inject(BulkFoodExpeditionImportService);
  readonly #supplierService = inject(SupplierService);
  readonly #organizationService = inject(OrganizationService);
  readonly #injector = inject(Injector);
  readonly #movementService = inject(MovementService);
  readonly #movementTypeService = inject(MovementTypeService);
  readonly #messageService = inject(MessageService);
  readonly #warehouseService = inject(WarehouseService);

  readonly data: WritableSignal<ImportRow[]> = signal([]);
  readonly duplicates: WritableSignal<Set<string>> = linkedSignal({
    source: () => this.data(),
    computation: (data) => {
      const type = this.currentImportType();
      const service = this.#getImportService(type);
      return service.findDuplicateRowIdentifiers(data);
    }
  });
  readonly currentUser: Signal<User | undefined>;
  readonly defaultWarehouse: Signal<Warehouse | undefined>;
  readonly selectedWarehouse = signal<Warehouse | undefined>(undefined);
  readonly selectedMovementType: WritableSignal<MovementType | undefined>;
  readonly movementTypeSearch: MovementTypeSearch = {
    movementKinds: ['RECEIPT', 'EXPEDITION']
  }
  readonly showOnlyProblems = signal(false);
  readonly selectedRows = signal<ImportRow[]>([]);
  readonly isSaving = signal(false);
  readonly saveProgress = signal<{ current: number, total: number } | null>(null);
  readonly isRestoringState = signal(false);
  readonly isRecalling = signal(false);
  readonly recallProgress = signal<{ current: number, total: number } | null>(null);

  // Subjects for debounced operations
  readonly #saveStateSubject = new Subject<void>();
  readonly #foodIdChangeSubject = new Subject<{ row: ImportRow, newId: string }>();
  readonly #partyRefIdChangeSubject = new Subject<{ row: ImportRow, newId: string }>();

  // Computed Properties
  readonly currentImportType = computed(() => this.selectedMovementType()?.movementKind === 'RECEIPT' ? 'RECEIPT' as const : 'EXPEDITION' as const);
  readonly hasData = computed(() => this.data().length > 0);
  readonly problemRows = computed(() => {
    const data = this.data();
    const type = this.currentImportType();
    const service = this.#getImportService(type);
    const duplicates = service.findDuplicateRowIdentifiers(data);

    return data.filter(row => {
      const isValid = row.isValid(row as any, duplicates)();
      return isValid === false;
    });
  });
  readonly modifiedRows = computed(() => {
    return this.data().filter(row => row.isModified);
  });
  readonly statusMessage = computed(() => {
    const problemCount = this.problemRows().length;
    const modifiedCount = this.modifiedRows().length;
    const messages: string[] = [];

    if (problemCount > 0) {
      messages.push(`There are ${problemCount} rows with problems`);
    }
    if (modifiedCount > 0) {
      messages.push(`${modifiedCount} unsaved changes`);
    }
    if (messages.length === 0) {
      return 'All rows are valid';
    }
    return messages.join(', ');
  });
  readonly initialized = signal(false);
  readonly title = computed(() => this.hasData() || this.isRestoringState() || !this.initialized() ? 'Imported data' : 'Upload Excel file');

  readonly filteredData = computed(() => {
    const rows = this.data();
    if (!this.showOnlyProblems()) return rows;
    return rows.filter(row => row.articleRef.isLoading() || !untracked(row.isValid(row as any, this.duplicates())));
  });

  constructor() {
    this.currentUser = this.#userService.getCurrentUser();
    this.defaultWarehouse = this.#userService.getDefaultWarehouse();
    this.selectedWarehouse = linkedSignal(() => this.defaultWarehouse());
    const movementTypeOptions$ = this.#movementTypeService.findMovementTypes$(this.movementTypeSearch, { page: 0, size: 1000 });
    const movementTypeOptions = toSignal(movementTypeOptions$.pipe(map(page => page.content)));
    this.selectedMovementType = linkedSignal(() => movementTypeOptions()?.find(movementType => movementType.movementKind === 'RECEIPT'));

    this.#initializeStateFromPreferences(this.#injector);
    this.#setupDebouncing();
    this.#setupEffects();
  }


  onUpload(event: { files: File[] }): void {
    const uploadedFile = event.files[0];
    const warehouse = this.selectedWarehouse();
    const movementType = this.selectedMovementType();
    const type = this.currentImportType();
    const service = this.#getImportService(type);

    service.importExcelFile(uploadedFile, warehouse!, movementType!, this.#injector)
      .then(importRows => {
        const duplicates = service.findDuplicateRowIdentifiers(importRows);
        importRows.forEach(row => {
          row.isDuplicate = duplicates.has(row.rowIdentifier);
        });

        this.data.set(importRows);
        this.#saveState();
      });
  }

  onFoodIdChange(row: ImportRow, newId: string): void {
    if (row.productId === newId) return;

    const type = this.currentImportType();
    row.productId = newId;
    row.isModified = true;
    row.rowIdentifier = this.#getRowIdentifier(row);
    this.#updateRowDuplicateStatus(row);

    row.stockRef = this.#getStockRef(type, newId);

    row.movementRef = this.#getMovementRef(type, row, this.defaultWarehouse(), this.selectedMovementType()!);

    this.#foodIdChangeSubject.next({ row, newId });
  }

  onPartyRefIdChange(row: ImportRow, newId: string): void {
    const type = this.currentImportType();
    if (isReceptionRow(row)) {
      row.supplierId = newId;
    } else {
      row.organizationId = newId;
    }
    row.isModified = true;
    row.rowIdentifier = this.#getRowIdentifier(row);
    this.#updateRowDuplicateStatus(row);

    if (isReceptionRow(row)) {
      row.supplierRef = this.#getPartyRef(type, newId) as ReturnType<typeof rxResource<Supplier | undefined, Error>>;
    } else {
      row.organizationRef = this.#getPartyRef(type, newId) as ReturnType<typeof rxResource<Organization | undefined, Error>>;
    }

    row.movementRef = this.#getMovementRef(type, row, this.defaultWarehouse(), this.selectedMovementType()!);

    this.#partyRefIdChangeSubject.next({ row, newId });
  }

  onArticleChange(row: ImportRow, newArticle: string): void {
    row.article = newArticle;
    row.isModified = true;
    this.#debouncedSave();
  }

  onQuantityChange(row: ImportRow, newQuantity: number): void {
    row.quantity = newQuantity;
    row.isModified = true;
    this.#debouncedSave();
  }

  clearData(): void {
    this.data.set([]);
    const user = this.currentUser();
    if (user) {
      this.#userService.setUserPreferences(user, STORAGE_KEY, null);
    }
  }

  hasValidRows(): boolean {
    const rowsToCheck = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
    return rowsToCheck.some(row => row.articleRef.value() && row.productId);
  }

  saveMovements() {
    this.saveMovementsAsync().catch(error => {
      console.error(error);
    });
  }

  async saveMovementsAsync() {
    this.isSaving.set(true);
    this.saveProgress.set({ current: 0, total: 0 });

    try {
      const type = this.currentImportType();
      const currentUserCompany = await firstValueFrom(this.#userService.getCurrentUserCompany$());
      const defaultWarehouse = await firstValueFrom(this.#userService.getDefaultWarehouse$());
      const currentUser = await firstValueFrom(this.#userService.getCurrentUser$());
      const movementType = this.selectedMovementType();

      if (!defaultWarehouse) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No default warehouse found'
        });
        return;
      }

      if (!currentUserCompany) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No company found'
        });
        return;
      }

      if (!movementType) {
        this.#messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No movement type selected'
        });
        return;
      }

      const rowsToProcess = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
      const validRows = rowsToProcess.filter(row => row.isValid(row as any, this.duplicates()));
      this.saveProgress.set({ current: 0, total: validRows.length });

      // Reset counters
      let createdCount = 0;
      let updatedCount = 0;

      for (const [index, row] of validRows.entries()) {
        const article = row.articleRef.value();
        const party = this.#getMovementParty(type, row);
        const stock = row.stockRef.value();
        const existingMovement = row.movementRef.value();

        if (!article || !party) continue;

        const movement = this.#createMovement(type, {
          row,
          article,
          party,
          stock,
          existingMovement,
          warehouse: defaultWarehouse,
          company: currentUserCompany,
          user: currentUser,
          movementType
        });

        if (row.quantity === 0) {
          continue;
        }

        try {
          const savedMovement = await firstValueFrom(this.#movementService.saveMovement(movement, this.#injector));
          row.movementRef.value.set(savedMovement);
          row.isModified = false;
          // Update the data signal to trigger UI refresh after each save
          this.data.set([...this.data()]);
          if (existingMovement) {
            updatedCount++;
          } else {
            createdCount++;
          }
          this.saveProgress.set({ current: index, total: validRows.length });
        } catch (error) {
          this.#messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to save movement for article ${article.id}`
          });
        }
      }

      // Save state to persist modified flags
      this.#saveState();

      this.#messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Saved ${createdCount + updatedCount} movements`
      });
    } finally {
      this.isSaving.set(false);
      this.saveProgress.set(null);
    }
  }

  async onCreatePartyRef(row: ImportRow): Promise<void> {
    const currentUser = await firstValueFrom(this.#userService.getCurrentUser$());
    const currentUserCompany = await firstValueFrom(this.#userService.getCurrentUserCompany$());
    const language: Language = currentUser.language === 'FRENCH' ? 'FRENCH' : 'DUTCH';
    if (isReceptionRow(row)) {
      const supplierDto: Partial<SupplierDto> = {
        id: row.supplierId,
        name: row.supplierName,
        language: language,
        city: row.city,
        zip: row.postalCode,
        fead: false
      };
      const supplierRefValue = await firstValueFrom(this.#supplierService.createSupplier$(supplierDto))
      row.supplierRef.value.set(supplierRefValue);
    } else {
      const organizationDto: Partial<OrganizationDto> = {
        id: Number(row.organizationId),
        name: row.organizationName,
        city: row.city,
        zip: row.postalCode,
        warehouseId: this.selectedWarehouse()?.id,
        companyId: currentUserCompany.id,
      };
      const organizationRefValue = await firstValueFrom(this.#organizationService.createOrganization$(organizationDto))
      row.organizationRef.value.set(organizationRefValue);
    }
  }

  onSelectionChange(selection: ImportRow[]): void {
    this.selectedRows.set(selection);
  }

  isReceptionRow = isReceptionRow;

  // Private Methods
  #initializeStateFromPreferences(injector: Injector): void {
    const currentUserPreferences$ = toObservable(this.#userService.getCurrentUserPreferences<BulkFoodImportPreferences | undefined>(STORAGE_KEY, undefined));
    const selectedWarehouse$ = toObservable(this.selectedWarehouse);
    const currentUser$ = toObservable(this.currentUser);
    const selectedMovementType$ = toObservable(this.selectedMovementType);

    forkJoin([
      currentUserPreferences$.pipe(filter(preferences => !!preferences), take(1)),
      selectedWarehouse$.pipe(filter(warehouse => !!warehouse), take(1)),
      currentUser$.pipe(filter(user => !!user), take(1)),
      selectedMovementType$.pipe(filter(movementType => !!movementType), take(1))
    ]).subscribe(([preferences, selectedWarehouse, currentUser, selectedMovementType]) => this.#restoreState(currentUser, selectedWarehouse, preferences, selectedMovementType, injector));
  }

  #setupDebouncing(): void {
    this.#saveStateSubject.pipe(
      debounceTime(DEBOUNCE_SAVE_MS)
    ).subscribe(() => {
      this.#saveState();
    });

    this.#foodIdChangeSubject.pipe(
      debounceTime(DEBOUNCE_FOOD_ID_MS)
    ).subscribe(({ row, newId }) => {
      this.#updateArticleRef(row, newId);
      this.#debouncedSave();
    });

    this.#partyRefIdChangeSubject.pipe(
      debounceTime(DEBOUNCE_FOOD_ID_MS)
    ).subscribe(({ row, newId }) => {
      this.#updatePartyRef(row, newId);
      this.#debouncedSave();
    });
  }

  #setupEffects(): void {
    effect(() => {
      this.showOnlyProblems();
      this.#debouncedSave();
    });
  }

  #updateArticleRef(row: ImportRow, newId: string): void {
    if (!newId?.trim()) {
      row.articleRef.value.set(undefined);
      return;
    }

    this.#articleService.getArticle$(newId)
      .pipe(take(1))
      .subscribe({
        next: article => row.articleRef.value.set(article),
        error: () => row.articleRef.value.set(undefined)
      });
  }

  #updatePartyRef(row: ImportRow, newId: string): void {
    if (!newId?.trim()) {
      if (isReceptionRow(row)) {
        row.supplierRef.value.set(undefined);
      } else {
        row.organizationRef.value.set(undefined);
      }
      return;
    }

    const type = this.currentImportType();
    const partyRef = this.#getPartyRef(type, newId);

    if (isReceptionRow(row)) {
      row.supplierRef = partyRef as ReturnType<typeof rxResource<Supplier | undefined, Error>>;
    } else {
      row.organizationRef = partyRef as ReturnType<typeof rxResource<Organization | undefined, Error>>;
    }
  }

  #updateRowDuplicateStatus(row: ImportRow): void {
    const data = this.data();
    const duplicates = this.#getImportService(this.currentImportType()).findDuplicateRowIdentifiers(data);
    this.duplicates.set(duplicates);
    const oldDuplicateStatus = row.isDuplicate;
    const newDuplicateStatus = duplicates.has(row.rowIdentifier);

    if (oldDuplicateStatus === newDuplicateStatus) {
      return;
    }

    if (newDuplicateStatus === true) {
      const duplicateRows = data.filter(r => r.rowIdentifier === row.rowIdentifier);
      duplicateRows.forEach(r => r.isDuplicate = true);
    } else {
      const duplicateRows = data.filter(r => r.isDuplicate);
      duplicateRows.forEach(r => r.isDuplicate = duplicates.has(r.rowIdentifier));
    }
  }

  #debouncedSave(): void {
    this.#saveStateSubject.next();
  }

  #saveState(): void {
    const user = this.currentUser();
    if (!user) return;

    const state: BulkFoodImportPreferences = {
      data: this.data().map(row => {
        const commonFields: ImportRow =
          isReceptionRow(row)
            ? copyCommonFields(row, ['articleRef', 'stockRef', 'movementRef', 'supplierRef', 'isValid'])
            : copyCommonFields(row, ['articleRef', 'stockRef', 'movementRef', 'organizationRef', 'isValid']);

        return {
          ...commonFields,
          ...(isReceptionRow(row)
            ? { supplierName: row.supplierName, supplierId: row.supplierId }
            : { organizationName: row.organizationName, organizationId: row.organizationId }
          )
        }
      }),
      showOnlyProblems: this.showOnlyProblems(),
      selectedWarehouseId: String(this.selectedWarehouse()?.id),
      selectedMovementKind: this.selectedMovementType()?.movementKind
    };

    this.#userService.setUserPreferences(user, STORAGE_KEY, state);
  }

  #restoreState(currentUser: User | undefined, warehouse: Warehouse, preferences: BulkFoodImportPreferences | null, movementType: MovementType | undefined, injector: Injector): void {
    if (!preferences?.data?.length) return;

    try {
      this.isRestoringState.set(true);
      this.initialized.set(true);

      const selectedWarehouseFromPreferences$ = preferences.selectedWarehouseId ? this.#warehouseService.getWarehouse$(Number(preferences.selectedWarehouseId)) : of(warehouse);
      const selectedMovementTypeFromPreferences$ = preferences.selectedMovementKind
        ? this.#movementTypeService.getMovementType$(preferences.selectedMovementKind as MovementKind) : of(movementType);


      combineLatest([selectedWarehouseFromPreferences$, selectedMovementTypeFromPreferences$])
        .pipe(take(1))
        .subscribe(([selectedWarehouseFromPreferences, selectedMovementTypeFromPreferences]) => {
          const selectedWarehouse = selectedWarehouseFromPreferences ?? warehouse;
          if (selectedWarehouseFromPreferences) {
            this.selectedWarehouse.set(selectedWarehouseFromPreferences);
          }
          const selectedMovementType = selectedMovementTypeFromPreferences ?? movementType;
          if (selectedMovementTypeFromPreferences) {
            this.selectedMovementType.set(selectedMovementTypeFromPreferences);
          }

          const type = selectedMovementTypeFromPreferences?.movementKind as ImportRowType;
          const service = this.#getImportService(type);
          service.loadImportMovements(preferences.data[0].yearMonth!, selectedWarehouse, selectedMovementType!, injector);
          const restoredData = preferences.data.map((item) => service.mapImportRowResources(item, injector));

          this.data.set(restoredData);

          if (typeof preferences.showOnlyProblems === 'boolean') {
            this.showOnlyProblems.set(preferences.showOnlyProblems);
          }

          this.isRestoringState.set(false);
        });
    } catch (e) {
      console.error('Failed to restore state:', e);
      if (currentUser) {
        this.#userService.setUserPreferences(currentUser, STORAGE_KEY, null);
      }
    }
  }

  #getImportService(type: ImportRowType): ImportService<ImportRow> {
    switch (type) {
      case 'RECEIPT':
        return this.#bulkFoodReceptionImportService;
      case 'EXPEDITION':
        return this.#bulkFoodExpeditionImportService;
    }
  }

  #getRowIdentifier(row: ImportRow) {
    if (isReceptionRow(row)) {
      return createRowReceptionIdentifier(row);
    } else if (isExpeditionRow(row)) {
      return createRowExpeditionIdentifier(row);
    }
    throw new Error('Unknown import row type');
  }

  #getMovementRef(type: ImportRowType, row: ImportRow, warehouse: Warehouse | undefined, movementType: MovementType) {
    if (isReceptionRow(row)) {
      return rxResource<Movement | undefined, Error>({
        loader: () => this.#bulkFoodReceptionImportService.updateMovementRef$(
          warehouse,
          row.yearMonth,
          row.productId,
          row.supplierId,
          row.rowIdentifier,
          movementType,
          this.#injector
        ),
        injector: this.#injector
      });
    } else if (isExpeditionRow(row)) {
      return rxResource<Movement | undefined, Error>({
        loader: () => this.#bulkFoodExpeditionImportService.updateMovementRef$(
          warehouse,
          row.yearMonth,
          row.productId,
          row.organizationId,
          row.rowIdentifier,
          movementType,
          this.#injector
        ),
        injector: this.#injector
      });
    }
    throw new Error('Unknown import row type');
  }

  #getStockRef(type: ImportRowType, productId: string) {
    switch (type) {
      case 'RECEIPT':
        return rxResource<Stock | undefined, Error>({
          loader: () => this.#bulkFoodReceptionImportService.loadStock$(productId, this.#injector),
          injector: this.#injector
        });
      case 'EXPEDITION':
        return rxResource<Stock | undefined, Error>({
          loader: () => this.#bulkFoodExpeditionImportService.loadStock$(productId, this.#injector),
          injector: this.#injector
        });
    }
  }

  #getPartyRef(type: ImportRowType, partyId: string) {
    switch (type) {
      case 'RECEIPT':
        return rxResource<Supplier | undefined, Error>({
          loader: () => this.#supplierService.getSupplier$(partyId),
          injector: this.#injector
        });
      case 'EXPEDITION':
        return rxResource<Organization | undefined, Error>({
          loader: () => this.#organizationService.getOrganization$(Number(partyId)),
          injector: this.#injector
        });
    }
  }

  #getMovementParty(type: ImportRowType, row: ImportRow) {
    switch (type) {
      case 'RECEIPT':
        return isReceptionRow(row) ? row.supplierRef.value() : undefined;
      case 'EXPEDITION':
        return isExpeditionRow(row) ? row.organizationRef.value() : undefined;
    }
  }

  #createMovement(type: ImportRowType, params: {
    row: ImportRow,
    article: Article,
    party: Supplier | Organization,
    stock: Stock | undefined,
    existingMovement: Movement | undefined,
    warehouse: Warehouse,
    company: any,
    user: User,
    movementType: MovementType
  }) {
    const { row, article, party, stock, existingMovement, warehouse, company, user, movementType } = params;

    const emptyPalletType: PalletType = {
      id: '0',
      languageNameMap: { FRENCH: '', DUTCH: '' },
      weight: 0
    };

    const emptyStockPallet: StockPallet = {
      id: 0,
      palletType$: of(emptyPalletType)
    };

    const description = `[${row.rowIdentifier}] ${row.article}`.substring(0, 40);
    const commonMovement = {
      ...(existingMovement || {}),
      quantity: row.quantity,
      dateTime: existingMovement?.dateTime || new Date(),
      date: existingMovement?.date || new Date(),
      timestamp: new Date(),
      internalBatchName: existingMovement?.internalBatchName || '',
      comment: description,
      fullDescription: description,
      deliverBeforeDate: existingMovement?.deliverBeforeDate || new Date(),
      unitWeight: existingMovement?.unitWeight || 0,
      preparation: existingMovement?.preparation || 0,
      unitGrossWeight: existingMovement?.unitGrossWeight || 0,
      unitsPerParcel: existingMovement?.unitsPerParcel || 0,
      demand: existingMovement?.demand || 0,
      movementType$: of(movementType),
      article$: of(article),
      company$: of(company),
      warehouse$: of(warehouse),
      stockPallet$: stock ? stock.pallet$ : of(emptyStockPallet),
      user$: of(user),
      stock$: stock ? of(stock) : of(undefined)
    };

    switch (type) {
      case 'RECEIPT':
        return {
          ...commonMovement,
          location: 'RAMASSE',
          supplierBatchName: `RAMASSE ${row.yearMonth}`,
          reception$: of(undefined),
          supplier$: of(party as Supplier),
          organization$: of(undefined)
        } as Movement;
      case 'EXPEDITION':
        return {
          ...commonMovement,
          location: 'RAMASSE',
          supplierBatchName: `RAMASSE ${row.yearMonth}`,
          reception$: of(undefined),
          supplier$: of(undefined),
          organization$: of(party as Organization)
        } as Movement;
    }
  }

  hasMovementsToRecall(): boolean {
    const rowsToCheck = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
    return rowsToCheck.some(row => row.movementRef.value());
  }

  recallMovements() {
    this.recallMovementsAsync().catch(error => {
      console.error(error);
    });
  }

  async recallMovementsAsync() {
    this.isRecalling.set(true);
    this.recallProgress.set({ current: 0, total: 0 });

    try {
      const rowsToProcess = this.selectedRows().length > 0 ? this.selectedRows() : this.data();
      const rowsWithMovements = rowsToProcess.filter(row => row.movementRef.value());
      this.recallProgress.set({ current: 0, total: rowsWithMovements.length });

      let recalledCount = 0;

      for (const [index, row] of rowsWithMovements.entries()) {
        const movement = row.movementRef.value();
        if (!movement) continue;

        try {
          await firstValueFrom(this.#movementService.deleteMovement(movement.id!, this.#injector));
          row.movementRef.value.set(undefined);
          row.isModified = false;
          // Update the data signal to trigger UI refresh after each recall
          this.data.set([...this.data()]);
          recalledCount++;
          this.recallProgress.set({ current: index, total: rowsWithMovements.length });
        } catch (error) {
          console.error(error);
          this.#messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to recall movement for row ${row.rowNumber}`
          });
        }
      }

      // Save state to persist modified flags
      this.#saveState();

      this.#messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Recalled ${recalledCount} movements`
      });
    } finally {
      this.isRecalling.set(false);
      this.recallProgress.set(null);
    }
  }
}
