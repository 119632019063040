<p-dropdown [(ngModel)]="ngModel"
            [options]="organizationDistribWeeks"
            optionLabel="week"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-organization-distrib-week ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-supplyWeek pTemplate="item">
    <foodbank-organization-distrib-week ngDefaultControl [ngModel]="supplyWeek" />
  </ng-template>
</p-dropdown>
