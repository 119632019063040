import {Component, model} from '@angular/core';
import {OrganizationActivity} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-activity',
  imports: [NgIf],
  templateUrl: './organization-activity.component.html',
  styleUrl: './organization-activity.component.scss'
})
export class OrganizationActivityComponent {
  ngModel = model<OrganizationActivity>();

}
