import {Component, inject, input, model} from '@angular/core';
import {Cpas} from '@model/cpas';
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-cpas',
  templateUrl: './cpas.component.html',
  styleUrls: ['./cpas.component.scss'],
  imports: [
    NgIf
  ]
})
export class CpasComponent {

  ngModel = model<Cpas>();
  showId = input(true);



  constructor() {
  }

}
