import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {CpasDto, CpasSearchDto} from '@typedefs/stock-rest';
import {Cpas} from "@model/cpas";
import {CpasSearch} from "@model/search/cpas-search";
import {FoodbankCache} from "@services/foodabank-cache";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";

@Injectable({
  providedIn: 'root'
})
export class CpasService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getCpass$(): Observable<Cpas[]> {
    return this.#httpClient.get<Cpas[]>(`${environment.apiUrl}/cpass`)
      .pipe(shareReplay());
  }

  public getCpas$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Cpas> {
    return this.#httpClient.get<CpasDto>(`${environment.apiUrl}/cpass/${id}`)
      .pipe(
        map(cpasDto => this.mapToCpas(cpasDto, cache)),
        shareReplay(),
      );
  }

  public findCpass$(cpasSearch: CpasSearch, pagination: Pagination, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Page<Cpas>> {
    const cpasSearchDto = this.mapToCpasSearchDto(cpasSearch);
    return this.#httpClient.post<Page<CpasDto>>(`${environment.apiUrl}/cpass/search`, cpasSearchDto, {params: pagination})
      .pipe(map(cpasPage => this.loadCpasPage(cpasPage, cache)));
  }

  private loadCpasPage(cpasDtoPage: Page<CpasDto>, cache: FoodbankCache): Page<Cpas> {
    return {
      ...cpasDtoPage,
      content: this.loadCpasDetailsList(cpasDtoPage.content, cache)
    };
  }

  public loadCpasDetailsList(cpasDtos: CpasDto[], cache: FoodbankCache) {
    return cpasDtos.map(cpas => this.mapToCpas(cpas, cache));
  }

  updateCpas(cpas: Cpas): Observable<CpasDto> {
    // FIXME: convert to CpasDto
    return this.#httpClient.put<CpasDto>(`${environment.apiUrl}/cpass/${cpas.id}`, cpas)
  }

  public mapToCpas(cpasDto: CpasDto, cache: FoodbankCache): Cpas {
    return {
      ...cpasDto,

    };
  }

  mapToCpasSearchDto(cpasSearch: CpasSearch): CpasSearchDto {
    return {
      ...cpasSearch,
    };
  }

}
