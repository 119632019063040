<p-panel [styleClass]="'cancel-disabled-effects-for-last-tab'" *ngIf="createdPreparation$ | async as prep">
  <ng-template pTemplate="header">
    <div class="flex justify-content-between flex-wrap w-full -mb-1 -mt-1">
      <div class="flex flex-row align-items-center gap-2 mr-2">
        <div i18n>Warehouse:</div>
        <div>
          <foodbank-warehouse *ngIf="prep.id && selectedWarehouse" [ngModel]="selectedWarehouse" ngDefaultControl />
          <foodbank-warehouse-selection *ngIf="!prep.id || !selectedWarehouse"
                                        id="warehouse"
                                        [warehouseSearch]="(filterWarehouseSearch$ | async) || {}"
                                        [ngModel]="(selectedWarehouse$ | async) || undefined"
                                        (ngModelChange)="selectWarehouse($event)"
                                        [showClear]="false"
                                        ngDefaultControl />
        </div>
        <div class="ml-3">Zone:</div>
        <div>
          <foodbank-preparation-zone-single-selection [zoneSearch]="(filterZoneSearch$ | async) || {}"
                                                      [ngModel]="(selectedPreparationZone$ | async) || undefined"
                                                      (ngModelChange)="selectPreparationZone($event)"
                                                      [showClear]="false"
                                                      ngDefaultControl />
        </div>
        <div class="ml-3">Date:</div>
        <div class="flex">
          <foodbank-date-picker id="date" name="date"
                                [ngModel]="(selectedDate$ | async) || undefined"
                                class="mr-2"
                                (onSelect)="selectDate($event)"
                                [placeholder]="'Select a date'"
                                [showSelectionOptions]="false"
                                ngDefaultControl
           />
           <foodbank-calendar-button [floating]="false" />
        </div>
      </div>
      <div class="flex flex-row mb-1">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </div>
      <div class="break"></div>
      <div class="flex flex-row -mb-1 mt-2">
        <div>
          <p-fieldset [toggleable]="true" [collapsed]="true" [styleClass]="'mb-2'" [style]="{ 'max-width': '440px' }">
            <ng-template pTemplate="header">
              <div class="flex align-items-center gap-2">
                <span class="font-semibold" i18n>Preparation configuration</span>
              </div>
            </ng-template>

            <div class="flex field justify-content-between align-items-center">
              <label class="mb-0 mr-2" i18n>Don't propose distribution by Association</label>
              <p-checkbox id="parameter-dont-propose-distribution-by-association" name="parameter-dont-propose-distribution-by-association"
                          [ngModel]="!prep.proposeDistributionByAssociation" (ngModelChange)="prep.proposeDistributionByAssociation = !$event"
                          [binary]="true" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
            <div class="flex field justify-content-between align-items-center">
              <label for="parameter-propose-pallet-weights" class="mb-0 mr-2" i18n>Don't propose pallet weights</label>
              <p-checkbox id="parameter-propose-pallet-weights" name="parameter-propose-pallet-weights"
                          [ngModel]="!prep.proposePalletWeights" (ngModelChange)="prep.proposePalletWeights = !$event"
                          [binary]="true" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
            <div class="flex field justify-content-between align-items-center">
              <label for="parameter-exclude-preparation-warehouse" class="mb-0 mr-2" i18n>Exclude preparation warehouse</label>
              <p-checkbox id="parameter-exclude-preparation-warehouse" name="parameter-exclude-preparation-warehouse"
                          [ngModel]="!prep.handleMainWarehouses" (ngModelChange)="prep.handleMainWarehouses = !$event"
                          [binary]="true" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
            <div class="flex field justify-content-between align-items-center">
              <label for="parameter-ignore-article-restrictions" class="mb-0 mr-2" i18n>Ignore article restrictions</label>
              <p-checkbox id="parameter-ignore-article-restrictions" name="parameter-ignore-article-restrictions"
                          [(ngModel)]="prep.ignoreArticleRestrictions"
                          [binary]="true" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
            <div class="flex field justify-content-between align-items-center">
              <label for="parameter-package-distribution" class="mb-0 mr-2" i18n>Ignore week parameters</label>
              <p-checkbox id="parameter-ignore-week-params" name="parameter-ignore-week-params"
                          [(ngModel)]="prep.ignoreWeekParameters"
                          [binary]="true" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
            <div class="flex field justify-content-between align-items-center">
              <label for="parameter-package-distribution" class="mb-0 mr-2" i18n>Package distribution by</label>
              <p-dropdown id="parameter-package-distribution" name="parameter-package-distribution"
                          [options]="(packageDistributionOptions$ | async) || []" [(ngModel)]="prep.packageDistributionOption" (onChange)="updateDistributionConfigSelectedParameters($event)" />
            </div>
          </p-fieldset>
        </div>
        <div class="ml-3">
          <p-button icon="pi pi-plus"
            label="Distribute preparation"
            [rounded]="true"
            (click)="createPreparationLines()"
           />

          <!--            [disabled]="!(canCreatePreparation$ | async)"-->
        </div>
        <div class="ml-3">
          <p-button styleClass="p-button-secondary"
            icon="pi pi-eraser"
            label="Reset values"
            [rounded]="true"
           />
          <!--            (click)="resetValues()"-->
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *ngIf="!prep.id; else tabs">
    <p i18n>To create a preparation, we need you to select:</p>
    <p>
      <p-checkbox label="a warehouse" [readonly]="true" [disabled]="!!selectedWarehouse" [ngModel]="!!selectedWarehouse" [binary]="true" />
    </p>
    <p>
      <p-checkbox label="a preparation zone" [readonly]="true" [disabled]="!!selectedPreparationZone" [ngModel]="!!selectedPreparationZone" [binary]="true" />
    </p>
    <p>
      <p-checkbox label="a date" [readonly]="true" [disabled]="!!selectedDate" [ngModel]="!!selectedDate" [binary]="true" />
    </p>
  </ng-container>

  <ng-template #tabs>
    <p-tabView [(activeIndex)]="activeTabIndex">
      <p-tabPanel>
        <ng-template pTemplate="header">
        <span pDroppable (onDrop)="removeSelection()">
          <span class="fa-solid fa-boxes-stacked tab-icon mr-1"></span>Stock
        </span>
        </ng-template>
        <ng-container *ngIf="activeTabIndex === 0">
          <foodbank-preparation-stock-preparation-table *ngIf="selectedWarehouse$ | async; else noSelectedWarehouse"
                                                        [warehouse]="(selectedWarehouse$ | async) || undefined"
                                                        [stockFilter]="(stockFilter$ | async) || {}"
                                                        [preparation]="prep"
                                                        [tableSizeStyleClass]="tableSizeStyleClass"
                                                        (onStockDragStart)="updatePreparationSelectionToCreate($event)"
           />
        </ng-container>
        <ng-template #noSelectedWarehouse>
          <p>Select a warehouse</p>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
        <span pDroppable (onDrop)="addSelection()">
          <span class="fa-solid fa-square-parking tab-icon mr-1"></span>
          <span class="mr-1">Preparation</span>
        </span>
        </ng-template>
        <foodbank-setup-preparation-table *ngIf="activeTabIndex === 1"
                                          [warehouse]="(selectedWarehouse$ | async) || undefined"
                                          [stockFilter]="(stockFilter$ | async) || undefined"
                                          [preparation]="prep"
                                          [tableSizeStyleClass]="tableSizeStyleClass"
                                          (onStockDragStart)="updatePreparationSelectionToRemove($event)"
         />
      </p-tabPanel>
      <p-tabPanel *ngIf="prep.id">
        <ng-template pTemplate="header">
        <span>
          <span class="pi pi-list tab-icon mr-1"></span>
          <span class="mr-1">Distribution list</span>
        </span>
        </ng-template>
        <foodbank-preparation-distribution *ngIf="activeTabIndex === 2" [preparation]="prep"
                                           (onDistributionListChange)="updateDistributionList($event)"
                                           [tableSizeStyleClass]="tableSizeStyleClass" />
      </p-tabPanel>
    </p-tabView>
  </ng-template>
</p-panel>
