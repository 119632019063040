<p-dropdown [(ngModel)]="ngModel"
            [options]="organizationDistribDays"
            optionLabel="day"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-organization-distrib-day ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-supplyDay pTemplate="item">
    <foodbank-organization-distrib-day ngDefaultControl [ngModel]="supplyDay" />
  </ng-template>
</p-dropdown>
