import {Component, model} from '@angular/core';
import {NgIf} from "@angular/common";
import {OrganizationCategory} from "@typedefs/stock-rest";

@Component({
  selector: 'foodbank-organization-category',
  imports: [NgIf],
  templateUrl: './organization-category.component.html',
  styleUrl: './organization-category.component.scss'
})
export class OrganizationCategoryComponent {
  ngModel = model<OrganizationCategory>();
}
