import {Component, model} from '@angular/core';
import {OrganizationActivity, SupplyMonth} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-distrib-month',
  imports: [NgIf],
  templateUrl: './organization-distrib-month.component.html',
  styleUrl: './organization-distrib-month.component.scss'
})
export class OrganizationDistribMonthComponent {
  ngModel = model<SupplyMonth>();

}
