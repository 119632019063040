import {Component, Input} from '@angular/core';
import {ArticleCategoryDto} from '@typedefs/stock-rest';
import {map} from 'rxjs';
import {LanguageService} from '@services/language.service';
import {AsyncPipe, NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
  selector: 'foodbank-article-category',
  templateUrl: './article-category.component.html',
  styleUrls: ['./article-category.component.scss'],
  imports: [
    AsyncPipe,
    NgIf,
    LanguageDirective
  ]
})
export class ArticleCategoryComponent {

  @Input()
  articleCategory: ArticleCategoryDto | undefined | null;

  @Input()
  showId: boolean = true;

  showId$ = this.languageService.isCurrentLanguageDisplayable$()
    .pipe(map(isDisplayable => {
      if (!isDisplayable) {
        return true;
      } else {
        return this.showId;
      }
    }));

  constructor(private languageService: LanguageService) {
  }

}
