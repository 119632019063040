<div class="card p-fluid" *ngIf="warehouse() as warehouse">

  <form #warehouseForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="name" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Name</label>
      <div class="col-12 md:col-10">
        <input id="name" type="text" name="name" pInputText [(ngModel)]="warehouse.name" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@Active" for="active" class="col-12 mb-2 md:col-2 md:mb-0">Active</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="active" name="active" [(ngModel)]="warehouse.active"></p-inputSwitch>
      </div>
    </div>

    <div class="field grid">
      <label for="address" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Address</label>
      <div class="col-12 md:col-10">
        <input id="address" type="text" name="address" pInputText [(ngModel)]="warehouse.address" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="zip" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Zip</label>
      <div class="col-12 md:col-10">
        <input id="zip" type="text" name="zip" pInputText [(ngModel)]="warehouse.zip" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="city" class="col-12 mb-2 md:col-2 md:mb-0" i18n>City</label>
      <div class="col-12 md:col-10">
        <input id="city" type="text" name="city" pInputText [(ngModel)]="warehouse.city" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="email" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Email</label>
      <div class="col-12 md:col-10">
        <input #email="ngModel"  id="email" type="text" name="email" pInputText [(ngModel)]="warehouse.email"
               required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$" maxlength="50" ngDefaultControl>
        <p-message i18n-text="@@EmailIsMandatory" *ngIf="email.invalid && warehouseForm.touched" severity="error"
                   text="E-mail is required and must be lowercase and valid"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="phone" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Phone</label>
      <div class="col-12 md:col-10">
        <input id="phone" type="text" pInputText name="phone" [(ngModel)]="warehouse.phoneNo" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label  for="mainWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">Main Warehouse ?</label>
      <div class="col-12 md-10">
        <p-inputSwitch id="mainWarehouse" name="mainWarehouse" [(ngModel)]="warehouse.mainWarehouse"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label  for="federationWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">Federation Warehouse ?</label>
      <div class="col-12 md-10">
        <p-inputSwitch id="federationWarehouse" name="federationWarehouse" [(ngModel)]="warehouse.federationWarehouse"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label  for="esfWarehouse" class="col-12 mb-2 md:col-2 md:mb-0">ESF+ Warehouse ?</label>
      <div class="col-12 md-10">
        <p-inputSwitch id="esfWarehouse" name="esfWarehouse" [(ngModel)]="warehouse.esfWarehouse"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label for="mainIpAddress" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Main Ip Address</label>
      <div class="col-12 md:col-10">
        <input id="mainIpAddress" type="text" name="mainIpAddress" pInputText [(ngModel)]="warehouse.mainIpAddress" ngDefaultControl>
      </div>
    </div>
    <div class="field grid">
      <label for="stickerType" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Sticker Type</label>
      <div class="col-12 md:col-10">
          <p-dropdown [options]="stickerTypes" id="stickerType" name="stickerType" [(ngModel)]="warehouse.stickerType"></p-dropdown>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary"  [disabled] = "warehouseForm.invalid" (onClick)="save(warehouse)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>


