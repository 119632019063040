import {Component, input, InputSignal, model, ModelSignal, output, Signal} from '@angular/core';
import {FeadCampaignIdentifier} from '@model/fead-campaign-identifier';
import {Warehouse} from '@model/warehouse';
import {map, tap} from 'rxjs';
import {FeadCampaignService} from '@services/fead-campaign.service';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {PrimeTemplate} from 'primeng/api';
import {NgIf} from '@angular/common';
import {derivedAsync} from "ngxtension/derived-async";

@Component({
  selector: 'foodbank-fead-campaign-selection',
  templateUrl: './fead-campaign-selection.component.html',
  styleUrls: ['./fead-campaign-selection.component.scss'],
  imports: [DropdownModule, FormsModule, PrimeTemplate, NgIf]
})
export class FeadCampaignSelectionComponent {

  warehouse: InputSignal<Warehouse | undefined> = input();

  ngModel: ModelSignal<FeadCampaignIdentifier | undefined> = model();
  onCampaignSelected = output<FeadCampaignIdentifier>();

  campaigns: Signal<FeadCampaignIdentifier[]>;

  loading: boolean = true;

  constructor(private feadCampaignService: FeadCampaignService) {
    const currentYear = new Date().getFullYear();
    this.campaigns = derivedAsync(() =>
        this.feadCampaignService.findFeadCampaignIdentifiers$({warehouseId: this.warehouse()?.id})
          .pipe(
            map(campaigns => campaigns.sort((feadCampaignIdentifier1, feadCampaignIdentifier2) => feadCampaignIdentifier1.year - feadCampaignIdentifier2.year)),
            tap(campaigns => this.selectCampaignClosestToCurrentYear(campaigns, currentYear)),
          ),
      {initialValue: []});
  }

  select(campaign: FeadCampaignIdentifier) {
    this.ngModel.set(campaign);
    this.onCampaignSelected.emit(campaign);
  }

  private selectCampaignClosestToCurrentYear(campaigns: FeadCampaignIdentifier[], currentYear: number) {
    if (campaigns && campaigns.length !== 0) {
      const closestCampaign = campaigns.reduce((closest, campaign) => Math.abs(campaign.year - currentYear) <= Math.abs(closest.year - currentYear) ? campaign : closest);
      this.select(closestCampaign);
    }

    this.loading = false;
  }

}
