import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Bank} from '@model/bank';
import {BankDto, BankSearchDto} from '@typedefs/stock-rest';
import {BankSearch} from '@model/search/bank-search';
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {FoodbankCache} from "@services/foodabank-cache";
import {copyCommonFields} from "@model/mapping-utils";

@Injectable({
  providedIn: 'root'
})
export class BankService {

  #httpClient = inject(HttpClient);
  #foodbankCacheFactory = inject(FoodbankCacheFactory);
  #injector = inject(Injector);

  public getBank$(id: number, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Bank> {
    return this.#httpClient.get<BankDto>(`${environment.apiUrl}/banks/${id}`)
      .pipe(
        map(bankDto => this.mapToBank$(bankDto, cache)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  public findBanks$(bankSearch: BankSearch, injector: Injector, pagination: Pagination, cache = this.#foodbankCacheFactory.create(injector)): Observable<Page<Bank>> {
    const bankSearchDto = this.mapToBankSearchDto(bankSearch);
    return this.#httpClient.post<Page<BankDto>>(`${environment.apiUrl}/banks/search`, bankSearchDto, {params: pagination})
      .pipe(map(bankPage => this.loadBankPage(bankPage, cache)));
  }

  private loadBankPage(bankDtoPage: Page<BankDto>, cache: FoodbankCache): Page<Bank> {
    return {
      ...bankDtoPage,
      content: this.loadBankDetailsList(bankDtoPage.content, cache)
    };
  }

  public loadBankDetailsList(bankDtos: BankDto[], cache: FoodbankCache) {
    return bankDtos.map(bank => this.mapToBank$(bank, cache));
  }

  updateBank(bank: Bank, cache = this.#foodbankCacheFactory.create(this.#injector)): Observable<Bank> {
    const bankDto = this.mapToBankDto(bank);
    return this.#httpClient.put<BankDto>(`${environment.apiUrl}/banks`, bankDto)
      .pipe(map(bankDto => this.mapToBank$(bankDto, cache)));
  }

  public mapToBank$(bankDto: BankDto, cache: FoodbankCache): Bank {
    const commonFields: Bank | BankDto = copyCommonFields(bankDto, ['regionId', 'presidentId', 'vicePresidentId', 'secretaryId', 'treasurerId', 'logisticsManagerId'
      , 'securityAndHealthManagerId', 'associationManagerId', 'supplyManagerId'
      , 'itmanagerId', 'hrmanagerId', 'ceoid', 'qualityManagerId', 'esfmanagerId', 'publicRelationsManagerId']);
    return {
      ...commonFields,
      bankProg: cache.bankProgCache.get(bankDto.id),
      region: cache.regionCache.get(bankDto.regionId),
      president: cache.memberCache.get(bankDto.presidentId),
      vicePresident: cache.memberCache.get(bankDto.vicePresidentId),
      secretary: cache.memberCache.get(bankDto.secretaryId),
      treasurer: cache.memberCache.get(bankDto.treasurerId),
      logisticsManager: cache.memberCache.get(bankDto.logisticsManagerId),
      securityAndHealthManager: cache.memberCache.get(bankDto.securityAndHealthManagerId),
      associationManager: cache.memberCache.get(bankDto.associationManagerId),
      supplyManager: cache.memberCache.get(bankDto.supplyManagerId),
      itmanager: cache.memberCache.get(bankDto.itmanagerId),
      hrmanager: cache.memberCache.get(bankDto.hrmanagerId),
      ceo: cache.memberCache.get(bankDto.ceoid),
      qualityManager: cache.memberCache.get(bankDto.qualityManagerId),
      esfmanager: cache.memberCache.get(bankDto.esfmanagerId),
      publicRelationsManager: cache.memberCache.get(bankDto.publicRelationsManagerId),
    }
  }

  mapToBankDto(bank: Bank): BankDto {
    const commonFields: Bank | BankDto = copyCommonFields(bank, ['bankProg','region', 'president', 'vicePresident', 'secretary', 'treasurer', 'logisticsManager'
      , 'securityAndHealthManager', 'associationManager', 'supplyManager'
      , 'itmanager', 'hrmanager', 'ceo', 'qualityManager', 'esfmanager', 'publicRelationsManager']);
    return {
      ...commonFields,
      regionId: bank.region.value()?.id!,
      presidentId: bank.president.value()?.id!,
      vicePresidentId: bank.vicePresident.value()?.id!,
      secretaryId: bank.secretary.value()?.id!,
      treasurerId: bank.treasurer.value()?.id!,
      logisticsManagerId: bank.logisticsManager.value()?.id!,
      securityAndHealthManagerId: bank.securityAndHealthManager.value()?.id!,
      associationManagerId: bank.associationManager.value()?.id!,
      supplyManagerId: bank.supplyManager.value()?.id!,
      itmanagerId: bank.itmanager.value()?.id!,
      hrmanagerId: bank.hrmanager.value()?.id!,
      ceoid: bank.ceo.value()?.id!,
      qualityManagerId: bank.qualityManager.value()?.id!,
      esfmanagerId: bank.esfmanager.value()?.id!,
      publicRelationsManagerId: bank.publicRelationsManager.value()?.id!,

    };
  }

  mapToBankSearchDto(bankSearch: BankSearch): BankSearchDto {
    return {
      nameContains: bankSearch.nameContains,
      companyId: bankSearch.companyId,
    }
  }

  public findAllBanks$(banksSearch: BankSearch, injector: Injector, cache = this.#foodbankCacheFactory.create(injector)): Observable<Bank[]> {
    const banksSearchDto = this.mapToBankSearchDto(banksSearch);
    return this.#httpClient.post<BankDto[]>(`${environment.apiUrl}/banks/searchAll`, banksSearchDto)
      .pipe(map(banksDtos => this.loadBankDetailsList(banksDtos, cache)));
  }
}
