<p-table #dt
         [value]="(auditPage.value()?.content) || []"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="auditPage.value()?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         [autoLayout]="true"
         sortMode="multiple"
         [multiSortMeta]="[{ field: 'AUDIT_DATE', order: -1 }, { field: 'ID', order: 1 }]"
         dataKey="id">

  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th pSortableColumn="ORGANIZATION_NAME">
        <span i18n>Organization</span>
        <p-sortIcon field="ORGANIZATION_NAME" />
      </th>

      <th pSortableColumn="USERNAME">
        <span i18n>User</span>
        <p-sortIcon field="USERNAME" />
      </th>
      <th pSortableColumn="AUDIT_DATE">
        <span i18n>Date Login</span>
        <p-sortIcon field="AUDIT_DATE" />
      </th>

    </tr>
    <tr>
      <th>
        <p-columnFilter field="organizations" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-organization>
            <foodbank-organization-selection ngDefaultControl
                                             [(ngModel)]="filterOrganization"
                                             [organizationSearch]="organizationSearch()"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="users" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-user>
            <foodbank-user-selection ngDefaultControl
                                     [(ngModel)]="filterUser"
                                     [userSearch]="userSearch()"
            />
          </ng-template>
        </p-columnFilter>

      </th>
      <p-columnFilter field="auditDateSearch" [showMenu]="false" style="width: 15em; display: inline-block">
        <ng-template pTemplate="filter">
          <foodbank-date-search
            ngDefaultControl
            [(ngModel)]="auditDateSearch"
            [required]="false"
            [showButtonBar]="true"
          />
        </ng-template>
      </p-columnFilter>
      <th >

      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-audit>
    <ng-container *ngIf="identity(audit) as audit">
      <tr>
        <td>
          {{ audit.organization.value()?.name }}
        </td>
        <td>
          {{ audit.user.value()?.name }}
        </td>
        <td>
          {{ audit.dateTime }}
        </td>

      </tr>
    </ng-container>
  </ng-template>
</p-table>


