
import {Component, model} from '@angular/core';
import {OrganizationActivity, SupplyDay} from "@typedefs/stock-rest";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-organization-distrib-day',
  imports: [NgIf],
  templateUrl: './organization-distrib-day.component.html',
  styleUrl: './organization-distrib-day.component.scss'
})
export class OrganizationDistribDayComponent {
  ngModel = model<SupplyDay>();

}
