import {Component, inject, input, model, ResourceRef} from '@angular/core';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import {WarehouseService} from "@services/warehouse.service";
import {Page} from "@typedefs/page";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {rxResource} from "@angular/core/rxjs-interop";
import {PaginationService} from "@services/pagination.service";

@Component({
  selector: 'foodbank-warehouse-selection',
  templateUrl: './warehouse-single-selection.component.html',
  styleUrls: ['./warehouse-single-selection.component.scss'],
  imports: [
    DropdownModule,
    FormsModule,
    WarehouseComponent
  ]
})
export class WarehouseSingleSelectionComponent {

  ngModel = model<Warehouse>();
  required = input(false);
  warehouseSearch = model<WarehouseSearch>({}); // you could for example create a warehouse filter to get warehouses of a specific user
  showClear = input(true);
  showCompany = input(false);
  showId = input(false);
  loading = input(false);

  warehousesPage: ResourceRef<Page<Warehouse>>;

  #warehouseService = inject(WarehouseService);
  #paginationService = inject(PaginationService);

  constructor() {
    const pagination = this.#paginationService.getDefaultComponentPaginationSignal();
    this.warehousesPage = rxResource({
      request: () => ({
        warehouseSearch: this.warehouseSearch(),
        pagination: pagination(),
      }),
      loader: params => this.#warehouseService.findWarehouses$(params.request.warehouseSearch, params.request.pagination)
    });
  }

}
