import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {FeadPlanningLineDto, FeadPlanningSearchDto} from '@typedefs/stock-rest';
import {ArticleService} from '@services/article.service';
import {WarehouseService} from '@services/warehouse.service';
import {FeadPlanningSearch} from '@model/search/fead-planning-search';
import {FeadPlanningLine} from '@model/fead-planning-line';
import {OrganizationService} from '@services/organization.service';

export type FeadPlanningPagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class FeadPlanningService {

  constructor(
    private httpClient: HttpClient,
    private articleService: ArticleService,
    private organizationService: OrganizationService,
    private warehouseService: WarehouseService,
  ) {
  }

  public getFeadPlanning$(feadPlanningSearch: FeadPlanningSearch, pagination: FeadPlanningPagination): Observable<Page<FeadPlanningLine>> {
    const feadPlanningSearchDto: FeadPlanningSearchDto = this.mapToFeadPlanningSearchDto(feadPlanningSearch);
    return this.httpClient.post<Page<FeadPlanningLineDto>>(`${environment.apiUrl}/fead/plannings/search`, feadPlanningSearchDto, {params: pagination})
      .pipe(
        map((feadPlanningLinePage: Page<FeadPlanningLineDto>) => ({
          ...feadPlanningLinePage,
          content: feadPlanningLinePage.content.map(feadPlanningLineDto => this.loadFeadPlanningLine(feadPlanningLineDto)),
        })),
        shareReplay()
      );
  }

  private loadFeadPlanningLine(feadPlanningLineDto: FeadPlanningLineDto): FeadPlanningLine {
    return {
      article$: this.articleService.getArticle$(feadPlanningLineDto.articleId),
      organization$: this.organizationService.getOrganization$(feadPlanningLineDto.organizationId),
      warehouse$: this.warehouseService.getWarehouse$(feadPlanningLineDto.warehouseId),
      quantity: feadPlanningLineDto.quantity,
      weekOrDay: feadPlanningLineDto.weekOrDay,
      year: feadPlanningLineDto.year,
    }
  }

  public mapToFeadPlanningSearchDto(feadPlanningSearch: FeadPlanningSearch): FeadPlanningSearchDto {
    return {
      year: feadPlanningSearch.year,
      articleId: feadPlanningSearch.article?.id,
      organizationId: feadPlanningSearch.organization?.id,
      warehouseId: feadPlanningSearch.warehouse?.id,
      warehouseSearchDto: feadPlanningSearch.warehouseSearch && this.warehouseService.mapToWarehouseSearchDto(feadPlanningSearch.warehouseSearch),
      organizationStatus: feadPlanningSearch.organizationStatus,
      articleSearchDto: feadPlanningSearch.articleSearch && this.articleService.mapToArticleSearchDto(feadPlanningSearch.articleSearch),
      period: feadPlanningSearch.period,
      startPeriod: feadPlanningSearch.startPeriod,
      endPeriod: feadPlanningSearch.endPeriod,
    }
  }
}
