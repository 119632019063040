<div class="flex flex-row justify-content-end flex-wrap">
  @if (editing) {
    @if (!isPreferredWeekForDelivery()) {
      <input class="quantity-edit not-preferred-week" pInputText type="text"
             [class.ng-invalid]="!isTempValueValid()"
             [(ngModel)]="tempValue"
             (keyup.enter)="saveEditing()" (keyup.escape)="cancelEditing()"
             [pTooltip]="preferredSupplyWeek()" tooltipPosition="top"/>
    } @else {
      <input class="quantity-edit" pInputText type="text"
             [class.ng-invalid]="!isTempValueValid()"
             [(ngModel)]="tempValue"
             (keyup.enter)="saveEditing()" (keyup.escape)="cancelEditing()"/>
    }

    <button pButton icon="pi pi-check" class="p-button-success p-button-sm p-button-text"
            (click)="saveEditing()"></button>
    <button pButton icon="pi pi-times" class="p-button-danger p-button-sm p-button-text"
            (click)="cancelEditing()"></button>

  } @else {
    <div class="display-container" (click)="startEditing()">
      @if (!isPreferredWeekForDelivery()) {
        <div class="editable not-preferred-week" [pTooltip]="preferredSupplyWeek()" tooltipPosition="top">
          <span>{{ period() || '\u00A0\u00A0\u00A0\u00A0' }}</span>
        </div>
      } @else {
        <div class="editable">{{ period() || '\u00A0' }}</div>
      }
    </div>
  }
</div>
