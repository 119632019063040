import {Component, input, model} from '@angular/core';
import {SupplyDay} from "@typedefs/stock-rest";
import {DropdownModule} from "primeng/dropdown";
import {OrganizationDistribDayComponent} from "@components/organization-distrib-day/organization-distrib-day.component";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'foodbank-organization-distrib-day-selection',
  imports: [
    DropdownModule,
    OrganizationDistribDayComponent,
    FormsModule
  ],
  templateUrl: './organization-distrib-day-selection.component.html',
  styleUrl: './organization-distrib-day-selection.component.scss'
})
export class OrganizationDistribDaySelectionComponent {
  ngModel = model<SupplyDay>();
  required = input(false);

  organizationDistribDays: SupplyDay[];

  constructor() {

    this.organizationDistribDays = [  "EVERYDAY" , "MONDAY" , "TUESDAY" , "WEDNESDAY" , "THURSDAY" , "FRIDAY" , "SATURDAY" , "SUNDAY"];
  }


  clear() {
    this.ngModel.set(undefined);
  }
}
