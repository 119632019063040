<p-dropdown [(ngModel)]="ngModel"
            [options]="dbchangeEntities"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-audit-change-entity ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-dbchangeEntity pTemplate="item">
    <foodbank-audit-change-entity ngDefaultControl [ngModel]="dbchangeEntity" />
  </ng-template>
</p-dropdown>
