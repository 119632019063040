<p-table [value]="(distributionPage$ | async)?.content || []"
         responsiveLayout="scroll"
         [totalRecords]="(distributionPage$ | async)?.totalElements || 0"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="lazyLoad($event)"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="rowId"
         styleClass="p-datatable-sm p-datatable-striped"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         editMode="cell"
         [multiSortMeta]="[{ field: 'ORGANIZATION_NAME', order: 1 }]"
         [styleClass]="tableSizeStyleClass">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="ORGANIZATION_ID" i18n>Id
        <p-sortIcon field="ORGANIZATION_ID" />
      </th>
      <th pSortableColumn="ORGANIZATION_NAME" i18n>Association ({{ (distributionPage$ | async)?.totalElements || 0 }})
        <p-sortIcon field="ORGANIZATION_NAME" />
      </th>
      <th pSortableColumn="ORGANIZATION_ESFPLUS_CODE" style="width: 110px;" i18n>ESF+ code
        <p-sortIcon field="ORGANIZATION_ESFPLUS_CODE" />
      </th>
      <th pSortableColumn="ORGANIZATION_BENEFICIARIES" style="width: 110px;" i18n>Initial repartition
        <span *ngIf="totalBeneficiaries$ | async as totalBeneficiaries">({{ totalBeneficiaries }})</span>
        <p-sortIcon field="ORGANIZATION_BENEFICIARIES" />
      </th>
      <th pSortableColumn="ORGANIZATION_BENEFICIARIES_OVERRIDE" style="width: 110px;" i18n>Final repartition
        <span *ngIf="totalBeneficiariesOverride$ | async as totalBeneficiariesOverride">({{ totalBeneficiariesOverride }})</span>
        <p-sortIcon field="ORGANIZATION_BENEFICIARIES_OVERRIDE" />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-distribution let-index="rowIndex" let-editing="editing">
    <ng-container *ngIf="distributionIdentity(distribution) as distribution">
      <tr *ngIf="organizationIdentity(distribution.organization$ | async) as distributionOrganization">
        <td>
          {{ distributionOrganization.id }}
        </td>
        <td>
          <foodbank-organization ngDefaultControl [ngModel]="distributionOrganization" [showId]="false" />
        </td>
        <td>
          {{ distributionOrganization.code }}
        </td>
        <td class="aligned-quantity">
          {{ distribution.beneficiaries }}
          <span *ngIf="totalBeneficiaries$ | async as totalBeneficiaries">({{ distribution.beneficiaries / totalBeneficiaries * 100 | number: '1.2-2' }} %)</span>
        </td>
        <td class="aligned-quantity" pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input #beneficiariesOverride id="distribution-list-{{index}}-final-supply" pInputText type="number" [(ngModel)]="distribution.beneficiariesOverride"
                     (ngModelChange)="save(distribution)" required/>
            </ng-template>
            <ng-template pTemplate="output">
              <div class="aligned-quantity flex align-items-center justify-content-end" [class.editable]="editing">
                <span class="font-bold px-2 py-1 border-round" 
                      [class.bg-yellow-100]="isOverridden(distribution)"
                      [pTooltip]="isOverridden(distribution) ? 'This value has been manually overridden' : undefined"
                      tooltipPosition="top">
                  {{ distribution.beneficiariesOverride | number: '1.0-0' }}
                </span>
                <i class="fa-regular fa-pen-to-square ml-1 mr-2"></i>
                <span *ngIf="totalBeneficiariesOverride$ | async as totalBeneficiariesOverride" 
                      class="text-sm text-500">
                  {{ distribution.beneficiariesOverride / totalBeneficiariesOverride * 100 | number: '1.1-1' }}%
                </span>
                <div class="w-3rem">
                  <p-badge *ngIf="distribution.distributionFactor > 1 && distribution.beneficiariesOverride !== 0" 
                          [value]="(distribution.distributionFactor | number: '1.0-2') + 'x'"
                          severity="warning" />
                </div>
              </div>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>

<div class="card flex justify-content-center">
  <p-chart type="doughnut"
           [data]="chartData$ | async"
           title="Distribution (current page)"
           width="800px"
           height="400px"
           [options]="{
              plugins: {
                  legend: {
                      display: true,
                      position: 'left',
                      labels: {
                          fontColor: '#000080'
                      }
                  }
              }
          }"
  />
</div>
