import { AfterViewInit, Component, computed, effect, ElementRef, HostListener, inject, Injector, input, OnChanges, output, signal, Signal, untracked, ViewChild, WritableSignal } from '@angular/core';
import {BehaviorSubject, debounceTime, filter, forkJoin, map, Observable, of, pipe, switchMap, take} from 'rxjs';
import { MessageService, PrimeTemplate } from 'primeng/api';
import {DialogService} from "primeng/dynamicdialog";
import {Warehouse} from "@model/warehouse";
import {PaginationService} from "@services/pagination.service";
import {UserService} from "@services/user.service";
import {StockSearch} from "@model/search/stock-search";
import {PreparationSelectionItem} from "@model/preparation-selection-item";
import {Page} from "@typedefs/page";
import {PackageDistributionOption} from "@typedefs/stock-rest";
import {Preparation} from "@model/preparation";
import {PreparationStockSelection} from "@model/preparation-stock-selection";
import {Pagination} from "@services/pagination";
import {Article} from "@model/article";
import {ArticleStorageCondition} from "@model/article-storage-condition";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import {PreparationSelectionService} from "@services/preparation-selection.service";
import {Stock} from "@model/stock";

import {ArticleSearch} from "@model/search/article-search";
import {ArticleStorageConditionSearch} from "@model/search/article-storage-condition-search";
import {createSetAndRemoveDragImage} from "@util/drag-and-drop";
import {ArticleCategory} from "@model/article-category";
import {PreparationSelectionSearch} from "@model/search/preparation-selection-search";
import {overrideSignal, pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {activeWarehouseSearchByCompany, WarehouseSearch} from "@model/search/warehouse-search";
import {ArticleCategorySearch} from "@model/search/article-category-search";
import { NgIf, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { ArticleStorageConditionSelectionComponent } from '../../../article-storage-condition/article-storage-condition-selection.component';
import { ArticleMultiSelectionComponent } from '../../../article/selection/multi/article-multi-selection.component';
import { DatePickerComponent } from '../../../date/date-picker/date-picker.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from 'primeng/dragdrop';
import { ArticleStorageConditionComponent } from '../../../article-storage-condition/article-storage-condition.component';
import { ArticleComponent } from '../../../article/article.component';
import { InputTextModule } from 'primeng/inputtext';
import { PalletComponent } from '../../../pallet/pallet.component';
import { InternalBatchMultiSelectionComponent } from '@components/internal-batch/selection/multi/internal-batch-multi-selection.component';
import { InternalBatchSearch } from '@model/search/internal-batch-search';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';

type PackageDistributionOptionLabels = { label: string, value: PackageDistributionOption; companyDefault?: boolean };

@Component({
    selector: 'foodbank-setup-preparation-table',
    templateUrl: './setup-preparation-table.component.html',
    styleUrl: './setup-preparation-table.component.scss',
    providers: [DialogService],
    imports: [NgIf, TableModule, PrimeTemplate, ArticleStorageConditionSelectionComponent, ArticleMultiSelectionComponent, DatePickerComponent, TooltipModule, FormsModule, DragDropModule, ArticleStorageConditionComponent, ArticleComponent, InputTextModule, PalletComponent, AsyncPipe, DecimalPipe, DatePipe, InternalBatchMultiSelectionComponent]
})
export class SetupPreparationTableComponent implements AfterViewInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = 15;

  warehouse = input<Warehouse>();
  stockFilter = input<StockSearch>();
  preparation = input<Preparation>();
  fead = input<boolean | undefined>(false);
  tableSizeStyleClass = input(localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '');
  onStockSelect = output<PreparationStockSelection[]>();
  onStockDragStart = output<PreparationStockSelection[]>();
  onStockDragEnd = output();

  emptyMessage?: string;

  pagination: WritableSignal<Pagination>;
  preparationSelectionPage: Signal<Page<PreparationStockSelection> | undefined>;
  refreshTrigger = signal(0);

  selectedPreparationSelections: PreparationStockSelection[] = [];
  selectionEnabled = signal(true);

  tableHeight!: string;
  @ViewChild('tableContainer')
  tableElementRef!: ElementRef;

  savePreparationSelectionSink$: BehaviorSubject<PreparationStockSelection | undefined>;

  selectedArticles = signal<Article[]>([])
  overrideSelectedArticles = overrideSignal(this.selectedArticles);
  selectedArticleCategories = signal<ArticleCategory[]>([]);
  overrideSelectedArticleCategories = overrideSignal(this.selectedArticleCategories);
  selectedArticleStorageConditions = signal<ArticleStorageCondition[]>([]);
  overrideSelectedArticleStorageConditions = overrideSignal(this.selectedArticleStorageConditions);
  selectedBestBeforeDateRange = signal<Date | Date[] | undefined>(undefined);
  overrideSelectedBestBeforeDateRange = overrideSignal(this.selectedBestBeforeDateRange);
  selectedPackageDistribution: Signal<PackageDistributionOption |undefined>;

  // searches for filters
  filterWarehouseSearch: Signal<WarehouseSearch>;
  filterArticleCategorySearch = signal<ArticleCategorySearch | undefined>(undefined)
  filterArticleStorageConditionSearch = signal<ArticleStorageConditionSearch | undefined>(undefined)

  readonly filterArticleSearch: Signal<ArticleSearch>;
  readonly preparationSelectionSearch: Signal<PreparationSelectionSearch>;

  selectedInternalBatches = signal<string[]>([]);
  overrideSelectedInternalBatches = overrideSignal(this.selectedInternalBatches);
  filterInternalBatchSearch: Signal<InternalBatchSearch>;

  fullDescriptionContains = signal<string | undefined>(undefined);

  #injector = inject(Injector)

  constructor(private paginationService: PaginationService,
              private userService: UserService,
              private preparationSelectionService: PreparationSelectionService,
              private messageService: MessageService) {
    this.pagination = this.paginationService.getDefaultPaginationSignal(15);
    const currentUserCompany = this.userService.getCurrentUserCompany();

    this.selectedPackageDistribution = computed(() => this.preparation()?.packageDistributionOption ?? (untracked(() => currentUserCompany())!.allocationByPackage ? 'PARCEL' : 'UNIT'));
    this.preparationSelectionPage = this.loadPage$();

    this.savePreparationSelectionSink$ = new BehaviorSubject<PreparationStockSelection | undefined>(undefined);
    this.savePreparationSelectionSink$.pipe(
      filter(value => !!value),
      debounceTime(500),
      switchMap(value => this.preparationSelectionService.savePreparationSelection$(value!, this.#injector)),
    ).subscribe({
      next: value => console.log('savePreparationSelection', value),
      error: error => this.messageService.add({severity: 'error', summary: 'Error', detail: 'Error saving preparation selection'})
    });

    const fullDescriptionContains$ = toObservable(this.fullDescriptionContains);
    const debouncedFullDescriptionContains$ = fullDescriptionContains$.pipe(debounceTime(500));
    const debouncedFullDescriptionContains = toSignal(debouncedFullDescriptionContains$);

    const stockSearch = computed(() => ({
      ...this.stockFilter(),
      minBestBeforeDate: this.getMinBestBeforeDate(this.selectedBestBeforeDateRange()),
      maxBestBeforeDate: this.getMaxBestBeforeDate(this.selectedBestBeforeDateRange()),
      articleSearch: {
        articles: this.overrideSelectedArticles(),
        articleCategorySearch: {
          articleCategories: this.overrideSelectedArticleCategories()
        },
        articleStorageConditionSearch: {
          articleStorageConditions: this.overrideSelectedArticleStorageConditions()
        },
        fead: this.fead()
      },
      internalBatchSearch: {
        internalBatches: this.overrideSelectedInternalBatches()
      },
      fullDescriptionContains: debouncedFullDescriptionContains()
    }));

    this.preparationSelectionSearch = computed(() => {
      return {
        stockSearch: stockSearch(),
        preparation: this.preparation()
      }
    });

    this.filterInternalBatchSearch = computed(() => ({
      stockSearch: {
        ...stockSearch(),
        internalBatchSearch: {},
      },
      preparation: this.preparation()
    }));

    this.filterWarehouseSearch = computed(() => {
      return activeWarehouseSearchByCompany(currentUserCompany()!);
    });

    this.filterArticleSearch = computed(() => ({
      stockSearch: {
        ...stockSearch(),
        warehouseSearch: this.filterWarehouseSearch()
      },
      articleCategorySearch: this.filterArticleCategorySearch(),
      articleStorageConditionSearch: this.filterArticleStorageConditionSearch(),
      fead: this.fead(),
      preparation: this.preparation()
    }));
  }

  private loadPage$(): Signal<Page<PreparationStockSelection> | undefined> {
    const triggers: Signal<[Pagination, PreparationSelectionSearch, number]> = computed(() => [
      this.pagination(),
      this.preparationSelectionSearch(),
      this.refreshTrigger()
    ]);

    return pipeSignal(triggers,
      pipe(
        debounceTime(10),
        switchMap(([pagination, preparationSelectionSearch, _]) => this.preparationSelectionService.find(preparationSelectionSearch, pagination, this.#injector)),
        map(page => ({
          ...page,
          content: page.content.map(preparationSelection => this.mapToPreparationSelectionItem(preparationSelection))
        }) as Page<PreparationStockSelection>)
    ));
  }

  ngAfterViewInit() {
    this.updateTableHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableHeight();
  }

  updateTableHeight() {
    const tableOffsetTop = this.tableElementRef?.nativeElement?.getBoundingClientRect()?.top || 289;
    const windowHeight = window.innerHeight;
    // FIXME: can't actually use tableOffsetTop: it gets weird number after tab gets clicked, ok after page resize
    // const availableHeight = windowHeight - tableOffsetTop - 250; // Adjust for footer
    const availableHeight = windowHeight - 289 - 250; // Adjust for footer
    this.tableHeight = `${availableHeight}px`;
  }

  identity(stock: any): PreparationSelectionItem {
    return stock;
  }

  stockIdentity(stock: any): Stock {
    return stock;
  }

  loadPreparationSelectionList(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  onTakenQuantityChanged(preparationSelectionItem: PreparationSelectionItem) {
    preparationSelectionItem.stock$.pipe(take(1))
      .subscribe(stock => {
        const dispatchWeight = preparationSelectionItem.dispatchWeight || 0;
        if (stock.unitWeight <= 0) {
          preparationSelectionItem.dispatchUnitCount = 0;
          preparationSelectionItem.dispatchParcelCount = 0;
        } else {
          preparationSelectionItem.dispatchUnitCount = dispatchWeight * 1000 / stock.unitWeight;
          preparationSelectionItem.dispatchParcelCount = preparationSelectionItem.dispatchUnitCount / stock.unitsPerParcel;
        }
        preparationSelectionItem.valid$ = this.validTakenValues(preparationSelectionItem);
        this.savePreparationSelection(preparationSelectionItem);
      })
  }

  onTakenUnitsChanged(preparationSelection: PreparationSelectionItem) {
    preparationSelection.stock$.pipe(take(1))
      .subscribe(stock => {
        const dispatchUnitCount = preparationSelection.dispatchUnitCount || 0;
        preparationSelection.dispatchParcelCount = dispatchUnitCount / stock.unitsPerParcel;
        preparationSelection.dispatchWeight = dispatchUnitCount * stock.unitWeight / 1000;
        preparationSelection.valid$ = this.validTakenValues(preparationSelection);
        this.savePreparationSelection(preparationSelection);
      })
  }

  onTakenParcelsChanged(stockPreparationItem: PreparationSelectionItem) {
    stockPreparationItem.stock$.pipe(take(1))
      .subscribe(stock => {
        const dispatchParcelCount = stockPreparationItem.dispatchParcelCount || 0;
        stockPreparationItem.dispatchUnitCount = dispatchParcelCount * stock.unitsPerParcel;
        stockPreparationItem.dispatchWeight = stockPreparationItem.dispatchUnitCount * stock.unitWeight / 1000;
        stockPreparationItem.valid$ = this.validTakenValues(stockPreparationItem);
        this.savePreparationSelection(stockPreparationItem);
      })
  }

  private savePreparationSelection(preparationSelection: PreparationStockSelection) {
    this.savePreparationSelectionSink$.next(preparationSelection);
  }

  resetValues() {
    this.refreshTrigger.update(i => i + 1);
  }

  isInteger(value?: number) {
    return Number.isInteger(value);
  }

  validTakenValues(preparationSelection: PreparationStockSelection) {
    return preparationSelection.stock$.pipe(
      take(1),
      map((stock) => {
        if (this.selectedPackageDistribution() === 'UNIT' && !this.isInteger(preparationSelection.dispatchUnitCount) && stock.unitWeight > 0) {
          return false;
        }

        if (this.selectedPackageDistribution() === 'PARCEL' && !this.isInteger(preparationSelection.dispatchParcelCount) && stock.unitWeight > 0) {
          return false;
        }

        const dispatchWeight = preparationSelection.dispatchWeight || 0;
        return dispatchWeight > 0 && dispatchWeight <= stock.quantity;
      })
    );
  }

  isStockDraggable(stock: PreparationStockSelection): boolean {
    if (!this.selectionEnabled()) {
      return false;
    }

    return !this.selectedPreparationSelections || this.selectedPreparationSelections.length === 0 || this.selectedPreparationSelections.includes(stock);
  }


  notifyStockDragStart(event: DragEvent, stock: PreparationStockSelection) {
    const value = this.selectedPreparationSelections.length === 0
      ? [stock]
      : this.selectedPreparationSelections;
    this.onStockDragStart.emit(value);

    const selectedPallets = this.selectedPreparationSelections.length === 0 ? 1 : this.selectedPreparationSelections.length
    const text = `Moving ${selectedPallets} pallet${selectedPallets > 1 ? 's' : ''}`;

    createSetAndRemoveDragImage(event, text);
  }
  notifyStockDragEnd(event: DragEvent) {
    if (event.dataTransfer && event.dataTransfer.dropEffect !== 'none') {
      this.selectedPreparationSelections = [];
    }

    this.onStockDragEnd.emit();
  }

  notifySelectionChange(stock: PreparationStockSelection[]) {
    this.selectedPreparationSelections = stock;
    this.onStockSelect.emit(stock);
  }

  private mapToPreparationSelectionItem(preparationSelection: PreparationStockSelection): PreparationSelectionItem {
    return {
      ...preparationSelection,
      valid$: this.validTakenValues(preparationSelection)
    };
  }

  private getMinBestBeforeDate(selectedBestBeforeDateRange?: Date | Date[]): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange[0];
    }

    return undefined;
  }

  private getMaxBestBeforeDate(selectedBestBeforeDateRange?: Date | Date[]): Date | undefined {
    if (Array.isArray(selectedBestBeforeDateRange)) {
      return selectedBestBeforeDateRange.length === 2 ? selectedBestBeforeDateRange[1] : undefined;
    }

    return selectedBestBeforeDateRange;
  }
}
