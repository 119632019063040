import { Component, computed, inject, input, model, output, Signal } from '@angular/core';
import { Organization } from '@model/organization';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { UserService } from "@services/user.service";
import { Company } from "@model/company";

export type PeriodUpdatedEvent = {
  previousValue: string,
  newValue: string
}

@Component({
    selector: 'foodbank-fead-planning-period-cell-editor',
    templateUrl: './fead-planning-period-cell-editor.component.html',
    styleUrls: ['./fead-planning-period-cell-editor.component.scss'],
    imports: [FormsModule, InputTextModule, TooltipModule, ButtonModule],
    standalone: true
})
export class FeadPlanningPeriodCellEditorComponent {
  period = model.required<string>();
  organization = input<Organization | undefined>();

  editing = false;
  tempValue = model<string>('')
  previousValue: string = '';

  isPreferredWeekForDelivery = computed(() => this.period() && this.organization() ? this.organization()!.preferredWeekForDelivery(this.period()) : true);
  preferredSupplyWeek = computed(() => this.organization()?.preferredSupplyWeek);
  isWaitingColumnAllowed: Signal<boolean>;
  company: Signal<Company | undefined>;
  isTempValueValid = computed(() => this.isValidValue(this.tempValue(), this.company()))

  periodUpdated = output<PeriodUpdatedEvent>();

  #userService = inject(UserService);

  constructor() {
    this.company = this.#userService.getCurrentUserCompany();
    this.isWaitingColumnAllowed = computed(() => this.company()?.waitingColumn ?? true);
  }

  startEditing(): void {
    this.editing = true;
    this.previousValue = this.period();
    this.tempValue.set(this.period());
  }

  cancelEditing(): void {
    this.editing = false;
    // Discard changes and revert to previous value
    this.tempValue.set(this.previousValue);
  }

  saveEditing(): void {
    if (this.isTempValueValid()) {
      const newValue = this.tempValue();
      // Only emit if there was an actual change
      if (newValue !== this.previousValue) {
        this.periodUpdated.emit({
          previousValue: this.previousValue,
          newValue: newValue
        });
        this.period.set(newValue);
      }
      this.editing = false;
    }
  }

  isValidValue(value: string, company: Company | undefined): boolean {
    if (value === 'ONHOLD') {
      return this.isWaitingColumnAllowed();
    }

    const pattern = company?.dailyFeadPlanning ? /^\d{4}\/\d{2}\/\d{2}$/ : /^\d{4}\/\d{2}$/;
    return value !== undefined && value !== null && value !== '' && pattern.test(value);
  }
}
