import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FeadPlanningByArticle} from '@model/fead-planning-by-article';
import {Organization} from '@model/organization';
import { NgIf, NgFor, NgClass, AsyncPipe, DecimalPipe } from '@angular/common';
import { TableModule } from 'primeng/table';
import { PrimeTemplate } from 'primeng/api';
import { Button } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ArticleComponent } from '../../../article/article.component';
import { FeadPlanningQuantityCellEditorComponent } from '../../fead-planning-quantity-cell-editor/fead-planning-quantity-cell-editor.component';
import { FeadPlanningPeriodCellEditorComponent, PeriodUpdatedEvent } from "@components/fead-planning/fead-planning-period-cell-editor/fead-planning-period-cell-editor.component";

interface FeadOrganizationPlanningEntrySummary {
  saldo: number;
  total: number;
}

interface FeadOrganizationPlanningEntryWithSummary extends FeadPlanningByArticle {
  summary: FeadOrganizationPlanningEntrySummary;
}

interface FeadOrganizationPlanningSummary {
  plannedQuantity: number;
  shippedQuantity: number;
  total: number;
  saldo: number;
  periodQuantityMap: { [index: string]: number };
}

@Component({
    selector: 'foodbank-fead-planning-organization-table',
    templateUrl: './fead-planning-organization-table.component.html',
    styleUrls: ['./fead-planning-organization-table.component.scss'],
    imports: [NgIf, TableModule, PrimeTemplate, NgFor, Button, FormsModule, InputTextModule, ArticleComponent, NgClass, FeadPlanningQuantityCellEditorComponent, AsyncPipe, DecimalPipe, FeadPlanningPeriodCellEditorComponent]
})
export class FeadPlanningOrganizationTableComponent implements OnChanges {

  @Input()
  planningData!: FeadPlanningByArticle[];
  @Input()
  organization!: Organization;
  @Input()
  year!: number;
  @Output()
  dataChange = new EventEmitter<FeadPlanningByArticle[]>();

  periods: string[] = [];
  entryWithSummaryList: FeadOrganizationPlanningEntryWithSummary[] = [];
  generalSummary: FeadOrganizationPlanningSummary | undefined;

  constructor() {
  }

  ngOnChanges(): void {
    this.load();
  }

  updateEntryPeriodQuantity(feadOrganizationPlanningEntryWithSummary: FeadOrganizationPlanningEntryWithSummary, period: string, quantity: number) {
    feadOrganizationPlanningEntryWithSummary.periodQuantityMap[period] = quantity;
    feadOrganizationPlanningEntryWithSummary.summary = this.getSummary(feadOrganizationPlanningEntryWithSummary);
    this.calcGeneralSummary();
    this.dataChange.emit(this.entryWithSummaryList);
  }

  addPeriod() {
    const newPeriod = this.year + '/';
    if (!this.periods.includes(newPeriod)) {
      this.periods.push(newPeriod);
      this.periods.sort(this.comparePeriods);
    }
  }

  onPeriodUpdated(event: PeriodUpdatedEvent) {
    const { previousValue, newValue } = event;
    const existingPeriodIndex = this.periods.findIndex(period => period === previousValue);
    
    if (existingPeriodIndex >= 0) {
      // Update the period in the periods array
      this.periods[existingPeriodIndex] = newValue;
      
      // Update all entries' periodQuantityMap keys
      this.entryWithSummaryList.forEach(entry => {
        if (previousValue in entry.periodQuantityMap) {
          const value = entry.periodQuantityMap[previousValue];
          entry.periodQuantityMap[newValue] = value;
          delete entry.periodQuantityMap[previousValue];
        }
      });
      
      // Update the general summary periodQuantityMap
      if (this.generalSummary && previousValue in this.generalSummary.periodQuantityMap) {
        const value = this.generalSummary.periodQuantityMap[previousValue];
        this.generalSummary.periodQuantityMap[newValue] = value;
        delete this.generalSummary.periodQuantityMap[previousValue];
      }
      
      // Resort periods
      this.periods.sort(this.comparePeriods);
      
      // Emit changes
      this.dataChange.emit(this.entryWithSummaryList);
    }
  }

  private load() {
    this.getPeriods();
    this.getSummaries();
    this.calcGeneralSummary();
  }

  private getPeriods() {
    const rawPeriods = this.planningData
      .map(entry => Object.keys(entry.periodQuantityMap))
      .flat()
      .reduce((periods: string[], period: string) => [...periods, period], [])
      .sort(this.comparePeriods);
    this.periods = Array.from(new Set(rawPeriods));
  }

  private getSummaries() {
    this.entryWithSummaryList = this.planningData.map(entry => this.getFeadOrganizationPlanningEntrySummary(entry));
  }

  private calcGeneralSummary() {
    this.generalSummary = this.entryWithSummaryList
      .reduce((currentSummary, entryWithSummary) => this.summarizeEntry(currentSummary, entryWithSummary), this.createInitialSummary());
  }

  private createInitialSummary(): FeadOrganizationPlanningSummary {
    return {
      plannedQuantity: 0,
      shippedQuantity: 0,
      total: 0,
      saldo: 0,
      periodQuantityMap: {},
    };
  }

  private summarizeEntry(summary: FeadOrganizationPlanningSummary, entry: FeadOrganizationPlanningEntryWithSummary): FeadOrganizationPlanningSummary {
    summary.plannedQuantity += entry.plannedQuantity;
    summary.shippedQuantity += entry.shippedQuantity;

    summary.total += entry.summary.total;
    summary.saldo += entry.summary.saldo;

    for (const period in entry.periodQuantityMap) {
      let summaryPeriodQuantity = summary.periodQuantityMap[period] || 0;
      summaryPeriodQuantity += entry.periodQuantityMap[period];
      summary.periodQuantityMap[period] = summaryPeriodQuantity;
    }

    return summary;
  }

  private getFeadOrganizationPlanningEntrySummary(feadOrganizationPlanningEntry: FeadPlanningByArticle): FeadOrganizationPlanningEntryWithSummary {
    return {
      ...feadOrganizationPlanningEntry,
      summary: this.getSummary(feadOrganizationPlanningEntry)
    };
  }

  private getSummary(feadOrganizationPlanningEntry: FeadPlanningByArticle): FeadOrganizationPlanningEntrySummary {
    const total = this.getTotal(feadOrganizationPlanningEntry);
    return {
      saldo: feadOrganizationPlanningEntry.plannedQuantity - total,
      total: total
    }
  }

  private getTotal(feadOrganizationPlanningEntry: FeadPlanningByArticle): number {
    const periodQuantityMap = feadOrganizationPlanningEntry.periodQuantityMap;
    const quantitySum = Object.values(periodQuantityMap)
      .reduce((runningSum, quantity) => runningSum + quantity, 0);
    const shippedQuantity = feadOrganizationPlanningEntry.shippedQuantity;
    return quantitySum + shippedQuantity;
  }

  private comparePeriods(period1: string, period2: string): number {
    // extract year and week from the strings
    const [year1, week1] = period1.split("/");
    const [year2, week2] = period2.split("/");

    // compare years
    const yearComparison = parseInt(year1) - parseInt(year2);

    if (yearComparison!==0) {
      // years are different, done
      return yearComparison;
    }

    // compare weeks
    return parseInt(week1) - parseInt(week2);
  }
}
