<p-dropdown [(ngModel)]="ngModel"
            [options]="organizationCategories"
            optionLabel="category"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-organization-category ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-organizationCategory pTemplate="item">
    <foodbank-organization-category ngDefaultControl [ngModel]="organizationCategory" />
  </ng-template>
</p-dropdown>
