import {Component, computed, inject, Injector, model, Resource, signal, Signal, WritableSignal} from '@angular/core'
import {AuditChange} from '@model/audit-change';
import {AuditChangeService} from '@services/audit-change.service';
import {UserService} from "@services/user.service";
import {AuditChangeSearch} from "@model/search/audit-change-search";
import {Pagination} from "@services/pagination";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {Page} from "@typedefs/page";
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {MultiSelectModule} from "primeng/multiselect";
import {rxResource} from "@angular/core/rxjs-interop";
import {Organization} from "@model/organization";
import {OrganizationSearch} from "@model/search/organization-search";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {formatDate, NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {DateSearchComponent} from "@components/date/date-search/date-search.component";
import {DateSearch} from "@model/search/date-search";
import {UserSelectionComponent} from "@components/users/selection/user-selection.component";
import {UserS} from "@model/user";
import {UserSearch} from "@model/search/user-search";
import {DbChangeAction, DbChangeEntity} from "@typedefs/stock-rest";
import {AuditChangeActionSelectionComponent} from "@components/audit-change-action/selection/audit-change-action-selection.component";
import {AuditChangeEntitySelectionComponent} from "@components/audit-change-entity/selection/audit-change-entity-selection.component";

@Component({
  selector: 'foodbank-audit-changes',
  templateUrl: './audit-change-list.component.html',
  styleUrls: ['./audit-change-list.component.scss'],

  imports: [TableModule, InputTextModule, ButtonModule, MultiSelectModule, OrganizationSingleSelectionComponent, NgIf, PaginatorModule, DateSearchComponent, UserSelectionComponent, AuditChangeActionSelectionComponent, AuditChangeEntitySelectionComponent]
})
export class AuditChangeListComponent {

  // selected search filters for this view
  filterOrganization = model<Organization>();
  filterUser = model<UserS>();
  filterAction = model<DbChangeAction>();
  filterEntity = model<DbChangeEntity>();
  filterEntityKeyContains = model('');

  // searches for filters (e.g. user)
  userSearch: Signal<UserSearch>;

  // searches for filters (e.g. organization)
  organizationSearch: Signal<OrganizationSearch>;
  // this view search
  auditChangeSearch: Signal<AuditChangeSearch>;

  auditChangeDateSearch = signal<DateSearch>({});
  pagination: WritableSignal<Pagination>;

  // results
  auditChangePage: Resource<Page<AuditChange> | undefined>;

  // services
  #auditChangeService = inject(AuditChangeService);
  #paginationService = inject(PaginationService);
  #userService = inject(UserService);
  #injector = inject(Injector);

  constructor() {
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.organizationSearch = computed(() => ({
      company: currentUserCompany(),
    }));
    this.userSearch = computed(() => ({
      company: currentUserCompany(),
    }));

    this.auditChangeSearch = computed(() => ({
      company: currentUserCompany(),
      organization: this.filterOrganization(),
      user: this.filterUser(),
      dateSearchDto: this.auditChangeDateSearch(),
      action: this.filterAction(),
      entity: this.filterEntity(),
      entityKeyContains: this.filterEntityKeyContains(),
    }));

    this.pagination = this.#paginationService.getDefaultPaginationSignal(DEFAULT_ROWS_PER_PAGE);

    this.auditChangePage = rxResource({
      request: () => ({
        auditChangeSearch: this.auditChangeSearch(),
        pagination: this.pagination(),
      }),
      loader: param => this.#auditChangeService.findAuditChanges$(this.auditChangeSearch(), this.#injector, this.pagination())
    });
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  identity(auditChange: AuditChange): AuditChange {
    return auditChange;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  protected readonly formatDate = formatDate;
}






