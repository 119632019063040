<div class="card p-fluid" *ngIf="user() as user">
  <form #userForm="ngForm" style="padding: 1em">
    <div class="field grid">
      <label for="id" class="col-12 mb-2 md:col-2 md:mb-0" i18n> User Id</label>
      <div class="col-12 md:col-10">
        <input #myiduser="ngModel" pInputText id="id" name="id"   required [disabled] ="!booIsCreate" minlength="3" maxlength="60" [(ngModel)]="user.id" />
        <p-message i18n-text="@@UserLoginIsMandatory" *ngIf="myiduser.invalid && userForm.touched" severity="error" text="User Login is required and must be minimum 3 chars long"></p-message>
      </div>
    </div>
    <div class="field grid">
      <label for="user-active" class="col-12 mb-2 md:col-2 md:mb-0">Active?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="user-active" name="user-active" [(ngModel)]="user.active"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label for="organization" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Organization</label>
      <div class="col-12 md:col-10">
        <foodbank-organization-selection name="organization" ngDefaultControl id="organization" [(ngModel)]="user.organization.value"/>
      </div>
    </div>
    <div class="field grid">
      <label for="member" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Member</label>
      <div class="col-12 md:col-10">
        <foodbank-member-selection   #mymember="ngModel" name="member"  [required]="true" ngDefaultControl id="member" [(ngModel)]="user.member.value" />
        <p-message i18n-text="@@MemberIsMandatory" *ngIf="mymember.invalid && userForm.touched" severity="error" text="Member Selection is required"></p-message>
      </div>
    </div>
    <div *ngIf="booIsCreate" class="field grid">
      <label i18n="@@UserPassword" for="password"  class="col-12 mb-2 md:col-2 md:mb-0" i18n>Password</label>
      <div class="col-12 md:col-10">

          <input #mypassword="ngModel" pInputText id="password" name="password"  required pattern ="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,20}$" [(ngModel)]="user.password" />
          <p-message i18n-text="@@UserPasswordIsMandatory" *ngIf="mypassword.invalid && userForm.touched" severity="error" text="Password must contain at least a uppercase and a lowercase letter, a number and contain 8 to 20 characters"></p-message>
        </div>
      </div>
    <div class="field grid">
      <label i18n="@@UserRights" for="rights" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Rights</label>
      <div class="col-12 md:col-10">
        <foodbank-user-role-selection name="rights" ngDefaultControl id="rights" [(ngModel)]="user.userRole"/>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserRightsManageOrgs" for="organizationManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Manage Orgs ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="organizationManagement" name="organizationManagement" [(ngModel)]="user.organizationManagement"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserRightsManageOCMW" for="cpasManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Manage Cpas/OCMW ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="cpasManagement" name="cpasManagement" [(ngModel)]="user.cpasManagement"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserRightsManageGifts" for="donationManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Manage Gifts ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="donationManagement" name="donationManagement" [(ngModel)]="user.donationManagement"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserRightsManageStock" for="feadManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Manage ESF+ Stock ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="feadManagement" name="feadManagement" [(ngModel)]="user.feadManagement"></p-inputSwitch>
      </div>
    </div>
    <div  class="field grid">
      <label i18n="@@UserRightsManageBeneficiaries" for="beneficiaryManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Managing Beneficiaries ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="beneficiaryManagement" name="beneficiaryManagement" [(ngModel)]="user.beneficiaryManagement"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserRightsManageMembers" for="memberManagement" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Managing Members ?</label>
      <div class="col-12 md:col-10">
        <p-inputSwitch id="memberManagement" name="memberManagement" [(ngModel)]="user.memberManagement"></p-inputSwitch>
      </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserWarehouse" for="warehouse" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Warehouse ?</label>
      <div class="col-12 md:col-10">
      <ng-container *ngIf="warehouseSearch() as warehouseSearch">
        <foodbank-warehouse-selection
          ngDefaultControl
          id="warehouse"
          name="warehouse"
          [(ngModel)]="user.warehouse.value"
          [warehouseSearch]="warehouseSearch"
        />
      </ng-container>
    </div>
    </div>
    <div class="field grid">
      <label i18n="@@UserCpas" for="cpas" class="col-12 mb-2 md:col-2 md:mb-0" i18n>Cpas ?</label>
      <div class="col-12 md:col-10">
        <ng-container *ngIf="cpasSearch() as cpasSearch">
          <foodbank-cpas-selection
            ngDefaultControl
            id="cpas"
            name="cpas"
            [(ngModel)]="user.cpas.value"
            [cpasSearch]="cpasSearch"
          />
        </ng-container>
      </div>
    </div>

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" [disabled] = "userForm.invalid" (onClick)="save(user)"/>
      </div>
      <div class="col-3">
        <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </form>
</div>


