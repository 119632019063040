import { Component, effect, input, model } from "@angular/core";
import { BankProg } from "@model/bankprog";
import { FormsModule } from "@angular/forms";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";

@Component({
    selector: 'foodbank-bank-edit-bank-program-tab',
    templateUrl: './bank-edit-bank-program-tab.component.html',
    imports: [FormsModule, InputSwitchModule, InputTextModule]
})
export class BankEditBankProgramTabComponent {

    bankProg = model.required<BankProg | undefined>();

    constructor() {
        effect(() => {
            console.log('bankProg:', this.bankProg());
        });
    }
}
