@if(bankProg()!) {
<div class="field grid">
    <label for="membershipYearlyFeeRequested" class="col-12 mb-2 md:col-2 md:mb-0">Yearly Fee Requested</label>
    <div class="col-12 md:col-10">
        <p-inputSwitch id="membershipYearlyFeeRequested" name="membershipYearlyFeeRequested"
            [(ngModel)]="bankProg()!.membershipYearlyFeeRequested"></p-inputSwitch>
    </div>
</div>

<div class="field grid">
    <label for="membershipYearlyFee" class="col-12 mb-2 md:col-2 md:mb-0">Yearly Fee</label>
    <div class="col-12 md-10">
        <p-inputNumber [(ngModel)]="bankProg()!.membershipYearlyFee" id="membershipYearlyFee" name="membershipYearlyFee"
            mode="decimal" [showButtons]="true" inputId="membershipYearlyFee" [min]="0">
        </p-inputNumber>
    </div>
</div>
<div class="field grid">
    <label for="additionalFeeRequested" class="col-12 mb-2 md:col-2 md:mb-0"> Additional Fee Requested</label>
    <div class="col-12 md:col-10">
        <p-inputSwitch id="additionalFeeRequested" name="additionalFeeRequested"
            [(ngModel)]="bankProg()!.membershipAdditionalFeeRequested"></p-inputSwitch>
    </div>
</div>
<div class="field grid">
    <label for="membershipAdditionalFee" class="col-12 mb-2 md:col-2 md:mb-0">Additional Fee</label>
    <div class="col-12 md-10">
        <p-inputNumber [(ngModel)]="bankProg()!.membershipAdditionalFee" id="membershipAdditionalFee"
            name="membershipAdditionalFee" mode="decimal" [showButtons]="true" inputId="membershipAdditionalFee"
            [min]="0">
        </p-inputNumber>
    </div>
</div>
<div class="field grid">
    <label for="membershipMailingTextIsCustom" class="col-12 mb-2 md:col-2 md:mb-0">Custom Mailing Text?</label>
    <div class="col-12 md:col-10">
        <p-inputSwitch id="membershipMailingTextIsCustom" name="membershipMailingTextIsCustom" [(ngModel)]="bankProg()!.membershipMailingTextIsCustom"></p-inputSwitch>
    </div>
</div>
<div *ngIf="bankProg()!.membershipMailingTextIsCustom" class="field grid">
    <label for="mailingTextFr" class="col-12 mb-2 md:col-2 md:mb-0">Membership Mailing Text Fr</label>
    <div class="col-12 md:col-10">
        <p-editor [(ngModel)]="mailingTextFr" i18n-placeholder="@@EnterYourMemberShipText"
            placeholder="Enter your MemberShip Text" name="mailingTextFr" id="mailingTextFr"
            [style]="{'height':'320px'}">
            <p-header>
                <span class="ql-formats">
                    <select class="ql-header" tabindex="-1">
                        <option value="1" tabindex="-1">Heading</option>
                        <option value="2" tabindex="-1">Subheading</option>
                        <option selected tabindex="-1">Normal</option>
                    </select>
                    <select class="ql-font" tabindex="-1">
                        <option selected tabindex="-1">Sans Serif</option>
                        <option value="serif" tabindex="-1">Serif</option>
                        <option value="monospace" tabindex="-1">Monospace</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold" tabindex="-1"></button>
                    <button class="ql-italic" aria-label="Italic" tabindex="-1"></button>
                    <button class="ql-underline" aria-label="Underline" tabindex="-1"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color" tabindex="-1"></select>
                    <select class="ql-background" tabindex="-1"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                    <select class="ql-align" tabindex="-1">
                        <option selected tabindex="-1"></option>
                        <option value="center" tabindex="-1"></option>
                        <option value="right" tabindex="-1"></option>
                        <option value="justify" tabindex="-1"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Insert Link" tabindex="-1"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                </span>
            </p-header>
        </p-editor>
    </div>
</div>
<div *ngIf="bankProg()!.membershipMailingTextIsCustom" class="field grid">
    <label for="mailingTextNl" class="col-12 mb-2 md:col-2 md:mb-0">Membership Mailing Text Nl</label>
    <div class="col-12 md-10">
        <p-editor [(ngModel)]="mailingTextNl" i18n-placeholder="@@EnterYourMemberShipText"
            placeholder="Enter your MemberShip Text" name="mailingTextNl" id="mailingTextNl"
            [style]="{'height':'320px'}">
            <p-header>
                <span class="ql-formats">
                    <select class="ql-header" tabindex="-1">
                        <option value="1" tabindex="-1">Heading</option>
                        <option value="2" tabindex="-1">Subheading</option>
                        <option selected tabindex="-1">Normal</option>
                    </select>
                    <select class="ql-font" tabindex="-1">
                        <option selected tabindex="-1">Sans Serif</option>
                        <option value="serif" tabindex="-1">Serif</option>
                        <option value="monospace" tabindex="-1">Monospace</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-bold" aria-label="Bold" tabindex="-1"></button>
                    <button class="ql-italic" aria-label="Italic" tabindex="-1"></button>
                    <button class="ql-underline" aria-label="Underline" tabindex="-1"></button>
                </span>
                <span class="ql-formats">
                    <select class="ql-color" tabindex="-1"></select>
                    <select class="ql-background" tabindex="-1"></select>
                </span>
                <span class="ql-formats">
                    <button class="ql-list" value="ordered" aria-label="Ordered List" tabindex="-1"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List" tabindex="-1"></button>
                    <select class="ql-align" tabindex="-1">
                        <option selected tabindex="-1"></option>
                        <option value="center" tabindex="-1"></option>
                        <option value="right" tabindex="-1"></option>
                        <option value="justify" tabindex="-1"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button class="ql-link" aria-label="Insert Link" tabindex="-1"></button>
                </span>
                <span class="ql-formats">
                    <button class="ql-clean" aria-label="Remove Styles" tabindex="-1"></button>
                </span>
            </p-header>
        </p-editor>

    </div>
</div>
}