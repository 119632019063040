import { Component, computed, inject, Injector, model, Signal } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Bank } from '@model/bank';
import { BankService } from '@services/bank.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Button } from 'primeng/button';
import { MessageService } from "primeng/api";
import { MessageModule } from "primeng/message";
import { DropdownModule } from "primeng/dropdown";
import { MemberSearch } from "@model/search/member-search";
import { UserService } from "@services/user.service";
import { AuditChangeService } from "@services/audit-change.service";
import { AuditChangeCreationBase } from "@model/audit-change";
import { UserSignalService } from "@services/user-signal.service";
import { MemberSelectionComponent } from "@components/member/selection/single/member-selection.component";
import { RegionSelectionComponent } from "@components/region/selection/region-selection.component";
import { TabViewModule } from "primeng/tabview";
import { BankProg } from "@model/bankprog";
import { BankProgService } from "@services/bankprog.service";
import { EditorModule } from "primeng/editor";
import { InputNumberModule } from "primeng/inputnumber";
import { mergeMap, Observable, of } from "rxjs";
import { BankEditBankProgramTabComponent } from './bank-edit-bank-program-tab/bank-edit-bank-program-tab.component';
import { BankEditMembershipTabComponent } from './bank-edit-membership-tab/bank-edit-membership-tab.component';
@Component({
  selector: 'foodbank-bank-edit',
  templateUrl: './bank-edit.component.html',
  styleUrls: ['./bank-edit.component.scss'],
  imports: [NgIf, FormsModule, InputTextModule, InputSwitchModule, Button, MessageModule, DropdownModule, EditorModule,
    MemberSelectionComponent, MemberSelectionComponent, RegionSelectionComponent, TabViewModule, EditorModule, InputNumberModule,
    BankEditBankProgramTabComponent, BankEditMembershipTabComponent
  ]
})
export class BankEditComponent {

  bank = model<Bank>();

  ref = inject(DynamicDialogRef);
  config = inject(DynamicDialogConfig<Bank>);
  #bankService = inject(BankService);
  #bankProgService = inject(BankProgService);
  #messageService = inject(MessageService);
  #userService = inject(UserService);
  #userSignalService = inject(UserSignalService);
  #auditChangeService = inject(AuditChangeService);
  #injector = inject(Injector);

  memberSearch: Signal<MemberSearch>;


  constructor() {
    this.bank.set(this.config.data);
    
    const currentUserCompany = this.#userService.getCurrentUserCompany();
    this.memberSearch = computed(() => ({
      company: currentUserCompany(),
    }));

  }

  save(bank: Bank, bankProg: BankProg | undefined) {

    const currentUser = this.#userSignalService.$$getCurrentUser(this.#injector);
    const currentUserOrg = this.#userSignalService.$$getCurrentUserOrganization(this.#injector);
    const currentUserCompany = this.#userSignalService.$$getCurrentUserCompany(this.#injector);
    const auditChange: AuditChangeCreationBase = {
      company: currentUserCompany,
      organization: currentUserOrg,
      user: currentUser,
      entity: "BANK",
      entityKey: bank.companyId,
      action: "UPDATE",
    }
    
    this.saveBankProg(bankProg).pipe(
      mergeMap(_ => this.#bankService.updateBank(bank))
    ).subscribe(bank => {
      this.bank.set(bank);
      this.#messageService.add({ severity: 'success', summary: 'Success', detail: `Bank ${bank.companyId}  UPDATE Successful` });
      this.ref.close(bank);
      this.#auditChangeService.createAuditChange(auditChange).subscribe();
    });
  }

  saveBankProg(bankProg: BankProg | undefined): Observable<BankProg | undefined> {
    return bankProg ? this.#bankProgService.updateBankProg(bankProg) : of(undefined);
  }

  close() {
    this.ref.close({});
  }

  getCotMailingRecommendationsfr() {
    return `Choisissez la langue du texte que vous voulez adapter avec les boutons radio Français - Nederlands. \n
        Commencez ou Recommencez par charger le texte par défaut avec le bouton "Charger le texte par défaut". \n
        Modifiez le texte à votre guise mais veillez à ne rien changer dans les valeurs entre {{ }} . \n
        Les valeurs entre {{ }} sont des valeurs qui seront remplacées lors de la création des mails pour une association. \n
        Si vous modifiez le texte entre {{ }} la subsitution n'aura plus lieu. \n
        Si vous ne tenez pas à afficher une valeur de substitution vous pouvez enlever cette valeur entre {{ }} du texte. \n
        Enregistrez le texte avec le bouton "Sauver".` ;
  }
  getCotMailingRecommendationsnl() {
    return `Kies de taal van de tekst die u wilt aanpassen met de radioknoppen Frans - Nederlands. \n
        Begin of herlaad de standaard tekst met de knop "Laad de standaard tekst". \n
        Pas de tekst naar eigen inzicht aan, maar zorg ervoor dat u niets verandert tussen {{ }} . \n
        De waarden tussen {{ }} zijn waarden die worden vervangen bij het maken van de e-mails voor een vereniging. \n
        Als u de tekst tussen {{ }} wijzigt, vindt de vervanging niet meer plaats. \n
        Als u geen waarde voor vervanging wilt weergeven, kunt u deze waarde tussen {{ }} uit de tekst verwijderen. \n
        Sla de tekst op met de knop "Opslaan"`;
  }

}

