@let page = transferRequestItemGroupWithTotalPage();
@let transferRequestItemGroupWithTotalList = page?.content || [];

<p-table #dt
         [value]="transferRequestItemGroupWithTotalList"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="page?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoadEvent($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="single"
         [multiSortMeta]="[{ field: sortFieldName('ARTICLE'), order: 1 }, { field: sortFieldName('RECEPTION_ID'), order: 1 }]"
         [scrollable]="true"
         [scrollHeight]="'calc(100vh - 350px)'"
>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th i18n pFrozenColumn>
      </th>
      <th i18n>
      </th>
      <th i18n>
      </th>
      <th i18n>
      </th>
      <th>
      </th>
      <th colspan="2">
        🏬 Stock
      </th>
      <th>
        Total
      </th>
      <th [colSpan]="targetWarehouses()?.length">
        Warehouses
      </th>
    </tr>
    <tr style="vertical-align: bottom">
      <th i18n [pSortableColumn]="sortFieldName('ARTICLE')" pFrozenColumn>
        Article
        <p-sortIcon [field]="sortFieldName('ARTICLE')"/>
      </th>
      <th i18n [pSortableColumn]="sortFieldName('SUPPLIER_ID')">
        Supplier
        <p-sortIcon [field]="sortFieldName('SUPPLIER_ID')"/>
      </th>
      <th i18n [pSortableColumn]="sortFieldName('RECEPTION_ID')">
        Reception
        <p-sortIcon [field]="sortFieldName('RECEPTION_ID')"/>
      </th>
      <th i18n [pSortableColumn]="sortFieldName('DELIVER_BEFORE_DATE')">
        Deliver before
        <p-sortIcon [field]="sortFieldName('DELIVER_BEFORE_DATE')"/>
      </th>
      <th [pSortableColumn]="sortFieldName('FULL_DESCRIPTION')">
        Full description
        <p-sortIcon [field]="sortFieldName('FULL_DESCRIPTION')"/>
      </th>
      <th>
        Pallets
      </th>
      <th>
        🏋️
      </th>
      <th>
        Total
      </th>
      @for (targetWarehouse of targetWarehouses(); track targetWarehouse?.id) {
        <th>
          <foodbank-warehouse ngDefaultControl [ngModel]="targetWarehouse" [showDetails]="false" [showId]="true" [showCompany]="true"/>
          <p-button icon="pi pi-cloud-upload"
                    styleClass="p-button-primary"
                    (onClick)="transfer(targetWarehouse)"
          />
        </th>
      }
    </tr>
    <tr>
      <th pFrozenColumn>
        <p-columnFilter field="article" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-selection ngDefaultControl [(ngModel)]="articleFilter" [articleSearch]="articleSearch()"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="article" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-supplier-selection ngDefaultControl [(ngModel)]="supplierFilter" [supplierSearch]="supplierSearch()"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
      </th>
      <th>
        <p-columnFilter field="exactDeliverBeforeDate" [showMenu]="false" style="width: 15em; display: inline-block">
          <ng-template pTemplate="filter">
            <foodbank-date-search
              ngDefaultControl
              [(ngModel)]="deliverBeforeDateSearch"
              [required]="false"
              [showButtonBar]="true"
            />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter [showMenu]="false">
          <ng-template pTemplate="filter">
            <input ngDefaultControl pInputText [(ngModel)]="fullDescriptionFilter"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
      </th>
      <th>
      </th>
      <th>
      </th>
      @for (targetWarehouse of targetWarehouses(); track targetWarehouse?.id) {
        <th>
        </th>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    @let transferRequestItemGroup = typing(row);
    @let stockGroup = transferRequestItemGroup?.stockGroup?.value();
    @if (transferRequestItemGroup && stockGroup) {
      <tr>
        <td pFrozenColumn>
          <foodbank-article ngDefaultControl [ngModel]="stockGroup.article.value()"/>
        </td>
        <td>
          <foodbank-supplier ngDefaultControl [ngModel]="stockGroup.supplier.value()"/>
        </td>
        <td>
          {{ stockGroup.reception.value()?.id }}
        </td>
        <td>
          {{ stockGroup.deliverBeforeDate | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ stockGroup.fullDescription }}
        </td>
        <td>
          {{ transferRequestItemGroup.numberOfPallets }}
        </td>
        <td>
          {{ transferRequestItemGroup.totalWeight }}
        </td>
        <td>
          <span
            [class.toomuch]="(transferRequestItemGroup.total() ?? 0) > transferRequestItemGroup.numberOfPallets"
            [class.exact]="transferRequestItemGroup.total()  === transferRequestItemGroup.numberOfPallets"
          >
          {{ transferRequestItemGroup.total() }}
          </span>
        </td>
        @let warehouseTransferRequestItemMap = transferRequestItemGroup.warehouseTransferRequestItemMap();
        @for (targetWarehouse of targetWarehouses(); track targetWarehouse.id) {
          <td>
            @let transferRequestItem = warehouseTransferRequestItemMap.get(targetWarehouse.id);
            @if (transferRequestItem) {
              <p-inputNumber [ngModel]="transferRequestItem.palletQuantity" (ngModelChange)="updatePalletQuantity(transferRequestItem, $event)" class="pallet-quantity-edit" [ngModelOptions]="{updateOn: 'blur'}" [size]="3" [minFractionDigits]="0"  [maxFractionDigits]="0" [showButtons]="true" incrementButtonClass="p-button-xs p-button-text no-padding" decrementButtonClass="p-button-xs p-button-text no-padding"/>
            } @else {
              <p-inputNumber [ngModel]="null" min="0" (ngModelChange)="assignPalletQuantity(transferRequestItemGroup, targetWarehouse, $event)" class="pallet-quantity-edit" [ngModelOptions]="{updateOn: 'blur'}" [size]="3" [showButtons]="true" incrementButtonClass="p-button-xs p-button-text no-padding" decrementButtonClass="p-button-xs p-button-text no-padding"/>
            }
          </td>
        }
      </tr>
    } @else {
      <tr>
        <td colspan="100%">
          <span i18n>Loading transfer request item group ...</span>
        </td>
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <span i18n>No transfer requests found.</span>
  </ng-template>
</p-table>
