<p-dropdown [(ngModel)]="ngModel"
            [options]="organizationDistribMonths"
            optionLabel="month"
            placeholder="None"
            [required]="required()"
            (onClear)="clear()"
            [showClear]="true"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-organization-distrib-month ngDefaultControl [ngModel]="ngModel()" />
  </ng-template>
  <ng-template let-supplyMonth pTemplate="item">
    <foodbank-organization-distrib-month ngDefaultControl [ngModel]="supplyMonth" />
  </ng-template>
</p-dropdown>
