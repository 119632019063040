import {computed, inject, Injectable, Injector, Resource, resource, ResourceRef, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UserDto, UserRole, UserSearchDto} from '@typedefs/stock-rest';
import {map, Observable} from 'rxjs';
import {environment} from '@environments/environment';
import {UserS, UserSCreationBase} from '@model/user';
import {UserSearch} from "@model/search/user-search";
import {Pagination} from "@services/pagination";
import {AuthenticationService} from "@services/authentication.service";
import {rxResource} from "@angular/core/rxjs-interop";
import {FoodbankCacheFactory} from "@services/foodabank-cache-factory";
import {copyCommonFields} from "@model/mapping-utils";
import {derivedAsync} from "ngxtension/derived-async";
import {Company} from "@model/company";
import {Page} from "@typedefs/page";
import {MemberService} from "@services/member.service";
import {Organization} from "@model/organization";

@Injectable({
  providedIn: 'root'
})
export class UserSignalService {

  readonly #$currentUser: Resource<UserS>;

  readonly #httpClient = inject(HttpClient);
  readonly #authenticationService = inject(AuthenticationService);
  readonly #memberService = inject(MemberService);
  readonly #foodbankCacheFactory = inject(FoodbankCacheFactory);
  readonly #injector = inject(Injector);

  readonly currentUserCompany: Signal<Company | undefined>;
  readonly currentUserOrganization: Signal<Organization | undefined>;
  constructor() {
    const ready = derivedAsync(() => this.#authenticationService.isReady$());
    this.#$currentUser = rxResource({
      request: () => ready(),
      loader: () => this.#loadCurrentUserS()
    });

    this.currentUserCompany = computed(() => this.#$currentUser.value()?.company.value());
    this.currentUserOrganization = computed(() => this.#$currentUser.value()?.organization.value());
  }

  get $currentUser(): Signal<UserS | undefined> {
    return this.#$currentUser.value;
  }


  $userHasRole(role: UserRole): Signal<boolean> {
    return computed(() => {
      const $user = this.#$currentUser.value();
      return $user ? this.userHasRole($user, role) : false;
    });
  }

  $canAccessTransferRequests(): Signal<boolean> {
    return computed(() => {
      const $federationAdmin = this.$userHasRole('FEDERATION_ADMIN');
      const $extAdmin = this.$userHasRole('EXT_ADMIN');
      const currentUserCompany = this.currentUserCompany();
      const federation = currentUserCompany?.federation ?? false;
      return $federationAdmin() || ($extAdmin() && federation);
    })
  }

  $$getCurrentUserCompany(injector: Injector): ResourceRef<Company> {
    return resource({
      request: () => this.currentUserCompany()!,
      loader: param => Promise.resolve(param.request),
      injector: injector,
    });
  }
  $$getCurrentUserOrganization(injector: Injector): ResourceRef<Organization> {
    return resource({
      request: () => this.currentUserOrganization()!,
      loader: param => Promise.resolve(param.request),
      injector: injector,
    });
  }
  $$getCurrentUser(injector: Injector): ResourceRef<UserS> {
    return resource({
      request: () => this.#$currentUser.value()!,
      loader: param => Promise.resolve(param.request),
      injector: injector,
    });
  }

  #loadCurrentUserS(): Observable<UserS> {
    return this.#httpClient.get<UserDto>(`${environment.apiUrl}/users/me`).pipe(
      map(userDto => this.#mapToUser(userDto)),
    );
  }

  public userExists(id: String): Observable<boolean> {
    return this.#httpClient.get<boolean>(`${environment.apiUrl}/users/exists/${id}`);
  }

  public findUsers$(userSearch: UserSearch, injector: Injector, pagination: Pagination) {
    const userSearchDto = this.#mapToUserSearchDto(userSearch);
    return this.#httpClient.post<Page<UserDto>>(`${environment.apiUrl}/users/search`, userSearchDto, {params: pagination})
      .pipe(map(userPage => this.loadUserPage(userPage)));
  }
  public findAllUsers$(userSearch: UserSearch) : Observable< UserS[]> {
    const userSearchDto = this.#mapToUserSearchDto(userSearch);
    return this.#httpClient.post<UserDto[]>(`${environment.apiUrl}/users/searchAll`, userSearchDto)
      .pipe(map(userDtos => this.loadUserDetailsList(userDtos)));
  }

  public findUsersOfMember$(memberId: number) :Observable<UserS[]> {
    return this.#httpClient.get<UserDto[]>(`${environment.apiUrl}/users/member/${memberId}`)
      .pipe(map(userDtos => this.loadUserDetailsList(userDtos)));
  }

  private loadUserPage(userDtoPage: Page<UserDto>): Page<UserS> {
    return {
      ...userDtoPage,
      content: this.loadUserDetailsList(userDtoPage.content)
    };
  }

  public loadUserDetailsList(userDtos: UserDto[]) {
    return userDtos.map(user => this.#mapToUser(user));
  }

  save(user: UserS | UserSCreationBase,booIsCreate:boolean): Observable<UserS> {
    const userDto = this.#mapToUserDto(user);
    if (booIsCreate) {
      return this.#httpClient.post<UserDto>(`${environment.apiUrl}/users`, userDto)
        .pipe(map(userDto => this.#mapToUser(userDto)));
    } else {
      return this.#httpClient.put<UserDto>(`${environment.apiUrl}/users`, userDto)
        .pipe(map(userDto => this.#mapToUser(userDto)));
    }
  }

  deleteUser(user: UserS) {
    return this.#httpClient.delete(`${environment.apiUrl}/users/${user.id}`);
  }

  #mapToUser(userDto: UserDto): UserS {
    const commonFields: UserS | UserDto = copyCommonFields(userDto, ['warehouseId', 'companyId', 'organizationId', 'memberId', 'cpasId']);

    const cache = this.#foodbankCacheFactory.getLongLivedCache();

    return {
      ...commonFields,
      company: cache.companyCache.get(userDto.companyId),
      warehouse: cache.warehouseCache.getIfDefined(userDto.warehouseId),
      organization: cache.organizationCache.getIfDefined(userDto.organizationId),
      member: cache.memberCache.getIfDefined(userDto.memberId),
      cpas: cache.cpasCache.getIfDefined(userDto.cpasId)
    };
  }

  #mapToUserDto(user: UserS | UserSCreationBase): Partial<UserDto> {
    const commonFields: UserS | UserSCreationBase | UserDto = copyCommonFields(user, ['company', 'member', 'organization', 'warehouse', 'cpas']);

    return {
      ...commonFields,
      companyId: user.company.value()?.id!,
      memberId: user.member.value()?.id!,
      organizationId: user.organization.value()?.id!,
      warehouseId: user.warehouse.value()?.id!,
      cpasId: user.cpas.value()?.id!
    };
  }

  userHasRole($user: UserS, role: UserRole): boolean {
    return $user?.userRole === role;
  }

  #mapToUserSearchDto(userSearch: UserSearch): UserSearchDto {
    const userSearchDto: UserSearch | UserSearchDto = copyCommonFields(userSearch, ['company', 'organization', 'member', 'warehouse']);
    return {
      ...userSearchDto,
      companyId: userSearch.company?.id,
      organizationId: userSearch.organization?.id,
      memberId: userSearch.member?.id,
    }
  }
}
