import { inject, Injectable, Injector, Signal } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Article } from '@model/article';
import { Supplier } from '@model/supplier';
import { Stock } from '@model/stock';
import { Observable, of } from 'rxjs';
import { read, utils } from 'xlsx';
import { ArticleService } from './article.service';
import { StockService } from './stock.service';
import { switchMap, map } from 'rxjs/operators';
import { StockSearch } from '@model/search/stock-search';
import { FoodbankCacheFactory } from './foodabank-cache-factory';
import { MovementService } from './movement.service';
import { MovementType } from '@model/movement-type';
import { Movement } from '@model/movement';
import { Warehouse } from '@model/warehouse';
import { Organization } from '@model/organization';
import { BulkFoodImportMovementService } from './bulk-food-import-movement.service';
// Excel Column Mappings
export const COLUMN_MAPPINGS = {
  yearMonth: 'JaarMaand',
  supplierName: 'Naam',
  supplierId: 'Oorspong',
  organizationName: 'Naam',
  organizationId: 'FoodIT_Organisatie',
  postalCode: 'Postcode',
  city: 'Gemeente',
  productCode: 'Productcode',
  productId: 'FoodIT_Product',
  article: 'Artikel',
  unit: 'Eenheid',
  quantity: 'Sum_Aantalop Productcode'
};

export function createRowReceptionIdentifier(row: Partial<ReceptionImportRow>): string {
  return `${row.yearMonth}_${row.supplierId}_${row.productCode}`;
}

export function createRowExpeditionIdentifier(row: Partial<ExpeditionImportRow>): string {
  return `${row.yearMonth}_${row.organizationId}_${row.productCode}`;
}

export interface ImportRow<T = any> {
  rowNumber: number;
  rowIdentifier: string;
  yearMonth: string;
  postalCode: string;
  city: string;
  productCode: string;
  productId: string;
  article: string;
  unit: string;
  quantity: number;
  articleRef: ReturnType<typeof rxResource<Article | undefined, Error>>;
  stockRef: ReturnType<typeof rxResource<Stock | undefined, Error>>;
  movementRef: ReturnType<typeof rxResource<Movement | undefined, Error>>;
  movementType: MovementType;
  isModified: boolean;
  isDuplicate: boolean;
  isValid: (row: T, duplicates: Set<string>) => Signal<boolean | undefined>;
}

export interface ReceptionImportRow extends ImportRow<ReceptionImportRow> {
  supplierName: string;
  supplierId: string;
  supplierRef: ReturnType<typeof rxResource<Supplier | undefined, Error>>;
}

export interface ExpeditionImportRow extends ImportRow<ExpeditionImportRow> {
  organizationName: string;
  organizationId: string;
  organizationRef: ReturnType<typeof rxResource<Organization | undefined, Error>>;
}

interface ExcelRow {
  __rowNum__: number;
  [key: string]: any;
}


export interface ImportService<T extends ImportRow> {
  findDuplicateRowIdentifiers(rows: T[]): Set<string>;
  importExcelFile(file: File, warehouse: Warehouse, movementType: MovementType, injector: Injector): Promise<T[]>;
  mapImportRowResources(item: any, injector: Injector): T;
  loadImportMovements(yearMonth: string, warehouse: Warehouse, movementType: MovementType, injector: Injector): void;
}


@Injectable()
export abstract class BulkFoodImportService<T extends ImportRow<T>> implements ImportService<T> {
  protected readonly articleService = inject(ArticleService);
  protected readonly stockService = inject(StockService);
  protected readonly foodbankCacheFactory = inject(FoodbankCacheFactory);
  protected readonly movementService = inject(MovementService);
  protected readonly bulkFoodImportMovementService = inject(BulkFoodImportMovementService);
  
  constructor() { }
  
  loadImportMovements(yearMonth: string, warehouse: Warehouse, movementType: MovementType, injector: Injector): void {
    this.bulkFoodImportMovementService.importMovementsForImport(this.computeBatchName(yearMonth), warehouse, movementType, injector);
  }

  abstract mapImportRowResources(item: any, injector: Injector): T;
  protected abstract mapExcelToImportRows(data: ExcelRow[], warehouse: Warehouse, movementType: MovementType, injector: Injector): T[];

  findDuplicateRowIdentifiers(data: ImportRow[]): Set<string> {
    const rowIdentifiers = new Set<string>();
    const duplicates = new Set<string>();

    data.forEach(row => {
      if (row.rowIdentifier) {
        if (rowIdentifiers.has(row.rowIdentifier)) {
          duplicates.add(row.rowIdentifier);
        } else {
          rowIdentifiers.add(row.rowIdentifier);
        }
      }
    });
    return duplicates;
  }

  async importExcelFile(file: File, warehouse: Warehouse, movementType: MovementType, injector: Injector): Promise<T[]> {
    const excelRows = await this.readExcel(file);
    return this.mapExcelToImportRows(excelRows, warehouse, movementType, injector);
  }

  computeBatchName(yearMonth: string): string {
    return `RAMASSE ${yearMonth}`;
  }

  loadStock$(productId: string, injector: Injector, cache = this.foodbankCacheFactory.create(injector)): Observable<Stock | undefined> {
    if (!productId) return of(undefined);
    return this.articleService.getArticle$(productId).pipe(
      switchMap(article => {
        if (!article) return of(undefined);
        const stockSearch: StockSearch = {
          articleSearch: {
            articles: [article]
          }
        };
        return this.stockService.findStock(stockSearch, { page: 0, size: 1 }, injector).pipe(
          map(stockPage => stockPage.content[0])
        );
      })
    );
  }

  protected async readExcel(file: File): Promise<ExcelRow[]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        try {
          const bufferArray = e.target?.result;
          const wb = read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          const data = utils.sheet_to_json(ws);
          resolve(data.map((row: any, index: number) => ({ ...row, __rowNum__: index + 1 })));
        } catch (error) {
          reject(error);
        }
      };
    });
  }

  protected cleanString(str: string | undefined): string {
    if (!str) return '';
    if (typeof str !== 'string') return String(str);
    // Replace all whitespace characters (spaces, tabs, newlines, carriage returns) with a single space and trim
    return str.replace(/[\s\r\n]+/g, ' ').trim();
  }
} 